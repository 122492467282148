import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  PageHeader,
  Row,
  Select,
  Space,
  message,
} from "antd";
import React, { useState } from "react";

import { ContaBancariaService } from "../../../services/conta-bancaria/conta-bancaria.service";
import CurrencyInput from "react-currency-input-field";
import { EmpresaService } from "../../../services/empresa/empresas.service";
import { NovaContaBancariaDto } from "../../../services/conta-bancaria/dto/nova-conta-bancaria.dto";
import c from "currency-formatter";
import { useHistory } from "react-router-dom";

export default function NovaContaBancaria({ visible, setVisible }) {
  const [formRef] = Form.useForm();
  const _empresaService = new EmpresaService();
  const _contaBancariaService = new ContaBancariaService();
  const history = useHistory();
  const [optionsEmpresa, setOptionsEmpresa] = useState([]);
  const [optionsEmpresaSearch, setOptionsEmpresaSearch] = useState(false);
  const [optionsBancos, setOptionsBancos] = useState([]);
  const [optionsBancosSearch, setOptionsBancosSearch] = useState(false);
  async function cadastrarContaBancariaHandler() {
    try {
      const {
        descricao,
        agencia,
        conta,
        banco,
        empresa,
        limite = 0,
        saldo = 0,
      } = formRef.getFieldsValue([
        "descricao",
        "agencia",
        "conta",
        "banco",
        "empresa",
        "limite",
        "saldo",
      ]);

      const contaBancaria: NovaContaBancariaDto = {
        descricao,
        agencia,
        conta,
        banco: { id: banco },
        empresa: { id: empresa },
        limite,
        saldo,
      };

      await _contaBancariaService.criarContaBancaria(contaBancaria);

      message.success("Conta bancária Cadastrada");
      setVisible(false);
    } catch (error) {
      if (error?.response?.data?.message && typeof Array) {
        for (const e of error?.response?.data?.message.toString().split(",")) {
          message.error(e);
        }
      } else {
        message.error(error?.message);
      }
    }
  }

  return (
    <Drawer
      visible={visible}
      width={1000}
      closable={false}
      bodyStyle={{ padding: 5 }}
      onClose={() => setVisible(false)}
    >
      <PageHeader
        className="site-page-header"
        onBack={() => {
          setVisible(false);
          history.push("contas-bancarias");
        }}
        title="Nova conta bancária"
        subTitle="Cadastrar Conta bancária"
        style={{ marginBottom: 5 }}
      />

      <Card title="Informações da Conta Bancária" bodyStyle={{ padding: 5 }}>
        <Form
          form={formRef}
          onFinish={() => cadastrarContaBancariaHandler()}
          layout="vertical"
        >
          <Card title="Geral" type="inner">
            <Row>
              <Col span={24}>
                <Input.Group compact>
                  <Form.Item
                    rules={[{ required: true, message: "Informe a descrição" }]}
                    name="descricao"
                    label="Descrição"
                    style={{ width: "50%", paddingRight: 5 }}
                  >
                    <Input placeholder="Ex: Conta Bradesco" />
                  </Form.Item>

                  <Form.Item
                    rules={[{ required: true, message: "Informe o valor" }]}
                    label="Saldo Inicial"
                    style={{ width: "25%", paddingRight: 5 }}
                  >
                    <CurrencyInput
                      className="ant-input ant-input-sm"
                      decimalScale={2}
                      defaultValue={0}
                      onValueChange={(value = "0") => {
                        formRef.setFieldsValue({
                          saldo: c.unformat(value, { code: "BRL" }),
                        });
                      }}
                      intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                    />
                  </Form.Item>

                  <Form.Item
                    rules={[{ required: true, message: "Informe o valor" }]}
                    label="Limite"
                    style={{ width: "25%" }}
                  >
                    <CurrencyInput
                      className="ant-input ant-input-sm"
                      decimalScale={2}
                      defaultValue={0}
                      onValueChange={(value = "0") => {
                        formRef.setFieldsValue({
                          limite: c.unformat(value, { code: "BRL" }),
                        });
                      }}
                      intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                    />
                  </Form.Item>
                </Input.Group>
              </Col>
              <Col span={24}>
                <Input.Group compact>
                  <Form.Item
                    label="Banco"
                    rules={[
                      {
                        required: true,
                        message: "Banco é Obrigatório",
                      },
                    ]}
                    name="banco"
                    style={{ width: "25%", paddingRight: "5px" }}
                  >
                    <Select
                      placeholder="Selecione o Banco"
                      options={optionsBancos}
                      showSearch
                      loading={optionsBancosSearch}
                      filterOption={(input, option) =>
                        option?.label
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onSearch={async (value) => {
                        if (value.length >= 3) {
                          setOptionsBancosSearch(true);
                          const result =
                            await _contaBancariaService.obterBancos(value);
                          const opts = result?.data?.data.map((o) => {
                            return {
                              key: o.id,
                              value: o.id,
                              label: o.nome,
                            };
                          });
                          setOptionsBancos(opts);
                          setOptionsBancosSearch(false);
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Agência"
                    name="agencia"
                    style={{ width: "12.5%", paddingRight: 5 }}
                    rules={[
                      {
                        required: true,
                        message: "Agência é obrigatório",
                      },
                    ]}
                  >
                    <Input placeholder="Agência" />
                  </Form.Item>
                  <Form.Item
                    label="Conta"
                    rules={[
                      {
                        required: true,
                        message: "Conta é obrigatório",
                      },
                    ]}
                    name="conta"
                    style={{ width: "12.5%", paddingRight: 5 }}
                  >
                    <Input placeholder="Conta" />
                  </Form.Item>
                  <Form.Item
                    style={{ width: "50%" }}
                    name="empresa"
                    label="Empresa"
                    rules={[{ required: true, message: "Selecione a empresa" }]}
                  >
                    <Select
                      placeholder="Selecione a empresa"
                      showSearch
                      options={optionsEmpresa}
                      loading={optionsEmpresaSearch}
                      filterOption={(input, option) =>
                        option?.label
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onSearch={async (value) => {
                        if (value.length >= 3) {
                          setOptionsEmpresaSearch(true);
                          const result =
                            await _empresaService.obterEmpresasSimplificado(
                              value
                            );
                          const opts = result?.data?.data.map((o) => {
                            return {
                              key: o.id,
                              value: o.id,
                              label: o.nomeFantasia,
                            };
                          });
                          setOptionsEmpresa(opts);
                          setOptionsEmpresaSearch(false);
                        }
                      }}
                    />
                  </Form.Item>
                </Input.Group>
              </Col>
            </Row>
          </Card>

          <Row>
            <Col span={24} style={{ marginTop: "10px", textAlign: "end" }}>
              <Space>
                <Button type="primary" size="middle" htmlType="submit">
                  SALVAR
                </Button>
                <Button
                  type="ghost"
                  size="middle"
                  onClick={() => {
                    setVisible(false);
                    formRef.resetFields();
                  }}
                >
                  CANCELAR
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Card>
    </Drawer>
  );
}
