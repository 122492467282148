import { Footer } from "antd/lib/layout/layout";
import React from "react";
import packageJson from "../../package.json";

export default function FooterDefault() {
  return (
    <Footer className="footer">
      <div>
        <p
          style={{ margin: 0, padding: 0 }}
        >{`Copyright © ${new Date().getFullYear()} Tração Live Marketing - Todos os direitos reservados`}</p>
        <p
          style={{
            fontSize: 10,
            fontWeight: 100,
            margin: 0,
            padding: 0,
            textTransform: "lowercase",
          }}
        >{`v${packageJson.version}`}</p>
      </div>
    </Footer>
  );
}
