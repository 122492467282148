export enum TipoNf {
  NotaFiscal = 0,
  NotaDeDebito = 1,
  SemNotaFiscal = 2,
}

export class TipoNfInfoItem {
  key: TipoNf;
  value: TipoNf;
  label: string;
  color: string;
}

export const TipoNfInfo: {
  [key in TipoNf]: TipoNfInfoItem;
} = {
  [TipoNf.NotaFiscal]: {
    key: TipoNf.NotaFiscal,
    value: TipoNf.NotaFiscal,
    label: "NOTA FISCAL",
    color: "darkblue",
  },
  [TipoNf.NotaDeDebito]: {
    key: TipoNf.NotaDeDebito,
    value: TipoNf.NotaDeDebito,
    label: "NOTA DE DÉBITO",
    color: "darkblue",
  },
  [TipoNf.SemNotaFiscal]: {
    key: TipoNf.SemNotaFiscal,
    value: TipoNf.SemNotaFiscal,
    label: "SEM NOTA FISCAL",
    color: "darkblue",
  },
};

function enumToArray(enumerator: any) {
  const list = [];
  for (let index = 0; index < Object.values(enumerator).length; index++) {
    const obj = Object.values(enumerator)[index];
    list.push(obj);
  }
  return list;
}

export const tipoNfList = enumToArray(TipoNfInfo);
