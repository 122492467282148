import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Modal,
  PageHeader,
  Select,
  Table,
  Tag,
  Tooltip,
  message,
} from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  PlusCircleOutlined,
  QuestionCircleTwoTone,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import {
  StatusRequisicaoCompra,
  statusRequisicaoDeCompraList,
} from "../../../core/enums/status-requisicao-de-compra.enum";
import { default as c, default as currencyFormatter } from "currency-formatter";

import AprovarRequisicaoDeComprarModal from "../../financeiro/pedidos-de-compra/aprovacao-requisicao-de-compra.modal";
import { AtualizarRequisicaoDeCompraDto } from "../../../services/requisicao-de-compra/dto/atualizar-requisica-de-compra.dto";
import { ColaboradorService } from "../../../services/colaborador/colaborador.service";
import { ContaAPagarService } from "../../../services/conta-a-pagar/conta-a-pagar.service";
import CurrencyInput from "react-currency-input-field";
import { Endpoints } from "../../../core/constants/endpoints";
import { NumeroDeParcelasModal } from "../../../components/numero-parcelas.modal.component";
import { ReprovarRequisicaoDeCompraDto } from "../../../services/requisicao-de-compra/dto/reprovar-requisicao-de-compra.dto";
import { RequisicaoDeCompraService } from "../../../services/requisicao-de-compra/requisicao-de-compra.service";
import { StatusParcelaDescricao } from "../../../core/enums/status-parcela.enum";
import { cepFormatter } from "../../../core/utils/formatters/cep.formatter";
import { identificadorFormatter } from "../../../core/utils/formatters/job.formatter";
import moment from "moment";
import { v4 as newId } from "uuid";
import { sequencialRequisicaoDeCompraFormatter } from "../../../core/utils/formatters/sequencial.formatter";
import useBuscarEmpresaHook from "../../../components/hooks/buscar-empresa.hook";
import useBuscarFormaDePagamentoHook from "../../../components/hooks/buscar-forma-de-pagamento.hook";
import useBuscarFornecedorHook from "../../../components/hooks/buscar-fornecedor.hook";
import useBuscarJobHook from "../../../components/hooks/buscar-job.hook";
import useBuscarPrazoDePagamentoHook from "../../../components/hooks/buscar-prazo-de-pagamento.hook";
import useSWR from "swr";
import useTitle from "../../../core/hooks/title.hook";

const cep = require("cep-promise");
export default function RequisicaoDeCompraModal({
  requisicaoId,
  setRequisicaoId,
  status,
}) {
  //#region States and Variables

  useState(false);
  const [modalAprovacao, setModalAprovacao] = useState(false);
  const [modalReprovacao, setModalReprovacao] = useState(false);
  const [modalNumeroDeParcelas, setModalNumeroDeParcelas] = useState(false);
  const [diaPagamentoPadrao, setDiaPagamentoPadrao] = useState(true);

  const { data: { data: { data: requisicaoDeCompra } = {} } = {}, error } =
    useSWR(
      requisicaoId ? Endpoints.requisicoesDeCompra + requisicaoId : null,
      () => _requisicaoDeCompraService.obterPorId(requisicaoId),
      { revalidateOnFocus: false, revalidateOnMount: true }
    );

  //#endregion

  useTitle(`Requisição de compra ${requisicaoDeCompra?.sequencial}`);
  const [parcelas, setParcelas] = useState([]);
  const _colaboradorService = new ColaboradorService();

  const _contaAPagarService = new ContaAPagarService();

  const _requisicaoDeCompraService = new RequisicaoDeCompraService();

  const [formRef] = Form.useForm();
  const [calcularPrazo, setCalcularPrazo] = useState(Date.now());
  const [itemSelecionado, setItemSelecionado] = useState(null);
  const [itens, setItens] = useState([]);
  const [inicializarForm, setInicializarForm] = useState(true);
  const [optionsSolicitante, setOptionsSolicitante] = useState([]);
  const [optionsSolicitanteSearch, setOptionsSolicitanteSearch] =
    useState(false);

  const {
    options: optionsFornecedor,
    buscarFornecedor,
    loading: optionsFornecedorSearch,
    filterOptions: filterOptsFornecedor,
  } = useBuscarFornecedorHook();

  const {
    options: optionsPrazoPagamento,
    buscarPrazoDePagamento,
    loading: loadingPrazoDePagamento,
  } = useBuscarPrazoDePagamentoHook();

  const {
    options: optionsFormaDePagamento,
    buscarFormaDePagamento,
    loading: loadingFormasDePagamento,
  } = useBuscarFormaDePagamentoHook();

  const {
    options: optionsJob,
    setOptions: setOptionsJob,
    loading: optionsJobSearch,
    filterOption: filterOptsJobs,
    buscarJob,
  } = useBuscarJobHook();

  const {
    options: optionsEmpresa,

    loading: optionsEmpresaSearch,
    buscarEmpresa,
    filterOptions: filterOptionsEmpresa,
  } = useBuscarEmpresaHook();

  useEffect(() => {
    if (requisicaoId != null && inicializarForm) {
      formRef.setFieldsValue({
        requisicao: {
          solicitante: requisicaoDeCompra?.solicitante?.id,
          job: requisicaoDeCompra?.job?.id,
          empresa: requisicaoDeCompra?.empresa?.id,
          fornecedor: requisicaoDeCompra?.fornecedor?.id,
          dataDeEntrega: moment(requisicaoDeCompra?.dataDeEntrega),
          dataDePagamento: moment(requisicaoDeCompra?.dataDePagamento),
          prazoDePagamento: requisicaoDeCompra?.prazoDePagamento?.id,
          formaDePagamento: requisicaoDeCompra?.formaDePagamento?.id,
        },
        enderecoDeEntrega: {
          ...requisicaoDeCompra?.enderecoDeEntrega,
          cep: cepFormatter(requisicaoDeCompra?.enderecoDeEntrega?.cep),
        },
      });

      setItens(requisicaoDeCompra?.itens);
      buscarFornecedor(requisicaoDeCompra?.fornecedor?.nomeFantasia);
      setOptionsSolicitante([
        {
          key: requisicaoDeCompra?.solicitante?.id,
          value: requisicaoDeCompra?.solicitante?.id,
          label: requisicaoDeCompra?.solicitante?.nome,
        },
      ]);
      if (requisicaoDeCompra?.condicaoDePagamento?.numeroDeParcelas > 1)
        setDiaPagamentoPadrao(false);
      setInicializarForm(false);
    }

    if (itens?.length > 0 && requisicaoId != null) {
      const {
        dataDePagamento,
        numeroDeParcelas,
        diasEntreParcelas,
        diasParaPrimeiraParcela,
      } = formRef.getFieldValue("requisicao");

      _contaAPagarService
        .gerarParcelamento({
          valor: calcularTotal(),
          prazo: {
            id: requisicaoDeCompra?.prazoDePagamento,
            numeroDeParcelas:
              numeroDeParcelas >= 0
                ? numeroDeParcelas
                : requisicaoDeCompra?.condicaoDePagamento?.numeroDeParcelas,
            diasEntreParcelas:
              diasEntreParcelas >= 0
                ? diasEntreParcelas
                : requisicaoDeCompra?.condicaoDePagamento?.diasEntreParcelas,
            diasParaPrimeiraParcela:
              diasParaPrimeiraParcela >= 0
                ? diasParaPrimeiraParcela
                : requisicaoDeCompra?.condicaoDePagamento
                    ?.diasParaPrimeiraParcela,
          },
          descricao: `PGTO FORNECEDOR ${
            requisicaoDeCompra?.fornecedor?.nomeFantasia
          } JOB ${identificadorFormatter(
            requisicaoDeCompra?.job?.identificador
          )}`,
          dataPrimeiroVencimento: dataDePagamento.toString(),
          diaPagamentoPadrao,
        })
        .then(({ data: { data: pItens } }) => setParcelas(pItens));
    }
  }, [requisicaoDeCompra, itens, calcularPrazo, inicializarForm]);

  async function atualizarRequisicaoDeCompra() {
    try {
      const { requisicao, enderecoDeEntrega } = formRef.getFieldsValue([
        "enderecoDeEntrega",
        "requisicao",
      ]);

      const dto: AtualizarRequisicaoDeCompraDto = {
        id: requisicaoId,
        observacao: requisicao?.observacao,
        solicitante: { id: requisicao.solicitante },
        formaDePagamento: { id: requisicao.formaDePagamento },
        prazoDePagamento: {
          id: requisicao.prazoDePagamento,
          numeroDeParcelas:
            requisicao?.numeroDeParcelas >= 0
              ? requisicao?.numeroDeParcelas
              : requisicaoDeCompra?.condicaoDePagamento?.numeroDeParcelas,
          diasEntreParcelas:
            requisicao?.diasEntreParcelas >= 0
              ? requisicao?.diasEntreParcelas
              : requisicaoDeCompra?.condicaoDePagamento?.diasEntreParcelas,
          diasParaPrimeiraParcela:
            requisicao?.diasParaPrimeiraParcela >= 0
              ? requisicao?.diasParaPrimeiraParcela
              : requisicaoDeCompra?.condicaoDePagamento
                  ?.diasParaPrimeiraParcela,
        },
        empresa: { id: requisicao.empresa },
        fornecedor: { id: requisicao.fornecedor },
        job: { id: requisicao.job },
        dataDeEntrega: requisicao?.dataDeEntrega?.toString(),
        dataDePagamento: requisicao?.dataDePagamento?.toString(),
        itens: itens.map((i) => {
          return {
            id: i.id,
            valor: i.valor,
            quantidade: i.quantidade,
            descricao: i.descricao,
          };
        }),
        enderecoDeEntrega: {
          id: requisicaoDeCompra?.enderecoDeEntrega?.id,
          ...enderecoDeEntrega,
          pais: "BR",
          cep: enderecoDeEntrega?.cep.replace(/[^\d]+/g, ""),
        },
      };

      await _requisicaoDeCompraService.atualizarNovaRequisicao(
        requisicaoId,
        dto
      );
    } catch (error) {
      if (error?.response?.data?.message && typeof Array) {
        for (const e of error?.response?.data?.message.toString().split(",")) {
          message.error(e);
        }
      } else {
        message.error(error?.message);
      }
    }
  }

  function AdicionarItemForm() {
    const [itemFormRef] = Form.useForm();

    if (itemSelecionado) {
      itemFormRef.setFieldsValue(itemSelecionado);
    }
    async function adicionarItem() {
      const item = itemFormRef.getFieldsValue([
        "id",
        "descricao",
        "valor",
        "quantidade",
      ]);
      await itemFormRef.validateFields();
      if (itemSelecionado) {
        item.valor = currencyFormatter.unformat(item.valor, {
          code: "BRL",
        });
        const itensNew = itens.filter((i) => i.id !== itemSelecionado.id);
        itensNew.push(item);
        setItens(itensNew);
        setItemSelecionado(null);
      } else {
        setItens([...itens, item]);
      }
    }

    return (
      <div
        title="Adicionar Item"
        style={{
          marginBottom: -20,
        }}
      >
        <Form
          form={itemFormRef}
          layout="horizontal"
          onFinish={() => adicionarItem()}
        >
          <div
            style={{ display: itemSelecionado ? "flex" : "none" }}
            onKeyPress={(e) => {
              if (e.key === "Enter" || e.key === "Tab") {
                itemFormRef.submit();
              }
            }}
          >
            <Input.Group compact>
              <Form.Item hidden initialValue={newId()} name="id" />
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
                name="descricao"
                style={{ width: "70%", paddingRight: 5 }}
              >
                <Input placeholder="Descrição" />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
                style={{ paddingRight: 5, width: "15%" }}
              >
                <CurrencyInput
                  name="valor"
                  defaultValue={itemFormRef.getFieldValue("valor")}
                  placeholder="Valor Unitário"
                  className="ant-input ant-input-sm"
                  decimalScale={2}
                  onValueChange={(value = "0") => {
                    itemFormRef.setFieldsValue({
                      valor: currencyFormatter.unformat(value, { code: "BRL" }),
                    });
                  }}
                  intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                />
              </Form.Item>
              <Form.Item
                style={{ width: "15%" }}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
                name="quantidade"
              >
                <Input placeholder="Quantidade" type="number" />
              </Form.Item>
            </Input.Group>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={() => itemFormRef.submit()}
            >
              atualizar Item
            </Button>
          </div>
        </Form>
      </div>
    );
  }

  function calcularTotal() {
    let total = 0;
    if (itens) {
      for (const item of itens) {
        total += Number(item.quantidade) * Number(item.valor);
      }
    }
    return total;
  }

  async function reprovarPedidoDeCompraHandler(descricao: string) {
    try {
      const dto: ReprovarRequisicaoDeCompraDto = {
        id: requisicaoId,
        observacao: {
          descricao,
        },
      };
      await _requisicaoDeCompraService.reprovarRequisicao(requisicaoId, dto);
      message.success(
        "Reprovação registrada, o responsável será notificado para efetuar as alterações"
      );
      setRequisicaoId(null);
    } catch (error) {
      if (error?.response?.data?.message && typeof Array) {
        for (const e of error?.response?.data?.message.toString().split(",")) {
          message.error(e);
        }
      } else {
        message.error(error?.message);
      }
    }
  }

  function ReprovarPedidoModal() {
    const [reprovacaoRef] = Form.useForm();
    return (
      <Modal
        visible={modalReprovacao}
        closable={false}
        okText="CONFIRMAR"
        destroyOnClose
        cancelText="CANCELAR"
        onCancel={() => {
          setModalReprovacao(false);
        }}
        onOk={async () => {
          await reprovacaoRef.validateFields();
          reprovacaoRef.submit();
          setModalReprovacao(false);
        }}
      >
        <Form
          form={reprovacaoRef}
          layout="vertical"
          onFinish={({ descricao }) => reprovarPedidoDeCompraHandler(descricao)}
        >
          <Form.Item
            name="descricao"
            label="Motivo"
            rules={[
              { message: "Motivo da reprovação é obrigatório", required: true },
            ]}
          >
            <Input.TextArea
              placeholder="Informe o motivo da reprovação"
              rows={4}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  }

  if (error) message.error(error?.message);

  return requisicaoDeCompra ? (
    <Modal
      footer={
        <div style={{ padding: 5 }}>
          <Button
            disabled={status !== StatusRequisicaoCompra.EnviadoAoFinanceiro}
            icon={<CloseCircleOutlined />}
            style={{ marginRight: 5 }}
            size="middle"
            className="btn-danger"
            type="primary"
            onClick={() => setModalReprovacao(true)}
          >
            REPROVAR
          </Button>
          <Button
            disabled={status !== StatusRequisicaoCompra.EnviadoAoFinanceiro}
            onClick={async () => {
              await atualizarRequisicaoDeCompra();
              setModalAprovacao(true);
            }}
            className="btn-success"
            type="primary"
            size="middle"
            icon={<CheckCircleOutlined />}
          >
            APROVAR
          </Button>
        </div>
      }
      visible={requisicaoId != null}
      width={1250}
      onCancel={() => {
        setRequisicaoId(null);
        setInicializarForm(true);
      }}
      closable={false}
      destroyOnClose
      bodyStyle={{ padding: 0 }}
    >
      <ReprovarPedidoModal />
      <AprovarRequisicaoDeComprarModal
        requisicaoId={requisicaoId}
        defaultEmail={requisicaoDeCompra?.fornecedor?.email}
        visible={modalAprovacao}
        setVisible={setModalAprovacao}
        setRequisicaoId={setRequisicaoId}
      />
      <NumeroDeParcelasModal
        setVisible={setModalNumeroDeParcelas}
        visible={modalNumeroDeParcelas}
        formRef={formRef}
        contexto={"requisicao"}
      />
      <div>
        <PageHeader
          className="site-page-header"
          title={`${sequencialRequisicaoDeCompraFormatter(
            String(requisicaoDeCompra?.sequencial)
          )}`}
        />

        <Form
          layout="vertical"
          form={formRef}
          onFinish={() => atualizarRequisicaoDeCompra()}
        >
          <Card title="Geral">
            <Input.Group compact>
              <Form.Item
                initialValue={requisicaoDeCompra?.solicitante?.id}
                label="Solicitante"
                rules={[
                  { required: true, message: "Solicitante é obrigatório" },
                ]}
                name={["requisicao", "solicitante"]}
                style={{ width: "25%", paddingRight: 5 }}
              >
                <Select
                  disabled
                  placeholder="Selecione o solicitante"
                  showSearch
                  options={optionsSolicitante}
                  loading={optionsSolicitanteSearch}
                  filterOption={(input, option) =>
                    option?.label
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSearch={async (value) => {
                    if (value.length >= 3) {
                      setOptionsSolicitanteSearch(true);
                      const result =
                        await _colaboradorService.obterSimplificado(value);
                      const opts = result?.data?.data.map((o) => {
                        return {
                          key: o.id,
                          value: o.id,
                          label: o.nome,
                        };
                      });
                      setOptionsSolicitante(opts);
                      setOptionsSolicitanteSearch(false);
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Job"
                initialValue={requisicaoDeCompra?.job?.id}
                rules={[{ required: true, message: "Job é obrigatório" }]}
                name={["requisicao", "job"]}
                style={{ width: "25%", paddingRight: 5 }}
              >
                <Select
                  disabled={true}
                  placeholder="Selecione o Job"
                  showSearch
                  options={optionsJob}
                  loading={optionsJobSearch}
                  filterOption={filterOptsJobs}
                  onSearch={buscarJob}
                />
              </Form.Item>
              <Form.Item
                initialValue={requisicaoDeCompra?.empresa?.id}
                label="Empresa contratante"
                rules={[
                  {
                    required: true,
                    message: "Empresa é obrigatório",
                  },
                ]}
                name={["requisicao", "empresa"]}
                style={{ width: "25%", paddingRight: 5 }}
              >
                <Select
                  placeholder="Selecione a empresa"
                  showSearch
                  options={optionsEmpresa}
                  loading={optionsEmpresaSearch}
                  filterOption={filterOptionsEmpresa}
                  onSearch={buscarEmpresa}
                />
              </Form.Item>
              <Form.Item
                label="Status"
                name="status"
                style={{ width: "25%" }}
                initialValue={requisicaoDeCompra?.status}
              >
                <Select
                  placeholder="Selecione o Status"
                  disabled
                  options={statusRequisicaoDeCompraList}
                />
              </Form.Item>
              <Form.Item
                initialValue={requisicaoDeCompra?.fornecedor?.id}
                label="Fornecedor"
                rules={[
                  { required: true, message: "Fornecedor é obrigatório" },
                ]}
                name={["requisicao", "fornecedor"]}
                style={{ width: "100%", paddingRight: 5 }}
              >
                <Select
                  placeholder="Selecione a fornecedor"
                  showSearch
                  options={optionsFornecedor}
                  loading={optionsFornecedorSearch}
                  filterOption={filterOptsFornecedor}
                  onSearch={buscarFornecedor}
                />
              </Form.Item>
              <Form.Item
                initialValue={moment(requisicaoDeCompra?.dataDeEntrega)}
                label="data de entrega"
                rules={[
                  {
                    required: true,
                    message: "Data de entrega é obrigatório",
                  },
                ]}
                name={["requisicao", "dataDeEntrega"]}
                style={{ width: "20%", paddingRight: 5 }}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  format="DD/MM/YYYY"
                  onSelect={() => {
                    setDiaPagamentoPadrao(false);
                    setCalcularPrazo(Date.now());
                  }}
                />
              </Form.Item>

              <Form.Item
                initialValue={requisicaoDeCompra?.prazoDePagamento?.id}
                label="Prazo de Pagamento"
                rules={[
                  {
                    required: true,
                    message: "Prazo de Pagamento é obrigatório",
                  },
                ]}
                name={["requisicao", "prazoDePagamento"]}
                style={{ width: "30%", paddingRight: 5 }}
              >
                <Select
                  placeholder="Selecione o prazo de pagamento"
                  showSearch
                  onSelect={(value, opt: any) => {
                    setDiaPagamentoPadrao(false);
                    if (opt?.solicitarParcelamento) {
                      setModalNumeroDeParcelas(true);
                    } else {
                      formRef.setFieldsValue({
                        requisicao: {
                          numeroDeParcelas: opt?.numeroDeParcelas,
                          diasEntreParcelas: opt?.diasEntreParcelas,
                          diasParaPrimeiraParcela: opt?.diasParaPrimeiraParcela,
                        },
                      });
                      setCalcularPrazo(Date.now());
                    }
                  }}
                  options={optionsPrazoPagamento}
                  loading={loadingPrazoDePagamento}
                  filterOption={(input, option) =>
                    option?.label
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSearch={buscarPrazoDePagamento}
                />
              </Form.Item>
              <div style={{ width: "20%", paddingRight: 5 }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Form.Item
                    style={{ width: "100%" }}
                    initialValue={moment(requisicaoDeCompra?.dataDePagamento)}
                    label="a partir de"
                    rules={[
                      {
                        required: true,
                        message: "Selecione a data",
                      },
                    ]}
                    name={["requisicao", "dataDePagamento"]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      format="DD/MM/YYYY"
                      disabledDate={(date) => {
                        return (
                          date.toDate().getDay() === 6 ||
                          date.toDate().getDay() === 0
                        );
                      }}
                      onSelect={() => {
                        setDiaPagamentoPadrao(false);
                        setCalcularPrazo(Date.now());
                      }}
                    />
                  </Form.Item>
                  <div style={{ marginTop: 8 }}>
                    <Tooltip
                      title={`O prazo de pagamento será iniciado a partir da data selecionada`}
                    >
                      <Button
                        icon={<QuestionCircleTwoTone />}
                        type="link"
                        size="middle"
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>

              <Form.Item
                initialValue={requisicaoDeCompra?.formaDePagamento?.id}
                label="Forma de Pagamento"
                rules={[
                  {
                    required: true,
                    message: "Forma de Pagamento é obrigatório",
                  },
                ]}
                name={["requisicao", "formaDePagamento"]}
                style={{ width: "30%", paddingRight: 5 }}
              >
                <Select
                  placeholder="Selecione a forma de pagamento"
                  showSearch
                  options={optionsFormaDePagamento}
                  loading={loadingFormasDePagamento}
                  filterOption={(input, option) =>
                    option?.label
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSearch={buscarFormaDePagamento}
                />
              </Form.Item>
            </Input.Group>
          </Card>

          <Card
            title="Condições de pagamento"
            type="inner"
            bodyStyle={{ padding: 0 }}
          >
            <Table
              size="small"
              bordered
              pagination={false}
              scroll={{ y: 180 }}
              dataSource={parcelas}
              columns={[
                { title: "Parcela", dataIndex: "descricao", width: 300 },
                {
                  title: "Valor",
                  dataIndex: "valor",
                  width: 120,
                  className: "currency",
                  render: (value) => c.format(value, { code: "BRL" }),
                },
                {
                  title: "Vencimento",
                  width: 120,
                  dataIndex: "dataVencimento",
                  render: (value) => moment(value).format("DD/MM/YYYY"),
                },
                {
                  title: "Situação",
                  dataIndex: "status",
                  width: 120,
                  render: (value) => (
                    <Tag color={StatusParcelaDescricao[value].cor}>
                      {StatusParcelaDescricao[value].label}
                    </Tag>
                  ),
                },
              ]}
            />
          </Card>

          <Card
            title="Itens da requisição"
            type="inner"
            bodyStyle={{ padding: 0 }}
          >
            <Table
              title={() => <AdicionarItemForm />}
              size="small"
              footer={() => (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  <Table
                    showHeader={false}
                    style={{
                      maxWidth: 200,
                      flex: 1,
                    }}
                    pagination={false}
                    columns={[
                      { dataIndex: "titulo", className: "titulo" },
                      {
                        dataIndex: "total",
                        className: "currency",
                        render: (value) =>
                          currencyFormatter.format(value, { code: "BRL" }),
                      },
                    ]}
                    dataSource={[{ titulo: "Total", total: calcularTotal() }]}
                  />
                </div>
              )}
              bordered
              pagination={false}
              dataSource={itens}
              columns={[
                { title: "Item", dataIndex: "descricao" },
                {
                  title: "Valor Unitário",
                  dataIndex: "valor",
                  width: 130,
                  className: "currency",
                  render: (value) =>
                    currencyFormatter.format(value, { code: "BRL" }),
                },
                {
                  title: "Quantidade",
                  dataIndex: "quantidade",
                  width: 100,
                },
                {
                  title: "Subtotal",
                  className: "currency",
                  width: 130,
                  render: (_, obj) =>
                    currencyFormatter.format(
                      Number(obj.valor) * Number(obj.quantidade),
                      { code: "BRL" }
                    ),
                },
                {
                  title: "Ações",
                  width: 100,
                  render: (_, obj) => (
                    <div>
                      <Button
                        type="link"
                        size="small"
                        onClick={() => {
                          setItemSelecionado(obj);
                        }}
                      >
                        Editar
                      </Button>
                    </div>
                  ),
                },
              ]}
            />
          </Card>
          <Card title="Endereço de entrega" type="inner">
            <Input.Group compact>
              <Form.Item
                name={["enderecoDeEntrega", "cidade"]}
                label="Cidade"
                rules={[{ required: true, message: "Informe a cidade" }]}
                style={{ width: "15%", paddingRight: "5px" }}
              >
                <Input placeholder="Cidade" />
              </Form.Item>
              <Form.Item
                name={["enderecoDeEntrega", "estado"]}
                label="Estado"
                rules={[{ required: true, message: "Informe o estado" }]}
                style={{ width: "10%", paddingRight: "5px" }}
              >
                <Input placeholder="Estado" />
              </Form.Item>
              <Form.Item
                name={["enderecoDeEntrega", "cep"]}
                label="CEP"
                style={{ width: "10%", paddingRight: "5px" }}
                rules={[
                  {
                    message: "CEP Inválido",
                    pattern: /^[0-9]{5}-[0-9]{3}$/g,
                  },
                ]}
              >
                <Input
                  placeholder="00000-000"
                  maxLength={9}
                  onChange={(event) => {
                    const cepValue = event.target.value;
                    formRef.setFieldsValue({
                      enderecoDeEntrega: {
                        cep: cepFormatter(event.target.value),
                      },
                    });
                    if (cepValue.replace("-", "").length >= 8) {
                      cep(cepValue).then((response) => {
                        formRef.setFieldsValue({
                          enderecoDeEntrega: {
                            estado: response.state,
                            logradouro: response.street,
                            bairro: response.neighborhood,
                            cidade: response.city,
                          },
                        });
                      });
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                name={["enderecoDeEntrega", "logradouro"]}
                label="Rua"
                style={{ width: "35%", paddingRight: "5px" }}
              >
                <Input placeholder="Rua" />
              </Form.Item>
              <Form.Item
                name={["enderecoDeEntrega", "numero"]}
                label="Número"
                style={{ width: "10%", paddingRight: 5 }}
              >
                <Input placeholder="Número" type="number" />
              </Form.Item>

              <Form.Item
                name={["enderecoDeEntrega", "bairro"]}
                label="Bairro"
                style={{ width: "20%", paddingRight: "5px" }}
              >
                <Input placeholder="Bairro" />
              </Form.Item>

              <Form.Item
                name={["enderecoDeEntrega", "complemento"]}
                label="Complemento"
                style={{ width: "100%" }}
              >
                <Input placeholder="Complemento" />
              </Form.Item>
            </Input.Group>
          </Card>
          <Form.Item
            name={["requisicao", "observacao"]}
            initialValue={requisicaoDeCompra?.observacao}
            label="Observações"
            style={{ marginTop: 5, padding: 10 }}
          >
            <Input.TextArea placeholder="..." rows={3} maxLength={400} />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  ) : null;
}
