import { BrowserRouter, Route } from "react-router-dom";
import { AtendimentoRoute } from "./pages/atendimento/atendimento.page";
import ConfirmarCadastroFornecedor from "./pages/atendimento/fornecedores/confirmar-cadastro-fornecedor.page";
import { ConfiguracaoRoute } from "./pages/configuracoes/configuracoes.page";
import { DashboardRoute } from "./pages/dashboard/dashboard.page";
import { FinanceiroRoute } from "./pages/financeiro/financeiro.page";
import ConfirmarRecebimentoPedidoDeCompra from "./pages/financeiro/pedidos-de-compra/confirmar-recebimento.page";
import { GerencialRoute } from "./pages/gerencial/gerencial.page";
import Home from "./pages/home/home.page";
import Login from "./pages/login/login.page";
import { RecursosHumanosRoute } from "./pages/recursos-humanos/recursos-humanos.page";

export default function Routes() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Route path="/" exact component={Login} />
      <Route path="/inicio" component={Home} />
      <Route
        path="/fornecedores/:id/confirmar-cadastro"
        exact
        component={ConfirmarCadastroFornecedor}
      />
      <Route
        path="/pedidos-de-compra/:id/confirmar-recebimento"
        exact
        component={ConfirmarRecebimentoPedidoDeCompra}
      />
    </BrowserRouter>
  );
}

export const AuthRoutes = [
  DashboardRoute,
  AtendimentoRoute,
  FinanceiroRoute,
  RecursosHumanosRoute,
  GerencialRoute,
  ConfiguracaoRoute,
];
