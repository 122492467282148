import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  PageHeader,
  Row,
  Select,
  Space,
  Table,
  message,
} from "antd";
import React, { useState } from "react";

import { ColaboradorService } from "../../../services/colaborador/colaborador.service";
import CurrencyInput from "react-currency-input-field";
import { NovoContratoDto } from "../../../services/colaborador/dto/novo-contrato.dto";
import { PlusCircleOutlined } from "@ant-design/icons";
import c from "currency-formatter";
import { currencyFormatter } from "../../../core/utils/formatters/currency.formatter";
import { v4 as newId } from "uuid";

export default function NovoContratoPage({ visible, setVisible }) {
  const [formRef] = Form.useForm();
  const [beneficios, setBeneficios] = useState([]);
  const [itemSelecionado, setItemSelecionado] = useState(null);
  const _colaboradorService = new ColaboradorService();

  function AdicionarItemForm() {
    const [itemFormRef] = Form.useForm();

    if (itemSelecionado) {
      itemFormRef.setFieldsValue(itemSelecionado);
    }
    async function adicionarItem() {
      const item = itemFormRef.getFieldsValue(["id", "descricao", "valor"]);
      await itemFormRef.validateFields();
      if (itemSelecionado) {
        item.valor = c.unformat(item.valor, {
          code: "BRL",
        });
        const itensNew = beneficios.filter((i) => i.id !== itemSelecionado.id);
        itensNew.push(item);
        setBeneficios(itensNew);
        setItemSelecionado(null);
      } else {
        setBeneficios([...beneficios, item]);
      }
    }

    return (
      <div title="ATUALIZAR ITEM" style={{ marginBottom: -20 }}>
        <Form
          form={itemFormRef}
          layout="horizontal"
          onFinish={() => adicionarItem()}
        >
          <div
            style={{ display: "flex" }}
            onKeyPress={(e) => {
              if (e.key === "Enter" || e.key === "Tab") {
                itemFormRef.submit();
              }
            }}
          >
            <Input.Group compact>
              <Form.Item name="id" initialValue={newId()} />
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
                name="descricao"
                style={{ width: "70%", paddingRight: 5 }}
              >
                <Input placeholder="Descrição" />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
                style={{ paddingRight: 5, width: "30%" }}
              >
                <CurrencyInput
                  name="valor"
                  defaultValue={itemFormRef.getFieldValue("valor")}
                  placeholder="Valor Benefício "
                  className="ant-input ant-input-sm"
                  decimalScale={2}
                  onValueChange={(value = "0") => {
                    itemFormRef.setFieldsValue({
                      valor: c.unformat(value, {
                        code: "BRL",
                      }),
                    });
                  }}
                  intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                />
              </Form.Item>
            </Input.Group>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={() => itemFormRef.submit()}
            >
              Adicionar Benefício
            </Button>
          </div>
        </Form>
      </div>
    );
  }

  async function adicionarContratoHandler() {
    try {
      const { regime, cargaHoraria, salario } =
        formRef.getFieldValue("contrato");
      const dto: NovoContratoDto = {
        salario,
        regime,
        cargaHoraria,
        beneficios,
      };
      await _colaboradorService.adicionarContrato(dto);
      message.success("Contrato Adicionado");
      setVisible(false);
      setBeneficios([]);
      formRef.resetFields();
    } catch (error) {
      message.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message
      );
    }
  }

  return (
    <div>
      <Drawer
        visible={visible}
        onClose={() => setVisible(false)}
        closable={false}
        width={1000}
        bodyStyle={{ padding: 3 }}
      >
        <PageHeader
          onBack={() => setVisible(false)}
          className="site-page-header"
          title={`Novo contrato de trabalho`}
          style={{ marginBottom: 10 }}
        />
        <Card title="Informações gerais">
          <Form
            form={formRef}
            layout="vertical"
            onFinish={() => adicionarContratoHandler()}
          >
            <Input.Group compact>
              <Form.Item
                name={["contrato", "regime"]}
                label="Regime"
                rules={[{ required: true, message: "Selecione o regime" }]}
                style={{ width: "40%", paddingRight: 5 }}
              >
                <Select
                  placeholder="Selecione o regime do contrato"
                  options={[
                    { key: 0, value: 0, label: "CLT" },
                    { key: 1, value: 1, label: "PJ" },
                  ]}
                />
              </Form.Item>
              <Form.Item
                name={["contrato", "cargaHoraria"]}
                label="Carga Horária / Semanal"
                rules={[{ required: true, max: 44, min: 0 }]}
                style={{ width: "30%", paddingRight: 5 }}
              >
                <Input placeholder="Informe a carga horaria" type="number" />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: "Informe o valor" }]}
                label="Remuneração"
                style={{ width: "30%", paddingRight: 5 }}
              >
                <CurrencyInput
                  className="ant-input ant-input-sm"
                  placeholder="Valor mensal"
                  decimalScale={2}
                  onValueChange={(value = "0") => {
                    formRef.setFieldsValue({
                      contrato: {
                        salario: c.unformat(value, { code: "BRL" }),
                      },
                    });
                  }}
                  intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                />
              </Form.Item>
            </Input.Group>
            <Card bodyStyle={{ padding: 0 }} title="Benefícios">
              <Table
                title={() => <AdicionarItemForm />}
                bordered
                dataSource={beneficios}
                pagination={false}
                columns={[
                  {
                    title: "Benefício",
                    dataIndex: "descricao",
                  },
                  {
                    title: "Valor",
                    dataIndex: "valor",
                    render: (value) => currencyFormatter(value, "BRL"),
                  },
                  {
                    title: "Ação",
                    width: 160,
                    render: (_, obj) => (
                      <div>
                        <Button
                          type="link"
                          size="small"
                          onClick={() => {
                            setItemSelecionado(obj);
                          }}
                        >
                          Editar
                        </Button>
                        <Button
                          type="link"
                          style={{ color: "red" }}
                          size="small"
                          onClick={() => {
                            setBeneficios(
                              beneficios.filter((i) => i.id !== obj.id)
                            );
                          }}
                        >
                          Remover
                        </Button>
                      </div>
                    ),
                  },
                ]}
              />
            </Card>
            <Row>
              <Col span={18} />
              <Col span={6} style={{ marginTop: "15px", textAlign: "end" }}>
                <Space>
                  <Button type="primary" size="middle" htmlType="submit">
                    SALVAR
                  </Button>
                  <Button
                    type="ghost"
                    size="middle"
                    onClick={() => {
                      setVisible(false);
                    }}
                  >
                    CANCELAR
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </Card>
      </Drawer>
    </div>
  );
}
