import {
  KeyOutlined,
  LockOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  PageHeader,
  Row,
  Select,
  Space,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import useSWR from "swr";
import Loading from "../../../components/loading.component";
import { Endpoints } from "../../../core/constants/endpoints";
import { RegimeDeContrato } from "../../../core/enums/regime-contrato.enum";
import { cepFormatter } from "../../../core/utils/formatters/cep.formatter";
import { cpfFormatter } from "../../../core/utils/formatters/cpj.formatter";
import { currencyFormatter } from "../../../core/utils/formatters/currency.formatter";
import { removeMask } from "../../../core/utils/formatters/removeMask.formatter";
import { rgFormatter } from "../../../core/utils/formatters/rg.formatter";
import { telefoneCelularFormatter } from "../../../core/utils/formatters/telefoneCelular.formatter";
import { ColaboradorService } from "../../../services/colaborador/colaborador.service";
import { AtualizarColaboradorDto } from "../../../services/colaborador/dto/atualizar-colaborador.dto";
import { AtualizarSenhaDto } from "../../../services/colaborador/dto/atualizar-senha.dto";
import NovoContratoPage from "./novo-contrato.page";

const cep = require("cep-promise");
export default function Colaborador() {
  const { id } = useParams<any>();
  const { data: { data: { data: colaboradorData } = {} } = {} } = useSWR(
    Endpoints.colaboradores + id,
    () => _colaboradorService.obterPorId(id),
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
    }
  );
  const [modalAlterarSenha, setModalAlterarSenha] = useState(false);
  const [formRef] = Form.useForm();
  const [formPasswordRef] = Form.useForm();
  const [optionsSetores, setOptionsSetores] = useState([]);
  const [optionsSetoresLoading, setOptionsSetoresLoading] = useState(false);
  const [optionsPerfis, setOptionsPerfis] = useState([]);
  const [optionsPerfisLoading, setOptionsPerfisLoading] = useState(false);
  const [cadastrarContrato, setCadastrarContrato] = useState(false);
  const [optionsContratos, setOptionsContratos] = useState([]);
  const _colaboradorService = new ColaboradorService();
  const history = useHistory();
  async function adicionarColaboradorHandler() {
    try {
      const { colaborador, endereco } = formRef.getFieldsValue([
        "colaborador",
        "endereco",
      ]);

      const dto: AtualizarColaboradorDto = {
        id: id,
        ...colaborador,
        usuario: String(colaborador?.usuario).toLowerCase(),
        email: String(colaborador?.email).toLowerCase(),
        perfil: { id: colaborador?.perfil },
        setor: { id: colaborador?.setor },
        cpf: removeMask(colaborador?.cpf),
        rg: removeMask(colaborador?.rg),
        telefoneCelular: removeMask(colaborador?.telefoneCelular),
        endereco: { ...endereco, cep: removeMask(endereco?.cep) },
      };

      await _colaboradorService.atualizar(dto, id);
      message.success("Colaborador Atualizado com sucesso!");
      history.goBack();
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  }

  useEffect(() => {
    if (optionsSetores.length <= 0) {
      setOptionsSetoresLoading(true);
      _colaboradorService.obterSetores().then((result) => {
        const setores = result?.data?.data?.map((s) => {
          return { key: s.id, label: s.nome, value: s.id };
        });
        setOptionsSetores(setores);
        setOptionsSetoresLoading(false);
      });
    }

    if (optionsContratos.length <= 0) {
      _colaboradorService
        .obterContratos()
        .then(({ data: { data: contratos } }) => {
          const opts = contratos.map((c) => {
            return {
              key: c.id,
              label: `${c.regime === RegimeDeContrato.CLT ? "CLT" : "PJ"} ${
                c.cargaHoraria
              } Horas - ${currencyFormatter(c.salario, "BRL")}`,
              value: c.id,
            };
          });
          setOptionsContratos(opts);
        });
    }

    if (optionsPerfis.length <= 0) {
      setOptionsPerfisLoading(true);

      _colaboradorService.obterPerfis().then((result) => {
        const perfis = result?.data?.data?.map((p) => {
          return { key: p.id, label: p.nome, value: p.id };
        });

        setOptionsPerfis(perfis);
        setOptionsPerfisLoading(false);
      });
    }

    formRef.setFieldsValue({
      colaborador: {
        nome: colaboradorData?.nome,
        cpf: cpfFormatter(colaboradorData?.cpf),
        rg: rgFormatter(colaboradorData?.rg),
        telefoneCelular: telefoneCelularFormatter(
          colaboradorData?.telefoneCelular
        ),
        email: colaboradorData?.email,
        usuario: colaboradorData?.usuario,
        senha: colaboradorData?.senha,
        setor: colaboradorData?.setor?.id,
        perfil: colaboradorData?.perfil,
        dataDeAdmissao: moment(colaboradorData?.dataDeAdmissao),
        contrato: colaboradorData?.contrato,
      },
      endereco: {
        ...colaboradorData?.endereco,
        cep: cepFormatter(colaboradorData?.endereco?.cep),
      },
    });
  }, [colaboradorData]);

  function AlterarSenhaModal() {
    async function AlterarSenhaHandler() {
      try {
        const { novaSenha, senhaAtual } =
          formPasswordRef.getFieldValue("alterarSenha");
        const dto: AtualizarSenhaDto = {
          novaSenha,
          senhaAtual,
          colaboradorId: colaboradorData?.id,
        };

        await _colaboradorService.atualizarSenha(dto, id);
        message.success("Senha atualizada com sucesso");
        setModalAlterarSenha(false);
      } catch (error) {
        message.error(error?.response?.data?.message);
        throw error;
      }
    }
    return (
      <Modal
        onCancel={() => {
          setModalAlterarSenha(false);
          formPasswordRef.resetFields(["alterarSenha"]);
        }}
        visible={modalAlterarSenha}
        closable={false}
        bodyStyle={{ padding: 0, margin: 0 }}
        footer
      >
        <Card title="Alterar Senha" size="default">
          <Form
            form={formPasswordRef}
            layout="vertical"
            onFinish={() => AlterarSenhaHandler()}
          >
            <Form.Item
              name={["alterarSenha", "senhaAtual"]}
              label="Senha Atual"
              rules={[
                {
                  required: true,
                  message: "Informe a senha atual",
                },
              ]}
            >
              <Input
                type="password"
                placeholder="Informe Senha Atual"
                prefix={<LockOutlined className="site-form-item-icon" />}
              />
            </Form.Item>
            <Form.Item
              name={["alterarSenha", "novaSenha"]}
              label="Nova Senha"
              rules={[
                {
                  required: true,
                  message: "Informe a nova senha",
                },
              ]}
            >
              <Input
                type="password"
                placeholder="Informe a Nova Senha"
                prefix={<LockOutlined className="site-form-item-icon" />}
              />
            </Form.Item>
            <Form.Item
              dependencies={["alterarSenha", "novaSenha"]}
              name={["alterarSenha", "confirmacao"]}
              label="Confirme a Senha"
              rules={[
                {
                  required: true,
                  message: "Informe a confirmação de senha",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (
                      !value ||
                      getFieldValue(["alterarSenha", "novaSenha"]) === value
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject("As senhas não conferem");
                  },
                }),
              ]}
            >
              <Input
                type="password"
                placeholder="Informe a confirmação de senha"
                prefix={<LockOutlined className="site-form-item-icon" />}
              />
            </Form.Item>
            <Button
              htmlType="submit"
              size="middle"
              type="primary"
              style={{ width: "100%" }}
              icon={<KeyOutlined />}
            >
              ALTERAR SENHA
            </Button>
          </Form>
        </Card>
      </Modal>
    );
  }

  return colaboradorData?.id ? (
    <div>
      <AlterarSenhaModal />
      <NovoContratoPage
        visible={cadastrarContrato}
        setVisible={setCadastrarContrato}
      />
      <PageHeader
        onBack={() => history.goBack()}
        className="site-page-header"
        title={`Colaborador`}
        style={{ marginBottom: 10 }}
        extra={
          <Button
            icon={<KeyOutlined />}
            type="primary"
            size="middle"
            onClick={() => setModalAlterarSenha(true)}
          >
            ALTERAR SENHA
          </Button>
        }
      />
      <Form
        form={formRef}
        layout="vertical"
        onFinish={() => adicionarColaboradorHandler()}
      >
        <Card title="Informações Colaborador" type="inner">
          <Input.Group compact>
            <Form.Item
              label="Nome Completo"
              name={["colaborador", "nome"]}
              style={{ width: "50%" }}
              rules={[
                {
                  required: true,
                  message: "Nome é obrigatório",
                },
              ]}
            >
              <Input placeholder="Ex: João da Silva" />
            </Form.Item>
            <Form.Item
              label="CPF"
              name={["colaborador", "cpf"]}
              rules={[
                {
                  required: true,
                  message: "CPF é obrigatório",
                },
              ]}
              style={{ width: "25%", paddingLeft: 5 }}
            >
              <Input
                placeholder="Ex: 000.000.000-00"
                onKeyUp={() => {
                  formRef.setFieldsValue({
                    colaborador: {
                      cpf: cpfFormatter(
                        formRef.getFieldValue(["colaborador", "cpf"])
                      ),
                    },
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="RG"
              name={["colaborador", "rg"]}
              style={{ width: "25%", paddingLeft: 5 }}
              rules={[
                {
                  required: true,
                  message: "RG é obrigatório",
                },
              ]}
            >
              <Input
                placeholder="Ex: 00.000.000-0"
                onKeyUp={() => {
                  formRef.setFieldsValue({
                    colaborador: {
                      rg: rgFormatter(
                        formRef.getFieldValue(["colaborador", "rg"])
                      ),
                    },
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Telefone Celular"
              rules={[
                {
                  required: true,
                  message: "Celular é obrigatório",
                },
              ]}
              name={["colaborador", "telefoneCelular"]}
              style={{ width: "25%" }}
            >
              <Input
                placeholder="Ex: (41) 99999-9999"
                onKeyUp={() => {
                  formRef.setFieldsValue({
                    colaborador: {
                      telefoneCelular: telefoneCelularFormatter(
                        formRef.getFieldValue([
                          "colaborador",
                          "telefoneCelular",
                        ])
                      ),
                    },
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="E-mail"
              rules={[
                {
                  required: true,
                  message: "E-mail é obrigatório",
                },
              ]}
              name={["colaborador", "email"]}
              style={{ width: "25%", paddingLeft: 5 }}
            >
              <Input placeholder="joao.s@tracao.com.br" type="email" />
            </Form.Item>
            <Form.Item
              label="Usuário sistema"
              name={["colaborador", "usuario"]}
              rules={[
                {
                  required: true,
                  message: "Usuário é obrigatório",
                },
              ]}
              style={{ width: "25%", paddingLeft: 5 }}
            >
              <Input placeholder="Ex: joao.s" />
            </Form.Item>

            <Form.Item
              label="Setor"
              name={["colaborador", "setor"]}
              rules={[
                {
                  required: true,
                  message: "Setor é obrigatório",
                },
              ]}
              style={{ width: "25%", paddingLeft: 5 }}
            >
              <Select
                placeholder="Selecione o setor"
                options={optionsSetores}
                loading={optionsSetoresLoading}
                onFocus={async () => {}}
              />
            </Form.Item>
            <div style={{ display: "flex" }}>
              <Form.Item
                label="Perfil"
                name={["colaborador", "perfil"]}
                rules={[
                  {
                    required: true,
                    message: "Perfil é obrigatório",
                  },
                ]}
                style={{ width: "33.33%", paddingLeft: 5 }}
              >
                <Select
                  placeholder="Selecione o perfil"
                  loading={optionsPerfisLoading}
                  options={optionsPerfis}
                />
              </Form.Item>
              <Form.Item
                label="Data de admissão"
                rules={[
                  {
                    required: true,
                    message: "Data de admissão é obrigatório",
                  },
                ]}
                name={["colaborador", "dataDeAdmissao"]}
                style={{ width: "33.33%", paddingLeft: 5 }}
              >
                <DatePicker
                  format={"DD/MM/yyyy"}
                  placeholder="Selecione a data de admissão"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <div style={{ display: "flex", width: "33.2%" }}>
                <Form.Item
                  label="Contrato de trabalho"
                  style={{ width: "100%", paddingLeft: 5 }}
                  name={["colaborador", "contrato"]}
                >
                  <Select
                    placeholder="Selecione o contrato de trabalho"
                    options={optionsContratos}
                    onFocus={async () => {
                      const opts = await _colaboradorService
                        .obterContratos()
                        .then(({ data: { data: contratos } }) =>
                          contratos.map((c) => {
                            return {
                              key: c.id,
                              label: `${
                                c.regime === RegimeDeContrato.CLT ? "CLT" : "PJ"
                              } ${c.cargaHoraria} Horas - ${currencyFormatter(
                                c.salario,
                                "BRL"
                              )}`,
                              value: c.id,
                            };
                          })
                        );
                      setOptionsContratos(opts);
                    }}
                  />
                </Form.Item>
                <Button
                  icon={<PlusCircleOutlined />}
                  type="default"
                  onClick={() => setCadastrarContrato(true)}
                  style={{
                    marginLeft: -5,
                    marginTop: 32,
                    borderTopRightRadius: 5,
                    borderEndEndRadius: 5,
                  }}
                />
              </div>
            </div>
          </Input.Group>
        </Card>
        <Card type="inner" title="Endereço">
          <Input.Group compact>
            <Form.Item
              name={["endereco", "cep"]}
              label="CEP"
              style={{ width: "12%", paddingRight: "5px" }}
              rules={[
                {
                  required: true,
                  message: "Informe o CEP",
                  max: 9,
                  min: 9,
                  pattern: /^\d{5}-\d{3}$/g,
                },
              ]}
            >
              <Input
                placeholder="00000-000"
                maxLength={9}
                onKeyUp={() => {
                  formRef.setFieldsValue({
                    cep: cepFormatter(
                      formRef.getFieldValue(["endereco", "cep"])
                    ),
                  });
                }}
                onChange={(event) => {
                  const cepValue = event.target.value;
                  formRef.setFieldsValue({
                    endereco: {
                      cep: cepFormatter(event.target.value),
                    },
                  });
                  if (cepValue.replace("-", "").length >= 8) {
                    cep(cepValue).then((response) => {
                      formRef.setFieldsValue({
                        endereco: {
                          estado: response.state,
                          logradouro: response.street,
                          bairro: response.neighborhood,
                          cidade: response.city,
                        },
                      });
                    });
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              name={["endereco", "logradouro"]}
              label="Rua"
              style={{ width: "38%", paddingRight: "5px" }}
              rules={[{ required: true, message: "Informe a Rua" }]}
            >
              <Input placeholder="Rua" />
            </Form.Item>
            <Form.Item
              name={["endereco", "numero"]}
              label="Número"
              style={{ width: "10%", paddingRight: 5 }}
              rules={[{ required: true, message: "Informe o número" }]}
            >
              <Input placeholder="Número" type="number" />
            </Form.Item>

            <Form.Item
              name={["endereco", "bairro"]}
              label="Bairro"
              rules={[{ required: true, message: "Informe o bairro" }]}
              style={{ width: "20%", paddingRight: "5px" }}
            >
              <Input placeholder="Bairro" />
            </Form.Item>
            <Form.Item
              name={["endereco", "cidade"]}
              label="Cidade"
              rules={[{ required: true, message: "Informe a cidade" }]}
              style={{ width: "12%", paddingRight: "5px" }}
            >
              <Input placeholder="Cidade" />
            </Form.Item>
            <Form.Item
              name={["endereco", "estado"]}
              label="Estado"
              rules={[{ required: true, message: "Informe o estado" }]}
              style={{ width: "8%" }}
            >
              <Input placeholder="Estado" />
            </Form.Item>
            <Form.Item
              name={["endereco", "complemento"]}
              label="Complemento"
              style={{ width: "100%" }}
            >
              <Input placeholder="Complemento" />
            </Form.Item>
            <Form.Item name={["endereco", "pais"]} initialValue="BR" />
          </Input.Group>
        </Card>
        <Row>
          <Col span={18} />
          <Col span={6} style={{ marginTop: "15px", textAlign: "end" }}>
            <Space>
              <Button type="primary" size="middle" htmlType="submit">
                SALVAR
              </Button>
              <Button
                type="ghost"
                size="middle"
                onClick={() => {
                  history.goBack();
                }}
              >
                CANCELAR
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </div>
  ) : (
    <Loading />
  );
}

export const ColaboradorRoute = {
  id: "colaborador",
  path: "/inicio/recursos-humanos/colaboradores/:id",
  exact: true,
  sidebar: "Recursos Humanos",
  icon: null,
  main: () => <Colaborador />,
  renderInMenu: false,
  permission: "all",
};
