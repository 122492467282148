import {
  Button,
  Input,
  PageHeader,
  Popconfirm,
  Table,
  Tag,
  message,
} from "antd";
import { Link, useHistory } from "react-router-dom";
import { PlusOutlined, ShopOutlined } from "@ant-design/icons";
import React, { useState } from "react";

import { Action } from "../../../core/permissions/actions/actions";
import { Can } from "../../../core/permissions/permission.context";
import { Empresa } from "../../../core/permissions/subjects";
import { EmpresaService } from "../../../services/empresa/empresas.service";
import { Endpoints } from "../../../core/constants/endpoints";
import NovaEmpresa from "./nova-empresa.page";
import { cnpjFormatter } from "../../../core/utils/formatters/cnpj.formatter";
import useSWR from "swr";

const _empresaService = new EmpresaService();

export default function Empresas() {
  const [atualizarLista, setAtualizarLista] = useState(Date.now());
  const [filtro, setFiltro] = useState("");
  const [drawerNovaEmpresa, setDrawerNovaEmpresa] = useState(false);
  const history = useHistory();
  const { data: empresas, error } = useSWR(
    Endpoints.empresas + filtro + atualizarLista + drawerNovaEmpresa,
    () => _empresaService.obterEmpresas(filtro),
    { revalidateOnFocus: false }
  );
  if (error) message.error(error.message);
  const columns = [
    {
      key: "id",
      title: "CNPJ",
      dataIndex: "cnpj",
      width: 140,
      render: (value, obj) => (
        <Link to={`empresas/${obj.id}`}>{cnpjFormatter(value)}</Link>
      ),
    },
    {
      key: "id",
      title: "Nome Fantasia",
      dataIndex: "nomeFantasia",
    },
    {
      key: "id",
      title: "Razão Social",
      dataIndex: "razaoSocial",
    },

    {
      key: "id",
      width: 80,
      title: "Status",
      dataIndex: "status",
      render: (value) =>
        value ? (
          <Tag color="green">ATIVA</Tag>
        ) : (
          <Tag color="warning">INATIVA</Tag>
        ),
    },
    {
      title: "Ação",
      width: 135,
      render: (_, obj) => (
        <>
          <a style={{ marginRight: 5 }}>
            <Link to={`empresas/${obj.id}`}>Acessar</Link>
          </a>
          <Popconfirm
            placement="bottom"
            title={`Deseja  ${
              obj?.status === 1 ? "inativar" : "ativar"
            } a empresa ${obj?.nomeFantasia} ?`}
            onConfirm={async () => {
              await _empresaService.inativarCadastro(obj?.id);
              message.success(
                `Empresa ${obj?.nomeFantasia} foi ${
                  obj?.status === 1 ? "Inativada" : "Ativada"
                }`
              );
              setAtualizarLista(Date.now());
            }}
            okText="SIM"
            cancelText="Não"
          >
            <a style={{ color: obj?.status === 1 ? "#900c3f" : "#1B4F72" }}>
              {obj?.status === 1 ? "Inativar" : "Ativar"}
            </a>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title="Empresas"
        subTitle="Todas as Empresas"
        extra={
          <Button
            type="primary"
            size="middle"
            icon={<PlusOutlined />}
            onClick={() => setDrawerNovaEmpresa(true)}
          >
            Nova Empresa
          </Button>
        }
      />
      <>
        <NovaEmpresa
          visible={drawerNovaEmpresa}
          setVisible={setDrawerNovaEmpresa}
        />
        <div style={{ paddingTop: 10 }}>
          <Input.Search
            placeholder="Buscar..."
            size="middle"
            onSearch={(value) => setFiltro(value)}
          />
        </div>
        <Can do={Action.Ler} on={Empresa} passThrough>
          <Table
            rowKey="id"
            style={{ marginTop: 10 }}
            scroll={{ y: 450 }}
            bordered
            columns={columns}
            loading={!empresas}
            dataSource={empresas?.data?.data || []}
          />
        </Can>
      </>
    </div>
  );
}

export const EmpresasRoute = {
  id: "empresas",
  path: "/inicio/financeiro/empresas",
  sidebar: "Empresas",
  exact: true,
  renderInMenu: true,
  main: () => <Empresas />,
  icon: <ShopOutlined />,
  permission: Empresa,
};
