export enum StatusParcela {
  AVencer = 0,
  Vencida = 1,
  Paga = 2,
  Cancelada = 3,
  Conciliada = 4,
}

export class StatusParcelaInfo {
  key: number;
  value: number;
  label: string;
  cor: string;
}

export const StatusParcelaDescricao: {
  [key in StatusParcela]: StatusParcelaInfo;
} = {
  [StatusParcela.AVencer]: {
    key: StatusParcela.AVencer,
    value: StatusParcela.AVencer,
    cor: "default",
    label: "À VENCER",
  },
  [StatusParcela.Vencida]: {
    key: StatusParcela.Vencida,
    value: StatusParcela.Vencida,
    cor: "red",
    label: "VENCIDA",
  },
  [StatusParcela.Paga]: {
    key: StatusParcela.Paga,
    value: StatusParcela.Paga,
    cor: "green",
    label: "PAGA",
  },
  [StatusParcela.Cancelada]: {
    key: StatusParcela.Cancelada,
    value: StatusParcela.Cancelada,
    cor: "gold",
    label: "CANCELADA",
  },
  [StatusParcela.Conciliada]: {
    key: StatusParcela.Conciliada,
    value: StatusParcela.Conciliada,
    cor: "blue",
    label: "CONCILIADA",
  },
};

function enumToArray(enumerator: any) {
  const list = [];
  for (let index = 0; index < Object.values(enumerator).length; index++) {
    const obj = Object.values(enumerator)[index];

    list.push(obj);
  }
  return list;
}
export const StatusParcelaList = enumToArray(StatusParcelaDescricao);
