import {
  FileDoneOutlined,
  FileProtectOutlined,
  ReconciliationOutlined,
  WarningTwoTone,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Descriptions,
  Drawer,
  List,
  message,
  PageHeader,
  Select,
} from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import useSWR from "swr";
import { Endpoints } from "../../../core/constants/endpoints";
import { TipoTransacaoBancariaDescricao } from "../../../core/enums/tipo-transacao-bancaria.enum";
import { currencyFormatter } from "../../../core/utils/formatters/currency.formatter";
import { ConciliacaoBancariaService } from "../../../services/conciliacao-bancaria/conciliacao-bancaria.service";
import ImportarExtrato from "./importar-extrato.modal";

function ConciliacaoBancaria() {
  const _conciliacaoBancariaService = new ConciliacaoBancariaService();
  const history = useHistory();
  const [importarExtratoVisible, setImportarExtratoVisible] = useState(false);
  const [itemSelecionado, setItemSelecionado] = useState(null);
  const { data: { data: { data: contas } = {} } = {} } = useSWR(
    Endpoints.obterTransacoesPendentes +
      importarExtratoVisible +
      itemSelecionado,
    () => _conciliacaoBancariaService.obterTransacoesPendentes(),
    {
      revalidateOnFocus: false,
    }
  );

  function Item({ item }) {
    const [opts, setOpts] = useState({ values: [], search: false });
    const [valorSelecionado, setValorSelecionado] = useState(null);
    const [loading, setLoading] = useState(false);

    return (
      <List.Item>
        <Card
          bodyStyle={{ padding: 0 }}
          style={{ width: "100%" }}
          loading={loading}
          onMouseEnter={async () => {
            if (opts.values.length === 0 && !opts.search) {
              setLoading(true);
              const optsBack: Array<any> = await obterSugestoesConciliacao(
                item.valor,
                item.contaBancaria
              );
              setOpts({ values: [...optsBack], search: true });
              setLoading(false);
              if (optsBack.length === 1)
                setValorSelecionado(optsBack.shift().key);
            }
          }}
        >
          <Descriptions bordered layout="vertical" size="small">
            <Descriptions.Item label="Descrição" style={{ minWidth: "580px" }}>
              <span>{item.descricao}</span>
            </Descriptions.Item>
            <Descriptions.Item
              label="Tipo Transação"
              style={{ minWidth: "200px" }}
            >
              {TipoTransacaoBancariaDescricao[item?.tipo]}
            </Descriptions.Item>
            <Descriptions.Item label="Valor" style={{ minWidth: "140px" }}>
              <span
                className={item?.valor > 0 ? "currency" : "currency-negative"}
              >
                {currencyFormatter(item.valor, "BRL")}
              </span>
            </Descriptions.Item>
            <Descriptions.Item
              span={1}
              label={`conta a ${item?.valor > 0 ? "receber" : "pagar"}`}
            >
              <Select
                style={{ width: "100%" }}
                size="middle"
                options={opts.values}
                value={valorSelecionado}
                onSelect={(value) => setValorSelecionado(value)}
                placeholder={`Selecione a conta a ${
                  item?.valor > 0 ? "receber" : "pagar"
                }`}
              />
            </Descriptions.Item>
            <Descriptions.Item label={"Ação"} style={{ width: "340px" }}>
              <Button
                size="middle"
                type="primary"
                icon={<FileDoneOutlined />}
                onClick={async () => {
                  try {
                    await _conciliacaoBancariaService.efetuarConciliacao({
                      parcela: { id: valorSelecionado },
                      transacaoFinanceira: { id: item.id },
                    });
                    setItemSelecionado({
                      ...itemSelecionado,
                      transacoes: itemSelecionado?.transacoes?.filter(
                        (i) => i.id !== item.id
                      ),
                    });
                    message.success("Conciliacao Efetuada");
                  } catch (error) {
                    message.error(
                      error?.response?.data?.message
                        ? error?.response?.data?.message
                        : error.message
                    );
                  }
                }}
                style={{ width: "100%" }}
              >
                Conciliar
              </Button>
            </Descriptions.Item>
          </Descriptions>
        </Card>
      </List.Item>
    );
  }

  async function obterSugestoesConciliacao(valor: number, conta: string) {
    try {
      return await _conciliacaoBancariaService
        .obterSugestoesDeConciliacao(valor, conta)
        .then(({ data: { data: parcelas } }) => {
          return parcelas.map((p) => {
            return {
              key: p.id,
              value: p.id,
              label: `${p.descricao} - ${currencyFormatter(p.valor, "BRL")}`,
            };
          });
        });
    } catch (error) {
      throw error;
    }
  }

  function TransacoesModal() {
    return (
      <Drawer
        title={"Transações"}
        bodyStyle={{ padding: 3 }}
        width={1300}
        closable={false}
        footer
        visible={itemSelecionado}
        onClose={() => setItemSelecionado(null)}
      >
        <List
          size="small"
          rowKey={"id"}
          dataSource={itemSelecionado?.transacoes}
          renderItem={(item: any) => <Item item={item} />}
        />
      </Drawer>
    );
  }

  return (
    <div>
      <TransacoesModal />
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title={`Conciliação bancária`}
        extra={
          <>
            <ImportarExtrato
              visible={importarExtratoVisible}
              setVisible={setImportarExtratoVisible}
            />
            <Button
              icon={<FileProtectOutlined />}
              size="middle"
              type="primary"
              onClick={() => setImportarExtratoVisible(true)}
            >
              Importar extrato
            </Button>
          </>
        }
        style={{ marginBottom: 5 }}
      />
      <Card title="Pendentes de conciliação">
        <List
          loading={!contas}
          grid={{ gutter: 16, xs: 1, sm: 2, md: 2, lg: 4, xl: 4, xxl: 3 }}
          dataSource={contas}
          renderItem={(item: any) => (
            <List.Item>
              <Card title={item.descricao} type="inner">
                <Button
                  type="link"
                  size="middle"
                  onClick={() => setItemSelecionado(item)}
                >
                  <WarningTwoTone twoToneColor="orange" size={80} />
                  {` ${item?.transacoes?.length} transações pendentes`}
                </Button>
              </Card>
            </List.Item>
          )}
        />
      </Card>
    </div>
  );
}

export const ConciliacaoBancariaRoute = {
  id: "conciliacao-bancaria",
  path: "/inicio/financeiro/conciliacao-bancaria",
  sidebar: "Conciliação Bancária",
  exact: true,
  renderInMenu: true,
  main: () => <ConciliacaoBancaria />,
  icon: <ReconciliationOutlined />,
  permission: ConciliacaoBancaria,
};
