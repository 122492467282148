import { useEffect, useState } from "react";

import { ContaBancariaService } from "../../services/conta-bancaria/conta-bancaria.service";
import { GrupoFinanceiroService } from "../../services/grupo-financeiro/grupo-financeiro.service";

export default function useGruposFinanceirosHook(tipo: string) {
  const [optsGrupos, setOptsGrupos] = useState([]);
  const [optsGruposSearch, setOptsGruposSearch] = useState(false);
  const _contasService = new ContaBancariaService();
  const _gruposServices = new GrupoFinanceiroService();

  useEffect(() => {
    _gruposServices.obterTodos().then(({ data: { data: grupos } }) => {
      const { gruposFilhos = [] } = grupos
        ?.filter((g) => g.grupo.includes(tipo.toUpperCase()))
        ?.shift();
      const convertGroups = gruposFilhos.map((g) => {
        return {
          key: g.id,
          value: g.id,
          label: `${g.grupo}`,
        };
      });

      setOptsGrupos(convertGroups);
    });
  }, []);

  const filterOptions = (input, option) =>
    option?.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;

  async function buscarGruposFinanceiros(value) {
    if (value.length >= 3) {
      setOptsGruposSearch(true);
      const result = await _contasService.obterTodas();
      const opts = result?.data?.data.map((c) => {
        return {
          key: c.id,
          value: c.id,
          label: c.descricao,
        };
      });
      setOptsGrupos(opts);
      setOptsGruposSearch(false);
    }
  }

  return {
    options: optsGrupos,
    loading: optsGruposSearch,
    buscarGruposFinanceiros,
    setOptions: setOptsGrupos,
    filterOptions,
  };
}
