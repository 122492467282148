import { SettingOutlined } from "@ant-design/icons";
import { Card, PageHeader, Tabs } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import { Configuracao } from "../../core/permissions/subjects";
import DashboardConfiguracao from "./dashboard.configuracao";
import FinanceiroConfiguracao from "./financeiro.configuracao";

const { TabPane } = Tabs;
export default function Configuracoes() {
  const history = useHistory();
  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => history.push("/inicio")}
        title="Configurações"
        subTitle="Central de configurações"
      />
      <Card style={{ marginTop: 5 }}>
        <Tabs tabPosition="left" style={{ marginTop: 15 }}>
          <TabPane tab="Página inicial" key="0">
            <DashboardConfiguracao />
          </TabPane>
          <TabPane tab="Atendimento" key="1" disabled>
            Content of Tab 1
          </TabPane>
          <TabPane tab="Financeiro" key="2">
            <FinanceiroConfiguracao />
          </TabPane>
          <TabPane tab="Recursos Humanos" key="3" disabled>
            Content of Tab 3
          </TabPane>
          <TabPane tab="Gerencial" key="4" disabled>
            Content of Tab 3
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
}
export const ConfiguracaoRoute = {
  id: "configuracoes",
  path: "/inicio/configuracoes",
  exact: true,
  sidebar: "Configurações",
  icon: <SettingOutlined />,
  main: () => <Configuracoes />,
  renderInMenu: true,
  permission: Configuracao,
  submenus: [],
};
