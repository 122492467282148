import { Layout, Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import { AbilityContext } from "../core/permissions/permission.context";
import { Action } from "../core/permissions/actions/actions";
import { AuthRoutes } from "../routes";
import MenuContext from "../contexts/menu.context";
import { ProfileUser } from "./user-profile.component";
import logo from "../assets/images/logo.png";

const { Header } = Layout;
const { SubMenu } = Menu;
export default function HeaderDefault() {
  const [profileVisible, setProfileVisible] = useState(false);
  const ability = useContext(AbilityContext);
  const { setState: setGlobalState, state } = useContext(MenuContext);

  const location = useLocation();

  useEffect(() => {
    const paths = location?.pathname
      .split("/")
      .filter((p) => p !== "" && p !== "inicio");
    setGlobalState({ activeMenu: paths });
  }, [location]);

  return (
    <Header className="header" style={{ zIndex: 999 }}>
      <div className="logo">
        <Link
          to={"/inicio"}
          onClick={() => setGlobalState({ activeMenu: ["inicio"] })}
        >
          <img src={logo} className="logo" alt="Logo" />
        </Link>
      </div>

      <Menu
        theme="light"
        mode="horizontal"
        className="header-menu-main"
        selectedKeys={state.activeMenu}
        onSelect={(e) => setGlobalState({ activeMenu: e.keyPath })}
        defaultSelectedKeys={[AuthRoutes[0].id]}
      >
        {AuthRoutes.map((route: any) => {
          if (route.submenus.length > 0) {
            return (
              <>
                {ability.can(Action.Ler, route.permission) && (
                  <SubMenu
                    key={route.id}
                    icon={route.icon}
                    title={route.sidebar}
                    style={{ paddingTop: 5 }}
                  >
                    {route.submenus.map((item: any) => (
                      <>
                        {ability.can(Action.Ler, item.permission) &&
                          item.renderInMenu && (
                            <Menu.Item key={item.id} icon={item.icon}>
                              <Link
                                to={item.path}
                                onClick={() =>
                                  setGlobalState({
                                    activeMenu: [...state.activeMenu, item.id],
                                  })
                                }
                              >
                                {item.sidebar}
                              </Link>
                            </Menu.Item>
                          )}
                      </>
                    ))}
                  </SubMenu>
                )}
              </>
            );
          } else {
            return (
              <>
                {ability.can(Action.Ler, route.permission) && (
                  <Menu.Item
                    key={route.id}
                    style={{ paddingTop: 5 }}
                    icon={route.icon}
                    hidden={!route.renderInMenu}
                  >
                    <Link to={route.path}>{route.sidebar}</Link>
                  </Menu.Item>
                )}
              </>
            );
          }
        })}
      </Menu>
      <ProfileUser visible={profileVisible} setVisible={setProfileVisible} />
    </Header>
  );
}
