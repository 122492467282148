import {
  Button,
  Card,
  Checkbox,
  Col,
  Drawer,
  Form,
  Input,
  PageHeader,
  Row,
  Space,
  message,
} from "antd";
import React, { useState } from "react";

import { NovoPrazoDePagamentoDto } from "../../../services/prazo-de-pagamento/dto/novo-prazo-de-pagamento.dto";
import { PrazoDePagamentoService } from "../../../services/prazo-de-pagamento/prazo-de-pagamento.service";

export default function NovoPrazoDePagamento({
  visible,
  setVisible,
  child = false,
}) {
  const _prazoDePagamentoService = new PrazoDePagamentoService();

  const [formRef] = Form.useForm();
  const [qtdParcelasState, setQtdParcelasState] = useState(true);
  async function cadastrarPrazoDePagamentoHandler() {
    try {
      const prazoDePagamento = formRef.getFieldValue("prazoDePagamento");
      const dto: NovoPrazoDePagamentoDto = {
        ...prazoDePagamento,
        diasEntreParcelas: Number(prazoDePagamento.diasEntreParcelas),
        diasParaPrimeiraParcela: Number(
          prazoDePagamento.diasParaPrimeiraParcela
        ),
        numeroDeParcelas: Number(prazoDePagamento.numeroDeParcelas),
      };

      await _prazoDePagamentoService.cadastrarPrazoDePagamento(dto);
      message.success("Prazo de pagamento cadastrado!");
      setVisible(false);
      formRef.resetFields(["prazoDePagamento"]);
    } catch (error) {
      if (error?.response?.data?.message && typeof Array) {
        for (const e of error?.response?.data?.message.toString().split(",")) {
          message.error(e);
        }
      } else {
        message.error(error?.message);
      }
    }
  }

  return (
    <Drawer
      visible={visible}
      closable={false}
      width={950}
      bodyStyle={{ padding: 5 }}
    >
      <div>
        <PageHeader
          className="site-page-header"
          onBack={() => {
            setVisible(false);
          }}
          title="Prazo de pagamento"
          style={{ marginBottom: 10 }}
        />
        <Card title="Informações forma de pagamento">
          <Form
            form={formRef}
            layout="vertical"
            onFinish={() => cadastrarPrazoDePagamentoHandler()}
          >
            <Input.Group compact>
              <Form.Item
                style={{ width: "100%" }}
                name={["prazoDePagamento", "nome"]}
                label="Nome"
                rules={[{ required: true, message: "Nome é obrigatório" }]}
              >
                <Input placeholder="Nome" />
              </Form.Item>

              <Form.Item
                rules={[
                  { required: true, message: "Informe os dias entre parcelas" },
                ]}
                style={{ width: "33%", paddingRight: 5 }}
                name={["prazoDePagamento", "diasEntreParcelas"]}
                initialValue={30}
                label="Dias entre parcelas"
              >
                <Input placeholder="Dias entre parcelas" type="number" />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Informe os dias para primeira parcela",
                  },
                ]}
                style={{ width: "25%", paddingRight: 5 }}
                name={["prazoDePagamento", "diasParaPrimeiraParcela"]}
                label="Dias para primeira parcela"
              >
                <Input placeholder="Dias para primeira parcela" type="number" />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: qtdParcelasState,
                    message: "Informe os dias para primeira parcela",
                  },
                ]}
                style={{ width: "20%", paddingRight: 5 }}
                name={["prazoDePagamento", "numeroDeParcelas"]}
                label="Quantidade De Parcelas"
              >
                <Input
                  placeholder="Dias para primeira parcela"
                  type="number"
                  disabled={!qtdParcelasState}
                />
              </Form.Item>
              <Form.Item
                style={{ marginTop: 32, marginLeft: 5 }}
                name={["prazoDePagamento", "solicitarQtdParcelas"]}
                initialValue={false}
                valuePropName="checked"
              >
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setQtdParcelasState(false);
                      formRef.setFieldsValue({
                        prazoDePagamento: {
                          numeroDeParcelas: 0,
                        },
                      });
                    } else {
                      setQtdParcelasState(true);
                      formRef.setFieldsValue({
                        prazoDePagamento: {
                          numeroDeParcelas: 1,
                        },
                      });
                    }
                  }}
                >
                  Solicitar ao usuário ?
                </Checkbox>
              </Form.Item>
              <Form.Item
                style={{ width: "100%" }}
                name={["prazoDePagamento", "descricao"]}
                label="Descrição"
              >
                <Input.TextArea placeholder="Descrição" />
              </Form.Item>
            </Input.Group>

            <Row>
              <Col span={18} />
              <Col span={6} style={{ marginTop: "30px", textAlign: "end" }}>
                <Space>
                  <Button type="primary" size="middle" htmlType="submit">
                    SALVAR
                  </Button>
                  <Button
                    type="ghost"
                    size="middle"
                    onClick={() => {
                      setVisible(false);
                      formRef.resetFields();
                    }}
                  >
                    CANCELAR
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </Drawer>
  );
}
