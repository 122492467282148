import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  PageHeader,
  Row,
  Space,
  message,
} from "antd";
import React, { useState } from "react";

import { FormaDePagamentoService } from "../../../services/formas-de-pagamento/formas-de-pagamento.service";
import { NovaFormaDePagamentoDto } from "../../../services/formas-de-pagamento/dto/nova-forma-de-pagamento.dto";
import { useHistory } from "react-router-dom";

export default function NovaFormaDePagamento({
  visible,
  setVisible,
  child = false,
}) {
  const _formaDePagamentoService = new FormaDePagamentoService();
  const history = useHistory();
  const [formRef] = Form.useForm();
  const [habilitarPercentual, setHabilitarPercentual] = useState(true);

  async function cadastrarFormaDePagamentoHandler() {
    try {
      const formaDePagamento = formRef.getFieldValue("formaDePagamento");
      const dto: NovaFormaDePagamentoDto = {
        ...formaDePagamento,
        percentualTaxaAdministrativa: Number(
          formaDePagamento.percentualTaxaAdministrativa
        ),
        diasEntreParcelas: Number(formaDePagamento.diasEntreParcelas),
        diasParaPrimeiraParcela: Number(
          formaDePagamento.diasParaPrimeiraParcela
        ),
        numeroDeParcelas: Number(formaDePagamento.numeroDeParcelas),
      };

      await _formaDePagamentoService.cadastrarFormaDePagamento(dto);
      message.success("Forma de Pagamento Cadastrada!");
      setVisible(false);
      formRef.resetFields(["formaDePagamento"]);
    } catch (error) {
      if (error?.response?.data?.message && typeof Array) {
        for (const e of error?.response?.data?.message.toString().split(",")) {
          message.error(e);
        }
      } else {
        message.error(error?.message);
      }
    }
  }

  return (
    <Drawer
      visible={visible}
      closable={false}
      width={900}
      bodyStyle={{ padding: 5 }}
    >
      <div>
        <PageHeader
          className="site-page-header"
          onBack={() => {
            setVisible(false);
          }}
          title="Nova forma de pagamento"
          style={{ marginBottom: 10 }}
        />
        <Card title="Informações forma de pagamento">
          <Form
            form={formRef}
            layout="vertical"
            onFinish={() => cadastrarFormaDePagamentoHandler()}
          >
            <Input.Group compact>
              <Form.Item
                style={{ width: "100%" }}
                name={["formaDePagamento", "nome"]}
                label="Nome"
                rules={[{ required: true, message: "Nome é obrigatório" }]}
              >
                <Input placeholder="Nome" />
              </Form.Item>
              <Form.Item
                style={{ width: "100%" }}
                name={["formaDePagamento", "descricao"]}
                label="Descrição"
              >
                <Input.TextArea placeholder="Descrição" />
              </Form.Item>
            </Input.Group>

            <Row>
              <Col span={18} />
              <Col span={6} style={{ marginTop: "30px", textAlign: "end" }}>
                <Space>
                  <Button type="primary" size="middle" htmlType="submit">
                    SALVAR
                  </Button>
                  <Button
                    type="ghost"
                    size="middle"
                    onClick={() => {
                      setVisible(false);
                      formRef.resetFields();
                    }}
                  >
                    CANCELAR
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </Drawer>
  );
}
