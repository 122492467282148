import {
  Button,
  Card,
  DatePicker,
  Form,
  PageHeader,
  Select,
  Table,
  Tag,
  message,
} from "antd";
import { FormOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import React, { useState } from "react";
import {
  StatusRequisicaoDeCompraInfo,
  statusRequisicaoDeCompraList,
} from "../../../core/enums/status-requisicao-de-compra.enum";

import { ColaboradorService } from "../../../services/colaborador/colaborador.service";
import { EmpresaService } from "../../../services/empresa/empresas.service";
import { Endpoints } from "../../../core/constants/endpoints";
import { FornecedorService } from "../../../services/fornecedor/fornecedor.service";
import NovaRequisicaoDeCompra from "./nova-requisicao-de-compra.modal";
import { RequisicaoDeCompra as RequisicaoDeCompraPermissao } from "../../../core/permissions/subjects";
import { RequisicaoDeCompraService } from "../../../services/requisicao-de-compra/requisicao-de-compra.service";
import { identificadorFormatter } from "../../../core/utils/formatters/job.formatter";
import moment from "moment";
import { sequencialRequisicaoDeCompraFormatter } from "../../../core/utils/formatters/sequencial.formatter";
import useSWR from "swr";
import useTitle from "../../../core/hooks/title.hook";

export default function RequisicoesDeCompra() {
  useTitle(`Requisições de compra`);
  const history = useHistory();
  const [filtrosRequisicoesFormRef] = Form.useForm();
  const [filtrosPedidosFormRef] = Form.useForm();
  const _requisicaoDeCompraService = new RequisicaoDeCompraService();
  const _empresaService = new EmpresaService();
  const _colaboradorService = new ColaboradorService();
  const _fornecedorService = new FornecedorService();
  const [exibirCadastroRequisicao, setExibirCadastroRequisicao] =
    useState(false);
  const [atualizarLista, setAtualizarLista] = useState(false);
  const [filtro, setFiltro] = useState("");
  const [optionsFornecedor, setOptionsFornecedor] = useState([]);
  const [optionsFornecedorSearch, setOptionsFornecedorSearch] = useState(false);
  const [optionsSolicitante, setOptionsSolicitante] = useState([]);
  const [optionsSolicitanteSearch, setOptionsSolicitanteSearch] =
    useState(false);
  const [optionsEmpresa, setOptionsEmpresa] = useState([]);
  const [optionsEmpresaSearch, setOptionsEmpresaSearch] = useState(false);
  const { data: { data: { data: requisicoesDeCompra } = {} } = {}, error } =
    useSWR(
      Endpoints.requisicoesDeCompra + filtro + atualizarLista,
      () => _requisicaoDeCompraService.obterTodos(filtro),
      { revalidateOnFocus: false }
    );

  if (error) message.error(error?.message);

  function handleSearch(values: any) {
    const { intervalo, status, empresa, solicitante, fornecedor } = values;

    const [dataInicio, dataFim] = intervalo || [];
    let query = `${status ? `status=${status.toString()}` : ""}${
      intervalo
        ? `&intervalo=${dataInicio.toString()},${dataFim.toString()}`
        : ""
    }${empresa ? `&empresa=${empresa}` : ""}
    ${fornecedor ? `&fornecedor=${fornecedor}` : ""}
    ${solicitante ? `&solicitante=${solicitante}` : ""}`;

    sessionStorage.setItem(
      "filtros-requisicoes",
      JSON.stringify({
        intervalo,
        status,
        empresa,
        solicitante,
        fornecedor,
        query,
        optionsFornecedor: optionsFornecedor ? optionsFornecedor : null,
        optionsEmpresa: optionsEmpresa ? optionsEmpresa : null,
        optionsSolicitante: optionsSolicitante ? optionsSolicitante : null,
      })
    );
    setFiltro(query);
  }
  const columns = [
    {
      title: "Requisição",
      dataIndex: "sequencial",
      width: 105,
      render: (value) => sequencialRequisicaoDeCompraFormatter(String(value)),
    },
    {
      title: "Solicitante",
      dataIndex: "solicitante",
      render: (value) => value.nome,
    },
    {
      title: "Job",
      width: 250,
      dataIndex: "job",
      render: (value) => (
        <a>
          <Link to={`/inicio/atendimento/jobs/${value.id}`}>
            {identificadorFormatter(value.identificador)} - {value.nome}
          </Link>
        </a>
      ),
    },
    {
      title: "Contratante",
      dataIndex: "empresa",
      render: (value) => (
        <a>
          <Link to={`/inicio/financeiro/empresas/${value?.id}`}>
            {value?.nomeFantasia}
          </Link>
        </a>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 210,
      render: (value) => (
        <Tag color={StatusRequisicaoDeCompraInfo[value].color}>
          {StatusRequisicaoDeCompraInfo[value].label}
        </Tag>
      ),
    },
    {
      title: "Data Solicitação",
      width: 140,
      dataIndex: "createdAt",
      render: (value) => moment(value).format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "Data de Entrega",
      width: 140,
      dataIndex: "dataDeEntrega",
      render: (value) => moment(value).format("DD/MM/YYYY "),
    },
    {
      title: "Ação",
      width: 120,
      render: (_, obj) => (
        <a>
          <Link to={`/inicio/atendimento/requisicoes-de-compra/${obj.id}`}>
            Acessar
          </Link>
        </a>
      ),
    },
  ];
  return (
    <div>
      <NovaRequisicaoDeCompra
        atualizarPai={setAtualizarLista}
        visible={exibirCadastroRequisicao}
        setVisible={setExibirCadastroRequisicao}
      />
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title="Requisições de compra"
        subTitle="Todas as requisições de compra"
        style={{ marginBottom: 5 }}
        extra={
          <Button
            type="primary"
            size="middle"
            icon={<PlusOutlined />}
            onClick={() => setExibirCadastroRequisicao(true)}
          >
            Nova Requisição
          </Button>
        }
      />
      <Card style={{ marginBottom: 5 }} title="Filtros" size="small">
        <Form
          layout="inline"
          style={{ display: "flex" }}
          onFinish={(values) => handleSearch(values)}
        >
          <Form.Item name="intervalo" style={{ flex: 1 }}>
            <DatePicker.RangePicker
              format="DD/MM/yyyy"
              style={{ display: "flex" }}
            />
          </Form.Item>
          <Form.Item name="status" style={{ flex: 1 }}>
            <Select
              placeholder="Status"
              options={statusRequisicaoDeCompraList}
              mode="tags"
            />
          </Form.Item>
          <Form.Item style={{ flex: 1 }} name="empresa">
            <Select
              placeholder="Empresa"
              showSearch
              style={{ display: "flex" }}
              options={optionsEmpresa}
              loading={optionsEmpresaSearch}
              filterOption={(input, option) =>
                option?.label
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onSearch={async (value) => {
                if (value.length >= 3) {
                  setOptionsEmpresaSearch(true);
                  const result =
                    await _empresaService.obterEmpresasSimplificado(value);
                  const opts = result?.data?.data.map((o) => {
                    return {
                      key: o.id,
                      value: o.id,
                      label: o.nomeFantasia,
                    };
                  });
                  setOptionsEmpresa(opts);
                  setOptionsEmpresaSearch(false);
                }
              }}
            />
          </Form.Item>

          <Form.Item name="solicitante" style={{ flex: 1 }}>
            <Select
              placeholder="Solicitante"
              showSearch
              options={optionsSolicitante}
              loading={optionsSolicitanteSearch}
              filterOption={(input, option) =>
                option?.label
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onSearch={async (value) => {
                if (value.length >= 3) {
                  setOptionsSolicitanteSearch(true);
                  const result = await _colaboradorService.obterSimplificado(
                    value
                  );
                  const opts = result?.data?.data.map((o) => {
                    return {
                      key: o.id,
                      value: o.id,
                      label: o.nome,
                    };
                  });
                  setOptionsSolicitante(opts);
                  setOptionsSolicitanteSearch(false);
                }
              }}
            />
          </Form.Item>
          <Form.Item name="fornecedor" style={{ flex: 1 }}>
            <Select
              placeholder="Fornecedor"
              showSearch
              options={optionsFornecedor}
              loading={optionsFornecedorSearch}
              filterOption={(input, option) =>
                option?.label
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onSearch={async (value) => {
                if (value.length >= 3) {
                  setOptionsFornecedorSearch(true);
                  const result = await _fornecedorService.obterSimplificado(
                    value
                  );
                  const opts = result?.data?.data.map((o) => {
                    return {
                      key: o.id,
                      value: o.id,
                      label: o.nomeFantasia,
                    };
                  });

                  setOptionsFornecedor(opts);
                  setOptionsFornecedorSearch(false);
                }
              }}
            />
          </Form.Item>
          <Button type="primary" icon={<SearchOutlined />} htmlType="submit" />
        </Form>
      </Card>
      <Table
        bordered
        scroll={{ y: window.screen.height - window.screen.height * 0.54 }}
        columns={columns}
        dataSource={requisicoesDeCompra?.itens}
        loading={!requisicoesDeCompra?.itens}
      />
    </div>
  );
}

export const RequisicoesDeCompraRoute = {
  id: "requisicoes-de-compra",
  path: "/inicio/atendimento/requisicoes-de-compra",
  sidebar: "Requisições de compra",
  exact: true,
  renderInMenu: false,
  main: () => <RequisicoesDeCompra />,
  icon: <FormOutlined />,
  permission: RequisicaoDeCompraPermissao,
};
