import { ClienteService } from "../../services/cliente/cliente.service";
import { useState } from "react";

export default function useBuscarClienteHook() {
  const [optionsCliente, setOptionsCliente] = useState([]);
  const [optionsClienteSearch, setOptionsClienteSearch] = useState(false);
  const _clienteService = new ClienteService();

  const filterOptions = (input, option) =>
    option?.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;

  async function buscarCliente(value) {
    if (value.length >= 3) {
      setOptionsClienteSearch(true);
      const result = await _clienteService.obterVersaoSimplificada(
        String(value).toLowerCase()
      );
      const opts = result?.data?.data.map((o) => {
        return {
          key: o.id,
          value: o.id,
          label: o.nomeFantasia,
        };
      });
      setOptionsCliente(opts);
      setOptionsClienteSearch(false);
    }
  }

  return {
    options: optionsCliente,
    loading: optionsClienteSearch,
    buscarCliente,
    setOptions: setOptionsCliente,
    filterOptions,
  };
}
