export enum StatusContaAReceber {
  Faturado = 0,
  Pago = 1,
  Vencido = 2,
  Conciliado = 3,
  Cancelado = 4,
}

export const StatusContaAReceberDescricao: {
  [key in StatusContaAReceber]: string;
} = {
  [StatusContaAReceber.Faturado]: "FATURADO",
  [StatusContaAReceber.Vencido]: "VENCIDO",
  [StatusContaAReceber.Pago]: "PAGO",
  [StatusContaAReceber.Conciliado]: "CONCILIADO",
  [StatusContaAReceber.Cancelado]: "CANCELADO",
};

export const StatusContaAReceberDescricaoCor: {
  [key in StatusContaAReceber]: string;
} = {
  [StatusContaAReceber.Faturado]: "blue",
  [StatusContaAReceber.Vencido]: "red",
  [StatusContaAReceber.Pago]: "green",
  [StatusContaAReceber.Conciliado]: "blue",
  [StatusContaAReceber.Cancelado]: "gold",
};

function enumToArray(enumerator: any) {
  const list = [];
  for (
    let index = 0;
    index <
    Object.values(enumerator).filter((value) => !isNaN(Number(value))).length;
    index++
  ) {
    const key = Object.keys(enumerator)[index];
    const value = StatusContaAReceberDescricao[index];
    list.push({
      key: Number(key),
      value: Number(key),
      label: value.toUpperCase(),
    });
  }
  return list;
}

export const statusContaAReceberList = enumToArray(StatusContaAReceber);
