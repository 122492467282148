export function currencyFormatter(value: number, currency: string) {
  const formater = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency,
  });
  return formater.format(value);
}

export function currencyFormatterMinimum(value: number) {
  const formater = new Intl.NumberFormat("pt-BR", {
    currency: "BRL",
    style: "decimal",
  });
  return formater.format(value);
}
