import { useEffect, useState } from "react";

import { EmpresaService } from "../../services/empresa/empresas.service";

export default function useBuscarEmpresaHook() {
  const [optionsEmpresa, setOptionsEmpresa] = useState([]);
  const [optionsEmpresaSearch, setOptionsEmpresaSearch] = useState(false);
  const _empresaService = new EmpresaService();

  useEffect(() => {
    _empresaService.obterEmpresasSimplificado(null).then((result) => {
      const opts = result?.data?.data.map((o) => {
        return {
          key: o.id,
          value: o.id,
          label: o.nomeFantasia,
          regimeTributario: o.regimeTributario,
        };
      });
      setOptionsEmpresa(opts);
    });
  }, []);

  const filterOptions = (input, option) =>
    option?.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;

  async function buscarEmpresa(value) {
    if (value.length >= 3) {
      setOptionsEmpresaSearch(true);
      const result = await _empresaService.obterEmpresasSimplificado(value);
      const opts = result?.data?.data.map((o) => {
        return {
          key: o.id,
          value: o.id,
          label: o.nomeFantasia,
        };
      });
      setOptionsEmpresa(opts);
      setOptionsEmpresaSearch(false);
    }
  }

  return {
    options: optionsEmpresa,
    loading: optionsEmpresaSearch,
    buscarEmpresa,
    setOptions: setOptionsEmpresa,
    filterOptions,
  };
}
