import { Button, Result } from "antd";

import { Gerencial as GerencialPermissao } from "../../core/permissions/subjects";
import { LineChartOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

export default function Gerencial() {
  const history = useHistory();
  return (
    <Result
      status="404"
      title="404"
      subTitle="Conteúdo indisponível no momento"
      extra={
        <Button type="primary" onClick={() => history.push("/inicio")}>
          Voltar para home
        </Button>
      }
    />
  );
}

export const GerencialRoute = {
  id: "gerencial",
  path: "/inicio/gerencial",
  exact: true,
  sidebar: "Gerencial",
  icon: <LineChartOutlined />,
  main: () => <Gerencial />,
  renderInMenu: false,
  permission: GerencialPermissao,
  submenus: [],
};
