import {
  Button,
  Card,
  Col,
  DatePicker,
  Descriptions,
  Form,
  Input,
  Modal,
  PageHeader,
  Popconfirm,
  Popover,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Tag,
  message,
} from "antd";
import {
  CheckCircleTwoTone,
  DeleteTwoTone,
  EditTwoTone,
  PlusCircleOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { Link, useHistory, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  StatusParcela,
  StatusParcelaDescricao,
  StatusParcelaList,
} from "../../../core/enums/status-parcela.enum";
import { default as c, default as currencyFormatter } from "currency-formatter";

import { AtualizarContaAPagarDto } from "../../../services/conta-a-pagar/dto/atualizar-conta-a-pagar.dto";
import CondicaoDePagamentoPopup from "../../../components/condicao-de-pagamento.component";
import { ContaAPagarService } from "../../../services/conta-a-pagar/conta-a-pagar.service";
import { ContaBancariaService } from "../../../services/conta-bancaria/conta-bancaria.service";
import CurrencyInput from "react-currency-input-field";
import { EmpresaService } from "../../../services/empresa/empresas.service";
import { Endpoints } from "../../../core/constants/endpoints";
import { FornecedorService } from "../../../services/fornecedor/fornecedor.service";
import Loading from "../../../components/loading.component";
import NovaFormaDePagamento from "../formas-de-pagamento/nova-forma-de-pagamento.page";
import NovoGrupoFinanceiro from "../grupos-financeiros/novo-grupo-financeiro.page";
import { RegistrarPagamentoContaAPagarDto } from "../../../services/conta-a-pagar/dto/registrar-pagamento-conta-a-pagar.dto";
import { StatusContaAPagar } from "../../../core/enums/status-conta-a-pagar.enum";
import { cnpjFormatter } from "../../../core/utils/formatters/cnpj.formatter";
import { cpfFormatter } from "../../../core/utils/formatters/cpj.formatter";
import { identificadorFormatter } from "../../../core/utils/formatters/job.formatter";
import moment from "moment";
import { v4 as newId } from "uuid";
import { sequencialPedidoDeCompraFormatter } from "../../../core/utils/formatters/sequencial.formatter";
import useBuscarJobHook from "../../../components/hooks/buscar-job.hook";
import useGruposFinanceirosHook from "../../../components/hooks/buscar-grupo-financeiro.hook";
import useSWR from "swr";
import useTitle from "../../../core/hooks/title.hook";

export default function ContaAPagar() {
  //#region Variables and States
  const _contaAPagarService = new ContaAPagarService();
  const { id } = useParams<any>();
  const [parcelaParaPagamento, setParcelaParaPagamento] = useState(null);
  const [revalidar, setRevalidar] = useState(Date.now());
  const [itens, setItens] = useState([]);
  const { data: { data: { data: contaAPagarData } = {} } = {} } = useSWR(
    Endpoints.contasAPagar + id + revalidar,
    () => _contaAPagarService.obterPorId(id),
    { revalidateOnFocus: false }
  );

  useTitle(`Conta a pagar ${contaAPagarData?.sequencial}`);
  const [parcela, setParcela] = useState(null);

  const _empresaService = new EmpresaService();
  const _fornecedorService = new FornecedorService();

  const history = useHistory();
  const [formRef] = Form.useForm();
  const [itemSelecionado, setItemSelecionado] = useState(null);
  const [optionsEmpresa, setOptionsEmpresa] = useState([]);
  const [optionsEmpresaSearch, setOptionsEmpresaSearch] = useState(false);
  const [optionsFornecedor, setOptionsFornecedor] = useState([]);
  const [optionsFornecedorSearch, setOptionsFornecedorSearch] = useState(false);
  const [optionsFormaDePagamento, setOptionsFormaDePagamento] = useState([]);
  const [optionsFormaDePagamentoSearch, setOptionsFormaDePagamentoSearch] =
    useState(false);
  const [formularioIniciado, setFormularioIniciado] = useState(false);
  const [formaDePagamento, setFormaDePagamento] = useState(false);
  const [parcelas, setParcelas] = useState(contaAPagarData?.parcelas || []);
  const [calcularParcelas, setCalcularParcelas] = useState(false);
  const [cadastroGrupoFinanceiro, setCadastroGrupoFinanceiro] = useState(false);

  const { options: optsGruposFinanceiros } =
    useGruposFinanceirosHook("despesa");

  //#endregion

  //#region Methods

  async function estornarContaAPagarHandler(id: string) {
    try {
      await _contaAPagarService.estornar(id);
      message.success("Conta a pagar cancelada");
      history.goBack();
    } catch (error) {
      message.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error.message
      );
    }
  }

  function calcularTotal() {
    let total = 0;
    for (const item of itens) {
      total += Number(item.valor);
    }

    formRef.setFieldsValue({
      contaAPagar: {
        valor: total,
      },
    });

    return total;
  }

  async function atualizarNovaContaAPagarHandler() {
    try {
      const contaAPagar = formRef.getFieldValue("contaAPagar");
      const dto: AtualizarContaAPagarDto = {
        id,
        descricao: contaAPagar?.descricao,
        valor: contaAPagar?.valor,
        dataVencimento: contaAPagar?.dataVencimento.toString(),
        formaDePagamento: { id: contaAPagar?.formaDePagamento },
        prazoDePagamento: { id: contaAPagar?.prazoDePagamento },
        grupoFinanceiro: { id: contaAPagar?.grupoFinanceiro },
        fornecedor: { id: contaAPagar?.fornecedor },
        empresa: { id: contaAPagar?.empresa },
        pedidoDeCompra: contaAPagarData?.pedidoDeCompra
          ? { id: contaAPagarData?.pedidoDeCompra?.id }
          : null,
        itens: itens.map((i) => {
          return {
            id: i?.id,
            descricao: i?.descricao,
            valor: i?.valor,
            job: i?.job ? { id: i?.job?.key } : null,
          };
        }),
      };
      await _contaAPagarService.atualizarContaAPagar(id, dto);
      message.success("Conta a pagar atualizada");
      history.goBack();
    } catch (error) {
      if (error?.response?.data?.message && typeof Array) {
        for (const e of error?.response?.data?.message.toString().split(",")) {
          message.error(e);
        }
      } else {
        message.error(error?.message);
      }
    }
  }

  function iniciarForm(
    setOptionsEmpresa: React.Dispatch<React.SetStateAction<any[]>>,
    contaAPagarData: any,
    setOptionsFornecedor: React.Dispatch<React.SetStateAction<any[]>>,
    setOptionsFormaDePagamento: React.Dispatch<React.SetStateAction<any[]>>,
    formRef
  ) {
    setOptionsEmpresa([
      {
        key: contaAPagarData?.empresa?.id,
        value: contaAPagarData?.empresa?.id,
        label: contaAPagarData?.empresa?.nomeFantasia,
      },
    ]);
    setOptionsFornecedor([
      {
        key: contaAPagarData?.fornecedor?.id,
        value: contaAPagarData?.fornecedor?.id,
        label: contaAPagarData?.fornecedor?.nomeFantasia,
      },
    ]);

    setOptionsFormaDePagamento([
      {
        key: contaAPagarData?.formaDePagamento?.id,
        value: contaAPagarData?.formaDePagamento?.id,
        label: contaAPagarData?.formaDePagamento?.nome,
      },
    ]);

    setParcelas(contaAPagarData?.parcelas);
    formRef.setFieldsValue({
      contaAPagar: {
        empresa: contaAPagarData?.empresa?.id,
        formaDePagamento: contaAPagarData?.formaDePagamento?.id,
        grupoFinanceiro: contaAPagarData?.grupoFinanceiro?.id,
        fornecedor: contaAPagarData?.fornecedor?.id,
        valor: contaAPagarData?.valor,
        descricao: contaAPagarData?.descricao,
        observacao: contaAPagarData?.observacao,
        dataVencimento: moment(contaAPagarData?.dataVencimento),
      },
    });

    setItens(
      contaAPagarData?.itens?.map((i) => {
        return {
          id: i.id,
          descricao: i.descricao,
          job: i?.job
            ? {
                key: i?.job?.id,
                value: i?.job?.id,
                label: `${identificadorFormatter(
                  String(i?.job?.identificador)
                )} - ${i?.job?.nome}`,
              }
            : null,
          valor: i.valor,
        };
      })
    );
  }

  async function estornarPagamentoHandler(parcelaId: string) {
    try {
      await _contaAPagarService.estornarPagamento(parcelaId);
      message.success("Pagamento estornado");
      setRevalidar(Date.now());
    } catch (error) {
      message.error(error.response?.data?.message || error.message);
    }
  }

  function AdicionarItemForm() {
    const [valorItem, setValorItem] = useState("0");
    const [itemFormRef] = Form.useForm();
    const {
      options: optsJobs,
      filterOption: filterOptsJobs,
      loading: loadingJobs,
    } = useBuscarJobHook();

    useEffect(() => {
      if (itemSelecionado) {
        setValorItem(itemSelecionado?.valor);
        itemFormRef.setFieldsValue({
          ...itemSelecionado,
          job: itemSelecionado?.job?.value,
        });
      }
    }, [itemSelecionado]);

    async function adicionarItem() {
      const item = itemFormRef.getFieldsValue([
        "id",
        "descricao",
        "valor",
        "job",
      ]);
      await itemFormRef.validateFields();
      if (itemSelecionado) {
        item.valor = currencyFormatter.unformat(item.valor, {
          code: "BRL",
        });
        item.job = optsJobs.filter((o) => o.key === item.job).shift();
        const itensNew = itens.filter((i) => i.id !== itemSelecionado.id);
        itensNew.push(item);
        setItens(itensNew);
        setItemSelecionado(null);
      } else {
        item.job = optsJobs.filter((o) => o.key === item.job).shift();

        setItens([...itens, item]);
      }
    }

    return (
      <div title="Adicionar Item" style={{ marginBottom: -20 }}>
        <Form
          form={itemFormRef}
          layout="horizontal"
          onFinish={() => adicionarItem()}
        >
          <div
            style={{ display: "flex" }}
            onKeyPress={(e) => {
              if (e.key === "Enter" || e.key === "Tab") {
                itemFormRef.submit();
              }
            }}
          >
            <Input.Group compact>
              <Form.Item hidden initialValue={newId()} name="id" />
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
                name="descricao"
                style={{ width: "50%", paddingRight: 5 }}
              >
                <Input placeholder="Descrição" />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
                style={{ width: "15%", paddingRight: 5 }}
              >
                <CurrencyInput
                  placeholder="Valor"
                  className="ant-input ant-input-sm"
                  decimalScale={2}
                  value={valorItem}
                  onValueChange={(value = "0") => {
                    setValorItem(value);
                    itemFormRef.setFieldsValue({
                      valor: currencyFormatter.unformat(value, {
                        code: "BRL",
                      }),
                    });
                  }}
                  intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                />
              </Form.Item>
              <Form.Item style={{ width: "35%" }} name="job">
                <Select
                  showSearch
                  placeholder="Selecione o Job (opcional)"
                  options={optsJobs}
                  filterOption={filterOptsJobs}
                  loading={loadingJobs}
                />
              </Form.Item>
            </Input.Group>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={() => itemFormRef.submit()}
            >
              {itemSelecionado ? "atualizar Item" : "adicionar item"}
            </Button>
          </div>
        </Form>
      </div>
    );
  }

  function EditarParcela() {
    const [parcelaFormRef] = Form.useForm();
    return (
      <Modal
        okText="ATUALIZAR"
        cancelText="CANCELAR"
        visible={parcela}
        width={600}
        bodyStyle={{ padding: 0 }}
        closable={false}
        onOk={async () => {
          try {
            const p = parcelaFormRef.getFieldValue("parcela");

            await _contaAPagarService.atualizarParcela(id, {
              id: parcela?.id,
              dataVencimento: p?.dataVencimento,
              status: parcela?.status,
            });

            for (let index = 0; index < parcelas?.length; index++) {
              if (parcelas[index]?.id === parcela?.id) {
                parcelas[index].dataVencimento = p?.dataVencimento;
                break;
              }
            }

            setParcela(null);
            setRevalidar(Date.now());
          } catch (error) {
            if (error?.response?.data?.message && typeof Array) {
              for (const e of error?.response?.data?.message
                .toString()
                .split(",")) {
                message.error(e);
              }
            } else {
              message.error(error?.message);
            }
          }
        }}
        onCancel={() => {
          setParcela(null);
          parcelaFormRef.resetFields();
        }}
      >
        <Card title={`Alterar vencimento - ${parcela?.descricao}`}>
          <Form layout="vertical" form={parcelaFormRef}>
            <Input.Group compact>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Data de vencimento é obrigatório",
                  },
                ]}
                style={{ width: "30%", paddingRight: 5 }}
                name={["parcela", "dataVencimento"]}
                label="Novo vencimento"
                initialValue={moment(parcela?.dataVencimento)}
              >
                <DatePicker
                  disabledDate={(date) =>
                    date.toDate().getDay() === 6 || date.toDate().getDay() === 0
                  }
                  format="DD/MM/YYYY"
                  style={{ width: "100%" }}
                  placeholder="Vencimento em"
                />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Descrição é obrigatório",
                  },
                ]}
                initialValue={parcela?.descricao}
                style={{ width: "40%", paddingRight: 5 }}
                name={["parcela", "descricao"]}
                label="Descrição"
              >
                <Input
                  style={{ width: "100%" }}
                  placeholder="Descrição"
                  disabled={true}
                />
              </Form.Item>
              <Form.Item
                style={{ width: "30%", paddingRight: 5 }}
                name={["parcela", "status"]}
                rules={[
                  {
                    required: true,
                    message: "Status é obrigatório",
                  },
                ]}
                initialValue={parcela?.status}
                label="Situação"
              >
                <Select
                  disabled={true}
                  style={{ width: "100%" }}
                  placeholder="Selecione o Status"
                  options={StatusParcelaList}
                />
              </Form.Item>
            </Input.Group>
          </Form>
        </Card>
      </Modal>
    );
  }

  function RegistrarPagamento() {
    const [registrarPagamentoFormRef] = Form.useForm();
    const [optsContasBancarias, setOptsContasBancarias] = useState([]);
    const [loadingOptsContasBancararias, setLoadingOptsContasBancararias] =
      useState(false);
    const _contaBancariaService = new ContaBancariaService();
    return (
      <Modal
        okText="REGISTRAR PAGAMENTO"
        cancelText="CANCELAR"
        visible={parcelaParaPagamento}
        width={600}
        bodyStyle={{ padding: 0 }}
        closable={false}
        onOk={async () => {
          await registrarPagamentoFormRef.validateFields();
          try {
            const p = registrarPagamentoFormRef.getFieldValue("parcela");
            const dto: RegistrarPagamentoContaAPagarDto = {
              parcela: { id: parcelaParaPagamento?.id },
              contaBancaria: { id: p?.contaBancaria },
              dataPagamento: p?.dataPagamento?.toISOString(),
            };
            await _contaAPagarService.registrarPagamento(id, dto);

            for (let index = 0; index < parcelas?.length; index++) {
              if (parcelas[index]?.id === parcelaParaPagamento?.id) {
                parcelas[index].status = StatusParcela.Paga;
                break;
              }
            }

            setParcelas(parcelas);
            setParcelaParaPagamento(null);
            setRevalidar(Date.now());
          } catch (error) {
            if (error?.response?.data?.message && typeof Array) {
              for (const e of error?.response?.data?.message
                .toString()
                .split(",")) {
                message.error(e);
              }
            } else {
              message.error(error?.message);
            }
          }
        }}
        onCancel={() => {
          setParcelaParaPagamento(null);
          registrarPagamentoFormRef.resetFields();
        }}
      >
        <Card
          title={`Registrar Pagamento de ${parcelaParaPagamento?.descricao}`}
        >
          <Form layout="vertical" form={registrarPagamentoFormRef}>
            <Input.Group compact>
              <Form.Item
                style={{ width: "50%", paddingRight: 5 }}
                name={["parcela", "dataPagamento"]}
                label="Data Pagamento"
                rules={[
                  { required: true, message: "Informe a data de pagamento" },
                ]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  style={{ width: "100%" }}
                  placeholder="Pago em"
                />
              </Form.Item>
              <Form.Item
                style={{ width: "50%", paddingRight: 5 }}
                rules={[
                  { required: true, message: "Conta bancária é obrigatório" },
                ]}
                name={["parcela", "contaBancaria"]}
                initialValue={parcela?.status}
                label="Conta bancária"
              >
                <Select
                  loading={loadingOptsContasBancararias}
                  style={{ width: "100%" }}
                  onClick={async () => {
                    if (optsContasBancarias?.length === 0) {
                      setLoadingOptsContasBancararias(true);
                      const response = await _contaBancariaService.obterTodas();
                      const opts = response?.data?.data?.map((c) => {
                        return {
                          key: c.id,
                          value: c.id,
                          label: c?.descricao,
                        };
                      });

                      setOptsContasBancarias(opts);
                      setLoadingOptsContasBancararias(false);
                    }
                  }}
                  placeholder="Selecione a conta bancária"
                  options={optsContasBancarias}
                />
              </Form.Item>
            </Input.Group>
          </Form>
        </Card>
      </Modal>
    );
  }

  //#endregion

  useEffect(() => {
    formRef.setFieldsValue({
      contaAPagar: {
        dataVencimento: moment(contaAPagarData?.dataVencimento),
      },
    });

    if (!formularioIniciado && contaAPagarData?.id)
      iniciarForm(
        setOptionsEmpresa,
        contaAPagarData,
        setOptionsFornecedor,
        setOptionsFormaDePagamento,
        formRef
      );
  }, [cadastroGrupoFinanceiro, contaAPagarData]);

  return contaAPagarData?.id ? (
    <div>
      <NovaFormaDePagamento
        visible={formaDePagamento}
        setVisible={setFormaDePagamento}
        child={true}
      />
      <EditarParcela />
      <RegistrarPagamento />
      <NovoGrupoFinanceiro
        tipo={"despesa"}
        visible={cadastroGrupoFinanceiro}
        setVisible={setCadastroGrupoFinanceiro}
      />
      <PageHeader
        className="site-page-header"
        onBack={() => {
          history.goBack();
        }}
        title={`Conta a pagar - ${contaAPagarData.sequencial}`}
        subTitle="Atualizar conta a pagar"
        style={{ marginBottom: 10 }}
        extra={
          <>
            <Popconfirm
              title="Deseja realmente cancelar a conta a pagar ?"
              placement="bottom"
              onConfirm={() => estornarContaAPagarHandler(id)}
              okText="confirmar"
              cancelText="Cancelar"
              disabled={contaAPagarData.status === StatusContaAPagar.Cancelado}
            >
              <Button
                type="primary"
                icon={<UndoOutlined />}
                className="btn-danger"
                disabled={
                  contaAPagarData.status === StatusContaAPagar.Cancelado
                }
              >
                CANCELAR
              </Button>
            </Popconfirm>
          </>
        }
      />

      <Card
        title={
          <>
            Informações Conta A Pagar
            {contaAPagarData?.pedidoDeCompra && (
              <Link
                to={`/inicio/financeiro/pedidos-de-compra/${contaAPagarData?.pedidoDeCompra?.id}`}
              >
                {" - "}
                {sequencialPedidoDeCompraFormatter(
                  String(contaAPagarData?.pedidoDeCompra?.sequencial)
                )}
              </Link>
            )}
          </>
        }
      >
        <Form
          form={formRef}
          layout="vertical"
          onFinish={() => atualizarNovaContaAPagarHandler()}
        >
          <Row>
            <Col sm={24}>
              <Input.Group compact>
                <Form.Item
                  label="Descrição"
                  name={["contaAPagar", "descricao"]}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Descrição é obrigatório",
                    },
                  ]}
                >
                  <Input
                    placeholder="Ex: Conta de Água"
                    disabled={contaAPagarData?.pedidoDeCompra != null}
                  />
                </Form.Item>

                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Form.Item
                    label="Fornecedor"
                    rules={[
                      {
                        required: true,
                        message: "Fornecedor é obrigatório",
                      },
                    ]}
                    name={["contaAPagar", "fornecedor"]}
                    style={{
                      width: "33.3%",
                      paddingRight: 5,
                    }}
                  >
                    <Select
                      disabled
                      placeholder="Selecione a fornecedor"
                      showSearch
                      options={optionsFornecedor}
                      loading={optionsFornecedorSearch}
                      filterOption={(input, option) =>
                        option?.label
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onSearch={async (value) => {
                        if (value.length >= 3) {
                          setOptionsFornecedorSearch(true);
                          const result =
                            await _fornecedorService.obterSimplificado(value);
                          const opts = result?.data?.data.map((o) => {
                            return {
                              key: o.id,
                              value: o.id,
                              label: o.nomeFantasia,
                            };
                          });
                          setOptionsFornecedor(opts);
                          setOptionsFornecedorSearch(false);
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Empresa"
                    rules={[
                      {
                        required: true,
                        message: "Empresa é obrigatório",
                      },
                    ]}
                    name={["contaAPagar", "empresa"]}
                    style={{ width: "33.3%", paddingRight: 5 }}
                  >
                    <Select
                      placeholder="Selecione a empresa"
                      showSearch
                      options={optionsEmpresa}
                      loading={optionsEmpresaSearch}
                      filterOption={(input, option) =>
                        option?.label
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onSearch={async (value) => {
                        if (value.length >= 3) {
                          setOptionsEmpresaSearch(true);
                          const result =
                            await _empresaService.obterEmpresasSimplificado(
                              value
                            );
                          const opts = result?.data?.data.map((o) => {
                            return {
                              key: o.id,
                              value: o.id,
                              label: o.nomeFantasia,
                            };
                          });
                          setOptionsEmpresa(opts);
                          setOptionsEmpresaSearch(false);
                        }
                      }}
                    />
                  </Form.Item>

                  <div
                    style={{
                      display: "flex",
                      width: "33.3%",
                      paddingRight: 5,
                    }}
                  >
                    <Form.Item
                      label="Grupo Financeiro"
                      style={{ width: "100%" }}
                      name={["contaAPagar", "grupoFinanceiro"]}
                    >
                      <Select
                        placeholder="Selecione o grupo financeiro"
                        options={optsGruposFinanceiros}
                      />
                    </Form.Item>
                    <Button
                      icon={<PlusCircleOutlined />}
                      type="default"
                      onClick={() => setCadastroGrupoFinanceiro(true)}
                      style={{
                        marginLeft: -5,
                        marginTop: 32,
                        borderTopRightRadius: 5,
                        borderEndEndRadius: 5,
                      }}
                    />
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{
                      display: "flex",
                      width: "33.3%",
                      paddingRight: 5,
                    }}
                  >
                    <Form.Item
                      label="Condição de pagamento"
                      name={["contaAPagar", "condicaoDePagamento"]}
                      style={{ width: "100%" }}
                    >
                      <Popover
                        placement="left"
                        title="Detalhes condição de pagamento"
                        content={() => (
                          <CondicaoDePagamentoPopup
                            data={contaAPagarData?.condicaoDePagamento}
                          />
                        )}
                      >
                        <Button type="link">
                          {contaAPagarData?.condicaoDePagamento?.nome}
                        </Button>
                      </Popover>
                    </Form.Item>
                  </div>
                  <Form.Item
                    rules={[{ required: true, message: "Data de vencimento" }]}
                    label="Próximo vencimento em"
                    name={["contaAPagar", "dataVencimento"]}
                    style={{ width: "33.3%", paddingRight: 5 }}
                    initialValue={moment(contaAPagarData?.dataVencimento)}
                  >
                    <DatePicker
                      disabled
                      disabledDate={(date) =>
                        date.toDate().getDay() === 6 ||
                        date.toDate().getDay() === 0
                      }
                      onChange={() => setCalcularParcelas(true)}
                      placeholder="Data Vencimento"
                      format="DD/MM/YYYY"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                  <Form.Item
                    rules={[{ required: true, message: "Informe o valor" }]}
                    label="Valor"
                    style={{ width: "33.3%", paddingRight: 5 }}
                  >
                    <CurrencyInput
                      disabled={true}
                      value={calcularTotal()}
                      className="ant-input ant-input-sm"
                      decimalScale={2}
                      onBlur={() => setCalcularParcelas(true)}
                      defaultValue={contaAPagarData?.valor}
                      onValueChange={(value = "0") => {
                        formRef.setFieldsValue({
                          contaAPagar: {
                            valor: c.unformat(value, { code: "BRL" }),
                          },
                        });
                      }}
                      intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                    />
                  </Form.Item>
                </div>
              </Input.Group>

              <Tabs
                type="card"
                style={{ border: "1px solid #eee", padding: 0, borderTop: "" }}
              >
                <Tabs.TabPane tab="Parcelas" key="1">
                  <div>
                    <Table
                      size="small"
                      bordered
                      scroll={{ y: 180 }}
                      dataSource={parcelas}
                      columns={[
                        {
                          title: "Parcela",
                          dataIndex: "descricao",
                          width: 250,
                        },
                        {
                          title: "Valor",
                          dataIndex: "valor",
                          width: 120,
                          className: "currency",
                          render: (value) => c.format(value, { code: "BRL" }),
                        },
                        {
                          title: "Vencimento",
                          width: 120,
                          dataIndex: "dataVencimento",
                          render: (value) => moment(value).format("DD/MM/YYYY"),
                        },
                        {
                          title: "Situação",
                          dataIndex: "status",
                          width: 120,
                          render: (value) => (
                            <Tag color={StatusParcelaDescricao[value].cor}>
                              {StatusParcelaDescricao[value].label}
                            </Tag>
                          ),
                        },
                        {
                          title: "Ações",
                          render: (item) => (
                            <>
                              <Button
                                size="small"
                                disabled={item?.status === StatusParcela.Paga}
                                style={{ marginRight: 3 }}
                                onClick={() => setParcela(item)}
                                type="dashed"
                                icon={<EditTwoTone />}
                              >
                                Alterar Vencimento
                              </Button>
                              {item?.status !== StatusParcela.Paga && (
                                <Button
                                  icon={<CheckCircleTwoTone />}
                                  size="small"
                                  type="dashed"
                                  onClick={() => setParcelaParaPagamento(item)}
                                >
                                  Registrar Pagamento
                                </Button>
                              )}
                              {item?.status === StatusParcela.Paga && (
                                <Button
                                  icon={<DeleteTwoTone />}
                                  size="small"
                                  type="dashed"
                                  onClick={() =>
                                    estornarPagamentoHandler(item.id)
                                  }
                                >
                                  estornar Pagamento
                                </Button>
                              )}
                              {item?.status === StatusParcela.Conciliada && (
                                <Button
                                  icon={<DeleteTwoTone />}
                                  size="small"
                                  type="dashed"
                                  disabled
                                >
                                  estornar Pagamento
                                </Button>
                              )}
                            </>
                          ),
                          width: 260,
                        },
                      ]}
                    />
                  </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Informações bancárias fornecedor">
                  <div>
                    <Descriptions size="small" bordered>
                      <Descriptions.Item label="Banco">
                        {`${contaAPagarData?.fornecedor?.informacaoBancaria?.banco?.codigoBancario} - ${contaAPagarData?.fornecedor?.informacaoBancaria?.banco?.nome}`}
                      </Descriptions.Item>
                      <Descriptions.Item label="Agência">
                        {
                          contaAPagarData?.fornecedor?.informacaoBancaria
                            ?.agencia
                        }
                      </Descriptions.Item>
                      <Descriptions.Item label="Tipo">
                        {contaAPagarData?.fornecedor?.informacaoBancaria
                          ?.tipo === 1
                          ? "PESSOA FÍSICA"
                          : "PESSOA JURÍDICA"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Titularidade">
                        {
                          contaAPagarData?.fornecedor?.informacaoBancaria
                            ?.titularidade
                        }
                      </Descriptions.Item>
                      <Descriptions.Item label="Conta">
                        {contaAPagarData?.fornecedor?.informacaoBancaria?.conta}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={
                          contaAPagarData?.fornecedor?.informacaoBancaria?.cnpj
                            ? "CNPJ"
                            : "CPF"
                        }
                      >
                        {contaAPagarData?.fornecedor?.informacaoBancaria?.cnpj
                          ? cnpjFormatter(
                              contaAPagarData?.fornecedor?.informacaoBancaria
                                ?.cnpj
                            )
                          : cpfFormatter(
                              contaAPagarData?.fornecedor?.informacaoBancaria
                                ?.cpf
                            )}
                      </Descriptions.Item>
                    </Descriptions>
                  </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Itens" key="3">
                  <Table
                    title={() => <AdicionarItemForm />}
                    pagination={false}
                    size="small"
                    loading={!contaAPagarData}
                    dataSource={itens}
                    columns={[
                      {
                        title: "Descrição",
                        dataIndex: "descricao",
                        width: 250,
                      },
                      {
                        title: "Valor",
                        dataIndex: "valor",
                        width: 120,
                        className: "currency",
                        render: (value) => c.format(value, { code: "BRL" }),
                      },
                      {
                        title: "Job",
                        dataIndex: "job",
                        width: 250,
                        render: (value) => value?.nome || value?.label || "n/a",
                      },
                      {
                        title: "Ação",
                        render: (item) => (
                          <Button
                            type="link"
                            size="small"
                            onClick={() => setItemSelecionado(item)}
                          >
                            Editar
                          </Button>
                        ),
                        width: 100,
                      },
                    ]}
                  />
                </Tabs.TabPane>
              </Tabs>
              <Form.Item
                label="Observações"
                style={{ marginTop: 5 }}
                name={["contaAPagar", "observacao"]}
              >
                <Input.TextArea placeholder="Observações..." rows={3} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={18} />
            <Col span={6} style={{ marginTop: "15px", textAlign: "end" }}>
              <Space>
                <Button type="primary" size="middle" htmlType="submit">
                  SALVAR
                </Button>
                <Button
                  type="ghost"
                  size="middle"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  CANCELAR
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  ) : (
    <Loading />
  );
}

export const ContaAPagarRoute = {
  id: "conta-a-pagar",
  path: "/inicio/financeiro/contas-a-pagar/:id",
  sidebar: "Contas A Pagar",
  exact: true,
  renderInMenu: false,
  main: () => <ContaAPagar />,
  icon: null,
  permission: ContaAPagar,
};
