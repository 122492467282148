import {
  BankOutlined,
  EnvironmentOutlined,
  MailOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Empty,
  Form,
  Input,
  List,
  PageHeader,
  Row,
  Select,
  Space,
  Tabs,
  Tag,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { AtualizarFornecedorDto } from "../../../services/fornecedor/dto/atualizar-fornecedor.dto";
import { ContaBancariaService } from "../../../services/conta-bancaria/conta-bancaria.service";
import { Endpoints } from "../../../core/constants/endpoints";
import { FornecedorService } from "../../../services/fornecedor/fornecedor.service";
import Loading from "../../../components/loading.component";
import { cepFormatter } from "../../../core/utils/formatters/cep.formatter";
import { cnpjFormatter } from "../../../core/utils/formatters/cnpj.formatter";
import { cpfFormatter } from "../../../core/utils/formatters/cpj.formatter";
import moment from "moment";
import useSWR from "swr";
import useTitle from "../../../core/hooks/title.hook";

const cep = require("cep-promise");

export default function Fornecedor() {
  const { id } = useParams<any>();
  const [formRef] = Form.useForm();
  const _fornecedorService = new FornecedorService();
  const _contaBancariaService = new ContaBancariaService();
  const { data: { data: { data: fornecedorData = {} } = {} } = {} } = useSWR(
    Endpoints.fornecedores + id,
    () => _fornecedorService.obterPorId(id),
    { revalidateOnFocus: false }
  );

  const [optionsBancos, setOptionsBancos] = useState([]);
  const [optionsBancosSearch, setOptionsBancosSearch] = useState(false);
  const [informacaoBancariaFisica, setInformacaoBancariaFisica] =
    useState(false);
  useTitle(`${fornecedorData?.nomeFantasia}`);
  useEffect(() => {
    if (fornecedorData?.informacaoBancaria) {
      setOptionsBancos([
        {
          key: fornecedorData?.informacaoBancaria?.banco?.id,
          value: fornecedorData?.informacaoBancaria?.banco?.id,
          label: `${fornecedorData?.informacaoBancaria?.banco?.codigoBancario} - ${fornecedorData?.informacaoBancaria?.banco?.nome}`,
        },
      ]);
      formRef.setFieldsValue({
        informacaoBancaria: {
          banco: fornecedorData?.informacaoBancaria?.banco?.id,
          agencia: fornecedorData?.informacaoBancaria?.agencia,
          conta: fornecedorData?.informacaoBancaria?.conta,
          tipo: fornecedorData?.informacaoBancaria?.tipo,
          chavePix: fornecedorData?.informacaoBancaria?.chavePix,
        },
      });
      if (fornecedorData?.informacaoBancaria?.tipo === 1) {
        setInformacaoBancariaFisica(true);
      }
    }
    if (fornecedorData?.endereco) {
      formRef.setFieldsValue({
        endereco: {
          cep: cepFormatter(fornecedorData?.endereco?.cep),
          logradouro: fornecedorData?.endereco?.logradouro,
          numero: fornecedorData?.endereco?.numero,
          estado: fornecedorData?.endereco?.estado,
          cidade: fornecedorData?.endereco?.cidade,
          bairro: fornecedorData?.endereco?.bairro,
          complemento: fornecedorData?.endereco?.complemento,
        },
      });
    }
    if (fornecedorData?.ramoDeAtividade)
      formRef.setFieldsValue({
        fornecedor: {
          ramoDeAtividade: fornecedorData?.ramoDeAtividade,
        },
      });
  }, [fornecedorData]);

  async function atualizarCadastroFornecedorHandler() {
    try {
      const fields = ["fornecedor", "endereco", "informacaoBancaria"];
      await formRef.validateFields([fields]);
      const { fornecedor, endereco, informacaoBancaria } =
        formRef.getFieldsValue(fields);

      if (!informacaoBancaria)
        throw new Error("Verifique as informações bancárias");
      if (!endereco) throw new Error("Verifique as informações de endereço");

      const dto: AtualizarFornecedorDto = {
        id,
        ...fornecedor,
        cnpj: fornecedor?.cnpj.replace(/[^\d]+/g, ""),
        informacaoBancaria: {
          ...informacaoBancaria,
          cnpj: informacaoBancaria?.cnpj?.replace(/[^\d]+/g, ""),
          cpf: informacaoBancaria?.cpf?.replace(/[^\d]+/g, ""),
          banco: { id: informacaoBancaria.banco },
        },
        endereco: {
          ...endereco,
          cep: endereco.cep.replace(/[^\d]+/g, ""),
        },
      };
      await _fornecedorService.atualizar(id, dto);
      message.success("Fornecedor atualizado com sucesso!");
      history.goBack();
    } catch (error) {
      if (error?.response?.data?.message && typeof Array) {
        for (const e of error?.response?.data?.message.toString().split(",")) {
          message.error(e);
        }
      } else {
        message.error(error?.message);
      }
    }
  }

  const history = useHistory();
  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => {
          history.goBack();
        }}
        title={`Cadastro Fornecedor`}
        subTitle="Atualizar cadastro fornecedor"
        extra={
          <div style={{ padding: 10 }}>
            {!fornecedorData?.cadastroVerificado && (
              <Button
                type="primary"
                size="middle"
                icon={<MailOutlined />}
                onClick={async () => {
                  await _fornecedorService.reenviarConfirmacaoCadastro(id);
                  message.success("Confirmação de cadastro reenviada");
                }}
              >
                Reenviar confirmação
              </Button>
            )}
          </div>
        }
        style={{ marginBottom: 10 }}
      />
      {fornecedorData?.id ? (
        <Tabs type="card" defaultActiveKey="1">
          <Tabs.TabPane tab={"Cadastro"} key="1">
            <Card
              title="Atualizar Cadastro"
              extra={
                <div style={{ padding: 10 }}>
                  {fornecedorData?.cadastroVerificado ? (
                    <Tag color="green">CADASTRO VERIFICADO</Tag>
                  ) : (
                    <Tag color="red">CADASTRO NÃO VERIFCADO</Tag>
                  )}
                </div>
              }
            >
              <Form
                layout="vertical"
                form={formRef}
                onFinish={() => atualizarCadastroFornecedorHandler()}
              >
                <Card
                  type="inner"
                  title="Informações gerais"
                  style={{ marginBottom: 10 }}
                >
                  <Row>
                    <Input.Group compact>
                      <Form.Item
                        rules={[
                          { required: true, message: "CNPJ é obrigatório" },
                        ]}
                        style={{ width: "20%", paddingRight: 5 }}
                        name={["fornecedor", "cnpj"]}
                        initialValue={cnpjFormatter(fornecedorData?.cnpj)}
                        label="CNPJ"
                      >
                        <Input
                          placeholder="CNPJ"
                          onKeyUp={async (e) =>
                            formRef.setFieldsValue({
                              cnpj: cnpjFormatter(
                                formRef.getFieldValue("cnpj")
                              ),
                            })
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        style={{ width: "30%", paddingRight: 5 }}
                        rules={[
                          {
                            required: true,
                            message: "Nome Fantasia é obrigatório",
                          },
                        ]}
                        name={["fornecedor", "nomeFantasia"]}
                        initialValue={fornecedorData?.nomeFantasia}
                        label="Nome Fantasia"
                      >
                        <Input placeholder="Nome Fantasia" />
                      </Form.Item>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Razão Social é obrigatório",
                          },
                        ]}
                        style={{ width: "30%", paddingRight: 5 }}
                        name={["fornecedor", "razaoSocial"]}
                        initialValue={fornecedorData?.razaoSocial}
                        label="Razão Social"
                      >
                        <Input placeholder="Razão Social" />
                      </Form.Item>
                      <Form.Item
                        rules={[{ required: true, message: "Telefone" }]}
                        style={{ width: "20%" }}
                        name={["fornecedor", "telefoneFixo"]}
                        initialValue={fornecedorData?.telefoneFixo}
                        label="Telefone"
                      >
                        <Input placeholder="Telefone" />
                      </Form.Item>
                      <Form.Item
                        style={{ width: "15%", paddingRight: 5 }}
                        rules={[
                          {
                            required: true,
                            message: "Inscrição Municipal é obrigatório",
                          },
                        ]}
                        name={["fornecedor", "inscricaoMunicipal"]}
                        initialValue={fornecedorData?.inscricaoMunicipal}
                        label="Inscrição Municipal"
                      >
                        <Input placeholder="Inscrição Municipal" />
                      </Form.Item>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Inscrição Estadual é obrigatório",
                          },
                        ]}
                        style={{ width: "15%", paddingRight: 5 }}
                        name={["fornecedor", "inscricaoEstadual"]}
                        initialValue={fornecedorData?.inscricaoEstadual}
                        label="Inscrição Estadual"
                      >
                        <Input placeholder="Inscrição Estadual" />
                      </Form.Item>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "E-mail é obrigatório",
                          },
                        ]}
                        style={{ width: "35%", paddingRight: 5 }}
                        name={["fornecedor", "email"]}
                        initialValue={fornecedorData?.email}
                        label="E-mail"
                      >
                        <Input placeholder="E-mail" />
                      </Form.Item>
                      <Form.Item
                        style={{ width: "35%" }}
                        name={["fornecedor", "ramoDeAtividade"]}
                        label="Ramo de atividade"
                      >
                        <Select
                          showSearch
                          filterOption={(input, option) =>
                            option?.value
                              .toString()
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          options={optionsRamoDeAtividade}
                          placeholder="Selecione o ramo de atividade"
                        />
                      </Form.Item>
                    </Input.Group>
                  </Row>
                </Card>
                <Card type="inner" title="Informações Complementares">
                  <Tabs
                    defaultActiveKey="1"
                    type="card"
                    tabBarStyle={{
                      color: "#e21c0c",
                      fontWeight: "bold",
                    }}
                  >
                    <Tabs.TabPane
                      tab={
                        <>
                          <EnvironmentOutlined />
                          Endereço
                        </>
                      }
                      key="1"
                      style={{
                        borderStyle: "solid",
                        marginTop: -16,
                        borderWidth: "1px",
                        borderTopWidth: 0,
                        padding: 10,
                        borderColor: "#f0f0f0",
                      }}
                    >
                      <Col span={24}>
                        <Input.Group compact>
                          <Form.Item
                            name={["endereco", "cep"]}
                            label="CEP"
                            style={{ width: "10%", paddingRight: "5px" }}
                            rules={[
                              {
                                required: true,
                                message: "Informe o CEP",
                                max: 9,
                                min: 9,
                                pattern: /^\d{5}-\d{3}$/g,
                              },
                            ]}
                          >
                            <Input
                              placeholder="00000-000"
                              maxLength={9}
                              onKeyUp={() => {
                                formRef.setFieldsValue({
                                  cep: cepFormatter(
                                    formRef.getFieldValue(["endereco", "cep"])
                                  ),
                                });
                              }}
                              onChange={(event) => {
                                const cepValue = event.target.value;
                                formRef.setFieldsValue({
                                  endereco: {
                                    cep: cepFormatter(event.target.value),
                                  },
                                });
                                if (cepValue.replace("-", "").length >= 8) {
                                  cep(cepValue).then((response) => {
                                    formRef.setFieldsValue({
                                      endereco: {
                                        estado: response.state,
                                        logradouro: response.street,
                                        bairro: response.neighborhood,
                                        cidade: response.city,
                                      },
                                    });
                                  });
                                }
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            name={["endereco", "logradouro"]}
                            label="Rua"
                            style={{ width: "40%", paddingRight: "5px" }}
                            rules={[
                              { required: true, message: "Informe a Rua" },
                            ]}
                          >
                            <Input placeholder="Rua" />
                          </Form.Item>
                          <Form.Item
                            name={["endereco", "numero"]}
                            label="Número"
                            style={{ width: "10%", paddingRight: 5 }}
                            rules={[
                              { required: true, message: "Informe o número" },
                            ]}
                          >
                            <Input placeholder="Número" type="number" />
                          </Form.Item>

                          <Form.Item
                            name={["endereco", "bairro"]}
                            label="Bairro"
                            rules={[
                              { required: true, message: "Informe o bairro" },
                            ]}
                            style={{ width: "20%", paddingRight: "5px" }}
                          >
                            <Input placeholder="Bairro" />
                          </Form.Item>
                          <Form.Item
                            name={["endereco", "cidade"]}
                            label="Cidade"
                            rules={[
                              { required: true, message: "Informe a cidade" },
                            ]}
                            style={{ width: "12%", paddingRight: "5px" }}
                          >
                            <Input placeholder="Cidade" />
                          </Form.Item>
                          <Form.Item
                            name={["endereco", "estado"]}
                            label="Estado"
                            rules={[
                              { required: true, message: "Informe o estado" },
                            ]}
                            style={{ width: "8%" }}
                          >
                            <Input placeholder="Estado" />
                          </Form.Item>
                          <Form.Item
                            name={["endereco", "complemento"]}
                            label="Complemento"
                            style={{ width: "100%" }}
                          >
                            <Input placeholder="Complemento" />
                          </Form.Item>
                          <Form.Item
                            name={["endereco", "pais"]}
                            initialValue="BR"
                          />
                        </Input.Group>
                      </Col>
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      style={{
                        borderStyle: "solid",
                        marginTop: -16,
                        borderWidth: "1px",
                        borderTopWidth: 0,
                        padding: 10,
                        borderColor: "#f0f0f0",
                      }}
                      tab={
                        <>
                          <BankOutlined />
                          Informações Bancárias
                        </>
                      }
                      key="2"
                    >
                      <Input.Group compact>
                        <Form.Item
                          initialValue={
                            fornecedorData?.informacaoBancaria?.banco?.id
                          }
                          label="Banco"
                          rules={[
                            {
                              required: true,
                              message: "Banco é Obrigatório",
                            },
                          ]}
                          name={["informacaoBancaria", "banco"]}
                          style={{ width: "20%", paddingRight: "5px" }}
                        >
                          <Select
                            placeholder="Selecione o Banco"
                            options={optionsBancos}
                            showSearch
                            loading={optionsBancosSearch}
                            filterOption={(input, option) =>
                              option?.label
                                .toString()
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onSearch={async (value) => {
                              if (value.length >= 2) {
                                setOptionsBancosSearch(true);
                                const result =
                                  await _contaBancariaService.obterBancos(
                                    value
                                  );
                                const opts = result?.data?.data.map((o) => {
                                  return {
                                    key: o.id,
                                    value: o.id,
                                    label: o.nome,
                                  };
                                });
                                setOptionsBancos(opts);
                                setOptionsBancosSearch(false);
                              }
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Tipo é Obrigatório",
                            },
                          ]}
                          name={["informacaoBancaria", "tipo"]}
                          label="Tipo"
                          style={{ width: "8%", paddingRight: 5 }}
                        >
                          <Select
                            placeholder="Selecione o tipo"
                            onSelect={(value) =>
                              value === 1
                                ? setInformacaoBancariaFisica(true)
                                : setInformacaoBancariaFisica(false)
                            }
                            options={[
                              { key: 1, value: 1, label: "FÍSICA" },
                              { key: 2, value: 2, label: "JURÍDICA" },
                            ]}
                          />
                        </Form.Item>
                        <Form.Item
                          initialValue={cpfFormatter(
                            fornecedorData?.informacaoBancaria?.cpf
                          )}
                          rules={[
                            {
                              required: informacaoBancariaFisica,
                              message: "Informe o CPF",
                            },
                          ]}
                          style={{
                            width: "15%",
                            paddingRight: 5,
                            display: informacaoBancariaFisica
                              ? "inline-flex"
                              : "none",
                          }}
                          name={["informacaoBancaria", "cpf"]}
                          label="CPF"
                        >
                          <Input
                            placeholder="CPF"
                            onKeyUp={async (e) =>
                              formRef.setFieldsValue({
                                informacaoBancaria: {
                                  cpf: cpfFormatter(
                                    formRef.getFieldValue([
                                      "informacaoBancaria",
                                      "cpf",
                                    ])
                                  ),
                                },
                              })
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          initialValue={cnpjFormatter(
                            fornecedorData?.informacaoBancaria?.cnpj
                          )}
                          rules={[
                            {
                              required: !informacaoBancariaFisica,
                              message: "Informe o CNPJ",
                            },
                          ]}
                          style={{
                            width: "15%",
                            paddingRight: 5,
                            display: !informacaoBancariaFisica
                              ? "inline-flex"
                              : "none",
                          }}
                          name={["informacaoBancaria", "cnpj"]}
                          label="CNPJ"
                        >
                          <Input
                            placeholder="CNPJ"
                            onKeyUp={async (e) =>
                              formRef.setFieldsValue({
                                informacaoBancaria: {
                                  cnpj: cnpjFormatter(
                                    formRef.getFieldValue([
                                      "informacaoBancaria",
                                      "cnpj",
                                    ])
                                  ),
                                },
                              })
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          initialValue={
                            fornecedorData?.informacaoBancaria?.titularidade
                          }
                          rules={[
                            {
                              required: true,
                              message: "Titularidade é Obrigatório",
                            },
                          ]}
                          style={{ width: "20%", paddingRight: 5 }}
                          name={["informacaoBancaria", "titularidade"]}
                          label="Titularidade"
                        >
                          <Input placeholder="Titularidade" />
                        </Form.Item>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Agência é Obrigatório",
                            },
                          ]}
                          style={{ width: "10%", paddingRight: 5 }}
                          name={["informacaoBancaria", "agencia"]}
                          label="Agência"
                        >
                          <Input placeholder="Agência" />
                        </Form.Item>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Conta é Obrigatório",
                            },
                          ]}
                          style={{ width: "10%", paddingRight: 5 }}
                          name={["informacaoBancaria", "conta"]}
                          label="Conta"
                        >
                          <Input placeholder="Conta" />
                        </Form.Item>
                        <Form.Item
                          style={{ width: "17%" }}
                          name={["informacaoBancaria", "chavePix"]}
                          label="Chave Pix"
                        >
                          <Input placeholder="Chave Pix" />
                        </Form.Item>
                      </Input.Group>
                    </Tabs.TabPane>
                  </Tabs>
                </Card>
                <Row>
                  <Col span={18} />
                  <Col span={6} style={{ marginTop: "15px", textAlign: "end" }}>
                    <Space>
                      <Button type="primary" size="middle" htmlType="submit">
                        SALVAR
                      </Button>
                      <Button
                        type="ghost"
                        size="middle"
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        CANCELAR
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Tabs.TabPane>
          <Tabs.TabPane tab={"Histórico"} key="2">
            {fornecedorData?.historico?.length > 0 ? (
              <List
                bordered
                dataSource={fornecedorData?.historico}
                loading={!fornecedorData}
                renderItem={(item: {
                  descricao: string;
                  updatedAt: string;
                  createdAt: string;
                }) => (
                  <List.Item>
                    <span style={{ fontWeight: 400 }}>
                      <p>Descrição</p>
                      {item.descricao}
                    </span>
                    <span style={{ fontWeight: 400 }}>
                      <p>última atualização</p>
                      {moment(item.createdAt).format("DD/MM/YYYY hh:mm:ss")}
                    </span>
                  </List.Item>
                )}
              />
            ) : (
              <Empty description="Nenhum histórico encontrado" />
            )}
          </Tabs.TabPane>
        </Tabs>
      ) : (
        <Loading />
      )}
    </div>
  );
}

export const FornecedorRoute = {
  id: "fornecedor",
  path: "/inicio/atendimento/fornecedores/:id",
  sidebar: "Fornecedores",
  exact: true,
  renderInMenu: false,
  main: () => <Fornecedor />,
  icon: null,
  permission: Fornecedor,
};

const optionsRamoDeAtividade = [
  { value: "Alimentos e Bebidas" },
  { value: "Arte e Antiguidades" },
  { value: "Artigos Religiosos" },
  { value: "Assinaturas e Revistas" },
  { value: "Automóveis e Veículos" },
  { value: "Bebês e Cia" },
  { value: "Blu-Ray" },
  { value: "Brindes / Materiais Promocionais" },
  { value: "Brinquedos e Games" },
  { value: "Casa e Decoração" },
  { value: "CDs" },
  { value: "Colecionáveis" },
  { value: "Compras Coletivas" },
  { value: "Construção e Ferramentas" },
  { value: "Cosméticos e Perfumaria" },
  { value: "Cursos e Educação" },
  { value: "Discos de Vinil" },
  { value: "DVDs" },
  { value: "Eletrodomésticos" },
  { value: "Eletrônicos" },
  { value: "Emissoras de Rádio" },
  { value: "Emissoras de Televisão" },
  { value: "Empregos" },
  { value: "Empresas de Telemarketing" },
  { value: "Esporte e Lazer" },
  { value: "Fitas K7 Gravadas" },
  { value: "Flores, Cestas e Presentes" },
  { value: "Fotografia" },
  { value: "HD-DVD" },
  { value: "Igrejas / Templos / Instituições Religiosas" },
  { value: "Indústria, Comércio e Negócios" },
  { value: "Infláveis Promocionais" },
  { value: "Informática" },
  { value: "Ingressos" },
  { value: "Instrumentos Musicais" },
  { value: "Joalheria" },
  { value: "Lazer" },
  { value: "LD" },
  { value: "Livros" },
  { value: "MD" },
  { value: "Moda e Acessórios" },
  { value: "Motéis" },
  { value: "Música Digital" },
  { value: "Natal" },
  { value: "Negócios e Oportunidades" },
  { value: "Outros Serviços" },
  { value: "Outros Serviços de Avaliação" },
  { value: "Papelaria e Escritório" },
  { value: "Páscoa" },
  { value: "Pet Shop" },
  { value: "Saúde" },
  { value: "Serviço Advocaticios" },
  { value: "Serviço de Distribuição de Jornais / Revistas" },
  { value: "Serviços Administrativos" },
  { value: "Serviços Artísticos" },
  { value: "Serviços de Abatedouros / Matadouros" },
  { value: "Serviços de Aeroportos" },
  { value: "Serviços de Agências" },
  { value: "Serviços de Aluguel / Locação" },
  { value: "Serviços de Armazenagem" },
  { value: "Serviços de Assessorias" },
  { value: "Serviços de Assistência Técnica / Instalações " },
  { value: "Serviços de Associações" },
  { value: "Serviços de Bancos de Sangue" },
  { value: "Serviços de Bibliotecas" },
  { value: "Serviços de Cartórios" },
  { value: "Serviços de Casas Lotéricas" },
  { value: "Serviços de Confecções" },
  { value: "Serviços de Consórcios" },
  { value: "Serviços de Consultorias" },
  { value: "Serviços de Cooperativas" },
  { value: "Serviços de Despachante" },
  { value: "Serviços de Engenharia" },
  { value: "Serviços de Estacionamentos" },
  { value: "Serviços de Estaleiros" },
  { value: "Serviços de Exportação / Importação" },
  { value: "Serviços de Geólogos" },
  { value: "Serviços de joalheiros" },
  { value: "Serviços de Leiloeiros" },
  { value: "Serviços de limpeza" },
  { value: "Serviços de Loja de Conveniência" },
  { value: "Serviços de Mão de Obra" },
  { value: "Serviços de Órgão Públicos" },
  { value: "Serviços de Pesquisas" },
  { value: "Serviços de Portos" },
  { value: "Serviços de Saúde / Bem Estar" },
  { value: "Serviços de Seguradoras" },
  { value: "Serviços de Segurança" },
  { value: "Serviços de Sinalização" },
  { value: "Serviços de Sindicatos / Federações" },
  { value: "Serviços de Traduções" },
  { value: "Serviços de Transporte" },
  { value: "Serviços de Utilidade Pública" },
  { value: "Serviços em Agricultura / Pecuária / Piscicultura" },
  { value: "Serviços em Alimentação" },
  { value: "Serviços em Arte" },
  { value: "Serviços em Cine / Foto / Som" },
  { value: "Serviços em Comunicação" },
  { value: "Serviços em Construção" },
  { value: "Serviços em Ecologia / Meio Ambiente" },
  { value: "Serviços em Eletroeletrônica / Metal Mecânica" },
  { value: "Serviços em Festas / Eventos" },
  { value: "Serviços em Informática" },
  { value: "Serviços em Internet" },
  { value: "Serviços em Jóias / Relógios / Óticas" },
  { value: "Serviços em Telefonia" },
  { value: "Serviços em Veículos" },
  { value: "Serviços Esotéricos / Místicos" },
  { value: "Serviços Financeiros" },
  { value: "Serviços Funerários" },
  { value: "Serviços Gerais" },
  { value: "Serviços Gráficos / Editoriais" },
  { value: "Serviços para Animais" },
  { value: "Serviços para Deficientes" },
  { value: "Serviços para Escritórios" },
  { value: "Serviços para Roupas" },
  { value: "Serviços Socias / Assistenciais" },
  { value: "Sex Shop" },
  { value: "Shopping Centers" },
  { value: "Tabacaria" },
  { value: "Tarifas Bancárias" },
  { value: "Tarifas Telefônicas" },
  { value: "Telefonia" },
  { value: "Turismo" },
];
