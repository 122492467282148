import {
  Button,
  Card,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  PageHeader,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tag,
  message,
} from "antd";
import React, { useEffect, useState } from "react";

import { ContaAPagarService } from "../../../services/conta-a-pagar/conta-a-pagar.service";
import CurrencyInput from "react-currency-input-field";
import { FornecedorService } from "../../../services/fornecedor/fornecedor.service";
import { NovaContaAPagarDto } from "../../../services/conta-a-pagar/dto/nova-conta-a-pagar.dto";
import NovoGrupoFinanceiro from "../grupos-financeiros/novo-grupo-financeiro.page";
import { NumeroDeParcelasModal } from "../../../components/numero-parcelas.modal.component";
import { PlusCircleOutlined } from "@ant-design/icons";
import { StatusParcelaDescricao } from "../../../core/enums/status-parcela.enum";
import c from "currency-formatter";
import moment from "moment";
import useBuscarEmpresaHook from "../../../components/hooks/buscar-empresa.hook";
import useBuscarFormaDePagamentoHook from "../../../components/hooks/buscar-forma-de-pagamento.hook";
import useBuscarPrazoDePagamentoHook from "../../../components/hooks/buscar-prazo-de-pagamento.hook";
import useGruposFinanceirosHook from "../../../components/hooks/buscar-grupo-financeiro.hook";

export default function NovaContaAPagar({ visible, setVisible, atualizarPai }) {
  //#region Variables and States
  const [valorContaAPagar, setValorContaAPagar] = useState("0");
  const _fornecedorService = new FornecedorService();

  const _contaAPagarService = new ContaAPagarService();
  const [recorrenciaAtiva, setRecorrenciaAtiva] = useState(false);
  const [btnSalvarLoading, setBtnSalvarLoading] = useState(false);
  const [formRef] = Form.useForm();

  const [optionsFornecedor, setOptionsFornecedor] = useState([]);
  const [optionsFornecedorSearch, setOptionsFornecedorSearch] = useState(false);

  const {
    options: optionsEmpresa,
    loading: optionsEmpresaSearch,
    filterOptions: filterOptsEmpresa,
  } = useBuscarEmpresaHook();
  const {
    options: optionsFormaDePagamento,
    filterOptions: filterOptsFormaDePagamento,
    loading: optionsFormaDePagamentoSearch,
    buscarFormaDePagamento,
  } = useBuscarFormaDePagamentoHook();
  const { options: optsGruposFinanceiros } =
    useGruposFinanceirosHook("despesa");
  const {
    options: optionsPrazoDePagamento,
    filterOptions: filterOptsPrazoDePagamento,
    loading: optionsPrazoDePagamentoSearch,
    buscarPrazoDePagamento,
  } = useBuscarPrazoDePagamentoHook();

  const [parcelas, setParcelas] = useState([]);
  const [calcularParcelas, setCalcularParcelas] = useState(Date.now());
  const [cadastroGrupoFinanceiro, setCadastroGrupoFinanceiro] = useState(false);
  const [modalNumeroDeParcelas, setModalNumeroDeParcelas] = useState(false);
  //#endregion

  //#region Methods

  async function cadastrarNovaContaAPagarHandler() {
    try {
      setBtnSalvarLoading(true);
      const contaAPagar = formRef.getFieldValue("contaAPagar");
      const dto: NovaContaAPagarDto = {
        descricao: contaAPagar?.descricao,
        valor: contaAPagar?.valor,
        recorrencia: recorrenciaAtiva,
        qtdMesesRecorrencia: Number(contaAPagar?.qtdMesesRecorrencia),
        dataVencimento: contaAPagar?.dataVencimento.toString(),
        formaDePagamento: { id: contaAPagar?.formaDePagamento },
        prazoDePagamento: {
          id: contaAPagar?.prazoDePagamento,
          numeroDeParcelas: contaAPagar?.numeroDeParcelas,
          diasEntreParcelas: contaAPagar?.diasEntreParcelas,
          diasParaPrimeiraParcela: contaAPagar?.diasParaPrimeiraParcela,
        },
        grupoFinanceiro: { id: contaAPagar?.grupoFinanceiro },
        fornecedor: { id: contaAPagar?.fornecedor },
        empresa: { id: contaAPagar?.empresa },
        itens: [
          {
            descricao: contaAPagar?.descricao,
            valor: contaAPagar?.valor,
            job: null,
          },
        ],
      };

      await _contaAPagarService.adicionarContaAPagar(dto);
      message.success("Conta a pagar adicionada");
      formRef.resetFields();
      setParcelas([]);
      setValorContaAPagar("0");
      atualizarPai && atualizarPai(Date.now());
      setBtnSalvarLoading(false);
      setVisible(false);
    } catch (error) {
      if (error?.response?.data?.message && typeof Array) {
        for (const e of error?.response?.data?.message.toString().split(",")) {
          message.error(e);
        }
      } else {
        message.error(error?.message);
      }
    }
  }

  //#endregion

  useEffect(() => {
    const {
      dataVencimento = null,
      prazoDePagamento = null,
      valor = null,
      descricao = null,
      numeroDeParcelas,
      diasParaPrimeiraParcela,
      diasEntreParcelas,
    } = formRef.getFieldValue(["contaAPagar"]) || {};

    if (dataVencimento && prazoDePagamento && valor && descricao) {
      _contaAPagarService
        .gerarParcelamento({
          valor,
          prazo: {
            id: prazoDePagamento,
            numeroDeParcelas,
            diasEntreParcelas,
            diasParaPrimeiraParcela,
          },
          descricao,
          dataPrimeiroVencimento: dataVencimento?.toString(),
          diaPagamentoPadrao: false,
        })
        .then(({ data: { data: pItens } }) => setParcelas(pItens));
    }
  }, [calcularParcelas, cadastroGrupoFinanceiro, modalNumeroDeParcelas]);

  return (
    <Drawer
      visible={visible}
      width={1200}
      bodyStyle={{ padding: 5 }}
      closable={false}
    >
      <NovoGrupoFinanceiro
        tipo={"despesa"}
        visible={cadastroGrupoFinanceiro}
        setVisible={setCadastroGrupoFinanceiro}
      />
      <NumeroDeParcelasModal
        contexto={"contaAPagar"}
        visible={modalNumeroDeParcelas}
        setVisible={setModalNumeroDeParcelas}
        formRef={formRef}
      />
      <PageHeader
        className="site-page-header"
        onBack={() => {
          setVisible(false);
        }}
        title={`Nova conta a pagar`}
        subTitle="Incluir uma conta a pagar"
        style={{ marginBottom: 10 }}
      />
      <Card title="Informações conta a pagar" type="inner">
        <Form
          form={formRef}
          layout="vertical"
          onFinish={() => cadastrarNovaContaAPagarHandler()}
        >
          <Row>
            <Col sm={24}>
              <Input.Group compact>
                <Form.Item
                  label="Descrição"
                  name={["contaAPagar", "descricao"]}
                  style={{ width: recorrenciaAtiva ? "72%" : "82%" }}
                  rules={[
                    {
                      required: true,
                      message: "Descrição é obrigatório",
                    },
                  ]}
                >
                  <Input
                    placeholder="Ex: Conta de Água"
                    onBlur={() => setCalcularParcelas(Date.now())}
                  />
                </Form.Item>
                <Form.Item
                  initialValue={false}
                  name={["contaAPagar", "recorrencia"]}
                  style={{ width: "18%", paddingTop: 32, paddingLeft: 10 }}
                >
                  <Switch onChange={(v) => setRecorrenciaAtiva(v)} /> conta
                  recorrente ?
                </Form.Item>
                <Form.Item
                  name={["contaAPagar", "qtdMesesRecorrencia"]}
                  rules={[
                    {
                      min: 1,
                      max: 12,
                      message: "O máximo de meses é 12",
                    },
                  ]}
                  style={{
                    width: "10%",
                    paddingTop: 32,
                    paddingLeft: 5,
                    display: recorrenciaAtiva ? "inline-flex" : "none",
                  }}
                >
                  <Input
                    type="number"
                    min={1}
                    max={12}
                    placeholder="1"
                    suffix="Meses"
                    disabled={!recorrenciaAtiva}
                  />
                </Form.Item>

                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Form.Item
                    label="Fornecedor"
                    rules={[
                      {
                        required: true,
                        message: "Fornecedor é obrigatório",
                      },
                    ]}
                    name={["contaAPagar", "fornecedor"]}
                    style={{
                      width: "33.3%",
                      paddingRight: 5,
                    }}
                  >
                    <Select
                      placeholder="Selecione a fornecedor"
                      showSearch
                      options={optionsFornecedor}
                      loading={optionsFornecedorSearch}
                      filterOption={(input, option) =>
                        option?.label
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onSearch={async (value) => {
                        if (value.length >= 3) {
                          setOptionsFornecedorSearch(true);
                          const result =
                            await _fornecedorService.obterSimplificado(value);
                          const opts = result?.data?.data.map((o) => {
                            return {
                              key: o.id,
                              value: o.id,
                              label: o.nomeFantasia,
                            };
                          });
                          setOptionsFornecedor(opts);
                          setOptionsFornecedorSearch(false);
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Empresa"
                    rules={[
                      {
                        required: true,
                        message: "Empresa é obrigatório",
                      },
                    ]}
                    name={["contaAPagar", "empresa"]}
                    style={{ width: "33.3%", paddingRight: 5 }}
                  >
                    <Select
                      placeholder="Selecione a empresa"
                      showSearch
                      options={optionsEmpresa}
                      loading={optionsEmpresaSearch}
                      filterOption={filterOptsEmpresa}
                    />
                  </Form.Item>

                  <div
                    style={{ display: "flex", width: "33.3%", paddingRight: 5 }}
                  >
                    <Form.Item
                      label="Grupo Financeiro"
                      style={{ width: "100%" }}
                      name={["contaAPagar", "grupoFinanceiro"]}
                    >
                      <Select
                        placeholder="Selecione o Grupo"
                        options={optsGruposFinanceiros}
                      />
                    </Form.Item>
                    <Button
                      icon={<PlusCircleOutlined />}
                      type="default"
                      onClick={() => setCadastroGrupoFinanceiro(true)}
                      style={{
                        marginLeft: -5,
                        marginTop: 31,
                        borderTopRightRadius: 5,
                        borderEndEndRadius: 5,
                      }}
                    />
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{ display: "flex", width: "25%", paddingRight: 5 }}
                  >
                    <Form.Item
                      label="Forma de Pagamento"
                      rules={[
                        {
                          required: true,
                          message: "Forma de Pagamento é obrigatório",
                        },
                      ]}
                      name={["contaAPagar", "formaDePagamento"]}
                      style={{ width: "100%" }}
                    >
                      <Select
                        placeholder="Selecione a forma de pagamento"
                        showSearch
                        options={optionsFormaDePagamento}
                        loading={optionsFormaDePagamentoSearch}
                        filterOption={filterOptsFormaDePagamento}
                        onSearch={buscarFormaDePagamento}
                      />
                    </Form.Item>
                  </div>
                  <div
                    style={{ display: "flex", width: "25%", paddingRight: 5 }}
                  >
                    <Form.Item
                      label="Prazo de Pagamento"
                      rules={[
                        {
                          required: true,
                          message: "Forma de Pagamento é obrigatório",
                        },
                      ]}
                      name={["contaAPagar", "prazoDePagamento"]}
                      style={{ width: "100%" }}
                    >
                      <Select
                        placeholder="Selecione o prazo de pagamento"
                        showSearch
                        onSelect={(value, opt: any) => {
                          if (opt?.solicitarParcelamento) {
                            setModalNumeroDeParcelas(true);
                          } else {
                            formRef.setFieldsValue({
                              contaAPagar: {
                                numeroDeParcelas: opt?.numeroDeParcelas,
                                diasEntreParcelas: opt?.diasEntreParcelas,
                                diasParaPrimeiraParcela:
                                  opt?.diasParaPrimeiraParcela,
                              },
                            });
                          }
                        }}
                        onChange={() => setCalcularParcelas(Date.now())}
                        options={optionsPrazoDePagamento}
                        loading={optionsPrazoDePagamentoSearch}
                        filterOption={filterOptsPrazoDePagamento}
                        onSearch={buscarPrazoDePagamento}
                      />
                    </Form.Item>
                  </div>
                  <Form.Item
                    rules={[{ required: true, message: "Selecione a data" }]}
                    label="A partir de"
                    name={["contaAPagar", "dataVencimento"]}
                    style={{ width: "25%", paddingRight: 5 }}
                  >
                    <DatePicker
                      onChange={() => setCalcularParcelas(Date.now())}
                      placeholder="Selecione a data"
                      disabledDate={(date) =>
                        date.toDate().getDay() === 6 ||
                        date.toDate().getDay() === 0
                      }
                      format="DD/MM/YYYY"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                  <Form.Item
                    rules={[{ required: true, message: "Informe o valor" }]}
                    label="Valor"
                    style={{ width: "25%", paddingRight: 5 }}
                  >
                    <CurrencyInput
                      className="ant-input ant-input-sm"
                      decimalScale={2}
                      value={valorContaAPagar}
                      onBlur={() => setCalcularParcelas(Date.now())}
                      defaultValue={0}
                      onValueChange={(value = "0") => {
                        setValorContaAPagar(value);
                        formRef.setFieldsValue({
                          contaAPagar: {
                            valor: c.unformat(value, { code: "BRL" }),
                          },
                        });
                      }}
                      intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                    />
                  </Form.Item>
                </div>
              </Input.Group>
              <Card type="inner" title="Parcelas" bodyStyle={{ padding: 0 }}>
                <Table
                  size="small"
                  bordered
                  scroll={{ y: 180 }}
                  dataSource={parcelas}
                  columns={[
                    { title: "Parcela", dataIndex: "descricao", width: 250 },
                    {
                      title: "Valor",
                      dataIndex: "valor",
                      width: 120,
                      className: "currency",
                      render: (value) => c.format(value, { code: "BRL" }),
                    },
                    {
                      title: "Vencimento",
                      width: 120,
                      dataIndex: "dataVencimento",
                      render: (value) => moment(value).format("DD/MM/YYYY"),
                    },
                    {
                      title: "Situação",
                      dataIndex: "status",
                      width: 120,
                      render: (value) => (
                        <Tag color={StatusParcelaDescricao[value].cor}>
                          {StatusParcelaDescricao[value].label}
                        </Tag>
                      ),
                    },
                    {
                      title: "Ação",
                      render: (item) => (
                        <Button disabled={true} type="link" size="small">
                          Editar
                        </Button>
                      ),
                      width: 100,
                    },
                  ]}
                />
              </Card>
              <Form.Item
                label="Observações"
                style={{ marginTop: 5 }}
                name={["contaAPagar", "observacao"]}
              >
                <Input.TextArea placeholder="Observações..." rows={3} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={18} />
            <Col span={6} style={{ marginTop: "15px", textAlign: "end" }}>
              <Space>
                <Button
                  type="primary"
                  size="middle"
                  htmlType="submit"
                  loading={btnSalvarLoading}
                >
                  SALVAR
                </Button>
                <Button
                  type="ghost"
                  size="middle"
                  onClick={() => {
                    setVisible(false);
                    formRef.resetFields();
                  }}
                >
                  CANCELAR
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Card>
    </Drawer>
  );
}
