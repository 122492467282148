import { BaseService } from "../base.service";
import { Endpoints } from "../../core/constants/endpoints";
import { NovoGrupoFinanceiroDto } from "./dto/novo-grupo-financeiro.dto";

export class GrupoFinanceiroService extends BaseService {
  async obterTodos() {
    try {
      return await this.api.get(Endpoints.gruposFinanceiros);
    } catch (error) {
      throw error;
    }
  }
  async adicionarGrupoFinanceiro(dto: NovoGrupoFinanceiroDto) {
    try {
      return await this.api.post(Endpoints.gruposFinanceiros, dto);
    } catch (error) {
      throw error;
    }
  }
}
