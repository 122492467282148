import { Card, DatePicker, Form, Input, Select } from "antd";
import locale from "antd/es/date-picker/locale/pt_BR";
import React from "react";

export default function FinanceiroConfiguracao() {
  const [formRef] = Form.useForm();

  return (
    <div>
      <Card title="Configuração financeiro">
        <Form form={formRef} layout="vertical">
          <Input.Group compact>
            <Form.Item
              label="Grupo financeiro para pedidos de compra"
              style={{ width: "33%", paddingRight: 4 }}
            >
              <Select placeholder="Selecione um grupo financeiro" />
            </Form.Item>
            <Form.Item
              label="Grupo financeiro para pagamento de salário"
              style={{ width: "33%", paddingRight: 4 }}
            >
              <Select placeholder="Selecione um grupo financeiro" />
            </Form.Item>
            <Form.Item
              label="Dia de pagamento contas a pagar"
              style={{ width: "33%" }}
              name="diaPagamento"
            >
              <DatePicker
                style={{ width: "100%" }}
                format={"dddd"}
                locale={locale}
                disabledDate={(date) =>
                  date.toDate().getDay() === 6 || date.toDate().getDay() === 0
                }
              />
            </Form.Item>
          </Input.Group>
        </Form>
      </Card>
    </div>
  );
}
