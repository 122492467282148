import "draft-js/dist/Draft.css";

import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  PageHeader,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Upload,
  message,
} from "antd";
import {
  CloseCircleOutlined,
  MailOutlined,
  PrinterOutlined,
  RetweetOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  StatusPedidoDeCompra,
  StatusPedidoDeCompraInfo,
} from "../../../core/enums/status-pedido-de-compra.enum";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { AtualizarPedidoDeCompraDto } from "../../../services/pedido-de-compra/dto/atualizar-pedido-de-compra.dto";
import CurrencyInput from "react-currency-input-field";
import { Endpoints } from "../../../core/constants/endpoints";
import { FornecedorService } from "../../../services/fornecedor/fornecedor.service";
import Loading from "../../../components/loading.component";
import { PedidoDeCompra as PedidoDeCompraPermissao } from "../../../core/permissions/subjects";
import { PedidoDeCompraService } from "../../../services/pedido-de-compra/pedido-de-compra.service";
import { SolicitarRetencaoModal } from "../../../components/solicitar-retencao.modal.compnent";
import { cepFormatter } from "../../../core/utils/formatters/cep.formatter";
import currencyFormatter from "currency-formatter";
import { datasValidasERetroativas } from "../../../core/utils/formatters/datas.formatter";
import moment from "moment";
import { v4 as newId } from "uuid";
import { saveAs } from "file-saver";
import { sequencialPedidoDeCompraFormatter } from "../../../core/utils/formatters/sequencial.formatter";
import useBuscarEmpresaHook from "../../../components/hooks/buscar-empresa.hook";
import useBuscarFormaDePagamentoHook from "../../../components/hooks/buscar-forma-de-pagamento.hook";
import useBuscarJobHook from "../../../components/hooks/buscar-job.hook";
import useBuscarPrazoDePagamentoHook from "../../../components/hooks/buscar-prazo-de-pagamento.hook";
import useSWR from "swr";

const cep = require("cep-promise");

export default function PedidoDeCompra() {
  const { id } = useParams<any>();
  const history = useHistory();
  const [formRef] = Form.useForm();
  const [formReenvioEmailRef] = Form.useForm();
  const _pedidoDeCompraService = new PedidoDeCompraService();
  const [solicitarRetencao, setSolicitarRetencao] = useState(false);

  const _fornecedorService = new FornecedorService();

  const [itens, setItens] = useState([]);

  const [itemSelecionado, setItemSelecionado] = useState(null);

  const [fileList, setFileList] = useState([]);
  const [gerarPdfStatus, setGerarPdfStatus] = useState(false);
  const [optionsFornecedor, setOptionsFornecedor] = useState([]);
  const [optionsFornecedorSearch, setOptionsFornecedorSearch] = useState(false);

  const { data: { data: { data: pedidoDeCompra } = {} } = {} } = useSWR(
    Endpoints.pedidosDeCompra + id,
    () => _pedidoDeCompraService.obterPorId(id),
    { revalidateOnFocus: false }
  );

  const {
    options: optionsPrazoPagamento,
    buscarPrazoDePagamento,
    loading: loadingPrazoDePagamento,
    filterOptions: filterOptsPrazo,
  } = useBuscarPrazoDePagamentoHook();

  const {
    options: optionsFormaDePagamento,
    buscarFormaDePagamento,
    loading: loadingFormasDePagamento,
  } = useBuscarFormaDePagamentoHook();
  const {
    options: optionsEmpresa,
    setOptions: setOptionsEmpresa,
    loading: optionsEmpresaSearch,
    buscarEmpresa,
    filterOptions: filterOptionsEmpresa,
  } = useBuscarEmpresaHook();

  const {
    buscarJob,
    options: optionsJob,
    setOptions: setOptionsJob,
    filterOption: filterOptionsJob,
    loading: loadingJobs,
  } = useBuscarJobHook();
  function calcularTotal() {
    let total = 0;
    if (itens) {
      for (const item of itens) {
        total += Number(item.quantidade) * Number(item.valor);
      }
    }
    return total;
  }

  async function uploadComprovantes(file) {
    setFileList([
      ...fileList,
      {
        uid: file.uid,
        name: file.name,
        status: "uploading",
        url: null,
      },
    ]);
    const form = new FormData();
    form.append("arquivo", file);
    const res = await _pedidoDeCompraService.carregarAnexos(
      form,
      pedidoDeCompra?.id
    );

    const { url, mimetype } = res;
    const list = fileList.filter((f) => f.uid !== file.uid);
    list.push({
      uid: file.uid,
      name: file.name,
      status: "done",
      url: url,
      mime: mimetype,
    });
    setFileList(list);
  }

  useEffect(() => {
    setItens(pedidoDeCompra?.itens);
    if (pedidoDeCompra?.anexos?.length > 0) {
      const list = pedidoDeCompra?.anexos?.map((f) => {
        return {
          uid: newId(),
          name: f.filename,
          status: "done",
          url: f.url,
        };
      });
      setFileList(list);
    }

    setOptionsFornecedor([
      {
        key: pedidoDeCompra?.fornecedor?.id,
        value: pedidoDeCompra?.fornecedor?.id,
        label: pedidoDeCompra?.fornecedor?.nomeFantasia,
      },
    ]);

    formRef.setFieldsValue({
      enderecoDeEntrega: {
        ...pedidoDeCompra?.enderecoDeEntrega,
        cep: cepFormatter(pedidoDeCompra?.enderecoDeEntrega?.cep),
      },
    });
  }, [pedidoDeCompra]);

  function AdicionarItemForm() {
    const [itemFormRef] = Form.useForm();

    if (itemSelecionado) {
      itemFormRef.setFieldsValue(itemSelecionado);
    }
    async function adicionarItem() {
      const item = itemFormRef.getFieldsValue([
        "id",
        "descricao",
        "valor",
        "quantidade",
      ]);
      await itemFormRef.validateFields();
      if (itemSelecionado) {
        item.valor = currencyFormatter.unformat(item.valor, {
          code: "BRL",
        });
        const itensNew = itens.filter((i) => i.id !== itemSelecionado.id);
        itensNew.push(item);
        setItens(itensNew);
        setItemSelecionado(null);
      } else {
        setItens([...itens, item]);
      }
    }

    return (
      <div title="ATUALIZAR ITEM" style={{ marginBottom: -20 }}>
        <Form
          form={itemFormRef}
          layout="horizontal"
          onFinish={() => adicionarItem()}
        >
          <div
            style={{ display: !itemSelecionado ? "none" : "flex" }}
            onKeyPress={(e) => {
              if (e.key === "Enter" || e.key === "Tab") {
                itemFormRef.submit();
              }
            }}
          >
            <Input.Group compact>
              <Form.Item hidden initialValue={newId()} name="id" />
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
                name="descricao"
                style={{ width: "70%", paddingRight: 5 }}
              >
                <Input placeholder="Descrição" disabled={!itemSelecionado} />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
                style={{ paddingRight: 5, width: "15%" }}
              >
                <CurrencyInput
                  name="valor"
                  disabled={!itemSelecionado}
                  defaultValue={itemFormRef.getFieldValue("valor")}
                  placeholder="Valor Unitário"
                  className="ant-input ant-input-sm"
                  decimalScale={2}
                  onValueChange={(value = "0") => {
                    itemFormRef.setFieldsValue({
                      valor: currencyFormatter.unformat(value, { code: "BRL" }),
                    });
                  }}
                  intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                />
              </Form.Item>
              <Form.Item
                style={{ width: "15%" }}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
                name="quantidade"
              >
                <Input
                  placeholder="Quantidade"
                  type="number"
                  disabled={!itemSelecionado}
                />
              </Form.Item>
            </Input.Group>
            <Button
              type="primary"
              icon={<RetweetOutlined />}
              disabled={!itemSelecionado}
              onClick={() => itemFormRef.submit()}
            >
              Atualizar Item
            </Button>
          </div>
        </Form>
      </div>
    );
  }

  async function atualizarPedidoDeCompraHandler() {
    try {
      const { pedido, enderecoDeEntrega } = formRef.getFieldsValue([
        "enderecoDeEntrega",
        "pedido",
      ]);

      const dto: AtualizarPedidoDeCompraDto = {
        id: id,
        status: pedido?.status,
        formaDePagamento: { id: pedido.formaDePagamento },
        prazoDePagamento: { id: pedido.prazoDePagamento },
        empresa: { id: pedido.empresa },
        fornecedor: { id: pedido.fornecedor },
        job: { id: pedido.job },
        notaFiscal: pedido?.notaFiscal,
        dataDeEntrega: pedido?.dataDeEntrega?.toString(),
        dataDePagamento: pedido?.dataDePagamento?.toString(),
        valorRetencao: pedido?.valorRetencao,
        dataRetencao: pedido?.dataRetencao
          ? pedido?.dataRetencao?.toString()
          : null,
        itens: itens.map((i) => {
          return {
            id: i.id,
            valor: i.valor,
            quantidade: i.quantidade,
            descricao: i.descricao,
          };
        }),
        enderecoDeEntrega: {
          id: pedidoDeCompra?.enderecoDeEntrega?.id,
          ...enderecoDeEntrega,
          pais: "BR",
          cep: enderecoDeEntrega?.cep.replace(/[^\d]+/g, ""),
        },
      };

      await _pedidoDeCompraService.atualizarPedido(id, dto);
      message.success("Pedido de compra atualizado!");
      history.goBack();
    } catch (error) {
      if (error?.response?.data?.message && typeof Array) {
        for (const e of error?.response?.data?.message.toString().split(",")) {
          message.error(e);
        }
      } else {
        message.error(error?.message);
      }
    }
  }

  async function cancelarPedidoHandler(id: string) {
    try {
      await _pedidoDeCompraService.cancelarPedido(id);
      message.success("Pedido cancelado");
      history.goBack();
    } catch (error) {
      if (error?.response?.data?.message && typeof Array) {
        for (const e of error?.response?.data?.message.toString().split(",")) {
          message.error(e);
        }
      } else {
        message.error(error?.message);
      }
    }
  }

  return (
    <div>
      <SolicitarRetencaoModal
        formRef={formRef}
        contexto={"pedido"}
        visible={solicitarRetencao}
        setVisible={setSolicitarRetencao}
      />
      <PageHeader
        className="site-page-header"
        onBack={() => {
          history.goBack();
        }}
        title={`${sequencialPedidoDeCompraFormatter(
          String(pedidoDeCompra?.sequencial)
        )}`}
        subTitle="Atualizar Pedido de compra"
        style={{ marginBottom: 10 }}
        extra={
          <>
            <Button
              icon={<PrinterOutlined />}
              size="middle"
              loading={gerarPdfStatus}
              onClick={async () => {
                setGerarPdfStatus(true);
                const pdf = await _pedidoDeCompraService.gerarPdf(id);

                setGerarPdfStatus(false);
                saveAs(
                  pdf.data,
                  `${sequencialPedidoDeCompraFormatter(
                    String(pedidoDeCompra?.sequencial)
                  )}`
                );
              }}
            >
              Imprimir
            </Button>
            <Popconfirm
              placement="bottom"
              title={
                <>
                  <p>Deseja realmente reenviar o pedido de compras ?</p>
                  <Form form={formReenvioEmailRef}>
                    <Form.Item
                      initialValue={pedidoDeCompra?.fornecedor?.email}
                      name="emailFornecedor"
                    >
                      <Input placeholder="E-mail fornecedor" />
                    </Form.Item>
                  </Form>
                </>
              }
              onConfirm={async () => {
                const { emailFornecedor } = formReenvioEmailRef.getFieldsValue([
                  "emailFornecedor",
                ]);

                await _pedidoDeCompraService.reenviarPedido(
                  id,
                  emailFornecedor?.toLowerCase()
                );

                message.success("Pedido reenviado ao fornecedor");
              }}
              okText="SIM"
              cancelText="Não"
            >
              <Button size="middle" type="primary" icon={<MailOutlined />}>
                Reenviar ao fornecedor
              </Button>
            </Popconfirm>
          </>
        }
      />
      {pedidoDeCompra ? (
        <Card
          title="Informações pedido de compra"
          type="inner"
          extra={
            <>
              <Popconfirm
                title="Deseja realmente cancelar o pedido de compras ?"
                placement="bottom"
                onConfirm={() => cancelarPedidoHandler(pedidoDeCompra?.id)}
                okText="confirmar"
                cancelText="Cancelar"
                disabled={
                  pedidoDeCompra?.status === StatusPedidoDeCompra.APagar ||
                  pedidoDeCompra.status === StatusPedidoDeCompra.Pago ||
                  pedidoDeCompra.status === StatusPedidoDeCompra.NfEmitida ||
                  pedidoDeCompra.status === StatusPedidoDeCompra.Cancelado
                }
              >
                <Button
                  icon={<CloseCircleOutlined />}
                  size="middle"
                  type="primary"
                  disabled={
                    pedidoDeCompra?.status === StatusPedidoDeCompra.APagar ||
                    pedidoDeCompra.status === StatusPedidoDeCompra.Pago ||
                    pedidoDeCompra.status === StatusPedidoDeCompra.NfEmitida ||
                    pedidoDeCompra.status === StatusPedidoDeCompra.Cancelado
                  }
                  className="btn-danger"
                >
                  Cancelar pedido
                </Button>
              </Popconfirm>
            </>
          }
        >
          <Form
            layout="vertical"
            form={formRef}
            onFinish={() => atualizarPedidoDeCompraHandler()}
          >
            <Row>
              <Col span={20}>
                <Input.Group compact>
                  <Form.Item
                    label="Job"
                    initialValue={pedidoDeCompra?.job?.id}
                    rules={[{ required: true, message: "Job é obrigatório" }]}
                    name={["pedido", "job"]}
                    style={{ width: "30%", paddingRight: 5 }}
                  >
                    <Select
                      placeholder="Selecione o Job"
                      showSearch
                      options={optionsJob}
                      loading={loadingJobs}
                      filterOption={filterOptionsJob}
                      onSearch={buscarJob}
                    />
                  </Form.Item>

                  <Form.Item
                    initialValue={pedidoDeCompra?.fornecedor?.id}
                    label="Fornecedor"
                    rules={[
                      { required: true, message: "Fornecedor é obrigatório" },
                    ]}
                    name={["pedido", "fornecedor"]}
                    style={{ width: "30%", paddingRight: 5 }}
                  >
                    <Select
                      placeholder="Selecione a fornecedor"
                      showSearch
                      options={optionsFornecedor}
                      loading={optionsFornecedorSearch}
                      filterOption={(input, option) =>
                        option?.label
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onSearch={async (value) => {
                        if (value.length >= 3) {
                          setOptionsFornecedorSearch(true);
                          const result =
                            await _fornecedorService.obterSimplificado(value);
                          const opts = result?.data?.data.map((o) => {
                            return {
                              key: o.id,
                              value: o.id,
                              label: o.nomeFantasia,
                            };
                          });
                          setOptionsFornecedor(opts);
                          setOptionsFornecedorSearch(false);
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    initialValue={pedidoDeCompra?.notaFiscal}
                    label="Nota Fiscal"
                    style={{ paddingRight: 5, width: "19%" }}
                    name={["pedido", "notaFiscal"]}
                  >
                    <Input
                      placeholder="Número da fiscal"
                      onClick={() => setSolicitarRetencao(true)}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Status"
                    name={["pedido", "status"]}
                    style={{ width: "21%" }}
                    initialValue={pedidoDeCompra?.status}
                  >
                    <Tag
                      color={
                        StatusPedidoDeCompraInfo[pedidoDeCompra?.status].color
                      }
                      style={{ width: "100%", textAlign: "center" }}
                    >
                      {StatusPedidoDeCompraInfo[pedidoDeCompra?.status].label}
                    </Tag>
                  </Form.Item>
                  <Form.Item
                    initialValue={pedidoDeCompra?.empresa?.id}
                    label="Empresa contratante"
                    rules={[
                      {
                        required: true,
                        message: "Empresa é obrigatório",
                      },
                    ]}
                    name={["pedido", "empresa"]}
                    style={{ width: "25%", paddingRight: 5 }}
                  >
                    <Select
                      placeholder="Selecione a empresa"
                      showSearch
                      options={optionsEmpresa}
                      loading={optionsEmpresaSearch}
                      filterOption={filterOptionsEmpresa}
                      onSearch={buscarEmpresa}
                    />
                  </Form.Item>
                  <Form.Item
                    initialValue={pedidoDeCompra?.formaDePagamento?.id}
                    label="Forma de Pagamento"
                    rules={[
                      {
                        required: true,
                        message: "Forma de Pagamento é obrigatório",
                      },
                    ]}
                    name={["pedido", "formaDePagamento"]}
                    style={{ width: "20%", paddingRight: 5 }}
                  >
                    <Select
                      placeholder="Selecione a forma de pagamento"
                      showSearch
                      disabled
                      options={optionsFormaDePagamento}
                      loading={loadingFormasDePagamento}
                      filterOption={(input, option) =>
                        option?.label
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onSearch={buscarFormaDePagamento}
                    />
                  </Form.Item>
                  <Form.Item
                    name={["pedido", "prazoDePagamento"]}
                    initialValue={pedidoDeCompra?.prazoDePagamento?.id}
                    label="Prazo de pagamento"
                    style={{ width: "25%", paddingRight: 5 }}
                  >
                    <Select
                      disabled
                      placeholder="Selecione o prazo de pagamento"
                      showSearch
                      options={optionsPrazoPagamento}
                      loading={loadingPrazoDePagamento}
                      filterOption={filterOptsPrazo}
                      onSearch={buscarPrazoDePagamento}
                    />
                  </Form.Item>
                  <Form.Item
                    initialValue={moment(pedidoDeCompra?.dataDePagamento)}
                    label="a partir de"
                    rules={[
                      {
                        required: true,
                        message: "Selecione a data",
                      },
                    ]}
                    name={["pedido", "dataDePagamento"]}
                    style={{ width: "15%", paddingRight: 5 }}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      format="DD/MM/YYYY"
                      disabledDate={(date) =>
                        datasValidasERetroativas(date.toDate())
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    initialValue={moment(pedidoDeCompra?.dataDeEntrega)}
                    label="Data de entrega"
                    rules={[
                      {
                        required: true,
                        message: "Data de entrega é obrigatório",
                      },
                    ]}
                    name={["pedido", "dataDeEntrega"]}
                    style={{ width: "15%" }}
                  >
                    <DatePicker style={{ width: "100%" }} format="DD/MM/YYYY" />
                  </Form.Item>
                </Input.Group>
              </Col>
              <Col span={4}>
                <Card
                  title="Anexos"
                  type="inner"
                  style={{
                    marginLeft: 5,
                    height: "100%",
                  }}
                >
                  <Upload
                    type="drag"
                    fileList={fileList}
                    customRequest={(opts) => uploadComprovantes(opts.file)}
                  >
                    <Button
                      type="link"
                      icon={<UploadOutlined />}
                      style={{ textAlign: "center" }}
                    >
                      CARREGAR NF-e
                    </Button>
                  </Upload>
                </Card>
              </Col>
            </Row>

            <Card
              title="Itens do pedido de compra"
              type="inner"
              bodyStyle={{ padding: 0 }}
            >
              <Table
                size="small"
                title={() => <AdicionarItemForm />}
                footer={() => (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Table
                      showHeader={false}
                      style={{
                        maxWidth: 200,
                        flex: 1,
                      }}
                      pagination={false}
                      columns={[
                        { dataIndex: "titulo", className: "titulo" },
                        {
                          dataIndex: "total",
                          className: "currency",
                          render: (value) =>
                            currencyFormatter.format(value, { code: "BRL" }),
                        },
                      ]}
                      dataSource={[{ titulo: "Total", total: calcularTotal() }]}
                    />
                  </div>
                )}
                bordered
                pagination={false}
                dataSource={itens}
                columns={[
                  { title: "Item", dataIndex: "descricao" },
                  {
                    title: "Valor Unitário",
                    dataIndex: "valor",
                    width: 130,
                    className: "currency",
                    render: (value) =>
                      currencyFormatter.format(value, { code: "BRL" }),
                  },
                  { title: "Quantidade", dataIndex: "quantidade", width: 100 },
                  {
                    title: "Subtotal",
                    className: "currency",
                    width: 130,
                    render: (_, obj) =>
                      currencyFormatter.format(
                        Number(obj.valor) * Number(obj.quantidade),
                        { code: "BRL" }
                      ),
                  },
                  {
                    title: "Ações",
                    width: 100,
                    render: (_, obj) => (
                      <div>
                        <Button
                          type="link"
                          size="small"
                          onClick={() => {
                            setItemSelecionado(obj);
                          }}
                        >
                          Editar
                        </Button>
                      </div>
                    ),
                  },
                ]}
              />
            </Card>
            <Input.Group compact style={{ marginTop: 10 }}>
              <Form.Item
                name={["enderecoDeEntrega", "cep"]}
                label="CEP"
                style={{ width: "10%", paddingRight: "5px" }}
                rules={[
                  {
                    message: "Informe o CEP",
                    max: 9,
                    min: 9,
                  },
                  {
                    message: "CEP Inválido",
                    pattern: /^[0-9]{5}-[0-9]{3}$/g,
                  },
                ]}
              >
                <Input
                  placeholder="00000-000"
                  maxLength={9}
                  onChange={(event) => {
                    const cepValue = event.target.value;
                    formRef.setFieldsValue({
                      enderecoDeEntrega: {
                        cep: cepFormatter(event.target.value),
                      },
                    });
                    if (cepValue.replace("-", "").length >= 8) {
                      cep(cepValue).then((response) => {
                        formRef.setFieldsValue({
                          enderecoDeEntrega: {
                            estado: response.state,
                            logradouro: response.street,
                            bairro: response.neighborhood,
                            cidade: response.city,
                          },
                        });
                      });
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                name={["enderecoDeEntrega", "logradouro"]}
                label="Rua"
                style={{ width: "35%", paddingRight: "5px" }}
              >
                <Input placeholder="Rua" />
              </Form.Item>
              <Form.Item
                name={["enderecoDeEntrega", "numero"]}
                label="Número"
                style={{ width: "10%", paddingRight: 5 }}
              >
                <Input placeholder="Número" type="number" />
              </Form.Item>

              <Form.Item
                name={["enderecoDeEntrega", "bairro"]}
                label="Bairro"
                style={{ width: "20%", paddingRight: "5px" }}
              >
                <Input placeholder="Bairro" />
              </Form.Item>
              <Form.Item
                name={["enderecoDeEntrega", "cidade"]}
                label="Cidade"
                rules={[{ required: true, message: "Informe a cidade" }]}
                style={{ width: "15%", paddingRight: "5px" }}
              >
                <Input placeholder="Cidade" />
              </Form.Item>
              <Form.Item
                name={["enderecoDeEntrega", "estado"]}
                label="Estado"
                rules={[{ required: true, message: "Informe o estado" }]}
                style={{ width: "10%" }}
              >
                <Input placeholder="Estado" />
              </Form.Item>
              <Form.Item
                name={["enderecoDeEntrega", "complemento"]}
                label="Complemento"
                style={{ width: "100%" }}
              >
                <Input placeholder="Complemento" />
              </Form.Item>
            </Input.Group>
            <Form.Item
              name={["pedido", "observacao"]}
              initialValue={pedidoDeCompra?.observacao}
              label="Observações"
              style={{ marginTop: 5 }}
            >
              <Input.TextArea placeholder="..." rows={3} maxLength={400} />
            </Form.Item>
            <Row>
              <Col span={18} />
              <Col span={6} style={{ marginTop: "30px", textAlign: "end" }}>
                <Space>
                  <Button
                    type="primary"
                    size="middle"
                    htmlType="submit"
                    disabled={
                      pedidoDeCompra?.status === StatusPedidoDeCompra.Cancelado
                    }
                  >
                    SALVAR
                  </Button>
                  <Button
                    type="ghost"
                    size="middle"
                    disabled={
                      pedidoDeCompra?.status === StatusPedidoDeCompra.Cancelado
                    }
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    CANCELAR
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </Card>
      ) : (
        <Loading />
      )}
    </div>
  );
}

export const PedidoDeCompraRoute = {
  id: "pedido-de-compra",
  path: "/inicio/financeiro/pedidos-de-compra/:id",
  sidebar: "Pedidos de compra",
  exact: true,
  renderInMenu: false,
  main: () => <PedidoDeCompra />,
  icon: null,
  permission: PedidoDeCompraPermissao,
};
