import { CheckSquareOutlined } from "@ant-design/icons";
import React from "react";
import { Atendimento as AtendimentoPermissao } from "../../core/permissions/subjects";
import { ClienteRoute } from "./clientes/cliente.page";
import { ClientesRoute } from "./clientes/clientes.page";
import { FornecedorRoute } from "./fornecedores/fornecedor.page";
import { FornecedoresRoute } from "./fornecedores/fornecedores.page";
import { JobRoute } from "./jobs/job.page";
import { JobsRoute } from "./jobs/jobs.page";
import { NovaRequisicaoDeCompraRoute } from "./requisicao-de-compra/nova-requisicao-de-compra.page";
import { RequisicaoDeCompraRoute } from "./requisicao-de-compra/requisicao-de-compra.page";
import { RequisicoesDeCompraRoute } from "./requisicao-de-compra/requisicoes-de-compra.page";

export default function Atendimento() {
  return (
    <div>
      <p>Atendimento</p>
    </div>
  );
}

export const AtendimentoRoute = {
  id: "atendimento",
  path: "/inicio/atendimento",
  exact: true,
  sidebar: "Atendimento",
  icon: <CheckSquareOutlined />,
  main: () => <Atendimento />,
  renderInMenu: true,
  permission: AtendimentoPermissao,
  submenus: [
    NovaRequisicaoDeCompraRoute,
    JobRoute,
    JobsRoute,
    ClientesRoute,
    FornecedoresRoute,
    FornecedorRoute,
    RequisicaoDeCompraRoute,
    RequisicoesDeCompraRoute,
    ClienteRoute,
  ],
};
