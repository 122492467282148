import React, { createContext, useState } from "react";

//Tipando os dados que quero para usuário
type MenuType = {
  activeMenu: string[];
};

//Tipando as Props do contexto
type PropsActiveMenuContext = {
  state: MenuType;
  setState: React.Dispatch<React.SetStateAction<MenuType>>;
};

//Valor default do contexto
const DEFAULT_VALUE = {
  state: {
    activeMenu: window.location.pathname
      .split("/")
      .filter((p) => p !== "" && p !== "inicio"),
  },
  setState: () => {}, //função de inicialização
};

//criando nosso contexto UserContext
const MenuContext = createContext<PropsActiveMenuContext>(DEFAULT_VALUE);

/**
 * Função que irá conter o estado e função que irá alterar o estado 'setState'
 * quer irá prover o contexto para os componentes filhos da árvore
 */
const MenuContextProvider: React.FC = ({ children }) => {
  const [state, setState] = useState(DEFAULT_VALUE.state);
  return (
    <MenuContext.Provider
      value={{
        state,
        setState,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};
export { MenuContextProvider };
export default MenuContext;
