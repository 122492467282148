import { AtualizarEmpresaDto } from "./dto/atualizar-empresa.dto";
import { BaseService } from "../base.service";
import { Endpoints } from "../../core/constants/endpoints";
import { NovaEmpresaDto } from "./dto/nova-empresa.dto";

export class EmpresaService extends BaseService {
  async obterEmpresas(busca: string = null) {
    try {
      if (busca && busca.length > 0)
        return await this.api.get(`${Endpoints.empresas}?busca=${busca}`);
      return await this.api.get(Endpoints.empresas);
    } catch (error) {
      throw error;
    }
  }
  async obterEmpresaPorId(id: string) {
    try {
      return await this.api.get(`${Endpoints.empresas}/${id}`);
    } catch (error) {
      throw error;
    }
  }
  async novaEmpresa(dto: NovaEmpresaDto) {
    try {
      return await this.api.post(Endpoints.empresas, dto);
    } catch (error) {
      throw error;
    }
  }
  async atualizarEmpresa(id: string, dto: AtualizarEmpresaDto) {
    try {
      return await this.api.patch(`${Endpoints.empresas}/${id}`, dto);
    } catch (error) {
      throw error;
    }
  }
  async verificarCnpjCadastrado(cnpj: string, empresa: string = null) {
    try {
      return await this.api.get(
        `${Endpoints.validarCnpjCadastradoEmpresa}?cnpj=${cnpj}${
          empresa ? `&empresa=${empresa}` : ""
        }`
      );
    } catch (error) {
      throw error;
    }
  }
  async obterEmpresasSimplificado(filtro: string) {
    try {
      if (filtro) {
        return await this.api.get(
          `${Endpoints.empresasSimplificado}?filtro=${filtro}`
        );
      }
      return await this.api.get(`${Endpoints.empresasSimplificado}`);
    } catch (error) {
      throw error;
    }
  }
  async inativarCadastro(id: string) {
    try {
      return await this.api.post(
        `${Endpoints.empresas}/${id}${Endpoints.inativarCadastro}`,
        {}
      );
    } catch (error) {
      throw error;
    }
  }
}
