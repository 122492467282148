import { useEffect, useState } from "react";

import { ContaBancariaService } from "../../services/conta-bancaria/conta-bancaria.service";

export default function useContasBancariasHook() {
  const [optionsContas, setOptionsContas] = useState([]);
  const [optionsContasSearch, setOptionsContasSearch] = useState(false);
  const _contasService = new ContaBancariaService();

  useEffect(() => {
    _contasService.obterTodas().then((result) => {
      const opts = result?.data?.data.map((c) => {
        return {
          key: c.id,
          value: c.id,
          label: c.descricao,
        };
      });
      setOptionsContas(opts);
    });
  }, []);

  const filterOptions = (input, option) =>
    option?.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;

  async function buscarContasBancarias(value) {
    if (value.length >= 3) {
      setOptionsContasSearch(true);
      const result = await _contasService.obterTodas();
      const opts = result?.data?.data.map((c) => {
        return {
          key: c.id,
          value: c.id,
          label: c.descricao,
        };
      });
      setOptionsContas(opts);
      setOptionsContasSearch(false);
    }
  }

  return {
    options: optionsContas,
    loading: optionsContasSearch,
    buscarContasBancarias,
    setOptions: setOptionsContas,
    filterOptions,
  };
}
