import { Button, Card, Col, Row, Space, Upload, message } from "antd";
import React, { useEffect, useState } from "react";

import { ConfiguracaoDashboardDto } from "../../services/dashboard/dto/configuracao-dashboard.dto";
import { DashboardService } from "../../services/dashboard/dashboard.service";
import { Endpoints } from "../../core/constants/endpoints";
import { PlusOutlined } from "@ant-design/icons";
import { v4 as newId } from "uuid";
import { useHistory } from "react-router-dom";
import useSWR from "swr";

export default function DashboardConfiguracao() {
  const _dashboardService = new DashboardService();
  const history = useHistory();

  const [bannersInferiores, setBannersInferiores] = useState([]);
  const [bannersSuperiores, setBannersSuperiores] = useState([]);
  const [documentos, setDocumentos] = useState([]);
  const { data: { data: { data: dashboardConfig } = {} } = {} } = useSWR(
    Endpoints.dashboard,
    () => _dashboardService.obterConfiguracao(),
    { revalidateOnFocus: false }
  );

  async function uploadHandler(file, setFileList, fileList) {
    setFileList([
      ...fileList,
      {
        uid: file.uid,
        name: file.name,
        status: "uploading",
        url: null,
      },
    ]);
    const form = new FormData();
    form.append("arquivo", file);
    const {
      data: { data },
    } = await _dashboardService.carregarAnexos(form);

    const { url, mimetype } = data;
    const list = fileList.filter((f) => f.uid !== file.uid);
    list.push({
      uid: file.uid,
      name: file.name,
      status: "done",
      url: url,
      mime: mimetype,
    });
    setFileList(list);
  }

  async function atualizarConfiguracaoHandler() {
    try {
      const dto: ConfiguracaoDashboardDto = {
        bannersInferiores: bannersInferiores.map((b) => {
          return { filename: b.name, url: b.url, mime: b.mime };
        }),
        documentos: documentos.map((b) => {
          return { filename: b.name, url: b.url, mime: b.mime };
        }),
        bannersSuperiores: bannersSuperiores.map((b) => {
          return { filename: b.name, url: b.url, mime: b.mime };
        }),
      };
      await _dashboardService.atualizarConfiguracao(dto);
      message.success("Dashboard atualizada");
    } catch (error) {
      message.error(error.message);
    }
  }

  useEffect(() => {
    if (dashboardConfig?.bannersInferiores?.length > 0) {
      const itens = dashboardConfig?.bannersInferiores.map((i) => {
        return { uid: newId(), name: i.filename, url: i.url, status: "done" };
      });
      setBannersInferiores(itens);
    }
    if (dashboardConfig?.bannersSuperiores?.length > 0) {
      const itens = dashboardConfig?.bannersSuperiores.map((i) => {
        return { uid: newId(), name: i.filename, url: i.url, status: "done" };
      });
      setBannersSuperiores(itens);
    }
    if (dashboardConfig?.documentos?.length > 0) {
      const itens = dashboardConfig?.documentos.map((i) => {
        return { uid: newId(), name: i.filename, url: i.url, status: "done" };
      });
      setDocumentos(itens);
    }
  }, [dashboardConfig]);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Carregar</div>
    </div>
  );

  return (
    <div>
      <Card title="Banners" loading={!dashboardConfig}>
        <Row>
          <Col span={12}>
            <Card title="Banners Superiores" style={{ marginRight: 4 }}>
              <Upload
                fileList={bannersSuperiores}
                onRemove={(file) => {
                  const list = bannersSuperiores.filter(
                    (f) => f.uid !== file.uid
                  );
                  setBannersSuperiores(list);
                }}
                listType="picture-card"
                customRequest={(opts) =>
                  uploadHandler(
                    opts.file,
                    setBannersSuperiores,
                    bannersSuperiores
                  )
                }
              >
                {bannersSuperiores.length >= 3 ? null : uploadButton}
              </Upload>
            </Card>
          </Col>
          <Col span={12}>
            <Card title="Banners Inferiores">
              <Upload
                fileList={bannersInferiores}
                onRemove={(file) => {
                  const list = bannersInferiores.filter(
                    (f) => f.uid !== file.uid
                  );
                  setBannersInferiores(list);
                }}
                listType="picture-card"
                customRequest={(opts) =>
                  uploadHandler(
                    opts.file,
                    setBannersInferiores,
                    bannersInferiores
                  )
                }
              >
                {bannersInferiores.length >= 2 ? null : uploadButton}
              </Upload>
            </Card>
          </Col>
        </Row>
      </Card>
      <Card title="Documentos" loading={!dashboardConfig}>
        <Upload
          onRemove={(file) => {
            const list = documentos.filter((f) => f.uid !== file.uid);
            setDocumentos(list);
          }}
          fileList={documentos}
          customRequest={(opts) =>
            uploadHandler(opts.file, setDocumentos, documentos)
          }
        >
          <Button>Carregar documento</Button>
        </Upload>
      </Card>
      <Row>
        <Col span={20} />
        <Col span={4} style={{ marginTop: "30px", textAlign: "end" }}>
          <Space>
            <Button
              type="primary"
              size="middle"
              onClick={() => atualizarConfiguracaoHandler()}
            >
              SALVAR
            </Button>
            <Button
              type="ghost"
              size="middle"
              onClick={() => {
                history.goBack();
              }}
            >
              CANCELAR
            </Button>
          </Space>
        </Col>
      </Row>
    </div>
  );
}
