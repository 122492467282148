import "./login.css";

import { Button, Card, Checkbox, Form, Input, Modal, message } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

import { AuthService } from "../../services/auth/auth.service";
import logo from "../../assets/images/logo.png";
import { useHistory } from "react-router-dom";
import useTitle from "../../core/hooks/title.hook";

const _authService = new AuthService();
function Login() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [recuperarSenha, setRecuperarSenha] = useState(false);

  useTitle("Login");
  useEffect(() => {
    const refreshToken = localStorage.getItem("refresh_token");
    if (refreshToken) {
      _authService
        .refreshToken()
        .then(() => {
          message.success("Login efetuado com sucesso!");
          setTimeout(() => {
            history.push(`/inicio`);
          }, 2000);
        })
        .catch(() => {
          message.warning("Efetue login para continuar");
          form.setFieldsValue({ senha: "" });
        });
    }
  }, []);

  async function handleLogin(values: any) {
    try {
      const {
        data: { data },
      } = await _authService.efetuarLogin(values);
      if (values?.remember) {
        localStorage.setItem("user", values?.email);
      }

      sessionStorage.setItem("token", data?.access_token);
      localStorage.setItem("refresh_token", data?.refresh_token);
      message.success("Login efetuado com sucesso!");
      setTimeout(() => {
        history.replace(`/inicio`);
      }, 2000);
    } catch (error) {
      message.error("Usuário/Senha são inválidos");
      form.setFieldsValue({ senha: "" });
    }
  }

  function RecuperarSenhaModal({ visible, setVisible }) {
    const [formRecuperarSenha] = Form.useForm();
    async function recuperarSenhaHandler(values) {
      try {
        const { email } = values;
        await _authService.recuperacaoDeSenha(email);
        message.success(
          "Uma senha temporária foi enviada para o endereço de e-mail " + email
        );
        setVisible(false);
      } catch (error) {
        message.error("Algo deu errado... Verifique o e-mail informado");
      }
    }
    return (
      <Modal
        width={330}
        visible={visible}
        title="Recuperar Senha"
        bodyStyle={{ padding: 0 }}
        closable={false}
        onCancel={() => setVisible(false)}
        footer
      >
        <Card>
          <Form
            form={formRecuperarSenha}
            layout="vertical"
            onFinish={recuperarSenhaHandler}
          >
            <Form.Item
              name="email"
              label="Informe o e-mail do cadastro:"
              rules={[{ required: true, message: "" }]}
            >
              <Input placeholder="E-mail" type="email" size="middle" />
            </Form.Item>
            <Button
              size="middle"
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
            >
              GERAR SENHA TEMPORÁRIA
            </Button>
          </Form>
        </Card>
      </Modal>
    );
  }

  return (
    <div className="container-login">
      <RecuperarSenhaModal
        visible={recuperarSenha}
        setVisible={setRecuperarSenha}
      />
      <Form
        autoComplete="on"
        form={form}
        id="login"
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
          email: localStorage.getItem("user"),
          senha: localStorage.getItem("user") ? "0000000000000000" : "",
        }}
        onFinish={handleLogin}
      >
        <div className="logo-container">
          <img src={logo} className="logo-login" alt="logo" />
        </div>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Informe o usuário/e-mail!",
            },
          ]}
        >
          <Input
            autoComplete="on"
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Usuário/E-mail"
            size="middle"
          />
        </Form.Item>
        <Form.Item
          name="senha"
          rules={[
            {
              required: true,
              message: "Informe a senha!",
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Senha"
            autoComplete="on"
            size="middle"
          />
        </Form.Item>
        <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Lembrar-me</Checkbox>
          </Form.Item>

          <Button
            type="link"
            className="login-form-forgot"
            onClick={() => setRecuperarSenha(true)}
          >
            Esqueceu a senha
          </Button>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            size="middle"
          >
            Entrar
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Login;
