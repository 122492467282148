import { BaseService } from "../base.service";
import { Endpoints } from "./../../core/constants/endpoints";
import { NovaConciliacaoBancariaDto } from "./dto/nova-conciliacao-bancaria.dto";

export class ConciliacaoBancariaService extends BaseService {
  async importarExtrato(form: FormData, contaBancaria: string) {
    try {
      return await this.api.upload(
        `${Endpoints.importarExtrato}/${contaBancaria}`,
        form
      );
    } catch (error) {
      throw error;
    }
  }
  async obterTransacoesPendentes() {
    try {
      return await this.api.get(Endpoints.obterTransacoesPendentes);
    } catch (error) {
      throw error;
    }
  }

  async efetuarConciliacao(dto: NovaConciliacaoBancariaDto) {
    try {
      return await this.api.post(Endpoints.efetuarConciliacao, dto);
    } catch (error) {
      throw error;
    }
  }
  async obterSugestoesDeConciliacao(valor: number, conta: string) {
    try {
      return await this.api.get(
        `${Endpoints.obterSugestoesDeConciliacao}?valor=${valor}&conta=${conta}`
      );
    } catch (error) {
      throw error;
    }
  }
}
