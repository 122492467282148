import { DollarOutlined } from "@ant-design/icons";
import { Button, PageHeader, Table } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Colaborador } from "../../../core/permissions/subjects";
import EfetuarPagamentoModal from "./efetuar-pagamento.modal";

function PagamentoColaboradores() {
  const history = useHistory();
  const [novoPagamentoModal, setNovoPagamentoModal] = useState(false);
  return (
    <div>
      <EfetuarPagamentoModal
        visible={novoPagamentoModal}
        setVisible={setNovoPagamentoModal}
      />
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title={`Pagamento de colaboradores`}
        style={{ marginBottom: 5 }}
        subTitle="pagamentos efetuados"
        extra={
          <Button
            size="middle"
            type="primary"
            onClick={() => setNovoPagamentoModal(true)}
          >
            Efetuar pagamento
          </Button>
        }
      />
      <Table bordered />
    </div>
  );
}

export const PagamentoColaboradoresRoute = {
  id: "pagamento-de-salario",
  renderInMenu: true,
  path: "/inicio/recursos-humanos/pagamento-de-salario",
  sidebar: "Pagamento de salário",
  exact: true,
  main: () => <PagamentoColaboradores />,
  icon: <DollarOutlined />,
  permission: Colaborador,
};
