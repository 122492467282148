import { BaseService } from "../base.service";
import { Endpoints } from "../../core/constants/endpoints";

export class FluxoDeCaixaService extends BaseService {
  async obterTransacoes(filtro: string = "") {
    try {
      console.log(`${Endpoints.fluxoDeCaixaTransacoes}?${filtro}`);

      return await this.api.get(
        `${Endpoints.fluxoDeCaixaTransacoes}?${filtro}`
      );
    } catch (error) {
      throw error;
    }
  }
}
