import { BaseService } from "../base.service";
import { Endpoints } from "./../../core/constants/endpoints";
import { NovoReembolsoDto } from "./dto/novo-reembolso.dto";
export class ReembolsoService extends BaseService {
  async carregarAnexos(form: FormData) {
    try {
      return await this.api.upload(Endpoints.arquivosReembolso, form);
    } catch (error) {
      throw error;
    }
  }
  async solicitarReembolso(data: NovoReembolsoDto) {
    try {
      return await this.api.post(Endpoints.reembolso, data);
    } catch (error) {
      throw error;
    }
  }
}
