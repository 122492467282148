import { AuthService } from "../services/auth/auth.service";
import { Endpoints } from "../core/constants/endpoints";
import useSWR from "swr";
const _authService = new AuthService();
export default function RefreshToken() {
  useSWR(Endpoints.refresh, () => _authService.refreshToken(), {
    refreshInterval: 1800000,
    revalidateOnFocus: false,
  });

  return <></>;
}
