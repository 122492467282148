import { AtualizarFormaDePagamentoDto } from "./dto/atualizar-forma-de-pagamento.dto";
import { BaseService } from "../base.service";
import { Endpoints } from "../../core/constants/endpoints";
import { NovaFormaDePagamentoDto } from "./dto/nova-forma-de-pagamento.dto";

export class FormaDePagamentoService extends BaseService {
  async obterTodas() {
    try {
      return await this.api.get(Endpoints.formasDePagamento);
    } catch (error) {
      throw error;
    }
  }
  async obterPorId(id: string) {
    try {
      return await this.api.get(`${Endpoints.formasDePagamento}/${id}`);
    } catch (error) {
      throw error;
    }
  }
  async inativarCadastro(id: string) {
    try {
      return await this.api.patch(
        `${Endpoints.formasDePagamento}/${id}/inativar`
      );
    } catch (error) {
      throw error;
    }
  }
  async obterFormasDePagamentoSimplificado(filtro: string) {
    try {
      if (filtro) {
        return await this.api.get(
          `${Endpoints.formasDePagamentoSimplificado}?filtro=${filtro}`
        );
      }
      return await this.api.get(`${Endpoints.formasDePagamentoSimplificado}`);
    } catch (error) {
      throw error;
    }
  }
  async cadastrarFormaDePagamento(dto: NovaFormaDePagamentoDto) {
    try {
      return await this.api.post(Endpoints.formasDePagamento, dto);
    } catch (error) {
      throw error;
    }
  }
  async atualizarFormaDePagamento(
    id: string,
    dto: AtualizarFormaDePagamentoDto
  ) {
    try {
      return await this.api.patch(`${Endpoints.formasDePagamento}/${id}`, dto);
    } catch (error) {
      throw error;
    }
  }
}
