export enum StatusContaAPagar {
  APagar = 0,
  Pago = 1,
  Cancelado = 2,
  Vencido = 3,
  Conciliado = 4,
  AguardandoNf = 5,
}

export const StatusContaAPagarDescricao: {
  [key in StatusContaAPagar]: string;
} = {
  [StatusContaAPagar.APagar]: "A PAGAR",
  [StatusContaAPagar.Pago]: "PAGO",
  [StatusContaAPagar.Cancelado]: "CANCELADO",
  [StatusContaAPagar.Vencido]: "VENCIDO",
  [StatusContaAPagar.Conciliado]: "CONCILIADO",
  [StatusContaAPagar.AguardandoNf]: "AGUARDANDO NOTA FISCAL",
};
export const StatusContaAPagarDescricaoCor: {
  [key in StatusContaAPagar]: string;
} = {
  [StatusContaAPagar.APagar]: "cyan",
  [StatusContaAPagar.Pago]: "green",
  [StatusContaAPagar.Cancelado]: "gold",
  [StatusContaAPagar.Vencido]: "red",
  [StatusContaAPagar.Conciliado]: "blue",
  [StatusContaAPagar.AguardandoNf]: "OrangeRed",
};

function enumToArray(enumerator: any) {
  const list = [];
  for (
    let index = 0;
    index <
    Object.values(enumerator).filter((value) => !isNaN(Number(value))).length;
    index++
  ) {
    const key = Object.keys(enumerator)[index];
    const value = StatusContaAPagarDescricao[index];
    list.push({
      key: Number(key),
      value: Number(key),
      label: value.toUpperCase(),
    });
  }
  return list;
}

export const statusContaAPagarList = enumToArray(StatusContaAPagar);
