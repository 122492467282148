import { PlusCircleOutlined, QuestionCircleTwoTone } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  message,
  PageHeader,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
} from "antd";
import currencyFormatter from "currency-formatter";
import React, { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import useSWR from "swr";
import { v4 as newId } from "uuid";
import useBuscarEmpresaHook from "../../../components/hooks/buscar-empresa.hook";
import useBuscarFormaDePagamentoHook from "../../../components/hooks/buscar-forma-de-pagamento.hook";
import useBuscarFornecedorHook from "../../../components/hooks/buscar-fornecedor.hook";
import useBuscarJobHook from "../../../components/hooks/buscar-job.hook";
import useBuscarPrazoDePagamentoHook from "../../../components/hooks/buscar-prazo-de-pagamento.hook";
import { NumeroDeParcelasModal } from "../../../components/numero-parcelas.modal.component";
import { Endpoints } from "../../../core/constants/endpoints";
import { cepFormatter } from "../../../core/utils/formatters/cep.formatter";
import { datasValidas } from "../../../core/utils/formatters/datas.formatter";
import { identificadorFormatter } from "../../../core/utils/formatters/job.formatter";
import { ColaboradorService } from "../../../services/colaborador/colaborador.service";
import { NovaRequisicaoDeCompraDto } from "../../../services/requisicao-de-compra/dto/nova-requisicao-de-compra.dto";
import { RequisicaoDeCompraService } from "../../../services/requisicao-de-compra/requisicao-de-compra.service";
import PreCadastroFornecedor from "../fornecedores/pre-cadastro-fornecedor.page";

const cep = require("cep-promise");

export default function NovaRequisicaoDeCompra({
  visible,
  setVisible,
  atualizarPai,
  job = null,
}) {
  const _colaboradorService = new ColaboradorService();
  const _requisicaoDeCompraService = new RequisicaoDeCompraService();
  const {
    setOptions: setOptionsFornecedor,
    options: optionsFornecedor,
    optionsFornecedorSearch,
    buscarFornecedor,
    filterOptions: filterOptionsFornecedor,
  } = useBuscarFornecedorHook();

  const {
    buscarEmpresa,
    options: optionsEmpresa,
    setOptions: setOptionsEmpresa,
    loading: optionsEmpresaSearch,
    filterOptions: filterOptionsEmpresa,
  } = useBuscarEmpresaHook();
  const {
    buscarJob,
    options: optionsJob,
    setOptions: setOptionsJob,
    filterOption: filterOptionsJob,
    loading: loadingJobs,
  } = useBuscarJobHook();

  const {
    options: optionsFormaDePagamento,
    buscarFormaDePagamento,
    loading: loadingFormasDePagamento,
  } = useBuscarFormaDePagamentoHook();
  const {
    options: optionsPrazoPagamento,
    buscarPrazoDePagamento,
    loading: loadingPrazoDePagamento,
  } = useBuscarPrazoDePagamentoHook();

  const [formRef] = Form.useForm();

  const [itemSelecionado, setItemSelecionado] = useState(null);
  const [itens, setItens] = useState([]);
  const [modalNumeroDeParcelas, setModalNumeroDeParcelas] = useState(false);

  const [preCadastroFornecedor, setPreCadastroFornecedor] = useState(false);
  const { data: { data: { data: usuario } = {} } = {} } = useSWR(
    Endpoints.colaboradoresInfo,
    () => _colaboradorService.obterInfoUsuario(),
    { revalidateOnFocus: false }
  );

  useEffect(() => {
    if (job) {
      setOptionsJob([
        {
          key: job?.id,
          value: job?.id,
          label: `${identificadorFormatter(job?.identificador)} - ${job?.nome}`,
        },
      ]);
      setOptionsEmpresa([
        {
          key: job?.empresa?.id,
          value: job?.empresa?.id,
          label: job?.empresa?.nomeFantasia,
        },
      ]);

      formRef.setFieldsValue({
        requisicao: {
          empresa: job?.empresa?.id,
          job: job?.id,
        },
      });
    }
  }, [usuario, job]);

  function AdicionarItemForm() {
    const [itemFormRef] = Form.useForm();

    if (itemSelecionado) {
      itemFormRef.setFieldsValue(itemSelecionado);
    }
    async function adicionarItem() {
      const item = itemFormRef.getFieldsValue([
        "id",
        "descricao",
        "valor",
        "quantidade",
      ]);
      await itemFormRef.validateFields();
      if (itemSelecionado) {
        item.valor = currencyFormatter.unformat(item.valor, {
          code: "BRL",
        });
        const itensNew = itens.filter((i) => i.id !== itemSelecionado.id);
        itensNew.push(item);
        setItens(itensNew);
        setItemSelecionado(null);
      } else {
        setItens([...itens, item]);
      }
    }

    return (
      <div title="Adicionar Item" style={{ marginBottom: -20 }}>
        <Form
          form={itemFormRef}
          layout="horizontal"
          onFinish={() => adicionarItem()}
        >
          <div
            style={{ display: "flex" }}
            onKeyPress={(e) => {
              if (e.key === "Enter" || e.key === "Tab") {
                itemFormRef.submit();
              }
            }}
          >
            <Input.Group compact>
              <Form.Item hidden initialValue={newId()} name="id" />
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
                name="descricao"
                style={{ width: "70%", paddingRight: 5 }}
              >
                <Input placeholder="Descrição" />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
                style={{ paddingRight: 5, width: "15%" }}
              >
                <CurrencyInput
                  name="valor"
                  defaultValue={itemFormRef.getFieldValue("valor")}
                  placeholder="Valor Unitário"
                  className="ant-input ant-input-sm"
                  decimalScale={2}
                  onValueChange={(value = "0") => {
                    itemFormRef.setFieldsValue({
                      valor: currencyFormatter.unformat(value, { code: "BRL" }),
                    });
                  }}
                  intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                />
              </Form.Item>
              <Form.Item
                style={{ width: "15%" }}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
                name="quantidade"
              >
                <Input placeholder="Quantidade" type="number" />
              </Form.Item>
            </Input.Group>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={() => itemFormRef.submit()}
            >
              Adicionar Item
            </Button>
          </div>
        </Form>
      </div>
    );
  }
  function calcularTotal() {
    let total = 0;
    for (const item of itens) {
      total += Number(item.quantidade) * Number(item.valor);
    }
    return total;
  }

  async function criarRequisicaoDeCompra() {
    try {
      const { requisicao, enderecoDeEntrega } = formRef.getFieldsValue([
        "enderecoDeEntrega",
        "requisicao",
      ]);

      const dto: NovaRequisicaoDeCompraDto = {
        observacao: requisicao?.observacao,
        solicitante: { id: usuario?.id },
        formaDePagamento: { id: requisicao.formaDePagamento },
        prazoDePagamento: {
          id: requisicao?.prazoDePagamento,
          numeroDeParcelas: requisicao?.numeroDeParcelas,
          diasEntreParcelas: requisicao?.diasEntreParcelas,
          diasParaPrimeiraParcela: requisicao?.diasParaPrimeiraParcela,
        },
        empresa: { id: requisicao.empresa },
        fornecedor: { id: requisicao.fornecedor },
        job: { id: requisicao.job },
        dataDeEntrega: requisicao?.dataDeEntrega?.toString(),
        dataDePagamento: requisicao.dataDePagamento,
        itens: itens.map((i) => {
          return {
            valor: i.valor,
            quantidade: i.quantidade,
            descricao: i.descricao,
          };
        }),
        enderecoDeEntrega: {
          ...enderecoDeEntrega,
          pais: "BR",
          numero: enderecoDeEntrega?.numero ? enderecoDeEntrega?.numero : 0,
          cep: enderecoDeEntrega?.cep
            ? enderecoDeEntrega?.cep.replace(/[^\d]+/g, "")
            : null,
        },
      };

      await _requisicaoDeCompraService.criarNovaRequisicao(dto);
      message.success("Requisição de compra adicionada!");
      formRef.resetFields();
      atualizarPai(true);
      setVisible(false);
      setItens([]);
    } catch (error) {
      if (error?.response?.data?.message && typeof Array) {
        for (const e of error?.response?.data?.message.toString().split(",")) {
          message.error(e);
        }
      } else {
        message.error(error?.message);
      }
    }
  }

  return (
    <div>
      <PreCadastroFornecedor
        visible={preCadastroFornecedor}
        setVisible={setPreCadastroFornecedor}
        child={true}
        form={formRef}
        setOptionsFornecedor={setOptionsFornecedor}
      />
      <NumeroDeParcelasModal
        visible={modalNumeroDeParcelas}
        setVisible={setModalNumeroDeParcelas}
        formRef={formRef}
        contexto={"requisicao"}
      />
      <Drawer
        visible={visible}
        width={1150}
        closable={false}
        bodyStyle={{ padding: 5 }}
      >
        <PageHeader
          className="site-page-header"
          onBack={() => {
            setVisible(false);
          }}
          title="Nova requisição de compra"
          style={{ marginBottom: 3 }}
        />

        <Form
          layout="vertical"
          form={formRef}
          onFinish={() => criarRequisicaoDeCompra()}
        >
          <Card title="Geral" type="inner">
            <Input.Group compact>
              <Form.Item
                label="Job"
                rules={[{ required: true, message: "Job é obrigatório" }]}
                name={["requisicao", "job"]}
                style={{ width: "50%", paddingRight: 5 }}
              >
                <Select
                  disabled={job !== null}
                  placeholder="Selecione o Job"
                  showSearch
                  options={optionsJob}
                  loading={loadingJobs}
                  filterOption={filterOptionsJob}
                  onSearch={buscarJob}
                />
              </Form.Item>
              <Form.Item
                label="Empresa contratante"
                rules={[
                  {
                    required: true,
                    message: "Empresa é obrigatório",
                  },
                ]}
                name={["requisicao", "empresa"]}
                style={{ width: "50%" }}
              >
                <Select
                  placeholder="Selecione a empresa"
                  showSearch
                  options={optionsEmpresa}
                  loading={optionsEmpresaSearch}
                  filterOption={filterOptionsEmpresa}
                  onSearch={buscarEmpresa}
                />
              </Form.Item>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  paddingRight: 5,
                }}
              >
                <Form.Item
                  label="Fornecedor"
                  rules={[
                    {
                      required: true,
                      message: "Fornecedor é obrigatório",
                    },
                  ]}
                  name={["requisicao", "fornecedor"]}
                  style={{
                    width: "100%",
                  }}
                >
                  <Select
                    placeholder="Busque por razão social ou nome fantasia"
                    showSearch
                    options={optionsFornecedor}
                    loading={optionsFornecedorSearch}
                    filterOption={filterOptionsFornecedor}
                    onSearch={buscarFornecedor}
                  />
                </Form.Item>
                <Button
                  icon={<PlusCircleOutlined />}
                  onClick={() => setPreCadastroFornecedor(true)}
                  type="default"
                  style={{
                    marginLeft: -5,
                    marginTop: 32,
                    borderTopRightRadius: 5,
                    borderEndEndRadius: 5,
                  }}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Form.Item
                  label="Data de entrega"
                  rules={[
                    {
                      required: true,
                      message: "Data de entrega é obrigatório",
                    },
                  ]}
                  name={["requisicao", "dataDeEntrega"]}
                  style={{ width: "20%", paddingRight: 5 }}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    format="DD/MM/YYYY"
                    onSelect={(value) => {
                      formRef.setFieldsValue({
                        requisicao: {
                          dataDePagamento: value,
                        },
                      });
                    }}
                  />
                </Form.Item>

                <div
                  style={{
                    display: "flex",
                    width: "50%",
                    alignItems: "center",
                  }}
                >
                  <Form.Item
                    label="Prazo de Pagamento"
                    rules={[
                      {
                        required: true,
                        message: "Prazo de Pagamento é obrigatório",
                      },
                    ]}
                    name={["requisicao", "prazoDePagamento"]}
                    style={{ width: "70%", paddingRight: 5 }}
                  >
                    <Select
                      placeholder="Selecione o prazo de pagamento"
                      showSearch
                      onSelect={(value, opt: any) => {
                        if (opt?.solicitarParcelamento) {
                          setModalNumeroDeParcelas(true);
                        }
                      }}
                      options={optionsPrazoPagamento}
                      loading={loadingPrazoDePagamento}
                      filterOption={(input, option) =>
                        option?.label
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onSearch={buscarPrazoDePagamento}
                    />
                  </Form.Item>
                  <Form.Item
                    label="A partir de"
                    rules={[
                      {
                        required: true,
                        message: "Selecione a data",
                      },
                    ]}
                    name={["requisicao", "dataDePagamento"]}
                    style={{ width: "30%" }}
                  >
                    <DatePicker
                      format={"DD/MM/YYYY"}
                      disabledDate={(date) => datasValidas(date.toDate())}
                      placeholder="Selecione a data"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                  <div
                    style={{
                      marginTop: 8,
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <Tooltip
                      title={`O prazo de pagamento será iniciado a partir da data selecionada`}
                    >
                      <Button
                        icon={<QuestionCircleTwoTone />}
                        type="link"
                        size="middle"
                      />
                    </Tooltip>
                  </div>
                </div>
                <div style={{ display: "flex", width: "30%" }}>
                  <Form.Item
                    label="Forma de Pagamento"
                    rules={[
                      {
                        required: true,
                        message: "Forma de Pagamento é obrigatório",
                      },
                    ]}
                    name={["requisicao", "formaDePagamento"]}
                    style={{ width: "100%", paddingRight: 5 }}
                  >
                    <Select
                      placeholder="Selecione a forma de pagamento"
                      showSearch
                      options={optionsFormaDePagamento}
                      loading={loadingFormasDePagamento}
                      filterOption={(input, option) =>
                        option?.label
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onSearch={buscarFormaDePagamento}
                    />
                  </Form.Item>
                </div>
              </div>
            </Input.Group>
          </Card>

          <Card
            title="Itens da requisição"
            type="inner"
            bodyStyle={{ padding: 0 }}
          >
            <Table
              size="small"
              bordered
              showHeader
              title={() => <AdicionarItemForm />}
              footer={() => (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  <Table
                    showHeader={false}
                    style={{
                      maxWidth: 200,
                      flex: 1,
                    }}
                    pagination={false}
                    columns={[
                      { dataIndex: "titulo", className: "titulo" },
                      {
                        dataIndex: "total",
                        className: "currency",
                        render: (value) =>
                          currencyFormatter.format(value, { code: "BRL" }),
                      },
                    ]}
                    dataSource={[{ titulo: "Total", total: calcularTotal() }]}
                  />
                </div>
              )}
              pagination={false}
              dataSource={itens}
              columns={[
                { title: "Item", dataIndex: "descricao" },
                {
                  title: "Valor Unitário",
                  dataIndex: "valor",
                  width: 130,
                  className: "currency",
                  render: (value) =>
                    currencyFormatter.format(value, { code: "BRL" }),
                },
                { title: "Quantidade", dataIndex: "quantidade", width: 100 },
                {
                  title: "Subtotal",
                  className: "currency",
                  width: 130,
                  render: (_, obj) =>
                    currencyFormatter.format(
                      Number(obj.valor) * Number(obj.quantidade),
                      { code: "BRL" }
                    ),
                },
                {
                  title: "Ações",
                  width: 200,
                  render: (_, obj) => (
                    <div>
                      <Button
                        type="link"
                        size="small"
                        onClick={() => {
                          setItemSelecionado(obj);
                        }}
                      >
                        Editar
                      </Button>
                      <Button
                        type="link"
                        style={{ color: "red" }}
                        size="small"
                        onClick={() => {
                          setItens(itens.filter((i) => i.id !== obj.id));
                        }}
                      >
                        Remover
                      </Button>
                    </div>
                  ),
                },
              ]}
            />
          </Card>
          <Card title="Endereço de entrega" type="inner">
            <Input.Group compact>
              <Form.Item
                name={["enderecoDeEntrega", "cidade"]}
                label="Cidade"
                rules={[{ required: true, message: "Informe a cidade" }]}
                style={{ width: "15%", paddingRight: "5px" }}
              >
                <Input placeholder="Cidade" />
              </Form.Item>
              <Form.Item
                name={["enderecoDeEntrega", "estado"]}
                label="Estado"
                rules={[{ required: true, message: "Informe o estado" }]}
                style={{ width: "10%", paddingRight: "5px" }}
              >
                <Input placeholder="Estado" />
              </Form.Item>
              <Form.Item
                name={["enderecoDeEntrega", "cep"]}
                label="CEP"
                style={{ width: "10%", paddingRight: "5px" }}
                rules={[
                  {
                    message: "CEP Inválido",
                    pattern: /^[0-9]{5}-[0-9]{3}$/g,
                  },
                ]}
              >
                <Input
                  placeholder="00000-000"
                  maxLength={9}
                  onChange={(event) => {
                    const cepValue = event.target.value;
                    formRef.setFieldsValue({
                      enderecoDeEntrega: {
                        cep: cepFormatter(event.target.value),
                      },
                    });
                    if (cepValue.replace("-", "").length >= 8) {
                      cep(cepValue).then((response) => {
                        formRef.setFieldsValue({
                          enderecoDeEntrega: {
                            estado: response.state,
                            logradouro: response.street,
                            bairro: response.neighborhood,
                            cidade: response.city,
                          },
                        });
                      });
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                name={["enderecoDeEntrega", "logradouro"]}
                label="Rua"
                style={{ width: "35%", paddingRight: "5px" }}
              >
                <Input placeholder="Rua" />
              </Form.Item>
              <Form.Item
                name={["enderecoDeEntrega", "numero"]}
                label="Número"
                style={{ width: "10%", paddingRight: 5 }}
              >
                <Input placeholder="Número" type="number" />
              </Form.Item>

              <Form.Item
                name={["enderecoDeEntrega", "bairro"]}
                label="Bairro"
                style={{ width: "20%", paddingRight: "5px" }}
              >
                <Input placeholder="Bairro" />
              </Form.Item>
            </Input.Group>
          </Card>
          <Form.Item
            name={["requisicao", "observacao"]}
            label="Observações"
            style={{ marginTop: 5 }}
          >
            <Input.TextArea placeholder="..." rows={3} maxLength={400} />
          </Form.Item>
          <Row>
            <Col span={18} />
            <Col span={6} style={{ marginTop: "30px", textAlign: "end" }}>
              <Space>
                <Button type="primary" size="middle" htmlType="submit">
                  SALVAR
                </Button>
                <Button
                  type="ghost"
                  size="middle"
                  onClick={() => {
                    setVisible(false);
                    formRef.resetFields();
                  }}
                >
                  CANCELAR
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </div>
  );
}
