export enum TipoTransacaoBancaria {
  CREDIT = "CREDIT",
  DEBIT = "DEBIT",
  INT = "INT",
  DIV = "DIV",
  DEP = "DEP",
  FEE = "FEE",
  ATM = "ATM",
  POS = "POS",
  SRVCHG = "SRVCHG",
  XFER = "XFER",
  CHECK = "CHECK",
  PAYMENT = "PAYMENT",
  CASH = "CASH",
  DIRECTDEP = "DIRECTDEP",
  DIRECTDEBIT = "DIRECTDEBIT",
  REPEATPMT = "REPEATPMT",
  HOLD = "HOLD",
  OTHER = "OTHER",
}

export const TipoTransacaoBancariaDescricao: {
  [key in TipoTransacaoBancaria]: string;
} = {
  [TipoTransacaoBancaria.CREDIT]: "Crédito em conta",
  [TipoTransacaoBancaria.DEBIT]: "Débito em conta",
  [TipoTransacaoBancaria.INT]: "Juros ganhos ou pagos",
  [TipoTransacaoBancaria.DIV]: "Dividendos",
  [TipoTransacaoBancaria.DEP]: "Depósito",
  [TipoTransacaoBancaria.FEE]: "Taxa de fundo de investimento",
  [TipoTransacaoBancaria.ATM]: "Operação no caixa eletrônico.",
  [TipoTransacaoBancaria.POS]: "Ponto de venda de débito ou crédito.",
  [TipoTransacaoBancaria.SRVCHG]: "Taxa de serviço",
  [TipoTransacaoBancaria.XFER]: "Transferência",
  [TipoTransacaoBancaria.CHECK]: "Cheque",
  [TipoTransacaoBancaria.PAYMENT]: "Pagamento eletrônico",
  [TipoTransacaoBancaria.CASH]: "Saque em dinheiro",
  [TipoTransacaoBancaria.DIRECTDEP]: "Depósito no caixa",
  [TipoTransacaoBancaria.DIRECTDEBIT]: "Débito iniciado pelo comerciante",
  [TipoTransacaoBancaria.REPEATPMT]: "Pagamento recorrente.",
  [TipoTransacaoBancaria.HOLD]: "Indica que o valor está retido",
  [TipoTransacaoBancaria.OTHER]: "Outra",
};
