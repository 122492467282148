import { ApiClient } from "./../../core/infra/api-client";
import { Endpoints } from "./../../core/constants/endpoints";
import { LoginDto } from "./dto/login.dto";

export class AuthService {
  private api: ApiClient;
  constructor() {
    this.api = new ApiClient();
  }
  async efetuarLogin(dto: LoginDto) {
    try {
      return await this.api.post(Endpoints.login, dto);
    } catch (error) {
      throw error;
    }
  }

  async recuperacaoDeSenha(email: string) {
    try {
      return await this.api.post(Endpoints.recuperarSenha, { email });
    } catch (error) {
      throw error;
    }
  }
  async refreshToken() {
    try {
      const refresh = localStorage.getItem("refresh_token");
      if (refresh) {
        const response = await this.api.post(Endpoints.refresh, {
          refreshToken: refresh,
        });
        const {
          data: { data: res },
        } = response;
        sessionStorage.setItem("token", res?.access_token);
        localStorage.setItem("refresh_token", res?.refresh_token);
      }
      return;
    } catch (error) {
      console.log(error.response);

      localStorage.removeItem("refresh_token");
      sessionStorage.clear();
      throw error;
    }
  }
}
