import { useEffect, useState } from "react";

import { PrazoDePagamentoService } from "../../services/prazo-de-pagamento/prazo-de-pagamento.service";

export default function useBuscarPrazoDePagamentoHook() {
  const _prazoDePagamentoService = new PrazoDePagamentoService();
  const [optionsPrazoDePagamento, setOptionsPrazoDePagamento] = useState([]);
  const [optionsPrazoDePagamentoSearch, setOptionsPrazoDePagamentoSearch] =
    useState(false);
  useEffect(() => {
    _prazoDePagamentoService
      .obterPrazosDePagamentoSimplificado(null)
      .then((result) => {
        const opts = result?.data?.data.map((o) => {
          return {
            key: o.id,
            value: o.id,
            label: o.nome,
            solicitarParcelamento: o.solicitarQtdParcelas,
            diasEntreParcelas: o.diasEntreParcelas,
            diasParaPrimeiraParcela: o.diasParaPrimeiraParcela,
            numeroDeParcelas: o.numeroDeParcelas,
          };
        });
        setOptionsPrazoDePagamento(opts);
      });
  }, []);
  const filterOptions = (input, option) =>
    option?.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;

  async function buscarPrazoDePagamento(value) {
    if (value.length >= 3) {
      setOptionsPrazoDePagamentoSearch(true);
      const result =
        await _prazoDePagamentoService.obterPrazosDePagamentoSimplificado(
          value
        );
      const opts = result?.data?.data.map((o) => {
        return {
          key: o.id,
          value: o.id,
          label: o.nome,
          solicitarParcelamento: o.solicitarQtdParcelas,
        };
      });
      setOptionsPrazoDePagamento(opts);
      setOptionsPrazoDePagamentoSearch(false);
    }
  }
  return {
    buscarPrazoDePagamento,
    options: optionsPrazoDePagamento,
    setOptions: setOptionsPrazoDePagamento,
    loading: optionsPrazoDePagamentoSearch,
    filterOptions,
  };
}
