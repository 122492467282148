import { AtualizarContaAReceberDto } from "./dto/atualizar-conta-a-receber.dto";
import { AtualizarParcelaDto } from "../conta-a-pagar/dto/atualizar-parcela.dto";
import { BaseService } from "../base.service";
import { Endpoints } from "../../core/constants/endpoints";
import { GerarParcelamentoDto } from "../conta-a-pagar/dto/gerar-parcelamento.dto";
import { NovaContaAReceberDto } from "./dto/nova-conta-a-receber.dto";
import { RegistrarPagamentoContaAReceberDto } from "./dto/registrar-pagamento-conta-a-receber.dto";

export class ContaAReceberService extends BaseService {
  async obterTodas(filtro: string = "") {
    try {
      return await this.api.get(`${Endpoints.contasAReceber}?${filtro}`);
    } catch (error) {
      throw error;
    }
  }
  async obterPorId(id: string) {
    try {
      return await this.api.get(`${Endpoints.contasAReceber}/${id}`);
    } catch (error) {
      throw error;
    }
  }

  async estornar(id: string) {
    try {
      return await this.api.patch(
        `${Endpoints.contasAReceber}/${id}${Endpoints.estornar}`
      );
    } catch (error) {
      throw error;
    }
  }
  async estornarPagamento(id: string) {
    try {
      return await this.api.patch(
        `${Endpoints.contasAReceberEstornarPagamento}/${id}`
      );
    } catch (error) {
      throw error;
    }
  }
  async atualizarParcela(id: string, dto: AtualizarParcelaDto) {
    try {
      return await this.api.patch(
        `${Endpoints.contasAReceber}/${id}${Endpoints.atualizarParcela}`,
        dto
      );
    } catch (error) {
      throw error;
    }
  }
  async obterInformacoesRelatorio(filtro: string = "") {
    try {
      return await this.api.download(
        `${Endpoints.contasAReceber}/relatorio?${filtro}`
      );
    } catch (error) {
      throw error;
    }
  }
  async obterParcelas(id: string) {
    return await this.api.get(`${Endpoints.contasAReceber}/${id}/parcelas`);
  }

  async registrarPagamento(
    id: string,
    dto: RegistrarPagamentoContaAReceberDto
  ) {
    try {
      return await this.api.post(
        `${Endpoints.contasAReceberRegistrarPagamento}/${id}`,
        dto
      );
    } catch (error) {
      throw error;
    }
  }
  async adicionarContaAReceber(dto: NovaContaAReceberDto) {
    try {
      return await this.api.post(Endpoints.contasAReceber, dto);
    } catch (error) {
      throw error;
    }
  }
  async atualizarContaAReceber(dto: AtualizarContaAReceberDto, id: string) {
    try {
      return await this.api.patch(`${Endpoints.contasAReceber}/${id}`, dto);
    } catch (error) {
      throw error;
    }
  }
  async gerarParcelamento(dto: GerarParcelamentoDto) {
    try {
      return await this.api.post(Endpoints.gerarParcelamentoContaAReceber, dto);
    } catch (error) {
      throw error;
    }
  }
}
