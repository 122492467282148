import { TipoFolhaDePagamentoItem } from "../../../core/enums/tipo-folha-de-pagamento-item.dto";
import { ColaboradorDto } from "../../colaborador/dto/colaborador.dto";
import { EmpresaDto } from "./../../empresa/empresa.dto";

export class NovaFolhaDePagamentoDto {
  folhasDePagamento: FolhaDePagamentoDto[] = new Array<FolhaDePagamentoDto>();
  dataContaAPagar: Date = new Date();
  empresa: EmpresaDto;
}

export class FolhaDePagamentoDto {
  colaborador: ColaboradorDto;
  descricao: string;
  holerite: { filename: string; url: string; mime: string };
  itens: FolhaDePagamentoItemDto[] = new Array<FolhaDePagamentoItemDto>();
}

export class FolhaDePagamentoItemDto {
  descricao: string;
  valor: number;
  tipo: TipoFolhaDePagamentoItem;
}
