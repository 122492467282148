import { AtualizarColaboradorDto } from "./dto/atualizar-colaborador.dto";
import { AtualizarSenhaDto } from "./dto/atualizar-senha.dto";
import { BaseService } from "../base.service";
import { Endpoints } from "./../../core/constants/endpoints";
import { NovoColaboradorDto } from "./dto/novo-colaborador.dto";
import { NovoContratoDto } from "./dto/novo-contrato.dto";

export class ColaboradorService extends BaseService {
  async obterTodos(busca: string = null) {
    try {
      if (busca && busca.length > 0)
        return await this.api.get(`${Endpoints.colaboradores}?busca=${busca}`);
      return await this.api.get(Endpoints.colaboradores);
    } catch (error) {
      throw error;
    }
  }

  async obterPorId(id: string) {
    try {
      return await this.api.get(`${Endpoints.colaboradores}/${id}`);
    } catch (error) {
      throw error;
    }
  }
  async adicionar(dto: NovoColaboradorDto) {
    try {
      return await this.api.post(Endpoints.colaboradores, dto);
    } catch (error) {
      throw error;
    }
  }
  async atualizar(dto: AtualizarColaboradorDto, id: string) {
    try {
      return await this.api.patch(`${Endpoints.colaboradores}/${id}`, dto);
    } catch (error) {
      throw error;
    }
  }
  async atualizarSenha(dto: AtualizarSenhaDto, id: string) {
    try {
      return await this.api.post(
        `${Endpoints.colaboradores}/${id}${Endpoints.atualizarSenha}`,
        dto
      );
    } catch (error) {
      throw error;
    }
  }
  async obterInfoUsuario() {
    try {
      return await this.api.get(Endpoints.colaboradoresInfo);
    } catch (error) {
      throw error;
    }
  }
  async obterSetores() {
    try {
      return await this.api.get(Endpoints.setores);
    } catch (error) {
      throw error;
    }
  }
  async obterPerfis() {
    try {
      return await this.api.get(Endpoints.perfis);
    } catch (error) {
      throw error;
    }
  }

  async obterSimplificado(filtro: string = "") {
    try {
      return await this.api.get(
        `${Endpoints.colaboradoresSimplificado}?filtro=${filtro}`
      );
    } catch (error) {
      throw error;
    }
  }

  async adicionarContrato(dto: NovoContratoDto) {
    try {
      return await this.api.post(Endpoints.contratos, dto);
    } catch (error) {
      throw error;
    }
  }
  async obterContratos() {
    try {
      return await this.api.get(Endpoints.contratos);
    } catch (error) {
      throw error;
    }
  }
  async inativarCadastro(id: string) {
    try {
      return await this.api.post(
        `${Endpoints.colaboradores}/${id}/inativar`,
        {}
      );
    } catch (error) {
      throw error;
    }
  }
}
