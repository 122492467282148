import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  PageHeader,
  Row,
  Space,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { AtualizarPrazoDePagamentoDto } from "../../../services/prazo-de-pagamento/dto/atualizar-prazo-de-pagamento.dto";
import { Endpoints } from "../../../core/constants/endpoints";
import { FormaDePagamento as FormaDePagamentoPermissao } from "../../../core/permissions/subjects";
import Loading from "../../../components/loading.component";
import { PrazoDePagamentoService } from "../../../services/prazo-de-pagamento/prazo-de-pagamento.service";
import useSWR from "swr";

export default function PrazoDePagamento() {
  const _prazosDePagamentoService = new PrazoDePagamentoService();
  const history = useHistory();
  const [qtdParcelasState, setQtdParcelasState] = useState(true);
  const { id } = useParams<any>();
  const { data: { data: { data: prazoDePagamentoData } = {} } = {} } = useSWR(
    Endpoints.prazosDePagamento + id,
    () => _prazosDePagamentoService.obterPorId(id),
    { revalidateOnFocus: false }
  );
  const [formRef] = Form.useForm();

  useEffect(() => {
    if (prazoDePagamentoData?.solicitarQtdParcelas) {
      setQtdParcelasState(false);
    }
  }, [prazoDePagamentoData]);

  async function atualizarFormaDePagamentoHandler() {
    try {
      const prazoDePagamento = formRef.getFieldValue("prazoDePagamento");
      const dto: AtualizarPrazoDePagamentoDto = {
        id: id,
        ...prazoDePagamento,
        diasEntreParcelas: Number(prazoDePagamento.diasEntreParcelas),
        diasParaPrimeiraParcela: Number(
          prazoDePagamento.diasParaPrimeiraParcela
        ),
        numeroDeParcelas: Number(prazoDePagamento.numeroDeParcelas),
      };

      await _prazosDePagamentoService.atualizarPrazoDePagamento(id, dto);
      message.success("Prazo de Pagamento Atualizado!");
      history.goBack();
    } catch (error) {
      if (error?.response?.data?.message && typeof Array) {
        for (const e of error?.response?.data?.message.toString().split(",")) {
          message.error(e);
        }
      } else {
        message.error(error?.message);
      }
    }
  }

  return prazoDePagamentoData ? (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => {
          history.goBack();
        }}
        title="Prazo de pagamento"
        style={{ marginBottom: 10 }}
      />
      <Card title="Informações prazo de pagamento">
        <Form
          form={formRef}
          layout="vertical"
          onFinish={() => atualizarFormaDePagamentoHandler()}
        >
          <Input.Group compact>
            <Form.Item
              initialValue={prazoDePagamentoData?.nome}
              style={{ width: "72%" }}
              name={["prazoDePagamento", "nome"]}
              label="Nome"
              rules={[{ required: true, message: "Nome é obrigatório" }]}
            >
              <Input placeholder="Nome" />
            </Form.Item>
            <Form.Item
              style={{ marginTop: 32, marginLeft: 8 }}
              name={["prazoDePagamento", "dataPadraoPagamentos"]}
              valuePropName="checked"
              initialValue={prazoDePagamentoData?.dataPadraoPagamentos}
            >
              <Checkbox>Transferir pagamentos para data padrão ?</Checkbox>
            </Form.Item>
            <Form.Item
              style={{ width: "100%" }}
              initialValue={prazoDePagamentoData?.descricao}
              name={["prazoDePagamento", "descricao"]}
              label="Descrição"
            >
              <Input.TextArea placeholder="Descrição" />
            </Form.Item>

            <Form.Item
              initialValue={prazoDePagamentoData?.diasEntreParcelas}
              rules={[
                {
                  required: qtdParcelasState,
                  message: "Informe os dias entre parcelas",
                },
              ]}
              style={{ width: "33%", paddingRight: 5 }}
              name={["prazoDePagamento", "diasEntreParcelas"]}
              label="Dias entre parcelas"
            >
              <Input
                placeholder="Dias entre parcelas"
                disabled={!qtdParcelasState}
              />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Informe os dias para primeira parcela",
                },
              ]}
              style={{ width: "33%", paddingRight: 5 }}
              initialValue={prazoDePagamentoData?.diasParaPrimeiraParcela}
              name={["prazoDePagamento", "diasParaPrimeiraParcela"]}
              label="Dias para primeira parcela"
            >
              <Input
                placeholder="Dias para primeira parcela"
                disabled={!qtdParcelasState}
              />
            </Form.Item>

            <Form.Item
              rules={[
                {
                  required: qtdParcelasState,
                  message: "Informe os dias para primeira parcela",
                },
              ]}
              style={{ width: "18%", paddingRight: 5 }}
              initialValue={prazoDePagamentoData?.numeroDeParcelas}
              name={["prazoDePagamento", "numeroDeParcelas"]}
              label="Quantidade De Parcelas"
            >
              <Input
                placeholder="Quantidade de parcelas"
                disabled={!qtdParcelasState}
              />
            </Form.Item>
            <Form.Item
              style={{ marginTop: 32, marginLeft: 5 }}
              name={["prazoDePagamento", "solicitarQtdParcelas"]}
              initialValue={prazoDePagamentoData?.solicitarQtdParcelas}
              valuePropName="checked"
            >
              <Checkbox
                onChange={(e) => {
                  if (e.target.checked) {
                    setQtdParcelasState(false);
                    formRef.setFieldsValue({
                      prazoDePagamento: {
                        numeroDeParcelas: 0,
                        diasParaPrimeiraParcela: 0,
                        diasEntreParcelas: 0,
                      },
                    });
                  } else {
                    setQtdParcelasState(true);
                    formRef.setFieldsValue({
                      prazoDePagamento: {
                        numeroDeParcelas: 1,
                        diasParaPrimeiraParcela: 0,
                        diasEntreParcelas: 0,
                      },
                    });
                  }
                }}
              >
                Solicitar ao usuário ?
              </Checkbox>
            </Form.Item>
          </Input.Group>

          <Row>
            <Col span={18} />
            <Col span={6} style={{ marginTop: "30px", textAlign: "end" }}>
              <Space>
                <Button type="primary" size="middle" htmlType="submit">
                  SALVAR
                </Button>
                <Button
                  type="ghost"
                  size="middle"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  CANCELAR
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  ) : (
    <Loading />
  );
}

export const PrazoDePagamentoRoute = {
  id: "prazos-de-pagamento",
  path: "/inicio/financeiro/prazos-de-pagamento/:id",
  sidebar: "Prazos de pagamento",
  exact: true,
  renderInMenu: false,
  main: () => <PrazoDePagamento />,
  icon: null,
  permission: FormaDePagamentoPermissao,
};
