import moment from "moment";

export function diasEntreDatas(
  dataInicial: Date,
  dataFinal: Date = new Date()
) {
  var x = moment(dataInicial);
  var y = moment(dataFinal);
  const milliseconds = x.diff(y);
  const days = Math.round(milliseconds / (1000 * 60 * 60 * 24));
  return days >>> 0;
}

export function datasValidas(dataAtual: Date) {
  const now = moment(new Date()).subtract("day", 1).toDate();
  return (
    dataAtual.getDay() === 6 || dataAtual.getDay() === 0 || now >= dataAtual
  );
}

export function datasValidasERetroativas(dataAtual: Date) {
  const now = moment(new Date()).subtract("day", 1).toDate();
  return dataAtual.getDay() === 6 || dataAtual.getDay() === 0;
}
