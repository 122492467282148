import {
  Button,
  Card,
  Col,
  DatePicker,
  Descriptions,
  Drawer,
  Form,
  Input,
  PageHeader,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Upload,
  message,
} from "antd";
import { FilePdfOutlined, PlusCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import {
  TipoFolhaDePagamentoDescricao,
  TipoFolhaDePagamentoItem,
  tipoFolhaDePagamentoItemArray,
} from "../../../core/enums/tipo-folha-de-pagamento-item.dto";

import CurrencyInput from "react-currency-input-field";
import { Endpoints } from "../../../core/constants/endpoints";
import { FolhaDePagamentoService } from "../../../services/folha-de-pagamento/folha-de-pagamento.service";
import { NovaFolhaDePagamentoDto } from "../../../services/folha-de-pagamento/dto/folha-de-pagamento.dto";
import c from "currency-formatter";
import { currencyFormatter } from "../../../core/utils/formatters/currency.formatter";
import { v4 as newId } from "uuid";
import useBuscarEmpresaHook from "../../../components/hooks/buscar-empresa.hook";
import useSWR from "swr";

let pagamentos = new NovaFolhaDePagamentoDto();

export default function EfetuarPagamentoModal({ visible, setVisible }) {
  const _folhaDePagamentoService = new FolhaDePagamentoService();
  const btnRef = useRef(null);
  const { options: optsEmpresa, loading: loadingEmpresas } =
    useBuscarEmpresaHook();
  const [dataDePagamento, setDataDePagamento] = useState(null);
  const [empresa, setEmpresa] = useState(null);
  const { data: { data: { data: colaboradores } = {} } = {} } = useSWR(
    Endpoints.folhaDePagamento,
    () => _folhaDePagamentoService.obterColaboradoresParaPagamento(),
    {
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    if (colaboradores && pagamentos.folhasDePagamento.length === 0) {
      for (const colaborador of colaboradores) {
        const list = colaborador?.contrato?.beneficios?.map((b) => {
          return {
            id: b.id,
            descricao: b.descricao,
            valor: b.valor,
            tipo: 1,
          };
        });
        pagamentos.folhasDePagamento.push({
          colaborador: { id: colaborador?.id },
          descricao: `PAGAMENTO COLABORADOR ${colaborador?.nome}`,
          holerite: null,
          itens: list,
        });
      }
    }
  }, [colaboradores]);

  function FolhaDePagamento({ colaborador, aberta }) {
    const [itens, setItens] = useState([]);
    const [itemSelecionado, setItemSelecionado] = useState(null);
    const [holerite, setHolerite] = useState(null);
    const [uploadHolerite, setUploadHolerite] = useState(false);
    const [calculo, setCalculo] = useState({
      totalDescontos: 0,
      totalSalarioBeneficios: 0,
    });
    const [valorItem, setValorItem] = useState(null);
    const [itemFormRef] = Form.useForm();

    useEffect(() => {
      if (itens.length == 0) {
        const list = colaborador?.contrato?.beneficios?.map((b) => {
          return { id: b.id, descricao: b.descricao, valor: b.valor, tipo: 1 };
        });
        pagamentos.folhasDePagamento.push({
          colaborador: { id: colaborador?.id },
          descricao: `PAGAMENTO COLABORADOR ${colaborador?.nome}`,
          holerite,
          itens: list,
        });

        calcularFolha(list, colaborador?.contrato?.salario);
        setItens(list);
      }
      calcularFolha(itens, colaborador?.contrato?.salario);
      atualizarFolha(itens);

      if (itemSelecionado) {
        itemFormRef.setFieldsValue({
          id: itemSelecionado.id,
          descricao: itemSelecionado.descricao,
          tipo: itemSelecionado.tipo,
        });
        setValorItem(itemSelecionado.valor);
      }
    }, [itens, itemSelecionado, holerite]);

    function atualizarFolha(itensFolha) {
      try {
        const backup = pagamentos.folhasDePagamento.filter(
          (f) => f.colaborador.id !== colaborador.id
        );
        backup.push({
          colaborador: { id: colaborador?.id },
          descricao: `PAGAMENTO COLABORADOR ${colaborador?.nome}`,
          holerite,
          itens: itensFolha,
        });
        pagamentos.folhasDePagamento = backup;
      } catch (error) {
        throw error;
      }
    }

    function calcularFolha(itensFolha: any[], salario: number) {
      try {
        let descontos = 0;
        for (let i = 0; i < itensFolha.length; i++) {
          if (itensFolha[i].tipo === TipoFolhaDePagamentoItem.DESCONTO)
            descontos += itensFolha[i].valor;
        }

        let beneficiosBonificacao = 0;
        for (let i = 0; i < itensFolha.length; i++) {
          if (itensFolha[i].tipo !== TipoFolhaDePagamentoItem.DESCONTO)
            beneficiosBonificacao += itensFolha[i].valor;
        }

        setCalculo({
          totalDescontos: descontos,
          totalSalarioBeneficios: salario + beneficiosBonificacao,
        });
      } catch (error) {
        throw error;
      }
    }

    return aberta ? (
      <Card
        type="inner"
        bodyStyle={{
          margin: 0,
          paddingRight: 0,
          paddingLeft: 0,
        }}
        title={`PAGAMENTO COLABORADOR ${colaborador?.nome}`}
        extra={
          <div>
            <Upload
              onRemove={() => setHolerite(null)}
              accept=".pdf"
              fileList={holerite ? [holerite] : []}
              customRequest={async ({ file }) => {
                try {
                  setUploadHolerite(true);
                  const formData = new FormData();
                  formData.append("arquivo", file);
                  const { url, filename, mimetype } =
                    await _folhaDePagamentoService.carregarAnexos(formData);
                  setHolerite({
                    url,
                    status: "success",
                    name: filename,
                    mimetype,
                  });
                  setUploadHolerite(false);
                } catch (error) {
                  message.error(error?.message);
                }
              }}
            >
              {!holerite ? (
                <Button
                  size="middle"
                  type="dashed"
                  icon={<FilePdfOutlined />}
                  loading={uploadHolerite}
                >
                  Anexar holerite
                </Button>
              ) : null}
            </Upload>
          </div>
        }
      >
        <Table
          sticky
          rowKey={"id"}
          style={{ marginLeft: -20 }}
          title={() => (
            <div style={{ display: "flex", width: "100%" }}>
              <Form
                form={itemFormRef}
                layout="inline"
                initialValues={{ valor: "0" }}
                onFinish={(values) => {
                  const {
                    descricao,
                    valor = 0,
                    tipo,
                  } = itemFormRef.getFieldsValue([
                    "descricao",
                    "valor",
                    "tipo",
                  ]);

                  if (itemSelecionado) {
                    const bkpList = itens.filter(
                      (i) => i.id !== itemSelecionado.id
                    );
                    bkpList.push({
                      id: itemSelecionado.id,
                      descricao,
                      valor: c.unformat(valor, {
                        code: "BRL",
                      }),
                      tipo,
                    });
                    setItens(bkpList);
                    setItemSelecionado(null);
                  } else {
                    setItens([
                      ...itens,
                      {
                        id: newId(),
                        descricao,
                        valor: c.unformat(valor, {
                          code: "BRL",
                        }),
                        tipo,
                      },
                    ]);
                  }

                  itemFormRef.resetFields();
                  setValorItem("0");
                }}
              >
                <div style={{ minWidth: "1150px" }}>
                  <Input.Group compact>
                    <Form.Item name="id" initialValue={newId()} />
                    <Form.Item
                      name="descricao"
                      rules={[
                        { required: true, message: "Informe a descrição" },
                      ]}
                      style={{ width: "35%", paddingRight: 4 }}
                    >
                      <Input placeholder="Informe a descrição" />
                    </Form.Item>
                    <Form.Item style={{ width: "25%", paddingRight: 4 }}>
                      <CurrencyInput
                        name="valor"
                        autoComplete="no"
                        value={valorItem}
                        placeholder="Valor"
                        className="ant-input ant-input-sm"
                        decimalScale={2}
                        onValueChange={(value = "0") => {
                          itemFormRef.setFieldsValue({
                            valor: c.unformat(value, {
                              code: "BRL",
                            }),
                          });
                          setValorItem(value);
                        }}
                        intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="tipo"
                      initialValue={0}
                      style={{ width: "25%", paddingRight: 4 }}
                    >
                      <Select
                        placeholder="Selecione o tipo"
                        options={tipoFolhaDePagamentoItemArray}
                      />
                    </Form.Item>
                    <div style={{ width: "15%", paddingRight: 5 }}>
                      <Button
                        type="primary"
                        style={{ width: "100%" }}
                        icon={<PlusCircleOutlined />}
                        htmlType="submit"
                      >
                        ADICIONAR ITEM
                      </Button>
                    </div>
                  </Input.Group>
                </div>
              </Form>
            </div>
          )}
          pagination={false}
          bordered
          dataSource={itens}
          columns={[
            { title: "Itens", dataIndex: "descricao", width: 800 },
            {
              title: "Valor",
              dataIndex: "valor",
              width: 100,
              render: (value) => currencyFormatter(value, "BRL"),
            },
            {
              title: "Tipo",
              dataIndex: "tipo",
              width: 100,
              render: (value) => (
                <Tag color={TipoFolhaDePagamentoDescricao[value].cor}>
                  {TipoFolhaDePagamentoDescricao[value].label}
                </Tag>
              ),
            },
            {
              title: "Ação",

              render: (value, obj) => (
                <>
                  <Button type="link" onClick={() => setItemSelecionado(obj)}>
                    Editar
                  </Button>
                  <Button type="link">Remover</Button>
                </>
              ),
            },
          ]}
          footer={() => (
            <Descriptions bordered size="small">
              <Descriptions.Item label="Salário, benefícios e bonificações">
                <span className="currency">
                  {currencyFormatter(calculo.totalSalarioBeneficios, "BRL")}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="Descontos">
                <span className="currency-negative">
                  {currencyFormatter(calculo.totalDescontos, "BRL")}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="Total">
                <span className="currency">
                  {currencyFormatter(
                    calculo.totalSalarioBeneficios - calculo.totalDescontos,
                    "BRL"
                  )}
                </span>
              </Descriptions.Item>
            </Descriptions>
          )}
        />
      </Card>
    ) : null;
  }

  async function efetuarPagamentoHandler(
    pagamentos: NovaFolhaDePagamentoDto,
    dataDePagamento: Date,
    empresa: string
  ) {
    try {
      pagamentos.dataContaAPagar = dataDePagamento;
      pagamentos.empresa = empresa ? { id: empresa } : null;
      await _folhaDePagamentoService.efetuarPagamentos(pagamentos);
      message.success("Pagamento efetuado com êxito!");

      setVisible(false);
    } catch (error) {
      if (error?.response?.data?.message?.length > 0) {
        message.error(
          error?.response?.data?.message?.toString().replace(",", ", ")
        );
      } else {
        message.error(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message
        );
      }
    }
  }

  return (
    <Drawer
      visible={visible}
      width={1200}
      bodyStyle={{ padding: 3 }}
      closable={false}
    >
      <PageHeader
        className="site-page-header"
        onBack={() => setVisible(false)}
        title={`Novo pagamento de salário`}
        style={{ marginBottom: 5 }}
      />

      <Card
        title="Colaboradores"
        bodyStyle={{ padding: 0 }}
        extra={
          <>
            <Select
              value={empresa}
              onSelect={(value) => setEmpresa(value)}
              placeholder="Selecione a empresa"
              options={optsEmpresa}
              loading={loadingEmpresas}
              style={{ paddingRight: 3, minWidth: "200px" }}
            />
            <DatePicker
              style={{ minWidth: "180px" }}
              placeholder="Data de pagamento"
              format={"DD/MM/YYYY"}
              disabledDate={(date) =>
                date.toDate().getDay() === 6 || date.toDate().getDay() === 0
              }
              value={dataDePagamento}
              onChange={(date) => setDataDePagamento(date)}
            />
          </>
        }
      >
        <Table
          rowKey={"id"}
          bordered
          loading={!colaboradores}
          dataSource={colaboradores}
          expandable={{
            expandedRowRender: (value, index, indent, expanded) => (
              <FolhaDePagamento colaborador={value} aberta={expanded} />
            ),
          }}
          columns={[
            {
              title: "Nome",
              dataIndex: "nome",
            },
            {
              title: "Setor",
              dataIndex: "setor",
              render: (value) => <Tag>{value?.nome}</Tag>,
            },
            {
              title: "Salário",
              dataIndex: "contrato",
              render: (value) => currencyFormatter(value?.salario, "BRL"),
            },
            {
              title: "Horas extras",
              dataIndex: "horasExtras",
              render: (value = 0) => currencyFormatter(value, "BRL"),
            },
          ]}
        />
        <Row>
          <Col span={18} />
          <Col span={6} style={{ padding: 5, marginBottom: 10 }}>
            <Space>
              <Button
                type="ghost"
                size="middle"
                onClick={() => {
                  setVisible(false);
                }}
              >
                CANCELAR
              </Button>
              <Button
                type="primary"
                size="middle"
                ref={btnRef}
                htmlType="submit"
                onClick={() =>
                  efetuarPagamentoHandler(pagamentos, dataDePagamento, empresa)
                }
              >
                EFETUAR PAGAMENTO
              </Button>
            </Space>
          </Col>
        </Row>
      </Card>
    </Drawer>
  );
}
