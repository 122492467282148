import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Form,
  Input,
  PageHeader,
  Radio,
  Select,
  Table,
  Tag,
  message,
} from "antd";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  SearchOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import {
  StatusParcelaDescricao,
  StatusParcelaList,
} from "../../../core/enums/status-parcela.enum";

import { ConciliacaoBancaria } from "../../../core/permissions/subjects";
import { Endpoints } from "../../../core/constants/endpoints";
import { FluxoDeCaixaService } from "../../../services/fluxo-de-caixa/fluxo-de-caixa.service";
import { currencyFormatter } from "../../../core/utils/formatters/currency.formatter";
import moment from "moment";
import useBuscarEmpresaHook from "../../../components/hooks/buscar-empresa.hook";
import useContasBancariasHook from "../../../components/hooks/buscar-contas-bancarias.hook";
import { useHistory } from "react-router-dom";
import useSWR from "swr";

const _fluxoDeCaixaService = new FluxoDeCaixaService();

export default function FluxoDeCaixa() {
  const history = useHistory();
  const [filtro, setFiltro] = useState("");
  const [formRef] = Form.useForm();
  const { options: optsEmpresa } = useBuscarEmpresaHook();
  const { options: optsContas } = useContasBancariasHook();
  function definirFiltros(values: any) {
    try {
      console.log(values);

      const { intervalo, status, descricao, empresas, contas, somarSaldo } =
        values;
      const [dataInicio, dataFim] = intervalo || [];

      let query = `${status ? `status=${status.toString()}&` : ""}${
        intervalo
          ? `intervalo=${dataInicio.toString()},${dataFim.toString()}&`
          : ""
      }${descricao ? `descricao=${descricao}&` : ""}${
        empresas ? `empresas=${empresas.toString()}&` : ""
      }${contas ? `contas=${contas.toString()}&` : ""}${
        somarSaldo ? `somar-saldo=${somarSaldo}&` : ""
      }`;
      console.log(query);

      setFiltro(query);
    } catch (error) {
      throw error;
    }
  }

  const { data: resultado, error } = useSWR(
    Endpoints.fluxoDeCaixaTransacoes + filtro,
    () => _fluxoDeCaixaService.obterTransacoes(filtro),
    { revalidateOnFocus: false }
  );

  if (error) message.error(error?.message);

  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title="Fluxo de caixa"
        subTitle="Transações de entrada e saída "
      />
      <div style={{ display: "flex", height: "100%" }}>
        <div
          style={{
            width: "25%",
            marginTop: 10,
            marginRight: 10,
            display: "flex",
          }}
        >
          <Card
            title="Filtros"
            style={{
              flex: 1,
            }}
          >
            <Form form={formRef} onFinish={(values) => definirFiltros(values)}>
              <Form.Item name="intervaloPredefinido" initialValue={1}>
                <Radio.Group
                  defaultValue="1"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    formRef.setFieldsValue({
                      intervalo: [
                        moment(new Date()),
                        moment(new Date()).add(e.target.value, "days"),
                      ],
                    });
                  }}
                >
                  {[
                    { label: "Diário", value: 1 },
                    { label: "Semanal", value: 7 },
                    { label: "Mensal", value: 30 },
                  ].map(({ value, label }) => (
                    <Radio.Button
                      value={value}
                      style={{ width: "33%", textAlign: "center" }}
                    >
                      {label}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </Form.Item>
              <Form.Item name="intervalo">
                <DatePicker.RangePicker
                  style={{ display: "flex" }}
                  format="DD/MM/yyyy"
                />
              </Form.Item>
              <Form.Item name="empresas">
                <Select
                  placeholder="Empresas"
                  mode="multiple"
                  options={optsEmpresa}
                />
              </Form.Item>
              <Form.Item name="descricao">
                <Input placeholder="Descrição" />
              </Form.Item>
              <Form.Item name="contas">
                <Select
                  placeholder="Contas Bancárias"
                  mode="multiple"
                  options={optsContas}
                />
              </Form.Item>
              <Form.Item name="status">
                <Select
                  placeholder="Status"
                  mode="multiple"
                  options={StatusParcelaList}
                />
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                initialValue={false}
                name="somarSaldo"
              >
                <Checkbox>Somar saldo contas bancárias ? </Checkbox>
              </Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                icon={<SearchOutlined />}
                style={{ width: "100%" }}
              >
                BUSCAR TRANSAÇÕES
              </Button>
            </Form>
          </Card>
        </div>
        <div style={{ width: "75%", marginTop: 10 }}>
          <Card title="Transações" bodyStyle={{ margin: 0, padding: 0 }}>
            <Table
              rowKey="id"
              bordered
              size="small"
              columns={columns}
              dataSource={resultado?.data?.data?.transacoes}
              loading={!resultado?.data?.data}
              footer={() => (
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Table
                    bordered
                    showHeader={false}
                    size="small"
                    sticky
                    style={{ width: 600, flex: 1 }}
                    pagination={false}
                    loading={!resultado?.data?.data}
                    dataSource={[
                      {
                        tituloDebitos: "Total Débitos",
                        totalDebitos: resultado?.data?.data?.totalDebitos,
                        tituloCreditos: "Total Créditos",
                        totalCreditos: resultado?.data?.data?.totalCreditos,
                        tituloResultado: "Resultado",
                        creditosEDebitos: resultado?.data?.data?.resultado,
                      },
                    ]}
                    columns={[
                      {
                        title: "",
                        className: "titulo",
                        dataIndex: "tituloDebitos",
                      },
                      {
                        title: "Total Débitos",
                        className: "currency-negative",
                        dataIndex: "totalDebitos",
                        render: (value) => currencyFormatter(value, "BRL"),
                      },
                      {
                        title: "",
                        className: "titulo",
                        dataIndex: "tituloCreditos",
                      },
                      {
                        title: "Total Créditos",
                        className: "currency",
                        dataIndex: "totalCreditos",
                        render: (value) => currencyFormatter(value, "BRL"),
                      },
                      {
                        title: "",
                        className: "titulo",
                        dataIndex: "tituloResultado",
                      },
                      {
                        title: "Resumo",
                        className:
                          resultado?.data?.data?.resultado >= 0
                            ? "currency"
                            : "currency-negative",
                        dataIndex: "creditosEDebitos",
                        render: (value) => currencyFormatter(value, "BRL"),
                      },
                    ]}
                  />
                </div>
              )}
            />
          </Card>
        </div>
      </div>
    </div>
  );
}

export const FluxoDeCaixaRoute = {
  id: "fluxo-de-caixa",
  path: "/inicio/financeiro/fluxo-de-caixa",
  sidebar: "Fluxo de Caixa",
  exact: true,
  renderInMenu: false,
  main: () => <FluxoDeCaixa />,
  icon: <SwapOutlined />,
  permission: ConciliacaoBancaria,
};

const columns = [
  {
    name: "tipoTransacao",
    title: "Transação",

    width: 50,
    render: (_, obj) =>
      obj?.contaAReceber ? (
        <Tag
          className="transaction-up"
          color="green"
          icon={<CaretUpOutlined />}
        >
          {`CRÉDITO`}
        </Tag>
      ) : (
        <Tag
          className="transaction-down"
          color="red"
          icon={<CaretDownOutlined />}
        >
          {`DÉBITO`}
        </Tag>
      ),
  },
  { name: "descricao", title: "Descrição", dataIndex: "descricao" },
  {
    name: "valor",
    title: "Valor",
    dataIndex: "valor",
    className: "currency",
    render: (value) => currencyFormatter(value, "BRL"),
  },
  {
    name: "dataTransacao",
    title: "Data Transação",
    dataIndex: "dataVencimento",
    render: (value) => moment(value).format("DD/MM/YYYY"),
  },
  {
    name: "empresa",
    title: "Empresa",

    render: (value, obj) =>
      obj?.contaAReceber
        ? obj?.contaAReceber?.empresa?.nomeFantasia
        : obj?.contaAPagar?.empresa?.nomeFantasia,
  },

  {
    name: "status",
    title: "Status",
    dataIndex: "status",
    render: (value) => (
      <Tag color={StatusParcelaDescricao[value].cor}>
        {StatusParcelaDescricao[value].label}
      </Tag>
    ),
  },
];
