import { AtualizarClienteDto } from "./dto/atualizar-cliente.dto";
import { BaseService } from "../base.service";
import { Endpoints } from "../../core/constants/endpoints";
import { NovoClienteDto } from "./dto/novo-cliente.dto";

export class ClienteService extends BaseService {
  async obterClientes(busca: string = null) {
    try {
      if (busca && busca.length > 0)
        return await this.api.get(`${Endpoints.clientes}?busca=${busca}`);
      return await this.api.get(Endpoints.clientes);
    } catch (error) {
      throw error;
    }
  }

  async obterClientePorId(id: string) {
    try {
      return await this.api.get(`${Endpoints.clientes}/${id}`);
    } catch (error) {
      throw error;
    }
  }
  async novoCliente(dto: NovoClienteDto) {
    try {
      return await this.api.post(Endpoints.clientes, dto);
    } catch (error) {
      throw error;
    }
  }
  async atualizarCliente(id: string, dto: AtualizarClienteDto) {
    try {
      return await this.api.patch(`${Endpoints.clientes}/${id}`, dto);
    } catch (error) {
      throw error;
    }
  }
  async verificarCnpjCadastrado(cnpj: string, cliente: string = null) {
    try {
      return await this.api.get(
        `${Endpoints.validarCnpjCadastradoCliente}?cnpj=${cnpj}${
          cliente ? `&cliente=${cliente}` : ""
        }`
      );
    } catch (error) {
      throw error;
    }
  }
  async obterVersaoSimplificada(filtro: string) {
    try {
      return await this.api.get(
        `${Endpoints.clientesSimplificado}?filtro=${filtro}`
      );
    } catch (error) {
      throw error;
    }
  }
  async inativarCadastro(id: string) {
    try {
      return await this.api.post(
        `${Endpoints.clientes}/${id}${Endpoints.inativarCadastro}`,
        {}
      );
    } catch (error) {
      throw error;
    }
  }
}
