import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  PageHeader,
  Row,
  Space,
  message,
} from "antd";
import { useEffect, useState } from "react";

import CurrencyInput from "react-currency-input-field";
import { Endpoints } from "../../../core/constants/endpoints";
import { JobService } from "../../../services/job/job.service";
import { OrcamentoDto } from "../../../services/job/dto/orcamento.dto";
import { default as c } from "currency-formatter";
import moment from "moment";
import useSWR from "swr";

export function OrcamentoJob({ job, visible, setVisible, setJob }) {
  const [formRef] = Form.useForm();
  const _service = new JobService();
  const { data: { data: { data: orcamento } = {} } = {} } = useSWR(
    Endpoints.orcamentoJob + job?.id,
    () => _service.obterOrcamento(job?.id),
    { revalidateOnMount: true, revalidateOnFocus: false }
  );
  const [valorOrcamento, setValorOrcamento] = useState("0");
  const [valorPedidos, setValorPedidos] = useState("0");
  const [valorTracao, setValorTracao] = useState("0");
  const [valorInterno, setValorInterno] = useState("0");
  const [honorarios, setHonorarios] = useState("0");
  const [impostoCobrado, setImpostoCobrado] = useState("0");
  const [somaPcs, setSomaPcs] = useState(0);
  const [saldo, setSaldo] = useState(0);
  const [previsaoDeSaidaPedidosDeCompra, setPrevisaoDeSaidaPedidosDeCompra] =
    useState(null);
  useEffect(() => {
    if (orcamento) {
      formRef.setFieldsValue({
        orcamento: {
          valorOrcamento: orcamento?.orcamentoEvento,
          valorPedidos: orcamento?.orcamentoParaPedidosDeCompra,
          valorTracao: orcamento?.orcamento,
          valorInterno: orcamento?.orcamentoInterno,
          impostoCobrado: orcamento?.impostoCobrado,
          honorarios: orcamento?.honorarios,
          previsaoDeSaidaPedidosDeCompra: moment(
            orcamento?.previsaoDeSaidaPedidosDeCompra
          ),
        },
      });
      setValorOrcamento(orcamento?.orcamentoEvento);
      setValorPedidos(orcamento?.orcamentoParaPedidosDeCompra);
      setHonorarios(orcamento?.honorarios);
      setValorInterno(orcamento?.orcamentoInterno);
      setImpostoCobrado(orcamento?.impostoCobrado);
      setValorTracao(orcamento?.orcamento);
      setPrevisaoDeSaidaPedidosDeCompra(
        moment(orcamento?.previsaoDeSaidaPedidosDeCompra)
      );
      setSomaPcs(orcamento?.valorUtilizadosPedidosDeCompra);
      setSaldo(
        orcamento?.orcamentoParaPedidosDeCompra -
          orcamento?.valorUtilizadosPedidosDeCompra
      );
    } else {
      setValorOrcamento("0");
      setValorPedidos("0");
      setHonorarios("0");
      setValorInterno("0");
      setImpostoCobrado("0");
      setValorTracao("0");
      setSomaPcs(0);
      setSaldo(0);
      setPrevisaoDeSaidaPedidosDeCompra(moment(new Date()));
    }
  }, [job, orcamento]);

  async function handleSalvarOrcamento() {
    const _jobService = new JobService();
    const {
      valorOrcamento,
      valorPedidos,
      valorTracao,
      valorInterno,
      honorarios,
      impostoCobrado,
      previsaoDeSaidaPedidosDeCompra,
    } = formRef.getFieldValue("orcamento");
    try {
      const dto: OrcamentoDto = {
        job: {
          id: job?.id,
        },
        orcamentoEvento: valorOrcamento,
        orcamento: valorTracao,
        orcamentoInterno: valorInterno,
        honorarios,
        impostoCobrado,
        previsaoDeSaidaPedidosDeCompra,
        orcamentoParaPedidosDeCompra: valorPedidos,
      };
      if (orcamento) {
        await _jobService.atualizarOrcamento(job?.id, dto);
        message.success("Orçamento Atualizado!");
      } else {
        await _jobService.cadastrarOrcamento(job?.id, dto);
        message.success("Orçamento Atualizado!");
      }
      setVisible(false);
      setJob(null);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  }

  return job?.id ? (
    <Modal
      visible={visible}
      onCancel={() => setVisible(false)}
      width={900}
      footer
      bodyStyle={{ padding: 3 }}
      closable={false}
    >
      <PageHeader className="site-page-header" title={`Orçamento Job`} />
      <Card>
        <Form
          form={formRef}
          layout="vertical"
          onFinish={() => handleSalvarOrcamento()}
        >
          <Input.Group compact>
            <Form.Item
              rules={[{ required: true, message: "Informe o valor" }]}
              label="valor total do projeto"
              style={{ width: "33%", paddingRight: 5 }}
            >
              <CurrencyInput
                value={valorOrcamento}
                className="ant-input ant-input-sm"
                placeholder="Valor total do projeto"
                defaultValue={job?.orcamento?.orcamentoEvento || 0}
                decimalScale={2}
                onValueChange={(value = "0") => {
                  setValorOrcamento(value);
                  formRef.setFieldsValue({
                    orcamento: {
                      valorOrcamento: c.unformat(value, { code: "BRL" }),
                    },
                  });
                }}
                intlConfig={{ locale: "pt-BR", currency: "BRL" }}
              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "Informe o valor" }]}
              label="valor Tração"
              style={{ width: "33%", paddingRight: 5 }}
            >
              <CurrencyInput
                value={valorTracao}
                className="ant-input ant-input-sm"
                placeholder="Valor"
                defaultValue={job?.orcamento?.orcamento || 0}
                decimalScale={2}
                onValueChange={(value = "0") => {
                  setValorTracao(value);
                  formRef.setFieldsValue({
                    orcamento: {
                      valorTracao: c.unformat(value, { code: "BRL" }),
                    },
                  });
                }}
                intlConfig={{ locale: "pt-BR", currency: "BRL" }}
              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "Informe o valor" }]}
              label="Honorários"
              style={{ width: "33%", paddingRight: 5 }}
            >
              <CurrencyInput
                value={honorarios}
                className="ant-input ant-input-sm"
                placeholder="Valor"
                defaultValue={job?.orcamento?.honorarios || 0}
                decimalScale={2}
                onValueChange={(value = "0") => {
                  setHonorarios(value);
                  formRef.setFieldsValue({
                    orcamento: {
                      honorarios: c.unformat(value, { code: "BRL" }),
                    },
                  });
                }}
                intlConfig={{ locale: "pt-BR", currency: "BRL" }}
              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "Informe o valor" }]}
              label="imposto cobrado"
              style={{ width: "33%", paddingRight: 5 }}
            >
              <CurrencyInput
                value={impostoCobrado}
                className="ant-input ant-input-sm"
                placeholder="Valor"
                defaultValue={job?.orcamento?.impostoCobrado || 0}
                decimalScale={2}
                onValueChange={(value = "0") => {
                  setImpostoCobrado(value);
                  formRef.setFieldsValue({
                    orcamento: {
                      impostoCobrado: c.unformat(value, { code: "BRL" }),
                    },
                  });
                }}
                intlConfig={{ locale: "pt-BR", currency: "BRL" }}
              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "Informe o valor" }]}
              label="valor para pedidos de compra"
              style={{ width: "33%", paddingRight: 5 }}
            >
              <CurrencyInput
                value={valorPedidos}
                className="ant-input ant-input-sm"
                placeholder="Valor"
                defaultValue={job?.orcamento?.orcamentoParaPedidosDeCompra || 0}
                decimalScale={2}
                onValueChange={(value = "0") => {
                  setValorPedidos(value);
                  const valor = c.unformat(value, { code: "BRL" });
                  setSaldo(valor - somaPcs);
                  formRef.setFieldsValue({
                    orcamento: {
                      valorPedidos: c.unformat(value, { code: "BRL" }),
                    },
                  });
                }}
                intlConfig={{ locale: "pt-BR", currency: "BRL" }}
              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "Informe o valor" }]}
              label="Valor utilizado por PCs"
              style={{ width: "33%", paddingRight: 5 }}
            >
              <CurrencyInput
                value={somaPcs}
                disabled
                className="ant-input ant-input-sm"
                placeholder="Valor"
                defaultValue={job?.orcamento?.orcamentoInterno || 0}
                decimalScale={2}
                intlConfig={{ locale: "pt-BR", currency: "BRL" }}
              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "Informe o valor" }]}
              label="Orçamento restante"
              style={{ width: "33%", paddingRight: 5 }}
            >
              <CurrencyInput
                value={saldo}
                disabled
                className="ant-input ant-input-sm"
                placeholder="Valor"
                defaultValue={job?.orcamento?.orcamentoInterno || 0}
                decimalScale={2}
                intlConfig={{ locale: "pt-BR", currency: "BRL" }}
              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "Informe o valor" }]}
              label="valor interno tração"
              style={{ width: "33%", paddingRight: 5 }}
            >
              <CurrencyInput
                value={valorInterno}
                className="ant-input ant-input-sm"
                placeholder="Valor"
                defaultValue={job?.orcamento?.orcamentoInterno || 0}
                decimalScale={2}
                onValueChange={(value = "0") => {
                  setValorInterno(value);
                  formRef.setFieldsValue({
                    orcamento: {
                      valorInterno: c.unformat(value, { code: "BRL" }),
                    },
                  });
                }}
                intlConfig={{ locale: "pt-BR", currency: "BRL" }}
              />
            </Form.Item>
            <Form.Item
              label="Previsão pagamento pedidos de compra"
              initialValue={moment(
                job?.orcamento?.previsaoDeSaidaPedidosDeCompra
              )}
              name={["orcamento", "previsaoDeSaidaPedidosDeCompra"]}
            >
              <DatePicker
                value={previsaoDeSaidaPedidosDeCompra}
                onSelect={(value) => setPrevisaoDeSaidaPedidosDeCompra(value)}
                format="DD/MM/yyyy"
                placeholder="Selecione a data"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Input.Group>

          <Row>
            <Col span={18} />
            <Col span={6} style={{ marginTop: "30px", textAlign: "end" }}>
              <Space>
                <Button type="primary" size="middle" htmlType="submit">
                  SALVAR
                </Button>
                <Button
                  type="ghost"
                  size="middle"
                  onClick={() => {
                    setVisible(false);
                    setJob(null);
                  }}
                >
                  CANCELAR
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Card>
    </Modal>
  ) : null;
}
