import { useEffect, useState } from "react";

import { JobService } from "../../services/job/job.service";
import { identificadorFormatter } from "../../core/utils/formatters/job.formatter";

export default function useBuscarJobHook() {
  useEffect(() => {
    _jobService.obterJobSimplificado(null).then((result) => {
      const opts = result?.data?.data.map((o) => {
        return {
          key: o.id,
          value: o.id,
          label: `${identificadorFormatter(o?.identificador)} - ${o?.nome}`,
          empresa: o.empresa,
        };
      });

      setOptionsJob(opts);
      setOptionsJobSearch(false);
    });
  }, []);

  const _jobService = new JobService();
  const [optionsJob, setOptionsJob] = useState([]);
  const [optionsJobSearch, setOptionsJobSearch] = useState(false);
  const filterOption = (input, option) =>
    option?.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;

  async function buscarJob(value) {
    if (value.length >= 0) {
      setOptionsJobSearch(true);

      const result = await _jobService.obterJobSimplificado(value);
      const opts = result?.data?.data.map((o) => {
        return {
          key: o.id,
          value: o.id,
          label: `${identificadorFormatter(o?.identificador)} - ${o?.nome}`,
        };
      });
      setOptionsJob(opts);
      setOptionsJobSearch(false);
    }
  }

  return {
    buscarJob,
    options: optionsJob,
    setOptions: setOptionsJob,
    loading: optionsJobSearch,
    filterOption,
  };
}
