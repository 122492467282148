import { Descriptions } from "antd";
import React from "react";

export default function CondicaoDePagamentoPopup({ data }) {
  return (
    <div>
      <Descriptions bordered size="small">
        <Descriptions.Item label="Prazo de pagamento" span={24}>
          {data?.nome}
        </Descriptions.Item>
        <Descriptions.Item label="Forma de pagamento" span={24}>
          {data?.formaDePagamento}
        </Descriptions.Item>
        <Descriptions.Item label="Parcelas" span={24}>
          {data?.numeroDeParcelas}
        </Descriptions.Item>
        <Descriptions.Item label="Primeira parcela em" span={24}>
          {`${
            data?.diasParaPrimeiraParcela === 0
              ? 1
              : data?.diasParaPrimeiraParcela
          } dia(s)`}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}
