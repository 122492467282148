import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  PageHeader,
  Result,
  Row,
  Space,
} from "antd";
import React, { useState } from "react";

import { FornecedorService } from "../../../services/fornecedor/fornecedor.service";
import { PreCadastroFornecedorDto } from "../../../services/fornecedor/dto/pre-cadastro-fornecedor.dto";
import { cnpjFormatter } from "../../../core/utils/formatters/cnpj.formatter";
import { cnpjValidator } from "../../../core/utils/validators/cnpj.validator";

export default function PreCadastroFornecedor({
  setVisible,
  visible,
  child = false,
  form,
  setOptionsFornecedor,
}) {
  const [formRef] = Form.useForm();
  const [cadastroEfetuado, setCadastroEfetuado] = useState(false);
  const _fornecedorService = new FornecedorService();
  const [cnpjCadastrado, setCnpjCadastrado] = useState({
    status: false,
    message: "",
  });
  async function gerarPreCadastroHandler() {
    try {
      const { email, nomeFantasia, cnpj } = formRef.getFieldsValue([
        "email",
        "nomeFantasia",
        "cnpj",
      ]);
      const dto: PreCadastroFornecedorDto = {
        email,
        nomeFantasia,
        cnpj: cnpj.replace(/[^\d]+/g, ""),
      };
      const response = await _fornecedorService.gerarPreCadastro(dto);

      setOptionsFornecedor([
        {
          key: response?.data?.data?.id,
          value: response?.data?.data?.id,
          label: response?.data?.data?.nomeFantasia,
        },
      ]);
      form.setFieldsValue({
        requisicao: {
          fornecedor: response?.data?.data?.id,
        },
      });
      setCadastroEfetuado(true);
    } catch (error) {
      throw error;
    }
  }

  return (
    <Drawer
      visible={visible}
      closable={false}
      width={900}
      bodyStyle={{ padding: 5 }}
    >
      <div>
        <PageHeader
          className="site-page-header"
          onBack={() => {
            setVisible(false);
          }}
          title="Pré cadastro fornecedor"
          subTitle="Gerar pré cadastro fornecedor"
          style={{ marginBottom: 10 }}
        />
        {!cadastroEfetuado ? (
          <Card title="Informações gerais">
            <Form
              layout="vertical"
              onFinish={() => gerarPreCadastroHandler()}
              form={formRef}
            >
              <Input.Group compact>
                <Form.Item
                  name="cnpj"
                  hasFeedback
                  validateStatus={cnpjCadastrado.status ? "error" : ""}
                  help={cnpjCadastrado.message}
                  rules={[
                    {
                      required: true,
                      max: 18,
                      message: "CNPJ é Obrigatório",
                    },
                  ]}
                  style={{ paddingRight: 5, width: "20%" }}
                  label="CNPJ"
                >
                  <Input
                    placeholder="CNPJ"
                    onKeyUp={async (e) => {
                      try {
                        formRef.setFieldsValue({
                          cnpj: cnpjFormatter(formRef.getFieldValue("cnpj")),
                        });
                        const cnpj = formRef
                          .getFieldValue("cnpj")
                          .replace(/[^\d]+/g, "");
                        if (cnpj.length >= 14) {
                          cnpjValidator(cnpj);
                          await _fornecedorService.verificarCnpjCadastrado(
                            cnpj
                          );
                          setCnpjCadastrado({
                            status: false,
                            message: "",
                          });
                        }
                      } catch (error) {
                        setCnpjCadastrado({
                          status: true,
                          message:
                            error?.response?.data?.message || error?.message,
                        });
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="nomeFantasia"
                  style={{ paddingRight: 5, width: "40%" }}
                  label="Nome Fantasia"
                  rules={[
                    { required: true, message: "Nome Fantasia é obrigatório" },
                  ]}
                >
                  <Input placeholder="Nome Fantasia" />
                </Form.Item>
                <Form.Item
                  name="email"
                  label="E-mail"
                  style={{ width: "40%" }}
                  rules={[{ required: true, message: "E-mail é obrigatório" }]}
                >
                  <Input placeholder="E-mail" type="email" />
                </Form.Item>
              </Input.Group>
              <Row>
                <Col span={18} />
                <Col span={6} style={{ marginTop: "15px", textAlign: "end" }}>
                  <Space>
                    <Button type="primary" size="middle" htmlType="submit">
                      ENVIAR
                    </Button>
                    <Button
                      type="ghost"
                      size="middle"
                      onClick={() => {
                        setVisible(false);
                        formRef.resetFields();
                      }}
                    >
                      CANCELAR
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Form>
          </Card>
        ) : (
          <Result
            title="Pré cadastro Gerado com Sucesso"
            status="success"
            subTitle={
              <div>
                Foi enviado um e-mail para
                <a style={{ fontWeight: "bold", padding: 5 }}>
                  {formRef.getFieldValue("email")}
                </a>
                com as instruções para completar o cadastro
              </div>
            }
            extra={
              <Button
                type="primary"
                size="middle"
                onClick={() => {
                  setVisible(false);
                  formRef.resetFields();
                  setCadastroEfetuado(false);
                }}
              >
                Concluir
              </Button>
            }
          />
        )}
      </div>
    </Drawer>
  );
}
