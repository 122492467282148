import { Card, Form, Select } from "antd";
import { EditorState, convertToRaw } from "draft-js";
import React, { useEffect, useState } from "react";

import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";

export default function EmailEditor({ defaultMail = null, form }) {
  const [editor, setEditor] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (defaultMail) {
      form.setFieldsValue({ emails: [defaultMail] });
    }
  }, [form]);

  return (
    <div>
      <Card size="small" title="Destinatários" style={{ marginBottom: 4 }}>
        <Form.Item
          label="E-mails"
          name="emails"
          rules={[{ required: true, message: "Destinatário é obrigatório" }]}
        >
          <Select
            mode="tags"
            placeholder="Ex: tracao@tracao.com.br"
            onSelect={(value) => {
              const re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              const validateSelect = re.test(value.toString().toLowerCase());
              if (validateSelect) {
                form.setFieldsValue({
                  emails: form.getFieldValue("emails"),
                });
              } else {
                const opts = form
                  .getFieldValue("emails")
                  ?.filter((e) => e !== value);

                form.setFieldsValue({ emails: opts });
              }
            }}
          />
        </Form.Item>
      </Card>
      <Card size="small" title="Mensagem" bodyStyle={{ padding: 0 }}>
        <Editor
          editorStyle={{ paddingRight: 10, paddingLeft: 10, minHeight: 200 }}
          localization={{
            locale: "pt",
          }}
          editorState={editor}
          onChange={() =>
            form.setFieldsValue({
              message: draftToHtml(convertToRaw(editor.getCurrentContent())),
            })
          }
          onEditorStateChange={setEditor}
          toolbar={{
            blockType: {
              inDropdown: true,
              options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
            },
            options: ["inline", "blockType", "list", "textAlign", "remove"],
            inline: {
              options: ["bold", "italic", "underline", "strikethrough"],
            },
          }}
        />
      </Card>
    </div>
  );
}
