import { Button, Result } from "antd";
import React, { useEffect, useState } from "react";

import Loading from "../../../components/loading.component";
import { PedidoDeCompraService } from "../../../services/pedido-de-compra/pedido-de-compra.service";
import { useParams } from "react-router-dom";

export default function ConfirmarRecebimentoPedidoDeCompra() {
  const _pedidoDeCompraService = new PedidoDeCompraService();
  const [confirmacaoEfetuada, setConfirmacaoEfetuada] = useState(false);
  const { id } = useParams<any>();

  useEffect(() => {
    _pedidoDeCompraService
      .confirmarRecebimento(id)
      .then(() => setConfirmacaoEfetuada(true));
  }, []);

  return confirmacaoEfetuada ? (
    <Result
      status="success"
      title="CONFIRMAÇÃO EFETUADA"
      subTitle="Confirmação efetuada com êxito, entre em contato com o atendimento caso for necessário"
      extra={
        <Button
          type="primary"
          size="middle"
          onClick={() => {
            var win = window.open("about:blank", "_self");
            win.close();
          }}
        >
          CONCLUIR
        </Button>
      }
    />
  ) : (
    <Loading />
  );
}
