import { BarChartOutlined } from "@ant-design/icons";
import { Financeiro as FinanceiroPermissao } from "../../core/permissions/subjects";
import { ConciliacaoBancariaRoute } from "./conciliacao-bancaria/conciliacao-bancaira.page";
import { ContaAPagarRoute } from "./contas-a-pagar/conta-a-pagar.page";
import { ContasAPagarRoute } from "./contas-a-pagar/contas-a-pagar.page";
import { ContaAReceberRoute } from "./contas-a-receber/conta-a-receber.page";
import { ContasAReceberRoute } from "./contas-a-receber/contas-a-receber.page";
import { ContaBancariaRoute } from "./contas-bancarias/conta-bancaria.page";
import { ContasBancariasRoute } from "./contas-bancarias/contas-bancarias.page";
import { EmpresaRoute } from "./empresas/empresa.page";
import { EmpresasRoute } from "./empresas/empresas.page";
import { FluxoDeCaixaRoute } from "./fluxo-de-caixa/fluxo-de-caixa.page";
import { CondicoesDePagamentoRoute } from "./formas-de-pagamento/condicoes-de-pagamento.page";
import { FormaDePagamentoRoute } from "./formas-de-pagamento/forma-de-pagamento.page";
import { PrazoDePagamentoRoute } from "./formas-de-pagamento/prazo-de-pagamento.page";
import { JobsRoute } from "./jobs/jobs.page";
import { PedidoDeCompraRoute } from "./pedidos-de-compra/pedido-de-compra.page";
import { PedidosDeCompraRoute } from "./pedidos-de-compra/pedidos-de-compra.page";
export const FinanceiroRoute = {
  id: "financeiro",
  path: "/inicio/financeiro",
  exact: true,
  sidebar: "Financeiro",
  icon: <BarChartOutlined />,
  main: () => null,
  renderInMenu: true,
  permission: FinanceiroPermissao,
  submenus: [
    PedidosDeCompraRoute,
    JobsRoute,
    PedidoDeCompraRoute,
    ContaAPagarRoute,
    ContaAReceberRoute,
    ContasAPagarRoute,
    ContasAReceberRoute,
    FluxoDeCaixaRoute,
    ConciliacaoBancariaRoute,
    CondicoesDePagamentoRoute,
    FormaDePagamentoRoute,
    PrazoDePagamentoRoute,
    EmpresasRoute,
    EmpresaRoute,
    ContasBancariasRoute,
    ContaBancariaRoute,
  ],
};
