import { ContactsOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  message,
  PageHeader,
  Popconfirm,
  Table,
  Tag,
} from "antd";
import moment from "moment";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import useSWR from "swr";
import { Endpoints } from "../../../core/constants/endpoints";
import useTitle from "../../../core/hooks/title.hook";
import { Cliente } from "../../../core/permissions/subjects";
import { cnpjFormatter } from "../../../core/utils/formatters/cnpj.formatter";
import { ClienteService } from "../../../services/cliente/cliente.service";
import NovoCliente from "./novo-cliente.page";

const _clienteService = new ClienteService();
export default function Clientes() {
  useTitle("Clientes");
  const [atualizarLista, setAtualizarLista] = useState<any>(0);
  const [filtro, setFiltro] = useState("");
  const history = useHistory();
  const [modalCadastro, setModalCadastro] = useState(false);

  const { data: clientes, error } = useSWR(
    Endpoints.clientes + filtro + atualizarLista,
    () => _clienteService.obterClientes(filtro),
    { revalidateOnFocus: false }
  );
  if (error) message.error(error.message);

  const columns = [
    {
      title: "CNPJ",
      dataIndex: "cnpj",
      width: 140,
      render: (value) => cnpjFormatter(value),
    },
    {
      key: "id",
      title: "Nome Fantasia",
      dataIndex: "nomeFantasia",
    },
    {
      key: "id",
      title: "Razão Social",
      dataIndex: "razaoSocial",
    },

    {
      title: "Data Cadastro",
      dataIndex: "createdAt",
      width: 140,
      render: (value) => moment(new Date(value)).format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "Status",
      dataIndex: "ativo",
      width: 80,
      render: (value) =>
        value ? (
          <Tag color="green">ATIVO</Tag>
        ) : (
          <Tag color="orange">INATIVO</Tag>
        ),
    },
    {
      title: "Ação",
      width: 160,
      dataIndex: "createdAt",
      render: (_, obj) => (
        <>
          <a style={{ paddingRight: 8 }}>
            <Link to={`/inicio/atendimento/clientes/${obj.id}`}>Acessar</Link>
          </a>

          <Popconfirm
            placement="bottom"
            title={`Deseja  ${
              obj?.ativo ? "inativar" : "ativar"
            } o cadastro de ${obj?.nomeFantasia} ?`}
            onConfirm={async () => {
              await _clienteService.inativarCadastro(obj?.id);
              message.success(
                `Cliente ${obj?.nomeFantasia} foi ${
                  obj?.ativo ? "Inativado" : "Ativado"
                }`
              );
              setAtualizarLista(Date.now());
            }}
            okText="SIM"
            cancelText="Não"
          >
            <a style={{ color: obj?.ativo ? "#900c3f" : "#1B4F72" }}>
              {obj?.ativo ? "Inativar" : "Ativar"}
            </a>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <div>
      <NovoCliente visible={modalCadastro} setVisible={setModalCadastro} />
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title="Clientes"
        subTitle="Todos os clientes"
        extra={
          <Button
            type="primary"
            size="middle"
            icon={<PlusOutlined />}
            onClick={() => setModalCadastro(true)}
          >
            Novo Cliente
          </Button>
        }
      />
      <div style={{ paddingTop: 10 }}>
        <Input.Search
          placeholder="Buscar..."
          size="middle"
          onSearch={(value) => setFiltro(value)}
        />
      </div>
      <Table
        rowKey="id"
        loading={!clientes}
        columns={columns}
        bordered
        style={{ marginTop: 10 }}
        dataSource={clientes?.data?.data || []}
        scroll={{ y: 450 }}
      />
    </div>
  );
}

export const ClientesRoute = {
  id: "clientes",
  path: "/inicio/atendimento/clientes",
  sidebar: "Clientes",
  exact: true,
  renderInMenu: true,
  main: () => <Clientes />,
  icon: <ContactsOutlined />,
  permission: Cliente,
};
