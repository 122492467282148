import { CSSProperties, useEffect, useState } from "react";
import { Card, Carousel, Col, Row, Upload } from "antd";
import { FileTextOutlined, HomeOutlined } from "@ant-design/icons";

import { Atendimento } from "../../core/permissions/subjects";
import { DashboardService } from "../../services/dashboard/dashboard.service";
import { Endpoints } from "../../core/constants/endpoints";
import { v4 as newId } from "uuid";
import useSWR from "swr";

export default function Dashboard() {
  const _dashboardService = new DashboardService();
  const [bannerInf1, setBannerInf1] = useState(null);
  const [bannerInf2, setBannerInf2] = useState(null);

  const { data: { data: { data: dashboardConfig } = {} } = {} } = useSWR(
    Endpoints.jobs,
    () => _dashboardService.obterConfiguracao(),
    {
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    if (dashboardConfig?.bannersInferiores?.length > 0) {
      const [b1 = null, b2 = null] = dashboardConfig?.bannersInferiores || [];
      setBannerInf1(b1);
      setBannerInf2(b2);
    }
  }, [dashboardConfig]);

  return (
    <Card loading={!dashboardConfig}>
      <Row>
        <Col span={24}>
          <div className="dashboard-itens">
            <Carousel>
              {dashboardConfig?.bannersSuperiores?.map((b) => (
                <div style={contentStyle}>
                  <img src={b.url} style={bannerStyle} />
                </div>
              ))}
            </Carousel>
          </div>
        </Col>
        <Col span={8}>
          <div className="dashboard-itens">
            <Carousel>
              <div style={contentStyle}>
                <img src={bannerInf1?.url} style={bannerStyle} />
              </div>
            </Carousel>
          </div>
        </Col>
        <Col span={8}>
          <div className="dashboard-itens">
            <Carousel>
              <div style={contentStyle}>
                <img src={bannerInf2?.url} style={bannerStyle} />
              </div>
            </Carousel>
          </div>
        </Col>
        <Col span={8}>
          <div className="dashboard-itens">
            <Card
              title="Documentos"
              style={{ minHeight: 350, maxHeight: 35, overflow: "auto" }}
            >
              <Upload
                listType="picture"
                iconRender={() => <FileTextOutlined />}
                style={{ maxHeight: 320, overflow: "auto" }}
                fileList={dashboardConfig?.documentos?.map((i) => {
                  return { uid: newId(), name: i.filename, url: i.url };
                })}
              />
            </Card>
          </div>
        </Col>
      </Row>
    </Card>
  );
}

const avisos = [
  { titulo: "Pagamento efetuado aos colaboradores" },
  { titulo: "Mudanças no processo de reembolso" },
  { titulo: "Encontro digital brasil" },
  { titulo: "Encontro anual Natura 2021 " },
  { titulo: "Encontro anual Natura 2021 " },
];

const bannerStyle: CSSProperties = {
  width: "100%",
  height: "350px",
  objectFit: "cover",
  borderRadius: 3,
  color: "#000",
  background: "#212121",
  padding: 0,
};
const contentStyle: any = {
  height: "300px",
  lineHeight: "160px",
  textAlign: "center",
  background: "#212121",
  borderRadius: 5,
};

export const DashboardRoute = {
  id: "inicio",
  path: "/inicio",
  exact: true,
  sidebar: "Página Inicial",
  icon: <HomeOutlined />,
  main: () => <Dashboard />,
  submenus: [],
  renderInMenu: true,
  permission: Atendimento,
};
