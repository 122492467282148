import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  PageHeader,
  Row,
  Select,
  Space,
  message,
} from "antd";
import React, { useState } from "react";

import { EmpresaService } from "../../../services/empresa/empresas.service";
import { NovaEmpresaDto } from "../../../services/empresa/dto/nova-empresa.dto";
import { cepFormatter } from "../../../core/utils/formatters/cep.formatter";
import { cnpjFormatter } from "../../../core/utils/formatters/cnpj.formatter";
import { cnpjValidator } from "../../../core/utils/validators/cnpj.validator";
import { regimesTributariosEnumList } from "../../../core/enums/regime-tributario.enum";
import { useHistory } from "react-router-dom";

const cep = require("cep-promise");
export default function NovaEmpresa({ visible, setVisible }) {
  const [formRef] = Form.useForm();
  const _empresaService = new EmpresaService();
  const history = useHistory();
  const [cnpjCadatrado, setCnpjCadatrado] = useState({
    status: false,
    message: "",
  });

  async function cadastrarClienteHandler() {
    try {
      const {
        cnpj,
        nomeFantasia,
        razaoSocial,
        email,
        inscricaoEstadual,
        inscricaoMunicipal,
        regimeTributario,
        endereco,
      } = formRef.getFieldsValue([
        "cnpj",
        "nomeFantasia",
        "razaoSocial",
        "inscricaoEstadual",
        "inscricaoMunicipal",
        "regimeTributario",
        "email",
        "endereco",
      ]);

      const empresaDto: NovaEmpresaDto = {
        cnpj: cnpj.replace(/[^\d]+/g, ""),
        nomeFantasia,
        razaoSocial,
        regimeTributario,
        endereco,
        inscricaoEstadual,
        inscricaoMunicipal,
      };
      await _empresaService.novaEmpresa(empresaDto);
      message.success("Empresa Cadastrada");
      setVisible(false);
    } catch (error) {
      if (error?.response?.data?.message && typeof Array) {
        for (const e of error?.response?.data?.message.toString().split(",")) {
          message.error(e);
        }
      } else {
        message.error(error?.message);
      }
    }
  }

  return (
    <Drawer
      visible={visible}
      width={1300}
      closable={false}
      bodyStyle={{ padding: 5 }}
      onClose={() => setVisible(false)}
    >
      <PageHeader
        className="site-page-header"
        onBack={() => {
          setVisible(false);
        }}
        title="Nova Empresa"
        subTitle="Cadastrar nova empresa"
        style={{ marginBottom: 5 }}
      />

      <Card title="Informações da Empresa" bodyStyle={{ padding: 5 }}>
        <Form
          form={formRef}
          onFinish={() => cadastrarClienteHandler()}
          layout="vertical"
        >
          <Card title="Geral" type="inner" style={{ marginBottom: 10 }}>
            <Row>
              <Col span={24}>
                <Input.Group compact>
                  <Form.Item
                    label="CNPJ"
                    hasFeedback
                    validateStatus={cnpjCadatrado.status ? "error" : ""}
                    help={cnpjCadatrado.message}
                    rules={[
                      {
                        required: true,
                        max: 18,
                        message: "CNPJ é Obrigatório",
                      },
                    ]}
                    name="cnpj"
                    style={{ width: "11%", paddingRight: "5px" }}
                  >
                    <Input
                      maxLength={18}
                      placeholder="CNPJ"
                      onKeyUp={async (e) => {
                        try {
                          formRef.setFieldsValue({
                            cnpj: cnpjFormatter(formRef.getFieldValue("cnpj")),
                          });
                          const cnpj = formRef
                            .getFieldValue("cnpj")
                            .replace(/[^\d]+/g, "");
                          if (cnpj.length >= 14) {
                            cnpjValidator(cnpj);
                            await _empresaService.verificarCnpjCadastrado(cnpj);
                            setCnpjCadatrado({
                              status: false,
                              message: "",
                            });
                          }
                        } catch (error) {
                          setCnpjCadatrado({
                            status: true,
                            message:
                              error?.response?.data?.message || error?.message,
                          });
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Nome Fantasia"
                    name="nomeFantasia"
                    style={{ width: "24%", paddingRight: 5 }}
                    rules={[
                      {
                        required: true,
                        message: "Nome fantasia é obrigatório",
                      },
                    ]}
                  >
                    <Input placeholder="Nome Fantasia" />
                  </Form.Item>
                  <Form.Item
                    label="Razão Social"
                    rules={[
                      {
                        required: true,
                        message: "Razão social é obrigatório",
                      },
                    ]}
                    name="razaoSocial"
                    style={{ width: "24%", paddingRight: 5 }}
                  >
                    <Input placeholder="Razão Social" />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Inscrição Estadual é obrigatório",
                      },
                    ]}
                    style={{ width: "13.5%", paddingRight: "5px" }}
                    name="inscricaoEstadual"
                    label="Inscrição Estadual"
                  >
                    <Input placeholder="Inscrição Estadual" />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Inscrição Municipal é obrigatório",
                      },
                    ]}
                    style={{ width: "13.5%", paddingRight: 5 }}
                    name="inscricaoMunicipal"
                    label="Inscrição Municipal"
                  >
                    <Input placeholder="Inscrição Municipal" />
                  </Form.Item>
                  <Form.Item
                    label="Regime tributário"
                    name="regimeTributario"
                    required
                    style={{ width: "14%" }}
                  >
                    <Select
                      options={regimesTributariosEnumList}
                      placeholder="Selecione o regime"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Input.Group>
              </Col>
            </Row>
          </Card>
          <Card title="Endereço" type="inner">
            <Col span={24}>
              <Input.Group compact>
                <Form.Item
                  name={["endereco", "cep"]}
                  label="CEP"
                  style={{ width: "10%", paddingRight: "5px" }}
                  rules={[
                    {
                      required: true,
                      message: "Informe o CEP",
                      max: 9,
                      min: 9,
                      pattern: /^\d{5}-\d{3}$/g,
                    },
                  ]}
                >
                  <Input
                    placeholder="00000-000"
                    maxLength={9}
                    onChange={(event) => {
                      const cepValue = event.target.value;

                      formRef.setFieldsValue({
                        cep: cepFormatter(event.target.value),
                      });
                      if (cepValue.replace("-", "").length >= 8) {
                        cep(cepValue).then((response) => {
                          formRef.setFieldsValue({
                            endereco: {
                              estado: response.state,
                              logradouro: response.street,
                              bairro: response.neighborhood,
                              cidade: response.city,
                            },
                          });
                        });
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name={["endereco", "logradouro"]}
                  label="Rua"
                  style={{ width: "40%", paddingRight: "5px" }}
                  rules={[{ required: true, message: "Informe a Rua" }]}
                >
                  <Input placeholder="Rua" />
                </Form.Item>
                <Form.Item
                  name={["endereco", "numero"]}
                  label="Número"
                  style={{ width: "10%", paddingRight: 5 }}
                  rules={[{ required: true, message: "Informe o número" }]}
                >
                  <Input placeholder="Número" type="number" />
                </Form.Item>

                <Form.Item
                  name={["endereco", "bairro"]}
                  label="Bairro"
                  rules={[{ required: true, message: "Informe o bairro" }]}
                  style={{ width: "20%", paddingRight: "5px" }}
                >
                  <Input placeholder="Bairro" />
                </Form.Item>
                <Form.Item
                  name={["endereco", "cidade"]}
                  label="Cidade"
                  rules={[{ required: true, message: "Informe a cidade" }]}
                  style={{ width: "15%", paddingRight: "5px" }}
                >
                  <Input placeholder="Cidade" />
                </Form.Item>
                <Form.Item
                  name={["endereco", "estado"]}
                  label="Estado"
                  rules={[{ required: true, message: "Informe o estado" }]}
                  style={{ width: "5%" }}
                >
                  <Input placeholder="Estado" />
                </Form.Item>
                <Form.Item
                  name={["endereco", "complemento"]}
                  label="Complemento"
                  style={{ width: "100%" }}
                >
                  <Input placeholder="Complemento" />
                </Form.Item>
                <Form.Item name={["endereco", "pais"]} initialValue="BR" />
              </Input.Group>
            </Col>
          </Card>
          <Row>
            <Col span={20} />
            <Col span={4} style={{ marginTop: "30px", textAlign: "end" }}>
              <Space>
                <Button type="primary" size="middle" htmlType="submit">
                  SALVAR
                </Button>
                <Button
                  type="ghost"
                  size="middle"
                  onClick={() => {
                    setVisible(false);
                    formRef.resetFields();
                  }}
                >
                  CANCELAR
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Card>
    </Drawer>
  );
}
