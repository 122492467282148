import { Route, BrowserRouter as Router, useHistory } from "react-router-dom";
import { createForUser, verifyAuth } from "../../core/permissions/ability";

import { AbilityContext } from "../../core/permissions/permission.context";
import { AuthRoutes } from "../../routes";
import FooterDefault from "../../components/footer.component";
import HeaderDefault from "../../components/header.component";
import { Layout } from "antd";
import React from "react";
import RefreshToken from "../../components/refresh-token.component";
import useTitle from "../../core/hooks/title.hook";

const { Content } = Layout;

export default function Main() {
  useTitle("Home");
  const history = useHistory();
  verifyAuth(history);

  const ability = createForUser();

  return (
    <AbilityContext.Provider value={ability}>
      <RefreshToken />
      <Router>
        <Layout className="container">
          <HeaderDefault />
          <Layout className="site-layout">
            <Content
              className="site-layout-background"
              style={{
                minHeight: 280,
                overflow: "auto",
              }}
            >
              {AuthRoutes.map((route: any) => {
                return (
                  <div key={route.id}>
                    <Route
                      key={route.id}
                      path={route.path}
                      exact={route.exact}
                      component={route.main}
                    />
                    {route.submenus.map((item: any) => (
                      <Route
                        key={item.id}
                        path={item.path}
                        exact={item.exact}
                        component={item.main}
                      />
                    ))}
                  </div>
                );
              })}
            </Content>
          </Layout>
        </Layout>
        <FooterDefault />
      </Router>
    </AbilityContext.Provider>
  );
}
