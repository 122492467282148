import {
  Button,
  Card,
  DatePicker,
  Descriptions,
  Form,
  Input,
  Modal,
  PageHeader,
  Radio,
  Select,
  Space,
  Table,
  Tag,
  message,
} from "antd";
import {
  CheckCircleTwoTone,
  ClearOutlined,
  DeleteTwoTone,
  EditTwoTone,
  FileExcelOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Cliente, ContaAPagar } from "../../../core/permissions/subjects";
import { Link, useHistory } from "react-router-dom";
import {
  StatusContaAPagarDescricao,
  StatusContaAPagarDescricaoCor,
  statusContaAPagarList,
} from "../../../core/enums/status-conta-a-pagar.enum";
import {
  StatusParcela,
  StatusParcelaDescricao,
  StatusParcelaList,
} from "../../../core/enums/status-parcela.enum";
import { useEffect, useState } from "react";

import { Action } from "../../../core/permissions/actions/actions";
import { Can } from "../../../core/permissions/permission.context";
import { ContaAPagarService } from "../../../services/conta-a-pagar/conta-a-pagar.service";
import { ContaBancariaService } from "../../../services/conta-bancaria/conta-bancaria.service";
import { Endpoints } from "../../../core/constants/endpoints";
import NovaContaAPagar from "./nova-conta-a-pagar.page";
import NovaContaAPagarMultipla from "./nova-conta-a-pagar-multipla.page";
import { RegistrarPagamentoContaAPagarDto } from "../../../services/conta-a-pagar/dto/registrar-pagamento-conta-a-pagar.dto";
import { cnpjFormatter } from "../../../core/utils/formatters/cnpj.formatter";
import { cpfFormatter } from "../../../core/utils/formatters/cpj.formatter";
import { currencyFormatter } from "../../../core/utils/formatters/currency.formatter";
import moment from "moment";
import { saveAs } from "file-saver";
import useBuscarFornecedorHook from "../../../components/hooks/buscar-fornecedor.hook";
import useSWR from "swr";
import useTitle from "../../../core/hooks/title.hook";

const { RangePicker } = DatePicker;

const _contaAPagarService = new ContaAPagarService();
export default function ContasAPagar() {
  useTitle("Contas A Pagar");
  const [revalidar, setRevalidar] = useState(Date.now());
  const [gerarRelatorioContasAPagar, setGerarRelatorioContasAPagar] =
    useState(false);
  const [novaContaAPagarDrawer, setNovaContaAPagarDrawer] = useState(false);
  const [novaContaAPagarMultipla, setNovaContaAPagarMultipla] = useState(false);
  const history = useHistory();
  const {
    buscarFornecedor,
    options: optsFornecedor,
    filterOptions: filterOptsFornecedor,
    setOptions: setOptsFornecedor,
    loading: loadingFornecedor,
  } = useBuscarFornecedorHook();
  const [filtro, setFiltro] = useState("");
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalItens, setTotalItens] = useState(10);
  const [parcelaParaPagamento, setParcelaParaPagamento] = useState(null);
  const [parcela, setParcela] = useState(null);
  const [filtrosFormRef] = Form.useForm();
  const { data: contasAPagar, error } = useSWR(
    Endpoints.contasAPagar + filtro + revalidar,
    () => _contaAPagarService.obterTodas(filtro),
    { revalidateOnFocus: false }
  );

  function salvarCachePaginacao(paginaAtual: number, totalItens: number) {
    sessionStorage.setItem(
      "paginacao-conta-a-pagar",
      JSON.stringify({
        paginaAtual,
        totalItens,
      })
    );
  }
  function verificarCachePaginacao() {
    let paginacaoCache = JSON.parse(
      sessionStorage.getItem("paginacao-conta-a-pagar")
    );
    if (paginacaoCache?.paginaAtual) {
      setPaginaAtual(paginacaoCache?.paginaAtual);
    }
    if (paginacaoCache?.totalItens) {
      setTotalItens(paginacaoCache?.totalItens);
    }
  }

  useEffect(() => {
    verificarCachePaginacao();
    let filterCache = JSON.parse(
      sessionStorage.getItem("filtros-conta-a-pagar")
    );
    if (filterCache) {
      setFiltro(filterCache?.query);
      if (filterCache?.status?.length > 0) {
        filtrosFormRef.setFieldsValue({
          status: filterCache?.status,
        });
      }
      if (filterCache?.intervalo) {
        const [inicial, final] = filterCache?.intervalo;
        filtrosFormRef.setFieldsValue({
          intervalo: [moment(inicial), moment(final)],
        });
      }
      if (filterCache?.optsFornecedor) {
        setOptsFornecedor(filterCache?.optsFornecedor);
        filtrosFormRef.setFieldsValue({ fornecedor: filterCache?.fornecedor });
      }
      console.log(filterCache);
    }
    console.log(filterCache);
  }, [history.location]);

  function definirFiltros(values: any) {
    try {
      const { intervalo = null, status = null, fornecedor = null } = values;
      const [dataInicio, dataFim] = intervalo || [];

      let query = `${
        status ? `status=${status.map((v) => v.key).toString()}&` : ""
      }${
        intervalo
          ? `intervalo=${dataInicio.toString()},${dataFim.toString()}&`
          : ""
      }${fornecedor ? `fornecedor=${fornecedor}` : ""}`;

      sessionStorage.setItem(
        "filtros-conta-a-pagar",
        JSON.stringify({
          intervalo,
          fornecedor,
          status,
          query,
          optsFornecedor: optsFornecedor ? optsFornecedor : null,
        })
      );

      setFiltro(query);
    } catch (error) {
      throw error;
    }
  }

  function EditarParcela() {
    const [parcelaFormRef] = Form.useForm();
    return (
      <Modal
        okText="ATUALIZAR"
        cancelText="CANCELAR"
        visible={parcela}
        width={600}
        bodyStyle={{ padding: 0 }}
        closable={false}
        onOk={async () => {
          try {
            const p = parcelaFormRef.getFieldValue("parcela");

            await _contaAPagarService.atualizarParcela(parcela?.contaAPagar, {
              id: parcela?.id,
              dataVencimento: p?.dataVencimento,
              status: parcela?.status,
            });

            setParcela(null);
            setRevalidar(Date.now());
          } catch (error) {
            if (error?.response?.data?.message && typeof Array) {
              for (const e of error?.response?.data?.message
                .toString()
                .split(",")) {
                message.error(e);
              }
            } else {
              message.error(error?.message);
            }
          }
        }}
        onCancel={() => {
          setParcela(null);
          parcelaFormRef.resetFields();
        }}
      >
        <Card title={`Alterar vencimento - ${parcela?.descricao}`}>
          <Form layout="vertical" form={parcelaFormRef}>
            <Input.Group compact>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Data de vencimento é obrigatório",
                  },
                ]}
                style={{ width: "30%", paddingRight: 5 }}
                name={["parcela", "dataVencimento"]}
                label="Novo vencimento"
                initialValue={moment(parcela?.dataVencimento)}
              >
                <DatePicker
                  disabledDate={(date) =>
                    date.toDate().getDay() === 6 || date.toDate().getDay() === 0
                  }
                  format="DD/MM/YYYY"
                  style={{ width: "100%" }}
                  placeholder="Vencimento em"
                />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Descrição é obrigatório",
                  },
                ]}
                initialValue={parcela?.descricao}
                style={{ width: "40%", paddingRight: 5 }}
                name={["parcela", "descricao"]}
                label="Descrição"
              >
                <Input
                  style={{ width: "100%" }}
                  placeholder="Descrição"
                  disabled={true}
                />
              </Form.Item>
              <Form.Item
                style={{ width: "30%", paddingRight: 5 }}
                name={["parcela", "status"]}
                rules={[
                  {
                    required: true,
                    message: "Status é obrigatório",
                  },
                ]}
                initialValue={parcela?.status}
                label="Situação"
              >
                <Select
                  disabled={true}
                  style={{ width: "100%" }}
                  placeholder="Selecione o Status"
                  options={StatusParcelaList}
                />
              </Form.Item>
            </Input.Group>
          </Form>
        </Card>
      </Modal>
    );
  }

  function RegistrarPagamento() {
    const [registrarPagamentoFormRef] = Form.useForm();
    const [optsContasBancarias, setOptsContasBancarias] = useState([]);
    const [loadingOptsContasBancararias, setLoadingOptsContasBancararias] =
      useState(false);
    const _contaBancariaService = new ContaBancariaService();

    return (
      <Modal
        okText="REGISTRAR PAGAMENTO"
        cancelText="CANCELAR"
        visible={parcelaParaPagamento}
        width={600}
        bodyStyle={{ padding: 0 }}
        closable={false}
        onOk={async () => {
          await registrarPagamentoFormRef.validateFields();
          try {
            const p = registrarPagamentoFormRef.getFieldValue("parcela");
            const dto: RegistrarPagamentoContaAPagarDto = {
              parcela: { id: parcelaParaPagamento?.id },
              contaBancaria: { id: p?.contaBancaria },
              dataPagamento: p?.dataPagamento?.toISOString(),
            };
            const response = await _contaAPagarService.registrarPagamento(
              parcelaParaPagamento.contaAPagar,
              dto
            );
            setParcelaParaPagamento(null);
            setRevalidar(Date.now());
          } catch (error) {
            if (error?.response?.data?.message && typeof Array) {
              for (const e of error?.response?.data?.message
                .toString()
                .split(",")) {
                message.error(e);
              }
            } else {
              message.error(error?.message);
            }
          }
        }}
        onCancel={() => {
          setParcelaParaPagamento(null);
          registrarPagamentoFormRef.resetFields();
        }}
      >
        <Card
          title={`Registrar Pagamento de ${parcelaParaPagamento?.descricao}`}
        >
          <Form layout="vertical" form={registrarPagamentoFormRef}>
            <Input.Group compact>
              <Form.Item
                style={{ width: "50%", paddingRight: 5 }}
                name={["parcela", "dataPagamento"]}
                label="Data Pagamento"
                rules={[
                  { required: true, message: "Informe a data de pagamento" },
                ]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  style={{ width: "100%" }}
                  placeholder="Pago em"
                />
              </Form.Item>
              <Form.Item
                style={{ width: "50%", paddingRight: 5 }}
                rules={[
                  { required: true, message: "Conta bancária é obrigatório" },
                ]}
                name={["parcela", "contaBancaria"]}
                initialValue={parcela?.status}
                label="Conta bancária"
              >
                <Select
                  loading={loadingOptsContasBancararias}
                  style={{ width: "100%" }}
                  onClick={async () => {
                    if (optsContasBancarias?.length === 0) {
                      setLoadingOptsContasBancararias(true);
                      const response = await _contaBancariaService.obterTodas();
                      const opts = response?.data?.data?.map((c) => {
                        return {
                          key: c.id,
                          value: c.id,
                          label: c?.descricao,
                        };
                      });

                      setOptsContasBancarias(opts);
                      setLoadingOptsContasBancararias(false);
                    }
                  }}
                  placeholder="Selecione a conta bancária"
                  options={optsContasBancarias}
                />
              </Form.Item>
            </Input.Group>
          </Form>
        </Card>
      </Modal>
    );
  }
  async function estornarPagamentoHandler(parcelaId: string) {
    try {
      await _contaAPagarService.estornarPagamento(parcelaId);
      message.success("Pagamento estornado");
      setRevalidar(Date.now());
    } catch (error) {
      message.error(error.response?.data?.message || error.message);
    }
  }
  if (error) message.error(error?.message);
  function Parcelas({ row }: any) {
    const [informacaoBancaria, setInformacaoBancaria] = useState(null);
    useEffect(() => {
      _contaAPagarService
        .obterInformacaoBancariaFornecedor(row?.id)
        .then(({ data: { data } }) => {
          setInformacaoBancaria(data);
        });
    }, [row]);

    const { parcelas } = row;

    const columns = [
      {
        title: "Parcela",
        dataIndex: "descricao",
        key: "descricao",
        width: 500,
      },
      {
        title: "Valor",
        dataIndex: "valor",
        className: "currency",
        width: 150,
        render: (value) => currencyFormatter(value, "BRL"),
      },

      {
        title: "Data Vencimento",
        dataIndex: "dataVencimento",
        render: (value) => moment(value).format("DD/MM/YYYY"),
        width: 150,
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (value) => (
          <Tag color={StatusParcelaDescricao[value].cor}>
            {StatusParcelaDescricao[value].label}
          </Tag>
        ),
        width: 100,
      },
      {
        title: "Ações",
        render: (item) => (
          <div style={{ textAlign: "center" }}>
            <Button
              size="small"
              disabled={item?.status === StatusParcela.Paga}
              style={{ marginRight: 3 }}
              onClick={() => setParcela(item)}
              type="dashed"
              icon={<EditTwoTone />}
            >
              Alterar Vencimento
            </Button>
            {item?.status !== StatusParcela.Paga && (
              <Button
                icon={<CheckCircleTwoTone />}
                size="small"
                type="dashed"
                onClick={() => setParcelaParaPagamento(item)}
              >
                Registrar Pagamento
              </Button>
            )}
            {item?.status === StatusParcela.Paga && (
              <Button
                icon={<DeleteTwoTone />}
                size="small"
                type="dashed"
                onClick={() => estornarPagamentoHandler(item.id)}
              >
                estornar Pagamento
              </Button>
            )}
            {item?.status === StatusParcela.Conciliada && (
              <Button
                icon={<DeleteTwoTone />}
                size="small"
                type="dashed"
                disabled
              >
                estornar Pagamento
              </Button>
            )}
          </div>
        ),
        width: 400,
      },
    ];

    return (
      <div>
        <Card
          loading={!informacaoBancaria}
          bodyStyle={{ padding: 0 }}
          title="Informação Bancária"
        >
          <Descriptions size="small" bordered>
            <Descriptions.Item label="Banco">
              {`${informacaoBancaria?.banco?.codigoBancario} - ${informacaoBancaria?.banco?.nome}`}
            </Descriptions.Item>
            <Descriptions.Item label="Agência">
              {informacaoBancaria?.agencia}
            </Descriptions.Item>
            <Descriptions.Item label="Tipo">
              {informacaoBancaria?.tipo === 1
                ? "PESSOA FÍSICA"
                : "PESSOA JURÍDICA"}
            </Descriptions.Item>
            <Descriptions.Item label="Titularidade">
              {informacaoBancaria?.titularidade}
            </Descriptions.Item>
            <Descriptions.Item label="Conta">
              {informacaoBancaria?.conta}
            </Descriptions.Item>
            <Descriptions.Item
              label={informacaoBancaria?.cnpj ? "CNPJ" : "CPF"}
            >
              {informacaoBancaria?.cnpj
                ? cnpjFormatter(informacaoBancaria?.cnpj)
                : cpfFormatter(informacaoBancaria?.cpf)}
            </Descriptions.Item>
          </Descriptions>
        </Card>
        <Table
          size="small"
          rowKey="id"
          bordered
          columns={columns}
          dataSource={parcelas}
          pagination={false}
        />
      </div>
    );
  }

  return (
    <div>
      <RegistrarPagamento />
      <EditarParcela />

      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title="Contas A Pagar"
        subTitle="Todas as Contas A Pagar"
        extra={
          <>
            <Button
              size="middle"
              icon={<FileExcelOutlined />}
              loading={gerarRelatorioContasAPagar}
              onClick={async () => {
                setGerarRelatorioContasAPagar(true);
                const xlsx =
                  await _contaAPagarService.obterInformacoesRelatorio(filtro);

                setGerarRelatorioContasAPagar(false);
                saveAs(xlsx.data, `Relatório.xlsx`);
              }}
            >
              exportar para excel
            </Button>
            <Button
              type="primary"
              size="middle"
              icon={<PlusOutlined />}
              onClick={() => setNovaContaAPagarDrawer(true)}
            >
              Conta A Pagar
            </Button>
            <Button
              type="primary"
              size="middle"
              icon={<PlusOutlined />}
              onClick={() => setNovaContaAPagarMultipla(true)}
            >
              Conta A Pagar múltipla
            </Button>
          </>
        }
      />
      <Can do={Action.Ler} on={Cliente} passThrough>
        {(ability) =>
          ability ? (
            <div>
              <NovaContaAPagar
                visible={novaContaAPagarDrawer}
                setVisible={setNovaContaAPagarDrawer}
                atualizarPai={setRevalidar}
              />
              <NovaContaAPagarMultipla
                visible={novaContaAPagarMultipla}
                setVisible={setNovaContaAPagarMultipla}
              />
              <Card title="Filtros" size="small" style={{ marginTop: 10 }}>
                <Form
                  onFinish={(values) => definirFiltros(values)}
                  layout="inline"
                  form={filtrosFormRef}
                >
                  <Space>
                    <Input.Group compact>
                      <Form.Item name="intervaloPredefinido" initialValue={1}>
                        <Radio.Group
                          defaultValue="1"
                          style={{ marginRight: 4 }}
                          onChange={(e) => {
                            filtrosFormRef.setFieldsValue({
                              intervalo: [
                                moment(new Date()),
                                moment(new Date()).add(e.target.value, "days"),
                              ],
                            });
                          }}
                        >
                          {[
                            { label: "Diário", value: 1 },
                            { label: "Semanal", value: 7 },
                            { label: "Mensal", value: 30 },
                          ].map(({ value, label }) => (
                            <Radio.Button value={value}>{label}</Radio.Button>
                          ))}
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item name="intervalo">
                        <RangePicker format="DD/MM/yyyy" />
                      </Form.Item>
                      <Form.Item name="status">
                        <Select
                          mode="tags"
                          labelInValue
                          style={{
                            marginLeft: 4,
                            minWidth: 180,
                            marginRight: 4,
                          }}
                          options={statusContaAPagarList}
                          placeholder="Status"
                        />
                      </Form.Item>
                      <Form.Item name="fornecedor" style={{ marginLeft: 4 }}>
                        <Select
                          showSearch
                          onSearch={buscarFornecedor}
                          filterOption={false}
                          loading={loadingFornecedor}
                          style={{
                            marginLeft: 4,
                            minWidth: 300,
                            marginRight: 4,
                          }}
                          placeholder="Fornecedor"
                          options={optsFornecedor}
                        />
                      </Form.Item>
                    </Input.Group>
                  </Space>
                  <Space style={{ minWidth: "15%" }}>
                    <Button
                      type="primary"
                      icon={<SearchOutlined />}
                      htmlType="submit"
                    >
                      Buscar
                    </Button>
                    <Button
                      type="default"
                      icon={<ClearOutlined />}
                      onClick={() => {
                        setFiltro("");
                        setOptsFornecedor([]);
                        sessionStorage.removeItem("filtros-conta-a-pagar");

                        filtrosFormRef.resetFields();
                      }}
                    >
                      Limpar filtro
                    </Button>
                  </Space>
                </Form>
              </Card>

              <Table
                rowKey={"id"}
                size="small"
                columns={columns}
                bordered
                loading={!contasAPagar?.data?.data}
                style={{ marginTop: 10 }}
                expandable={{
                  expandedRowRender: (row) => <Parcelas row={row} />,
                }}
                dataSource={contasAPagar?.data?.data}
                onChange={(pagination) => {
                  salvarCachePaginacao(pagination.current, pagination.pageSize);
                  setPaginaAtual(pagination.current);
                  setTotalItens(pagination.pageSize);
                }}
                pagination={{ current: paginaAtual, pageSize: totalItens }}
              />
            </div>
          ) : (
            <p>Sem Permissão...</p>
          )
        }
      </Can>
    </div>
  );
}

const columns = [
  { key: "id", title: "Conta", dataIndex: "sequencial", width: 80 },
  { key: "id", title: "Descrição", dataIndex: "descricao" },
  {
    title: "Valor Total",
    dataIndex: "valor",
    key: "valor",
    className: "currency",
    render: (value) => currencyFormatter(value, "BRL"),
  },

  {
    key: "dataVencimento",
    title: "Próximo Vencimento",
    dataIndex: "dataVencimento",
    render: (value) => moment(value).format("DD/MM/YYYY"),
  },
  {
    title: "Valor a pagar",
    className: "currency",
    render: (row) => {
      const parcelas = row?.parcelas;
      return currencyFormatter(Number(parcelas[0]?.valor), "BRL");
    },
  },
  {
    title: "Grupo Financeiro",
    dataIndex: "grupoFinanceiro",
    render: (grupo) => <Tag color="default">{grupo?.grupo?.toUpperCase()}</Tag>,
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (value) => (
      <Tag color={StatusContaAPagarDescricaoCor[value]}>
        {StatusContaAPagarDescricao[value]}
      </Tag>
    ),
  },
  {
    title: "Ação",
    width: 100,
    key: "x",
    render: (value, obj) => (
      <a>
        <Link to={`contas-a-pagar/${obj.id}`}>Acessar</Link>
      </a>
    ),
  },
];

export const ContasAPagarRoute = {
  id: "contas-a-pagar",
  path: "/inicio/financeiro/contas-a-pagar",
  sidebar: "Contas A Pagar",
  exact: true,
  renderInMenu: true,
  main: () => <ContasAPagar />,
  icon: <MinusCircleOutlined />,
  permission: ContaAPagar,
};
