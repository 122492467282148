import {
  Avatar,
  Badge,
  Button,
  Card,
  Drawer,
  Form,
  Input,
  Modal,
  message,
} from "antd";
import {
  KeyOutlined,
  LockOutlined,
  PoweroffOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";

import { AtualizarSenhaDto } from "../services/colaborador/dto/atualizar-senha.dto";
import { ColaboradorService } from "../services/colaborador/colaborador.service";
import { Endpoints } from "../core/constants/endpoints";
import useSWR from "swr";

export function ProfileUser({ visible, setVisible }) {
  const _colaboradorService = new ColaboradorService();
  const [modalAlterarSenha, setModalAlterarSenha] = useState(false);
  const { data: { data: { data: user } = {} } = {} } = useSWR(
    Endpoints.colaboradoresInfo,
    () => _colaboradorService.obterInfoUsuario(),
    { revalidateOnFocus: false }
  );

  useEffect(() => {
    if (user?.redefinirSenha) {
      setModalAlterarSenha(true);
    }
  }, [user]);

  function handleLogoff() {
    try {
      localStorage.clear();
      message.warn("Efetuando Logoff...");
      setTimeout(() => window.location.replace("/"), 1500);
    } catch (error) {
      throw error;
    }
  }

  function AlterarSenhaModal() {
    const [formPasswordRef] = Form.useForm();
    async function AlterarSenhaHandler() {
      try {
        const { novaSenha, senhaAtual } =
          formPasswordRef.getFieldValue("alterarSenha");
        const dto: AtualizarSenhaDto = {
          novaSenha,
          senhaAtual,
          colaboradorId: user?.id,
        };

        await _colaboradorService.atualizarSenha(dto, user?.id);
        message.success("Senha atualizada com sucesso");
        setModalAlterarSenha(false);
      } catch (error) {
        message.error(error?.response?.data?.message);
        throw error;
      }
    }
    return (
      <Modal
        onCancel={() => {
          setModalAlterarSenha(false);
          formPasswordRef.resetFields(["alterarSenha"]);
        }}
        visible={modalAlterarSenha}
        closable={false}
        bodyStyle={{ padding: 0, margin: 0 }}
        footer
      >
        <Card title="Alterar Senha" size="default">
          <Form
            form={formPasswordRef}
            layout="vertical"
            onFinish={() => AlterarSenhaHandler()}
          >
            <Form.Item
              name={["alterarSenha", "senhaAtual"]}
              label="Senha Atual"
              rules={[
                {
                  required: true,
                  message: "Informe a senha atual",
                },
              ]}
            >
              <Input
                type="password"
                placeholder="Informe Senha Atual"
                prefix={<LockOutlined className="site-form-item-icon" />}
              />
            </Form.Item>
            <Form.Item
              name={["alterarSenha", "novaSenha"]}
              label="Nova Senha"
              rules={[
                {
                  required: true,
                  message: "Informe a nova senha",
                },
              ]}
            >
              <Input
                type="password"
                placeholder="Informe a Nova Senha"
                prefix={<LockOutlined className="site-form-item-icon" />}
              />
            </Form.Item>
            <Form.Item
              dependencies={["alterarSenha", "novaSenha"]}
              name={["alterarSenha", "confirmacao"]}
              label="Confirme a Senha"
              rules={[
                {
                  required: true,
                  message: "Informe a confirmação de senha",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (
                      !value ||
                      getFieldValue(["alterarSenha", "novaSenha"]) === value
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject("As senhas não conferem");
                  },
                }),
              ]}
            >
              <Input
                type="password"
                placeholder="Informe a confirmação de senha"
                prefix={<LockOutlined className="site-form-item-icon" />}
              />
            </Form.Item>
            <Button
              htmlType="submit"
              size="middle"
              type="primary"
              style={{ width: "100%" }}
              icon={<KeyOutlined />}
            >
              ALTERAR SENHA
            </Button>
          </Form>
        </Card>
      </Modal>
    );
  }
  return user ? (
    <>
      <AlterarSenhaModal />
      <div className="main-avatar">
        <h4
          style={{
            alignSelf: "center",
            marginRight: "3px",
            marginTop: "5px",
            color: "gray",
          }}
        >
          Olá,
        </h4>
        <h4
          style={{
            alignSelf: "center",
            marginRight: "10px",
            marginTop: "5px",
          }}
        >
          {user && user.nome ? String(user.nome).split(" ")?.[0] : ""}
        </h4>
        <div
          style={{
            alignSelf: "center",
            display: "flex",
            cursor: "pointer",
          }}
          onClick={() => setVisible(true)}
        >
          <Badge dot>
            <Avatar shape="square" icon={<UserOutlined />} />
          </Badge>
        </div>
      </div>
      <Drawer
        placement="right"
        closable={false}
        onClose={() => setVisible(false)}
        visible={visible}
        width="300px"
      >
        <div className="profile-user">
          <div>
            <Avatar shape="square" size={95} icon={<UserOutlined />} />
          </div>
          <div style={{ marginLeft: "10px" }}>
            <h4>{`${user?.nome} (${user?.usuario})`}</h4>
            <h4>{`${user?.email}`}</h4>
            <Button
              type="primary"
              onClick={handleLogoff}
              icon={<PoweroffOutlined />}
            >
              Logoff
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  ) : null;
}
