import { Endpoints } from "../../core/constants/endpoints";
import { BaseService } from "../base.service";
import { AtualizarJobDto } from "./dto/atualizar-job.dto";
import { NovoJobDto } from "./dto/novo-job.dto";
import { OrcamentoDto } from "./dto/orcamento.dto";

export class JobService extends BaseService {
  async obterJobs(filtro: string = "") {
    try {
      return await this.api.get(`${Endpoints.jobs}?${filtro}`);
    } catch (error) {
      throw error;
    }
  }
  async obterRelatorioRequisicoes(jobId: string = "") {
    try {
      return await this.api.download(
        `${Endpoints.requisicoesDeCompra}/${jobId}/relatorio-por-job`
      );
    } catch (error) {
      throw error;
    }
  }

  async cadastrarJob(job: NovoJobDto) {
    try {
      return await this.api.post(Endpoints.jobs, job);
    } catch (error) {
      throw error;
    }
  }

  async verificarFaturamento(jobId: string) {
    try {
      return await this.api.get(
        `${Endpoints.jobs}/${jobId}${Endpoints.verificarFaturamentoJob}`
      );
    } catch (error) {
      throw error;
    }
  }
  async atualizarJob(id: string, job: AtualizarJobDto) {
    try {
      return await this.api.patch(`${Endpoints.jobs}/${id}`, job);
    } catch (error) {
      throw error;
    }
  }
  async obterJob(id: string) {
    try {
      return await this.api.get(`${Endpoints.jobs}/${id}`);
    } catch (error) {
      throw error;
    }
  }
  async obterRequisicoes(id: string) {
    try {
      return await this.api.get(`${Endpoints.jobs}/${id}/requisicoes`);
    } catch (error) {
      throw error;
    }
  }
  async obterJobSimplificado(filtro: string) {
    try {
      if (filtro) {
        return await this.api.get(
          `${Endpoints.jobsSimplificado}?filtro=${filtro}`
        );
      }
      return await this.api.get(`${Endpoints.jobsSimplificado}`);
    } catch (error) {
      throw error;
    }
  }
  async obterOrcamento(id: string) {
    try {
      return await this.api.get(
        `${Endpoints.jobs}/${id}${Endpoints.orcamentoJob}`
      );
    } catch (error) {
      throw error;
    }
  }
  async cadastrarOrcamento(jobId: string, orcamento: OrcamentoDto) {
    try {
      return await this.api.post(
        `${Endpoints.jobs}/${jobId}${Endpoints.orcamentoJob}`,
        orcamento
      );
    } catch (error) {
      throw error;
    }
  }
  async atualizarOrcamento(jobId: string, orcamento: OrcamentoDto) {
    try {
      return await this.api.patch(
        `${Endpoints.jobs}/${jobId}${Endpoints.orcamentoJob}`,
        orcamento
      );
    } catch (error) {
      throw error;
    }
  }
}
