import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  PageHeader,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tag,
  message,
} from "antd";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  SearchOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { AtualizarContaBancariaDto } from "../../../services/conta-bancaria/dto/atualizar-conta-bancaria.dto";
import { ContaBancariaService } from "../../../services/conta-bancaria/conta-bancaria.service";
import CurrencyInput from "react-currency-input-field";
import { EmpresaService } from "../../../services/empresa/empresas.service";
import { Endpoints } from "../../../core/constants/endpoints";
import { FluxoDeCaixaService } from "../../../services/fluxo-de-caixa/fluxo-de-caixa.service";
import Loading from "../../../components/loading.component";
import currencyFormatter from "currency-formatter";
import moment from "moment";
import useContasBancariasHook from "../../../components/hooks/buscar-contas-bancarias.hook";
import useSWR from "swr";

export default function ContaBancaria() {
  const _empresaService = new EmpresaService();
  const _contaBancariaService = new ContaBancariaService();
  const { id } = useParams<any>();
  const { data: { data: { data: contaBancariaData } = {} } = {} } = useSWR(
    Endpoints.contasBancarias + id,
    () => _contaBancariaService.obterPorId(id),
    { revalidateOnFocus: false }
  );
  const [recarregarTransacoes, setRecarregarTransacoes] = useState(Date.now());

  const [formRef] = Form.useForm();
  const history = useHistory();
  const [optionsEmpresa, setOptionsEmpresa] = useState([]);
  const [optionsEmpresaSearch, setOptionsEmpresaSearch] = useState(false);
  const [optionsBancos, setOptionsBancos] = useState([]);
  const [optionsBancosSearch, setOptionsBancosSearch] = useState(false);
  const [exibirTransferencia, setExibirTransferencia] = useState(false);
  useEffect(() => {
    setOptionsBancos([
      {
        key: contaBancariaData?.banco?.id,
        label: contaBancariaData?.banco?.nome,
        value: contaBancariaData?.banco?.id,
      },
    ]);
    setOptionsEmpresa([
      {
        key: contaBancariaData?.empresa?.id,
        label: contaBancariaData?.empresa?.nomeFantasia,
        value: contaBancariaData?.empresa?.id,
      },
    ]);
  }, [contaBancariaData]);

  async function atualizarContaBancariaHandler() {
    try {
      const { agencia, conta, banco, empresa, limite, descricao } =
        formRef.getFieldsValue([
          "agencia",
          "conta",
          "banco",
          "empresa",
          "limite",
          "descricao",
        ]);

      const contaBancaria: AtualizarContaBancariaDto = {
        id: contaBancariaData?.id,
        descricao,
        agencia,
        conta,
        banco: { id: banco },
        empresa: { id: empresa },
        limite: currencyFormatter.unformat(limite, { code: "BRL" }),
      };

      await _contaBancariaService.atualizarContaBancaria(id, contaBancaria);
      message.success("Conta bancária Atualizada");
      history.goBack();
    } catch (error) {
      if (error?.response?.data?.message && typeof Array) {
        for (const e of error?.response?.data?.message.toString().split(",")) {
          message.error(e);
        }
      } else {
        message.error(error?.message);
      }
    }
  }

  function EfetuarTransferencia() {
    const { options: optsContasBancarias } = useContasBancariasHook();
    const [formTransferencia] = Form.useForm();

    async function efetuarTransferenciaHandler() {
      try {
        const {
          valor = 0,
          contaBancariaOrigem,
          contaBancariaDestino,
        } = formTransferencia.getFieldValue("transferencia");
        await _contaBancariaService.efetuarTransferencia(id, {
          valor,
          contaOrigem: contaBancariaOrigem,
          contaDestino: contaBancariaDestino,
        });
        message.success("Transferencia efetuada com sucesso");
        setExibirTransferencia(false);
        setRecarregarTransacoes(Date.now());
      } catch (error) {
        message.error(error?.response?.data?.message || error?.message);
      }
    }
    return (
      <Modal
        visible={exibirTransferencia}
        closable={false}
        bodyStyle={{ padding: 0 }}
        footer={null}
      >
        <Card title="Efetuar transferência" type="inner">
          <Form
            form={formTransferencia}
            layout="vertical"
            onFinish={() => efetuarTransferenciaHandler()}
          >
            <Input.Group compact>
              <Form.Item
                label="Conta bancária de origem"
                name={["transferencia", "contaBancariaOrigem"]}
                initialValue={contaBancariaData?.id}
                style={{ width: "50%", paddingRight: 3 }}
                rules={[{ required: true, message: "Campo obrigatório" }]}
              >
                <Select
                  size="middle"
                  options={optsContasBancarias}
                  placeholder="Selecione a conta bancária"
                  disabled
                />
              </Form.Item>
              <Form.Item
                name={["transferencia", "contaBancariaDestino"]}
                label="Conta bancária de destino"
                style={{ width: "50%" }}
                rules={[{ required: true, message: "Campo obrigatório" }]}
              >
                <Select
                  size="middle"
                  options={optsContasBancarias.filter(
                    (c) => c.key !== contaBancariaData.id
                  )}
                  placeholder="Selecione a conta bancária"
                />
              </Form.Item>
            </Input.Group>
            <Form.Item label="Valor da transferência">
              <CurrencyInput
                name="valor"
                placeholder="Valor"
                className="ant-input ant-input-md"
                decimalScale={2}
                max={contaBancariaData?.limite + contaBancariaData?.saldo}
                onValueChange={(value = "0") => {
                  formTransferencia.setFieldsValue({
                    transferencia: {
                      valor: currencyFormatter.unformat(value, {
                        code: "BRL",
                      }),
                    },
                  });
                }}
                intlConfig={{ locale: "pt-BR", currency: "BRL" }}
              />
            </Form.Item>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                size="middle"
                onClick={() => {
                  setExibirTransferencia(false);
                }}
                style={{ marginRight: 5 }}
              >
                Cancelar
              </Button>
              <Button
                type="primary"
                size="middle"
                htmlType="submit"
                icon={<SwapOutlined />}
              >
                transferir
              </Button>
            </div>
          </Form>
        </Card>
      </Modal>
    );
  }

  function Transacoes() {
    const [filtrosFormRef] = Form.useForm();
    const [query, setQuery] = useState(null);
    const _fluxoDeCaixaService = new FluxoDeCaixaService();

    useEffect(() => {
      generateQuery();
    }, []);

    const { data: { data: { data } = {} } = {} } = useSWR(
      Endpoints.fluxoDeCaixaTransacoes + recarregarTransacoes + query,
      () => _fluxoDeCaixaService.obterTransacoes(query),
      { revalidateOnFocus: false }
    );

    function generateQuery() {
      try {
        let { intervalo } = filtrosFormRef.getFieldsValue(["intervalo"]);
        if (!intervalo)
          intervalo = [
            moment(new Date()).subtract(60, "days").toDate(),
            moment(new Date()).add(1, "d").toDate(),
          ];

        const [dataInicio, dataFim] = intervalo;
        setQuery(
          `contas=${id}&intervalo=${dataInicio.toString()},${dataFim.toString()}`
        );
      } catch (error) {
        throw error;
      }
    }

    return (
      <div>
        <Card title="Filtros" size="small" type="inner">
          <Form layout="inline" form={filtrosFormRef}>
            <Form.Item>
              <Radio.Group
                defaultValue="1"
                style={{ marginRight: 5 }}
                onChange={(e) => {
                  filtrosFormRef.setFieldsValue({
                    intervalo: [
                      moment(new Date()),
                      moment(new Date()).add(e.target.value, "days"),
                    ],
                  });
                }}
              >
                {[
                  { label: "Diário", value: 1 },
                  { label: "Semanal", value: 7 },
                  { label: "Mensal", value: 30 },
                ].map(({ value, label }) => (
                  <Radio.Button value={value}>{label}</Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>
            <Form.Item name="intervalo">
              <DatePicker.RangePicker format="DD/MM/yyyy" />
            </Form.Item>
            <Button
              type="primary"
              icon={<SearchOutlined />}
              onClick={() => generateQuery()}
            />
          </Form>
        </Card>
        <Card
          title="Transações"
          size="small"
          type="inner"
          style={{ marginTop: 10 }}
          bodyStyle={{ padding: 0 }}
          extra={
            <>
              {`Saldo Atual: `}
              <a className="currency">
                {currencyFormatter.format(contaBancariaData?.saldo, {
                  code: "BRL",
                })}
              </a>
            </>
          }
        >
          <Table
            bordered
            loading={!data}
            dataSource={data?.transacoes}
            columns={[
              {
                title: "Tipo Transação",
                width: 50,
                render: (_, obj) =>
                  obj?.contaAReceber ? (
                    <Tag
                      className="transaction-up"
                      color="green"
                      icon={<CaretUpOutlined />}
                    >
                      {`CRÉDITO`}
                    </Tag>
                  ) : (
                    <Tag
                      className="transaction-down"
                      color="red"
                      icon={<CaretDownOutlined />}
                    >
                      {`DÉBITO`}
                    </Tag>
                  ),
              },

              {
                title: "Descrição",
                dataIndex: "descricao",
              },
              {
                title: "Valor",
                dataIndex: "valor",
                render: (value) =>
                  currencyFormatter.format(value, { code: "BRL" }),
              },
              {
                title: "Data Transação",
                dataIndex: "dataPagamento",
                render: (value) => moment(value).format("DD/MM/YYYY HH:mm"),
              },
            ]}
            size="small"
          />
        </Card>
      </div>
    );
  }
  return contaBancariaData ? (
    <div>
      <EfetuarTransferencia />
      <PageHeader
        className="site-page-header"
        onBack={() => {
          history.goBack();
        }}
        title={`${contaBancariaData?.descricao}`}
        style={{ marginBottom: 5 }}
      />

      <Card
        title="Informações da Conta Bancária"
        bodyStyle={{ padding: 5 }}
        extra={
          <Button
            type="primary"
            size="middle"
            onClick={() => setExibirTransferencia(true)}
            icon={<SwapOutlined />}
          >
            Transferências
          </Button>
        }
      >
        <Form
          form={formRef}
          onFinish={() => atualizarContaBancariaHandler()}
          layout="vertical"
        >
          <Card title="Geral" type="inner">
            <Row>
              <Col span={24}>
                <Form.Item
                  name="descricao"
                  label="Descrição"
                  initialValue={contaBancariaData?.descricao}
                >
                  <Input placeholder="Ex: Conta Bradesco" />
                </Form.Item>
                <Input.Group compact>
                  <Form.Item
                    initialValue={contaBancariaData?.banco?.id}
                    label="Banco"
                    rules={[
                      {
                        required: true,
                        message: "Banco é Obrigatório",
                      },
                    ]}
                    name="banco"
                    style={{ width: "25%", paddingRight: "5px" }}
                  >
                    <Select
                      placeholder="Selecione o Banco"
                      options={optionsBancos}
                      showSearch
                      loading={optionsBancosSearch}
                      filterOption={(input, option) =>
                        option?.label
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onSearch={async (value) => {
                        if (value.length >= 3) {
                          setOptionsBancosSearch(true);
                          const result =
                            await _contaBancariaService.obterBancos(value);
                          const opts = result?.data?.data.map((o) => {
                            return {
                              key: o.id,
                              value: o.id,
                              label: o.nome,
                            };
                          });
                          setOptionsBancos(opts);
                          setOptionsBancosSearch(false);
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    initialValue={contaBancariaData?.agencia}
                    label="Agência"
                    name="agencia"
                    style={{ width: "12.5%", paddingRight: 5 }}
                    rules={[
                      {
                        required: true,
                        message: "Agência é obrigatório",
                      },
                    ]}
                  >
                    <Input placeholder="Agência" />
                  </Form.Item>
                  <Form.Item
                    initialValue={contaBancariaData?.conta}
                    label="Conta"
                    rules={[
                      {
                        required: true,
                        message: "Conta é obrigatório",
                      },
                    ]}
                    name="conta"
                    style={{ width: "12.5%", paddingRight: 5 }}
                  >
                    <Input placeholder="Conta" />
                  </Form.Item>
                  <Form.Item
                    initialValue={contaBancariaData?.empresa?.id}
                    style={{ width: "37%", paddingRight: 5 }}
                    name="empresa"
                    label="Empresa"
                    rules={[{ required: true, message: "Selecione a empresa" }]}
                  >
                    <Select
                      placeholder="Selecione a empresa"
                      showSearch
                      options={optionsEmpresa}
                      loading={optionsEmpresaSearch}
                      filterOption={(input, option) =>
                        option?.label
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onSearch={async (value) => {
                        if (value.length >= 3) {
                          setOptionsEmpresaSearch(true);
                          const result =
                            await _empresaService.obterEmpresasSimplificado(
                              value
                            );
                          const opts = result?.data?.data.map((o) => {
                            return {
                              key: o.id,
                              value: o.id,
                              label: o.nomeFantasia,
                            };
                          });
                          setOptionsEmpresa(opts);
                          setOptionsEmpresaSearch(false);
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    initialValue={currencyFormatter.format(
                      Number(contaBancariaData?.limite),
                      {
                        code: "BRL",
                      }
                    )}
                    rules={[
                      {
                        required: true,
                        message: "Limite é obrigatório",
                      },
                    ]}
                    style={{ width: "12.5%" }}
                    name="limite"
                    label="Limite"
                  >
                    <Input
                      placeholder="Limite"
                      onBlur={(e) =>
                        formRef.setFieldsValue({
                          limite: currencyFormatter.format(
                            Number(
                              currencyFormatter.unformat(
                                formRef.getFieldValue("limite"),
                                { code: "BRL" }
                              )
                            ),
                            {
                              code: "BRL",
                            }
                          ),
                        })
                      }
                    />
                  </Form.Item>
                </Input.Group>
              </Col>
            </Row>
          </Card>
          <Card title="Transações" type="inner">
            <Transacoes />
          </Card>
          <Row>
            <Col span={24} style={{ marginTop: "10px", textAlign: "end" }}>
              <Space>
                <Button type="primary" size="middle" htmlType="submit">
                  SALVAR
                </Button>
                <Button
                  type="ghost"
                  size="middle"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  CANCELAR
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  ) : (
    <Loading />
  );
}

export const ContaBancariaRoute = {
  id: "conta-bancaria",
  path: "/inicio/financeiro/contas-bancarias/:id",
  sidebar: "Contas Bancárias",
  exact: true,
  renderInMenu: false,
  main: () => <ContaBancaria />,
  icon: null,
  permission: ContaBancaria,
};
