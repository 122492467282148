import { Button, Card, Col, Form, Modal, Row, Space, message } from "antd";

import EmailEditor from "../../../components/email-editor.component";
import { MailOutlined } from "@ant-design/icons";
import { RequisicaoDeCompraService } from "../../../services/requisicao-de-compra/requisicao-de-compra.service";
import { useEffect } from "react";

export default function AprovarRequisicaoDeComprarModal({
  requisicaoId = null,
  setRequisicaoId,
  defaultEmail = null,
  visible,
  setVisible,
}) {
  const _requisicaoDeCompraService = new RequisicaoDeCompraService();
  const [formRef] = Form.useForm();

  useEffect(() => {}, []);

  async function aprovarPedidoDeCompraHandler() {
    try {
      await _requisicaoDeCompraService.aprovarRequisicao(requisicaoId, {
        destinatarios: formRef.getFieldValue("emails"),
        mensagem: formRef.getFieldValue("message"),
      });
      message.success("Pedido de compra gerado!");
      setRequisicaoId(null);
      formRef.resetFields();
      setVisible(false);
      window.location.reload();
    } catch (error) {
      if (error?.response?.data?.message && typeof Array) {
        for (const e of error?.response?.data?.message.toString().split(",")) {
          message.error(e);
        }
      } else {
        message.error(error?.message);
      }
    }
  }

  return (
    <div>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        width={800}
        footer
        bodyStyle={{ padding: 0 }}
        closable={false}
      >
        <Form
          form={formRef}
          layout="vertical"
          onFinish={async () => await aprovarPedidoDeCompraHandler()}
        >
          <Card>
            <Form.Item name="email" label="Mensagem ao fornecedor">
              <EmailEditor form={formRef} defaultMail={defaultEmail} />
            </Form.Item>

            <Row>
              <Col span={18} />
              <Col
                span={6}
                style={{
                  marginTop: "10px",
                  paddingRight: 10,
                  textAlign: "end",
                }}
              >
                <Space>
                  <Button
                    type="primary"
                    size="middle"
                    htmlType="submit"
                    icon={<MailOutlined />}
                  >
                    ENVIAR
                  </Button>
                </Space>
              </Col>
            </Row>
          </Card>
        </Form>
      </Modal>
    </div>
  );
}
