export enum StatusPedidoDeCompra {
  EnviadoAoFornecedor = 0,
  RecebidoPeloFornecedor = 1,
  NfEmitida = 2,
  APagar = 3,
  Pago = 4,
  Cancelado = 5,
}

export class StatusPedidoDeCompraInfoItem {
  key: StatusPedidoDeCompra;
  value: StatusPedidoDeCompra;
  label: string;
  color: string;
}

export const StatusPedidoDeCompraInfo: {
  [key in StatusPedidoDeCompra]: StatusPedidoDeCompraInfoItem;
} = {
  [StatusPedidoDeCompra.EnviadoAoFornecedor]: {
    key: StatusPedidoDeCompra.EnviadoAoFornecedor,
    value: StatusPedidoDeCompra.EnviadoAoFornecedor,
    label: "ENVIADO AO FORNECEDOR",
    color: "darkblue",
  },
  [StatusPedidoDeCompra.RecebidoPeloFornecedor]: {
    key: StatusPedidoDeCompra.RecebidoPeloFornecedor,
    value: StatusPedidoDeCompra.RecebidoPeloFornecedor,
    label: "RECEBIDO PELO FORNECEDOR",
    color: "DodgerBlue",
  },
  [StatusPedidoDeCompra.NfEmitida]: {
    key: StatusPedidoDeCompra.NfEmitida,
    value: StatusPedidoDeCompra.NfEmitida,
    label: "NOTA FISCAL EMITIDA",
    color: "SlateBlue",
  },
  [StatusPedidoDeCompra.APagar]: {
    key: StatusPedidoDeCompra.APagar,
    value: StatusPedidoDeCompra.APagar,
    label: "A PAGAR",
    color: "default",
  },
  [StatusPedidoDeCompra.Pago]: {
    key: StatusPedidoDeCompra.Pago,
    value: StatusPedidoDeCompra.Pago,
    label: "PAGO",
    color: "green",
  },

  [StatusPedidoDeCompra.Cancelado]: {
    key: StatusPedidoDeCompra.Cancelado,
    value: StatusPedidoDeCompra.Cancelado,
    label: "CANCELADO",
    color: "LightGray",
  },
};

function enumToArray(enumerator: any) {
  const list = [];
  for (let index = 0; index < Object.values(enumerator).length; index++) {
    const obj = Object.values(enumerator)[index];
    list.push(obj);
  }
  return list;
}

export const statusPedidoDeCompraList = enumToArray(StatusPedidoDeCompraInfo);
