export enum TipoReembolso {
  QuilometroRodado = 0,
  NotaFiscal = 1,
}

export class TipoReembolsoInfo {
  key: number;
  value: number;
  label: string;
  cor: string;
}

export const TipoReembolsoDescricao: {
  [key in TipoReembolso]: TipoReembolsoInfo;
} = {
  [TipoReembolso.QuilometroRodado]: {
    key: TipoReembolso.QuilometroRodado,
    value: TipoReembolso.QuilometroRodado,
    cor: "default",
    label: "QUILOMETRO RODADO",
  },
  [TipoReembolso.NotaFiscal]: {
    key: TipoReembolso.NotaFiscal,
    value: TipoReembolso.NotaFiscal,
    cor: "default",
    label: "NOTA FISCAL",
  },
};

function enumToArray(enumerator: any) {
  const list = [];
  for (let index = 0; index < Object.values(enumerator).length; index++) {
    const obj = Object.values(enumerator)[index];

    list.push(obj);
  }
  return list;
}
export const TipoReembolsoList = enumToArray(TipoReembolsoDescricao);
