import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  PageHeader,
  Row,
  Space,
  TreeSelect,
  message,
} from "antd";
import React, { useEffect, useState } from "react";

import { GrupoFinanceiroService } from "../../../services/grupo-financeiro/grupo-financeiro.service";
import { NovoGrupoFinanceiroDto } from "../../../services/grupo-financeiro/dto/novo-grupo-financeiro.dto";

export default function NovoGrupoFinanceiro({ visible, setVisible, tipo }) {
  const _grupoFinanceiroService = new GrupoFinanceiroService();
  const [optsGruposPai, setOptsGruposPai] = useState([]);
  const [formRef] = Form.useForm();

  useEffect(() => {
    _grupoFinanceiroService.obterTodos().then(({ data: { data: grupos } }) => {
      const convertGroups = grupos
        .filter((g) => g.grupo.includes(tipo.toUpperCase()))
        .map((g) => {
          const childs = g?.gruposFilhos?.map((gf) => {
            return {
              key: gf.id,
              value: gf.id,
              label: `${g.grupo} › ${gf.grupo}`,
            };
          });
          return {
            key: g.id,
            value: g.id,
            label: g.grupo,
            children: childs,
          };
        });

      setOptsGruposPai(convertGroups);
    });
  }, []);

  async function adicionarGrupoFinanceiroHandler() {
    try {
      const grupoFinanceiro = formRef.getFieldValue("grupoFinanceiro");
      const dto: NovoGrupoFinanceiroDto = {
        grupo: grupoFinanceiro?.grupo,
        grupoPai: grupoFinanceiro?.grupoPai,
      };
      await _grupoFinanceiroService.adicionarGrupoFinanceiro(dto);
      message.success("Grupo financeiro adicionado");
      setVisible(false);
    } catch (error) {
      if (error?.response?.data?.message && typeof Array) {
        for (const e of error?.response?.data?.message.toString().split(",")) {
          message.error(e);
        }
      } else {
        message.error(error?.message);
      }
    }
  }

  return (
    <Drawer
      width={900}
      visible={visible}
      closable={false}
      bodyStyle={{ padding: 5 }}
    >
      <PageHeader
        className="site-page-header"
        onBack={() => {
          setVisible(false);
        }}
        title={`Novo grupo financeiro`}
        subTitle="Incluir novo grupo financeiro"
        style={{ marginBottom: 10 }}
      />
      <Card title="Informações Grupo Financeiro" type="inner">
        <Form form={formRef} onFinish={() => adicionarGrupoFinanceiroHandler()}>
          <Row>
            <Col span={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Selecione o grupo pai",
                  },
                ]}
                name={["grupoFinanceiro", "grupoPai"]}
                style={{ paddingRight: 5 }}
              >
                <TreeSelect
                  placeholder="Selecione o Grupo Pai"
                  treeData={optsGruposPai}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={["grupoFinanceiro", "grupo"]}
                rules={[
                  {
                    required: true,
                    message: "Informe o nome do grupo",
                  },
                ]}
              >
                <Input placeholder="Nome do grupo" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={18} />
            <Col span={6} style={{ marginTop: "15px", textAlign: "end" }}>
              <Space>
                <Button type="primary" size="middle" htmlType="submit">
                  SALVAR
                </Button>
                <Button
                  type="ghost"
                  size="middle"
                  onClick={() => {
                    setVisible(false);
                    formRef.resetFields();
                  }}
                >
                  CANCELAR
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Card>
    </Drawer>
  );
}
