import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  PageHeader,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Upload,
  message,
} from "antd";
import { PlusCircleOutlined, UploadOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import {
  TipoReembolso,
  TipoReembolsoDescricao,
  TipoReembolsoList,
} from "../../../core/enums/tipo-reembolso.enum";

import { ColaboradorService } from "../../../services/colaborador/colaborador.service";
import { Endpoints } from "../../../core/constants/endpoints";
import { NovoReembolsoDto } from "../../../services/reembolso/dto/novo-reembolso.dto";
import { ReembolsoService } from "../../../services/reembolso/reembolso.service";
import currencyFormatter from "currency-formatter";
import useBuscarJobHook from "../../../components/hooks/buscar-job.hook";
import useSWR from "swr";

export default function SolicitarReembolsoModal({
  job = null,
  visible = false,
  setVisible,
}) {
  const [formRef] = Form.useForm();
  const [exibirCamposKmRodado, setExibirCamposKmRodado] = useState(false);
  const { data: { data: { data: user } = {} } = {} } = useSWR(
    Endpoints.colaboradoresInfo,
    () => _colaboradorService.obterInfoUsuario(),
    { revalidateOnFocus: false }
  );
  const [fileList, setFileList] = useState([]);
  const [itens, setItens] = useState([]);
  const {
    options: optsJob,
    buscarJob,
    loading: loadingJobs,
    filterOption: filterOptionsJobs,
  } = useBuscarJobHook();
  const _reembolsoService = new ReembolsoService();
  const _colaboradorService = new ColaboradorService();

  async function solicitarReembolsoHandler() {
    try {
      if (itens.length <= 0)
        throw new Error("Adicione os itens antes de solicitar o reembolso");
      const dto: NovoReembolsoDto = { itens, solicitante: { id: user?.id } };
      console.log(JSON.stringify(dto, null, 3));
      await _reembolsoService.solicitarReembolso(dto);
      message.success("Reembolso solicitado");
      setItens([]);
      setVisible(false);
    } catch (error) {
      message.error(error?.message);
    }
  }

  async function uploadComprovantes(file) {
    setFileList([
      ...fileList,
      {
        uid: file.uid,
        name: file.name,
        status: "uploading",
        url: null,
      },
    ]);
    const form = new FormData();
    form.append("arquivo", file);
    const res = await _reembolsoService.carregarAnexos(form);
    const { url, mimetype } = res?.data?.data;
    const list = fileList.filter((f) => f.uid !== file.uid);
    list.push({
      uid: file.uid,
      name: file.name,
      status: "done",
      url: url,
      mime: mimetype,
    });
    setFileList(list);
  }

  async function adicionarItemHandler() {
    try {
      const {
        job,
        valor,
        tipo,
        quantidadeKm = 0,
        enderecoInicial = null,
        enderecoFinal = null,
      } = formRef.getFieldValue("reembolso");

      const jobSelecionado = optsJob.filter((j) => j.value === job).shift();

      const item = {
        key: Number(itens.length + 1),
        job: {
          id: job,
          nome: String(jobSelecionado.label).split("-")[0].trim(),
        },
        tipo,
        valor: currencyFormatter.unformat(valor, {
          code: "BRL",
        }),
        quantidadeKm: Number(quantidadeKm),
        enderecoInicial,
        enderecoFinal,
        comprovantes: fileList.map((f) => {
          return { filename: f.name, url: f.url, mime: f.mime };
        }),
      };
      setItens([...itens, item]);
      formRef.resetFields();
      setFileList([]);
      setExibirCamposKmRodado(false);
    } catch (error) {
      throw error;
    }
  }

  return (
    <Drawer
      visible={visible}
      closable={false}
      onClose={() => setVisible(false)}
      width={950}
      footer
      bodyStyle={{ padding: 5 }}
    >
      <PageHeader
        className="site-page-header"
        title="Solicitar Reembolso"
        onBack={() => setVisible(false)}
      />

      <Card title="Informações do reembolso">
        <Row>
          <Col span={24}>
            <Form
              form={formRef}
              layout="vertical"
              onFinish={() => adicionarItemHandler()}
            >
              <Input.Group compact>
                <Form.Item
                  label="Tipo"
                  name={["reembolso", "tipo"]}
                  style={{ width: "30%", paddingRight: 5 }}
                  rules={[{ required: true, message: "Informe o tipo" }]}
                >
                  <Select
                    placeholder="Tipo reembolso"
                    options={TipoReembolsoList}
                    onChange={(value) => {
                      if (value === TipoReembolso.QuilometroRodado) {
                        setExibirCamposKmRodado(true);
                      } else {
                        setExibirCamposKmRodado(false);
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Job"
                  name={["reembolso", "job"]}
                  rules={[{ required: true, message: "Informe o Job" }]}
                  style={{ width: "45%", paddingRight: 5 }}
                >
                  <Select
                    placeholder="Job"
                    style={{ width: "100%" }}
                    options={optsJob}
                    showSearch
                    onSearch={buscarJob}
                    filterOption={filterOptionsJobs}
                    loading={loadingJobs}
                  />
                </Form.Item>
                <Form.Item
                  name={["reembolso", "valor"]}
                  rules={[
                    { required: true, message: "Informe o valor do reembolso" },
                  ]}
                  label="Valor"
                  style={{ width: "25%" }}
                >
                  <Input
                    placeholder="Valor"
                    onBlur={(e) =>
                      formRef.setFieldsValue({
                        reembolso: {
                          valor: currencyFormatter.format(+e.target.value, {
                            code: "BRL",
                          }),
                        },
                      })
                    }
                  />
                </Form.Item>

                <Form.Item
                  name={["reembolso", "quantidadeKm"]}
                  rules={[
                    {
                      required: exibirCamposKmRodado,
                      message: "Informe a quantidade de KMs",
                    },
                  ]}
                  label="Qtd. KMs"
                  style={{
                    width: "10%",
                    paddingRight: 5,
                    display: exibirCamposKmRodado ? "inline-flex" : "none",
                  }}
                  initialValue={0}
                >
                  <Input type="number" />
                </Form.Item>
                <Form.Item
                  name={["reembolso", "enderecoInicial"]}
                  label="Endereço Inicial"
                  style={{
                    width: "45%",
                    paddingRight: 5,
                    display: exibirCamposKmRodado ? "inline-flex" : "none",
                  }}
                >
                  <Input placeholder="Informe o endereço" />
                </Form.Item>
                <Form.Item
                  name={["reembolso", "enderecoFinal"]}
                  label="Endereço Final"
                  style={{
                    width: "45%",
                    display: exibirCamposKmRodado ? "inline-flex" : "none",
                  }}
                >
                  <Input placeholder="Informe o endereço" />
                </Form.Item>
              </Input.Group>

              <Upload
                fileList={fileList}
                customRequest={(opts) => uploadComprovantes(opts.file)}
                type="drag"
                className="upload-list-inline"
                style={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <Button type="link" icon={<UploadOutlined />} size="middle">
                  CARREGAR COMPROVANTES
                </Button>
              </Upload>
              <Button
                className="btn-success"
                icon={<PlusCircleOutlined />}
                size="middle"
                htmlType="submit"
                style={{ width: "100%", marginTop: 10 }}
              >
                ADICIONAR ITEM
              </Button>
            </Form>
          </Col>
        </Row>
        <Card
          title="Itens"
          type="inner"
          style={{ marginTop: 10 }}
          bodyStyle={{ padding: 0 }}
        >
          <Table
            bordered
            dataSource={itens}
            columns={[
              {
                title: "Job",
                dataIndex: "job",
                render: (value) => value?.nome,
              },
              {
                title: "Valor",
                dataIndex: "valor",
                className: "currency",
                render: (value) =>
                  currencyFormatter.format(value, { code: "BRL" }),
              },
              {
                title: "Tipo",
                dataIndex: "tipo",
                render: (value) => (
                  <Tag>{TipoReembolsoDescricao[value].label}</Tag>
                ),
              },
              {
                title: "Qtd. KMs",
                dataIndex: "quantidadeKm",
                render: (value) => (value ? value : "N/A"),
              },
              {
                title: "Endereço Inicial",
                dataIndex: "enderecoInicial",
                render: (value) => (value ? value : "N/A"),
              },
              {
                title: "Endereço Final",
                dataIndex: "enderecoFinal",
                render: (value) => (value ? value : "N/A"),
              },
              {
                title: "Ações",
                render: (_, record) => (
                  <Button
                    type="link"
                    style={{ color: "red" }}
                    onClick={() => {
                      const itensBackup = itens.filter(
                        (i) => i.key !== record?.key
                      );
                      setItens(itensBackup);
                    }}
                  >
                    Remover
                  </Button>
                ),
              },
            ]}
          />
        </Card>
        <Row>
          <Col span={15} />
          <Col span={4} style={{ marginTop: "30px", textAlign: "end" }}>
            <Space>
              <Button
                type="primary"
                size="middle"
                onClick={() => solicitarReembolsoHandler()}
              >
                SOLICITAR REEMBOLSO
              </Button>
              <Button
                type="ghost"
                size="middle"
                onClick={() => {
                  setVisible(false);
                  formRef.resetFields();
                }}
              >
                CANCELAR
              </Button>
            </Space>
          </Col>
        </Row>
      </Card>
    </Drawer>
  );
}
