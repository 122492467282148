import {
  Button,
  Card,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  PageHeader,
  Result,
  Row,
  Select,
  Steps,
  Table,
  message,
} from "antd";
import { Link, useHistory } from "react-router-dom";
import React, { useState } from "react";

import { ColaboradorService } from "../../../services/colaborador/colaborador.service";
import { Endpoints } from "../../../core/constants/endpoints";
import { JobService } from "../../../services/job/job.service";
import { NovoJobDto } from "../../../services/job/dto/novo-job.dto";
import { cepFormatter } from "../../../core/utils/formatters/cep.formatter";
import { identificadorFormatter } from "../../../core/utils/formatters/job.formatter";
import { v4 as newId } from "uuid";
import { statusJobArray } from "../../../core/enums/status-job.enum";
import useBuscarClienteHook from "../../../components/hooks/buscar-cliente.hook";
import useBuscarEmpresaHook from "../../../components/hooks/buscar-empresa.hook";
import useSWR from "swr";

const cep = require("cep-promise");

const _colaboradorService = new ColaboradorService();

export default function NovoJob({ visible, setVisible }) {
  const { data: { data: { data: user } = {} } = {} } = useSWR(
    Endpoints.colaboradoresInfo,
    () => _colaboradorService.obterInfoUsuario(),
    {
      revalidateOnFocus: false,
    }
  );
  const [job, setJob] = useState(null);
  const [handleCadastroJob, setHandleCadastroJob] = useState(false);
  const [current, setCurrent] = useState(0);
  const [formRef] = Form.useForm();
  const { Step } = Steps;

  function InformacoesGerais() {
    const {
      options: optionsCliente,
      setOptions: setOptionsCliente,
      buscarCliente,
      filterOptions: filterOptsCliente,
      loading: optionsClienteSearch,
    } = useBuscarClienteHook();
    const {
      options: optionsEmpresa,

      loading: optionsEmpresaSearch,
      buscarEmpresa,
      filterOptions: filterOptionsEmpresa,
    } = useBuscarEmpresaHook();

    return (
      <div>
        <Card title="Geral" type="inner">
          <Row>
            <Col span={24} className="col-input-entry">
              <Input.Group compact>
                <Form.Item
                  style={{ width: "60%", paddingRight: "5px" }}
                  name="nome"
                  label="Job"
                  rules={[
                    {
                      required: true,
                      message: "Defina o nome do Job",
                    },
                  ]}
                >
                  <Input placeholder="Ex: Evento especial fim de ano" />
                </Form.Item>
                <Form.Item
                  name="status"
                  label="Status"
                  style={{ width: "20%", paddingRight: "5px" }}
                  rules={[
                    {
                      required: true,
                      message: "Selecione o status do Job",
                    },
                  ]}
                >
                  <Select
                    placeholder="Selecione o status"
                    options={statusJobArray}
                  />
                </Form.Item>
                <Form.Item
                  name="dataDeCompetencia"
                  label="Competência do job"
                  style={{ width: "20%" }}
                  rules={[
                    {
                      required: true,
                      message: "Defina a data de competência",
                    },
                  ]}
                >
                  <DatePicker
                    disabledDate={(date) => {
                      const now = new Date();
                      return (
                        date.toDate().getMonth() < now.getMonth() &&
                        date.toDate().getFullYear() === now.getFullYear()
                      );
                    }}
                    placeholder="Competência"
                    format="MM/YYYY"
                    style={{ width: "100%" }}
                    picker="month"
                  />
                </Form.Item>

                <Form.Item
                  name="empresa"
                  label="Empresa"
                  style={{ width: "50%", paddingRight: "5px" }}
                  rules={[{ required: true, message: "Defina a empresa" }]}
                >
                  <Select
                    placeholder="Selecione a empresa"
                    showSearch
                    options={optionsEmpresa}
                    loading={optionsEmpresaSearch}
                    filterOption={filterOptionsEmpresa}
                    onSearch={buscarEmpresa}
                  />
                </Form.Item>
                <Form.Item
                  name="cliente"
                  label="Cliente"
                  style={{ width: "50%" }}
                  rules={[
                    {
                      required: true,
                      message: "Selecione o cliente",
                    },
                  ]}
                >
                  <Select
                    placeholder="Buscar  Cliente"
                    showSearch
                    options={optionsCliente}
                    loading={optionsClienteSearch}
                    filterOption={filterOptsCliente}
                    onSearch={buscarCliente}
                  />
                </Form.Item>
              </Input.Group>
            </Col>
          </Row>
        </Card>
        <Card title="Local do Evento" type="inner">
          <Row>
            <Col span={24} className="col-input-entry">
              <Input.Group compact>
                <Form.Item
                  name="cidade"
                  label="Cidade"
                  rules={[{ required: true, message: "Defina a cidade" }]}
                  style={{ width: "15%", paddingRight: "5px" }}
                >
                  <Input placeholder="Cidade" />
                </Form.Item>
                <Form.Item
                  name="estado"
                  label="Estado"
                  rules={[{ required: true, message: "Defina o estado" }]}
                  style={{ width: "10%", paddingRight: "5px" }}
                >
                  <Input placeholder="Estado" />
                </Form.Item>
                <Form.Item
                  name="cep"
                  label="CEP"
                  style={{ width: "10%", paddingRight: "5px" }}
                  rules={[
                    {
                      max: 9,
                      min: 9,
                      pattern: /^\d{5}-\d{3}$/g,
                    },
                  ]}
                >
                  <Input
                    placeholder="00000-000"
                    maxLength={9}
                    onChange={(event) => {
                      const cepValue = event.target.value;

                      formRef.setFieldsValue({
                        cep: cepFormatter(event.target.value),
                      });
                      if (cepValue.replace("-", "").length >= 8) {
                        cep(cepValue).then((response) => {
                          formRef.setFieldsValue({
                            estado: response.state,
                            logradouro: response.street,
                            bairro: response.neighborhood,
                            cidade: response.city,
                          });
                        });
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="logradouro"
                  label="Rua"
                  style={{ width: "45%", paddingRight: "5px" }}
                >
                  <Input placeholder="Rua" />
                </Form.Item>
                <Form.Item
                  name="numero"
                  label="Número"
                  style={{ width: "10%", paddingRight: "5px" }}
                >
                  <Input placeholder="Número" type="number" />
                </Form.Item>
                <Form.Item
                  name="bairro"
                  label="Bairro"
                  style={{ width: "10%", paddingRight: "5px" }}
                >
                  <Input placeholder="Bairro" />
                </Form.Item>
              </Input.Group>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }

  function DefinirEquipe() {
    const [optionsGerente, setOptionsGerente] = useState([
      {
        key: user.id,
        value: user.id,
        label: user.nome,
      },
    ]);
    formRef.setFieldsValue({ gerente: user?.id });
    const [optionsGerenteSearch, setOptionsGerenteSearch] = useState(false);
    const [optionsEquipe, setOptionsEquipe] = useState([
      {
        key: user.id,
        value: user.id,
        label: user.nome,
      },
    ]);
    formRef.setFieldsValue({
      equipe: [
        {
          key: user.id,
          value: user.id,
          label: user.nome,
        },
      ],
    });
    const [optionsEquipeSearch, setOptionsEquipeSearch] = useState(false);
    const [participantes, setParticipantes] = useState<any>([
      {
        key: user.id,
        value: user.id,
        label: user.nome,
      },
    ]);

    const [searchColaboradoresValue, setSearchColaboradoresValue] = useState(
      []
    );
    return (
      <Card title="Equipe e Empresa" type="inner">
        <Row>
          <Col span={24} className="col-input-entry">
            <Form.Item
              name="gerente"
              label="Responsável"
              initialValue={user?.id}
              rules={[
                {
                  required: true,
                  message: "Defina o responsável do Job",
                },
              ]}
            >
              <Select
                placeholder="Selecione o responsável"
                showSearch
                options={optionsGerente}
                loading={optionsGerenteSearch}
                onSelect={(_, option) => {
                  setParticipantes([...participantes, option]);
                  formRef.setFieldsValue({
                    equipe: [...participantes, option],
                  });
                }}
                filterOption={(input, option) =>
                  option?.label
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onSearch={async (value) => {
                  if (value.length >= 3) {
                    setOptionsGerenteSearch(true);
                    const result = await _colaboradorService.obterSimplificado(
                      value
                    );
                    const opts = result?.data?.data.map((o) => {
                      return {
                        key: o.id,
                        value: o.id,
                        label: o.nome,
                      };
                    });
                    setOptionsGerente(opts);
                    setOptionsGerenteSearch(false);
                  }
                }}
              />
            </Form.Item>
          </Col>

          <Col span={24} className="col-input-entry">
            <Form.Item name="equipe" label="Selecione os participantes">
              <Card
                title="Participantes"
                type="inner"
                bodyStyle={{ padding: 0 }}
                extra={
                  <Select
                    placeholder="Buscar Colaboradores"
                    value={searchColaboradoresValue}
                    style={{ width: "250px" }}
                    mode="multiple"
                    showSearch
                    options={optionsEquipe}
                    loading={optionsEquipeSearch}
                    filterOption={(input, option) =>
                      option?.label
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onSearch={async (value) => {
                      if (value.length >= 3) {
                        setOptionsEquipeSearch(true);
                        const result =
                          await _colaboradorService.obterSimplificado(value);
                        const opts = result?.data?.data.map((o) => {
                          return {
                            key: o.id,
                            value: o.id,
                            label: o.nome,
                          };
                        });
                        setOptionsEquipe(opts);
                        setOptionsEquipeSearch(false);
                      }
                    }}
                    onSelect={(value, option) => {
                      setParticipantes([...participantes, option]);
                      setSearchColaboradoresValue([]);
                      setOptionsEquipe([]);
                      formRef.setFieldsValue({
                        equipe: [...participantes, option],
                      });
                    }}
                  />
                }
              >
                <Table
                  pagination={false}
                  dataSource={participantes}
                  columns={[
                    { title: "Colaborador", dataIndex: "label" },
                    {
                      title: "Ação",
                      width: 120,
                      render: (_, obj) => (
                        <Button size="small" type="link">
                          Remover
                        </Button>
                      ),
                    },
                  ]}
                  size="small"
                  bordered
                />
              </Card>
            </Form.Item>
          </Col>
        </Row>
      </Card>
    );
  }

  function getFormValues(ref) {
    return (
      ref.getFieldsValue([
        "empresa",
        "nome",
        "cliente",
        "gerente",
        "status",
        "descricao",
        "equipe",
        "cep",
        "logradouro",
        "complemento",
        "dataDeCompetencia",
        "numero",
        "bairro",
        "estado",
        "cidade",
      ]) || {}
    );
  }
  const history = useHistory();
  const steps = [
    {
      key: newId(),
      title: "Geral",
      content: <InformacoesGerais />,
    },
    {
      key: newId(),
      title: "Informações Complementares",
      content: <DefinirEquipe />,
    },
  ];
  return (
    <Drawer
      visible={visible}
      width={1000}
      closable={false}
      bodyStyle={{ padding: 5 }}
      destroyOnClose
      onClose={() => {
        setVisible(false);
        formRef.resetFields();
      }}
    >
      <div>
        <PageHeader
          className="site-page-header"
          onBack={() => {
            setVisible(false);
            formRef.resetFields();
          }}
          title="Novo Job"
          subTitle="Adicionar novo job"
        />

        <Card
          title="Informações do Job"
          style={{ marginTop: 5 }}
          bodyStyle={{ padding: 0 }}
        >
          {job ? (
            <Result
              status="success"
              title={`Job ${identificadorFormatter(
                job?.identificador
              )} foi cadastrado com sucesso!`}
              extra={[
                <Button
                  type="primary"
                  key="jobs"
                  size="middle"
                  onClick={() => {
                    history.push("/inicio/atendimento/jobs");
                    setVisible(false);
                    setJob(null);
                    setCurrent(0);
                  }}
                >
                  ACESSAR JOBS
                </Button>,
              ]}
            />
          ) : (
            <Steps current={current} type="navigation" size="small">
              {steps.map((item) => (
                <Step key={item.key} title={item.title} />
              ))}
            </Steps>
          )}
          <Form
            form={formRef}
            layout="vertical"
            name="novoJob"
            onFinish={async () => {
              try {
                setHandleCadastroJob(true);
                const _jobService = new JobService();
                const {
                  nome,
                  descricao,
                  status,
                  dataDeCompetencia,
                  cep,
                  logradouro,
                  estado,
                  cidade,
                  numero,
                  bairro,
                  complemento,
                  cliente,
                  gerente,
                  empresa,
                  equipe,
                } = getFormValues(formRef);

                const jobData: NovoJobDto = {
                  nome,
                  descricao,
                  status,
                  dataDeCompetencia: dataDeCompetencia.toString(),
                  cliente: {
                    id: cliente,
                  },
                  gerente: {
                    id: gerente,
                  },
                  empresa: {
                    id: empresa,
                  },
                  endereco: {
                    cep,
                    bairro,
                    logradouro,
                    estado,
                    cidade,
                    numero,
                    complemento,
                    pais: "BR",
                  },
                };

                if (equipe.length > 0) {
                  jobData.equipe = {
                    nome: `${jobData.nome} Equipe`,
                    colaboradores: equipe.map((c) => {
                      return { id: c.key, nome: c.label };
                    }),
                  };
                }

                const response = await _jobService.cadastrarJob(jobData);
                setJob(response?.data?.data);
                formRef.resetFields();
                setHandleCadastroJob(false);
              } catch (error) {
                setHandleCadastroJob(false);

                message.error(error?.response?.data?.message);
              }
            }}
          >
            {!job && (
              <div className="steps-content">{steps[current].content}</div>
            )}
            {!job && (
              <div className="steps-action">
                {current < steps.length - 1 && (
                  <Button
                    size="middle"
                    type="primary"
                    onClick={async () => {
                      await formRef.validateFields();

                      setCurrent(current + 1);
                    }}
                  >
                    Próximo
                  </Button>
                )}
                {current === steps.length - 1 &&
                  (current == 4 ? (
                    <Button type="primary" size="middle">
                      <Link to="/app/main/notifications/">Concluir</Link>
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="middle"
                      loading={handleCadastroJob}
                    >
                      {handleCadastroJob
                        ? "Cadastrando..."
                        : "Concluir Cadastro"}
                    </Button>
                  ))}
                {current === 0 ? null : (
                  <Button
                    size="middle"
                    style={{ margin: "0 8px" }}
                    onClick={() => setCurrent(current - 1)}
                  >
                    Voltar
                  </Button>
                )}
              </div>
            )}
          </Form>
        </Card>
      </div>
    </Drawer>
  );
}
