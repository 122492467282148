import { AtualizarContaAPagarDto } from "./dto/atualizar-conta-a-pagar.dto";
import { AtualizarParcelaDto } from "./dto/atualizar-parcela.dto";
import { BaseService } from "../base.service";
import { Endpoints } from "../../core/constants/endpoints";
import { GerarParcelamentoDto } from "./dto/gerar-parcelamento.dto";
import { NovaContaAPagarDto } from "./dto/nova-conta-a-pagar.dto";
import { RegistrarPagamentoContaAPagarDto } from "./dto/registrar-pagamento-conta-a-pagar.dto";

export class ContaAPagarService extends BaseService {
  async obterTodas(filtro: string = "") {
    try {
      return await this.api.get(`${Endpoints.contasAPagar}?${filtro}`);
    } catch (error) {
      throw error;
    }
  }
  async obterInformacoesRelatorio(filtro: string = "") {
    try {
      return await this.api.download(
        `${Endpoints.contasAPagar}/relatorio?${filtro}`
      );
    } catch (error) {
      throw error;
    }
  }

  async registrarPagamento(id: string, dto: RegistrarPagamentoContaAPagarDto) {
    try {
      return await this.api.post(
        `${Endpoints.contasAPagarRegistrarPagamento}/${id}`,
        dto
      );
    } catch (error) {
      throw error;
    }
  }
  async estornarPagamento(id: string) {
    try {
      return await this.api.patch(
        `${Endpoints.contasAPagarEstornarPagamento}/${id}`
      );
    } catch (error) {
      throw error;
    }
  }
  async atualizarParcela(id: string, dto: AtualizarParcelaDto) {
    try {
      return await this.api.patch(
        `${Endpoints.contasAPagar}/${id}${Endpoints.atualizarParcela}`,
        dto
      );
    } catch (error) {
      throw error;
    }
  }
  async obterPorId(id: string) {
    try {
      return await this.api.get(`${Endpoints.contasAPagar}/${id}`);
    } catch (error) {
      throw error;
    }
  }
  async obterInformacaoBancariaFornecedor(id: string) {
    try {
      return await this.api.get(`${Endpoints.contasAPagar}/${id}/fornecedor`);
    } catch (error) {
      throw error;
    }
  }
  async atualizarContaAPagar(id: string, dto: AtualizarContaAPagarDto) {
    try {
      return await this.api.patch(`${Endpoints.contasAPagar}/${id}`, dto);
    } catch (error) {
      throw error;
    }
  }
  async estornar(id: string) {
    try {
      return await this.api.patch(
        `${Endpoints.contasAPagar}/${id}${Endpoints.estornar}`
      );
    } catch (error) {
      throw error;
    }
  }
  async obterParcelas(id: string) {
    try {
      return await this.api.get(`${Endpoints.contasAPagar}/${id}/parcelas`);
    } catch (error) {
      throw error;
    }
  }
  async adicionarContaAPagar(dto: NovaContaAPagarDto) {
    try {
      return await this.api.post(Endpoints.contasAPagar, dto);
    } catch (error) {
      throw error;
    }
  }
  async gerarParcelamento(dto: GerarParcelamentoDto) {
    try {
      return await this.api.post(Endpoints.gerarParcelamentoContaAPagar, dto);
    } catch (error) {
      throw error;
    }
  }
}
