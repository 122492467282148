export enum StatusRequisicaoCompra {
  EnviadoAoFinanceiro = 0,
  EmAnalise = 1,
  Aprovado = 2,
  Reprovado = 3,
  FornecedorNaoVerificado = 4,
  Cancelada = 5,
}

export class StatusRequisicaoDeCompraInfoItem {
  key: StatusRequisicaoCompra;
  value: StatusRequisicaoCompra;
  label: string;
  color: string;
}

export const StatusRequisicaoDeCompraInfo: {
  [key in StatusRequisicaoCompra]: StatusRequisicaoDeCompraInfoItem;
} = {
  [StatusRequisicaoCompra.EnviadoAoFinanceiro]: {
    key: StatusRequisicaoCompra.EnviadoAoFinanceiro,
    value: StatusRequisicaoCompra.EnviadoAoFinanceiro,
    label: "ENVIADO AO FINANCEIRO",
    color: "blue",
  },
  [StatusRequisicaoCompra.EmAnalise]: {
    key: StatusRequisicaoCompra.EmAnalise,
    value: StatusRequisicaoCompra.EmAnalise,
    label: "EM ANÁLISE",
    color: "yellow",
  },
  [StatusRequisicaoCompra.Aprovado]: {
    key: StatusRequisicaoCompra.Aprovado,
    value: StatusRequisicaoCompra.Aprovado,
    label: "EM ANÁLISE",
    color: "green",
  },
  [StatusRequisicaoCompra.Aprovado]: {
    key: StatusRequisicaoCompra.Aprovado,
    value: StatusRequisicaoCompra.Aprovado,
    label: "APROVADO",
    color: "green",
  },
  [StatusRequisicaoCompra.Reprovado]: {
    key: StatusRequisicaoCompra.Reprovado,
    value: StatusRequisicaoCompra.Reprovado,
    label: "REPROVADO",
    color: "red",
  },
  [StatusRequisicaoCompra.FornecedorNaoVerificado]: {
    key: StatusRequisicaoCompra.FornecedorNaoVerificado,
    value: StatusRequisicaoCompra.FornecedorNaoVerificado,
    label: "FORNECEDOR NÃO VERIFICADO",
    color: "Salmon",
  },
  [StatusRequisicaoCompra.Cancelada]: {
    key: StatusRequisicaoCompra.Cancelada,
    value: StatusRequisicaoCompra.Cancelada,
    label: "CANCELADA",
    color: "default",
  },
};

function enumToArray(enumerator: any) {
  const list = [];
  for (let index = 0; index < Object.values(enumerator).length; index++) {
    const obj = Object.values(enumerator)[index];

    list.push(obj);
  }
  return list;
}

export const statusRequisicaoDeCompraList = enumToArray(
  StatusRequisicaoDeCompraInfo
);
