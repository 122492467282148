import {
  Button,
  Card,
  PageHeader,
  Popconfirm,
  Table,
  Tabs,
  Tag,
  message,
} from "antd";
import { DollarCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import React, { useState } from "react";

import { Endpoints } from "../../../core/constants/endpoints";
import { FormaDePagamento } from "../../../core/permissions/subjects";
import { FormaDePagamentoService } from "../../../services/formas-de-pagamento/formas-de-pagamento.service";
import NovaFormaDePagamento from "./nova-forma-de-pagamento.page";
import NovoPrazoDePagamento from "./novo-prazo-de-pagamento.page";
import { PrazoDePagamentoService } from "../../../services/prazo-de-pagamento/prazo-de-pagamento.service";
import useSWR from "swr";

export default function CondicoesDePagamento() {
  const history = useHistory();

  function FormasDePagamento() {
    const _formaDePagamentoService = new FormaDePagamentoService();
    const [exibirCadastroFormaPagamento, setExibirCadastroFormaPagamento] =
      useState(false);
    const [
      atualizarListaFormasDePagamento,
      setAtualizarListaFormasDePagamento,
    ] = useState(null);
    const { data: { data: { data: formasDePagamento } = {} } = {}, error } =
      useSWR(
        Endpoints.formasDePagamento +
          exibirCadastroFormaPagamento +
          atualizarListaFormasDePagamento,
        () => _formaDePagamentoService.obterTodas(),
        { revalidateOnFocus: false }
      );
    if (error) message.error(error?.message);

    return (
      <div>
        <NovaFormaDePagamento
          visible={exibirCadastroFormaPagamento}
          setVisible={setExibirCadastroFormaPagamento}
        />
        <Card
          bodyStyle={{ padding: 0 }}
          type="inner"
          title="Formas de pagamento"
          extra={
            <Button
              type="primary"
              size="middle"
              icon={<PlusOutlined />}
              onClick={() => setExibirCadastroFormaPagamento(true)}
            >
              Nova forma de pagamento
            </Button>
          }
        >
          <Table
            bordered
            loading={!formasDePagamento}
            dataSource={formasDePagamento}
            columns={[
              { title: "Forma de Pagamento", dataIndex: "nome" },
              { title: "Descrição", dataIndex: "descricao" },
              {
                width: 80,
                title: "Status",
                dataIndex: "ativa",
                render: (ativa) =>
                  ativa ? (
                    <Tag color="green">Ativa</Tag>
                  ) : (
                    <Tag color="orange">Inativa</Tag>
                  ),
              },
              {
                width: 140,
                title: "Ação",
                render: (_, obj) => (
                  <>
                    <Link
                      to={`formas-de-pagamento/${obj?.id}`}
                      style={{ paddingRight: 8 }}
                    >
                      Acessar
                    </Link>
                    <Popconfirm
                      placement="bottom"
                      title={`Deseja  ${
                        obj?.ativa ? "inativar" : "ativar"
                      } a forma de pagamento "${obj?.nome}" ?`}
                      onConfirm={async () => {
                        await _formaDePagamentoService.inativarCadastro(
                          obj?.id
                        );
                        message.success(
                          `Forma de pagamento ${obj?.nome} foi ${
                            obj?.ativa ? "Inativado" : "Ativado"
                          }`
                        );
                        setAtualizarListaFormasDePagamento(Date.now());
                      }}
                      okText="SIM"
                      cancelText="Não"
                    >
                      <a style={{ color: obj?.ativa ? "#900c3f" : "#1B4F72" }}>
                        {obj?.ativa ? "Inativar" : "Ativar"}
                      </a>
                    </Popconfirm>
                  </>
                ),
              },
            ]}
          />
        </Card>
      </div>
    );
  }

  function PrazosDePagamento() {
    const _prazosDePagamentoService = new PrazoDePagamentoService();
    const [exibirCadastroPrazoPagamento, setExibirCadastroPrazoPagamento] =
      useState(false);
    const [atualizarListaPrazos, setAtualizarListaPrazos] = useState(null);
    const { data: { data: { data: prazosDePagamento } = {} } = {}, error } =
      useSWR(
        Endpoints.prazosDePagamento +
          exibirCadastroPrazoPagamento +
          atualizarListaPrazos,
        () => _prazosDePagamentoService.obterTodas(),
        { revalidateOnFocus: false }
      );
    if (error) message.error(error?.message);

    return (
      <div>
        <NovoPrazoDePagamento
          visible={exibirCadastroPrazoPagamento}
          setVisible={setExibirCadastroPrazoPagamento}
        />

        <Card
          bodyStyle={{ padding: 0 }}
          type="inner"
          title="Prazos de pagamento"
          extra={
            <Button
              type="primary"
              size="middle"
              icon={<PlusOutlined />}
              onClick={() => setExibirCadastroPrazoPagamento(true)}
            >
              Novo prazo de pagamento
            </Button>
          }
        >
          <Table
            bordered
            loading={!prazosDePagamento}
            dataSource={prazosDePagamento}
            columns={[
              { title: "Prazo de pagamento", dataIndex: "nome" },
              {
                title: "Descrição",
                dataIndex: "descricao",
                render: (value) => (value ? value : "N/A"),
              },
              {
                title: "Parcelas",
                dataIndex: "numeroDeParcelas",
                render: (value) => `${value}x`,
              },
              {
                title: "Primeira parcela",
                dataIndex: "diasParaPrimeiraParcela",
                render: (value) =>
                  value > 1 ? `${value} dias` : `${value} dia`,
              },
              {
                width: 80,
                title: "Status",
                dataIndex: "ativo",
                render: (ativo) =>
                  ativo ? (
                    <Tag color="green">Ativo</Tag>
                  ) : (
                    <Tag color="orange">Inativo</Tag>
                  ),
              },
              {
                title: "Ação",
                width: 140,
                render: (_, obj) => (
                  <>
                    <Link
                      to={`prazos-de-pagamento/${obj?.id}`}
                      style={{ paddingRight: 8 }}
                    >
                      Acessar
                    </Link>
                    <Popconfirm
                      placement="bottom"
                      title={`Deseja  ${
                        obj?.ativo ? "inativar" : "ativar"
                      } o prazo de pagamento "${obj?.nome}" ?`}
                      onConfirm={async () => {
                        await _prazosDePagamentoService.inativar(obj?.id);
                        message.success(
                          `Prazo de pagamento ${obj?.nome} foi ${
                            obj?.ativo ? "Inativado" : "Ativado"
                          }`
                        );
                        setAtualizarListaPrazos(Date.now());
                      }}
                      okText="SIM"
                      cancelText="Não"
                    >
                      <a style={{ color: obj?.ativo ? "#900c3f" : "#1B4F72" }}>
                        {obj?.ativo ? "Inativar" : "Ativar"}
                      </a>
                    </Popconfirm>
                  </>
                ),
              },
            ]}
          />
        </Card>
      </div>
    );
  }

  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title="Condições de pagamento"
        subTitle="Todas as formas de pagamento"
        style={{ marginBottom: 10 }}
      />
      <Tabs type="card">
        <Tabs.TabPane tab="Formas de pagamento" key="1">
          <FormasDePagamento />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Prazos de pagamento" key="2">
          <PrazosDePagamento />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}
export const CondicoesDePagamentoRoute = {
  id: "condicoes-de-pagamento",
  path: "/inicio/financeiro/condicoes-de-pagamento",
  sidebar: "Condições de pagamento",
  exact: true,
  renderInMenu: true,
  main: () => <CondicoesDePagamento />,
  icon: <DollarCircleOutlined />,
  permission: FormaDePagamento,
};
