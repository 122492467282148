export function sequencialPedidoDeCompraFormatter(sequencial: string) {
  if (sequencial && sequencial.match(/^(\d{4})/g)) {
    return sequencial.replace(/^(\d{4})/g, "PC-$1");
  }

  if (sequencial && sequencial.match(/^(\d{3})/g)) {
    return sequencial.replace(/^(\d{3})/g, "PC-0$1");
  }

  if (sequencial && sequencial.match(/^(\d{2})/g)) {
    return sequencial.replace(/^(\d{2})/g, "PC-00$1");
  }

  if (sequencial && sequencial.match(/^(\d{1})/g)) {
    return sequencial.replace(/^(\d{1})/g, "PC-000$1");
  }

  return "";
}

export function sequencialRequisicaoDeCompraFormatter(sequencial: string) {
  if (sequencial && sequencial.match(/^(\d{4})/g)) {
    return sequencial.replace(/^(\d{4})/g, "RC-$1");
  }

  if (sequencial && sequencial.match(/^(\d{3})/g)) {
    return sequencial.replace(/^(\d{3})/g, "RC-0$1");
  }

  if (sequencial && sequencial.match(/^(\d{2})/g)) {
    return sequencial.replace(/^(\d{2})/g, "RC-00$1");
  }

  if (sequencial && sequencial.match(/^(\d{1})/g)) {
    return sequencial.replace(/^(\d{1})/g, "RC-000$1");
  }

  return "";
}
