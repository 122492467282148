import { Button, Card, Col, DatePicker, Form, Input, Modal, Row } from "antd";
import {
  datasValidas,
  diasEntreDatas,
} from "../core/utils/formatters/datas.formatter";

export function NumeroDeParcelasModal({
  visible,
  setVisible,
  formRef,
  contexto,
}) {
  const [parcelasForm] = Form.useForm();
  return (
    <Modal
      visible={visible}
      closable={false}
      bodyStyle={{ padding: 0, marginBottom: -110 }}
      onCancel={() => {
        setVisible(false);
        formRef.setFieldsValue({
          [contexto]: {
            prazoDePagamento: null,
          },
        });
      }}
      footer
      width={800}
    >
      <Card title="Informe as condições de parcelamento" size="small">
        <Form
          layout="vertical"
          form={parcelasForm}
          onFinish={({
            numeroDeParcelas = 1,
            diasParaPrimeiraParcela,
            diasEntreParcelas = 30,
          }) => {
            formRef.setFieldsValue({
              [contexto]: {
                numeroDeParcelas: Number(numeroDeParcelas),
                diasParaPrimeiraParcela: diasEntreDatas(
                  diasParaPrimeiraParcela?.toDate()
                ),
                diasEntreParcelas: Number(diasEntreParcelas),
                dataVencimento: diasParaPrimeiraParcela,
                dataDePagamento: diasParaPrimeiraParcela,
              },
            });
            setVisible(false);
          }}
        >
          <Row>
            <Col span={20} style={{ paddingRight: 5 }}>
              <Input.Group compact>
                <Form.Item
                  label="Data da primeira parcela"
                  style={{ width: "33.33%", paddingRight: 5 }}
                  name="diasParaPrimeiraParcela"
                  rules={[
                    {
                      required: true,
                      message: " ",
                    },
                  ]}
                >
                  <DatePicker
                    placeholder="Selecione a data"
                    size="middle"
                    format={"DD/MM/yyyy"}
                    disabledDate={(date) => datasValidas(date.toDate())}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
                <Form.Item
                  label="Quantidade de parcelas"
                  style={{ width: "33.33%", paddingRight: 5 }}
                  name="numeroDeParcelas"
                  rules={[
                    {
                      required: true,
                      message: " ",
                      max: 24,
                      min: 1,
                    },
                  ]}
                >
                  <Input
                    placeholder="Número de parcelas"
                    type="number"
                    size="middle"
                    min={1}
                    max={24}
                  />
                </Form.Item>
                <Form.Item
                  label="Dias entre parcelas"
                  style={{ width: "33.33%", paddingRight: 5 }}
                  name="diasEntreParcelas"
                  initialValue={30}
                  rules={[
                    {
                      required: true,
                      message: " ",
                    },
                  ]}
                >
                  <Input
                    placeholder="DIAS ENTRE AS PARCELAS"
                    type="number"
                    size="middle"
                    min={1}
                  />
                </Form.Item>
              </Input.Group>
            </Col>
            <Col span={4}>
              <Button
                type="primary"
                size="middle"
                className="btn-success"
                htmlType="submit"
                style={{ marginTop: 31 }}
              >
                CONFIRMAR
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </Modal>
  );
}
