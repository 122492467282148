import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  PageHeader,
  Select,
  Table,
  Tag,
  message,
} from "antd";
import {
  ClearOutlined,
  PlusOutlined,
  ProjectOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import React, { useState } from "react";
import {
  StatusJobDescricao,
  statusJobArray,
} from "../../../core/enums/status-job.enum";

import { ClienteService } from "../../../services/cliente/cliente.service";
import { ColaboradorService } from "../../../services/colaborador/colaborador.service";
import { ColumnsType } from "antd/lib/table/interface";
import { Endpoints } from "../../../core/constants/endpoints";
import { Job } from "../../../core/permissions/subjects";
import { JobService } from "../../../services/job/job.service";
import NovoJob from "./novo-job.page";
import { identificadorFormatter } from "../../../core/utils/formatters/job.formatter";
import moment from "moment";
import useSWR from "swr";
import useTitle from "../../../core/hooks/title.hook";

const _jobService = new JobService();
const _clienteService = new ClienteService();
const _colaboradorService = new ColaboradorService();

export default function Jobs() {
  useTitle("Jobs");
  const [drawerNovoJob, setDrawerNovoJob] = useState(false);
  const history = useHistory();
  const [optionsCliente, setOptionsCliente] = useState([]);
  const [optionsClienteSearch, setOptionsClienteSearch] = useState(false);
  const [optionsGerente, setOptionsGerente] = useState([]);
  const [optionsGerenteSearch, setOptionsGerenteSearch] = useState(false);
  const [filtrosFormRef] = Form.useForm();
  const [filtro, setFiltro] = useState("");
  const { data: jobs, error } = useSWR(
    Endpoints.jobs + filtro + drawerNovoJob,
    () => _jobService.obterJobs(filtro),
    { revalidateOnFocus: false }
  );

  if (error) message.error(error.message);

  function verificarOrdemCache() {
    const sorter = JSON.parse(
      sessionStorage.getItem("sorter-order-atendimento")
    );
    return sorter ? sorter.order : null;
  }
  function handleSearch(values: any) {
    const { intervalo, status, empresa, cliente, job } = values;

    let dataInicio = moment(new Date()).startOf("year");
    let dataFim = moment(new Date()).endOf("year");

    if (intervalo) {
      dataInicio = moment(intervalo).startOf("year");
      dataFim = moment(intervalo).endOf("year");
    }

    let query = `${status ? `status=${status.toString()}` : ""}${
      intervalo
        ? `&intervalo=${dataInicio.toString()},${dataFim.toString()}`
        : ""
    }${empresa ? `&empresa=${empresa}` : ""}${job ? `&job=${job}` : ""}${
      cliente ? `&cliente=${cliente}` : ""
    }`;

    setFiltro(query);
  }
  const columns: ColumnsType<any> = [
    {
      key: "id",
      title: "Job",
      dataIndex: "identificador",
      width: 200,
      sorter: {
        compare: (a, b, order) => {
          sessionStorage.setItem(
            "sorter-order-atendimento",
            JSON.stringify({ order })
          );
          return a.identificador - b.identificador;
        },
        multiple: 1,
      },
      render: (value) => identificadorFormatter(value),
      defaultSortOrder: verificarOrdemCache(),
    },
    {
      title: "Nome",
      dataIndex: "nome",
    },
    {
      title: "Competência",
      dataIndex: "dataDeCompetencia",
      width: 200,
      render: (value) => moment(new Date(value)).format("MM/YYYY"),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 200,
      render: (value) => (
        <Tag color={StatusJobDescricao[value].cor}>
          {StatusJobDescricao[value].label}
        </Tag>
      ),
    },
    {
      title: "Ação",
      dataIndex: "",
      width: 120,
      key: "x",
      render: (value, obj) => (
        <a>
          <Link to={`jobs/${obj.id}`}>Acessar</Link>
        </a>
      ),
    },
  ];

  return (
    <div>
      <NovoJob visible={drawerNovoJob} setVisible={setDrawerNovoJob} />
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title="Jobs"
        subTitle="Todos os Jobs"
        extra={
          <Button
            type="primary"
            size="middle"
            icon={<PlusOutlined />}
            onClick={() => setDrawerNovoJob(true)}
          >
            Novo Job
          </Button>
        }
      />

      <Card
        style={{ marginTop: 10, marginRight: 10 }}
        title="Filtros"
        size="small"
      >
        <Form
          form={filtrosFormRef}
          layout="inline"
          style={{ display: "flex" }}
          onFinish={(values) => handleSearch(values)}
        >
          <Form.Item name="intervalo" style={{ flex: 1, maxWidth: "15%" }}>
            <DatePicker
              placeholder="Filtrar por ano"
              format="yyyy"
              style={{ display: "flex" }}
              picker="year"
            />
          </Form.Item>
          <Form.Item name="job" style={{ flex: 1 }}>
            <Input
              placeholder="Buscar por Job"
              onChange={() => filtrosFormRef.submit()}
            />
          </Form.Item>
          <Form.Item name="status" style={{ flex: 1 }}>
            <Select placeholder="Status" options={statusJobArray} mode="tags" />
          </Form.Item>

          <Form.Item name="cliente" style={{ flex: 1 }}>
            <Select
              placeholder="Cliente"
              showSearch
              options={optionsCliente}
              loading={optionsClienteSearch}
              filterOption={(input, option) =>
                option?.label
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onSearch={async (value) => {
                if (value.length >= 3) {
                  setOptionsClienteSearch(true);
                  const result = await _clienteService.obterVersaoSimplificada(
                    value
                  );
                  const opts = result?.data?.data.map((o) => {
                    return {
                      key: o.id,
                      value: o.id,
                      label: o.nomeFantasia,
                    };
                  });
                  setOptionsCliente(opts);
                  setOptionsClienteSearch(false);
                }
              }}
            />
          </Form.Item>
          <Button type="primary" icon={<SearchOutlined />} htmlType="submit">
            Buscar
          </Button>
          <Button
            type="default"
            icon={<ClearOutlined />}
            onClick={() => {
              setFiltro("");
              setOptionsCliente([]);
              setOptionsGerente([]);
              filtrosFormRef.resetFields();
            }}
            style={{ marginLeft: 3 }}
          >
            Limpar filtro
          </Button>
        </Form>
      </Card>

      <Table
        rowKey="id"
        loading={!jobs}
        columns={columns}
        bordered
        style={{ marginTop: 10 }}
        dataSource={jobs?.data?.data || []}
        scroll={{ y: window.screen.height - window.screen.height * 0.54 }}
      />
    </div>
  );
}

export const JobsRoute = {
  id: "jobs",
  path: "/inicio/atendimento/jobs",
  sidebar: "Jobs",
  exact: true,
  renderInMenu: true,
  main: () => <Jobs />,
  icon: <ProjectOutlined />,
  permission: Job,
};
