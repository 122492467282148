import { Form, Modal, Select, Upload, message } from "antd";
import React, { useEffect, useState } from "react";

import { ConciliacaoBancariaService } from "../../../services/conciliacao-bancaria/conciliacao-bancaria.service";
import { ContaBancariaService } from "../../../services/conta-bancaria/conta-bancaria.service";
import { FileTextOutlined } from "@ant-design/icons";

export default function ImportarExtrato({ visible, setVisible }) {
  const [contasOpts, setContasOpts] = useState([]);
  const [file, setFile] = useState(null);
  const [formRef] = Form.useForm();
  const _contaBancariaService = new ContaBancariaService();
  const _conciliacaoBancaria = new ConciliacaoBancariaService();
  useEffect(() => {
    _contaBancariaService.obterTodas().then(({ data: { data: contas } }) => {
      setContasOpts(
        contas?.map((c) => {
          return { key: c.id, value: c.id, label: c.descricao };
        })
      );
    });
  }, []);

  async function importarExtratoHandler() {
    try {
      await formRef.validateFields();
      const { conta } = formRef.getFieldValue("extrato");
      const form = new FormData();
      form.append("ofx", file);
      await _conciliacaoBancaria.importarExtrato(form, conta);
      message.success("O extrato foi importado com sucesso");
      formRef.resetFields();
      setFile(null);
      setVisible(false);
    } catch (error) {
      message.error(
        "Algo deu errado... " + error.response?.data?.message
          ? error.response?.data?.message
          : error.message
      );
    }
  }

  return (
    <Modal
      title="Importar Extrato"
      visible={visible}
      onCancel={() => setVisible(false)}
      closable={false}
      okText="Importar extrato"
      onOk={() => formRef.submit()}
    >
      <Form form={formRef} onFinish={() => importarExtratoHandler()}>
        <Form.Item
          name={["extrato", "arquivo"]}
          rules={[{ required: true, message: "Selecione o arquivo" }]}
        >
          <Upload
            maxCount={1}
            accept=".ofx"
            onRemove={() => setFile(null)}
            type={!file ? "drag" : "select"}
            listType="picture"
            iconRender={() => <FileTextOutlined />}
            customRequest={({ file: fileUpload }) => {
              setFile(fileUpload);
            }}
            fileList={
              file
                ? [
                    {
                      uid: file["uid"],
                      name: file["name"],
                      status: "success",
                      url: null,
                    },
                  ]
                : []
            }
          >
            {!file ? <>Selecione o arquivo OFX</> : null}
          </Upload>
        </Form.Item>
        <Form.Item
          name={["extrato", "conta"]}
          rules={[{ required: true, message: "Selecione a conta bancária" }]}
        >
          <Select
            placeholder="Conta bancária"
            options={contasOpts}
            loading={contasOpts.length <= 0}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
