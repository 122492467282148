import {
  CloseCircleOutlined,
  FileExcelOutlined,
  PlusOutlined,
  RetweetOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  PageHeader,
  Row,
  Select,
  Statistic,
  Table,
  Tabs,
  Tag,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  StatusContaAPagar,
  StatusContaAPagarDescricao,
  StatusContaAPagarDescricaoCor,
} from "../../../core/enums/status-conta-a-pagar.enum";
import {
  StatusRequisicaoCompra,
  StatusRequisicaoDeCompraInfo,
} from "../../../core/enums/status-requisicao-de-compra.enum";

import currencyFormatter from "currency-formatter";
import { saveAs } from "file-saver";
import moment from "moment";
import useSWR from "swr";
import useBuscarClienteHook from "../../../components/hooks/buscar-cliente.hook";
import Loading from "../../../components/loading.component";
import { Endpoints } from "../../../core/constants/endpoints";
import { statusJobArray } from "../../../core/enums/status-job.enum";
import useTitle from "../../../core/hooks/title.hook";
import { cepFormatter } from "../../../core/utils/formatters/cep.formatter";
import { identificadorFormatter } from "../../../core/utils/formatters/job.formatter";
import { ColaboradorService } from "../../../services/colaborador/colaborador.service";
import { EmpresaService } from "../../../services/empresa/empresas.service";
import { AtualizarJobDto } from "../../../services/job/dto/atualizar-job.dto";
import { JobService } from "../../../services/job/job.service";
import SolicitarReembolsoModal from "../../financeiro/pedidos-de-reembolso/solicitar-reembolso.modal";
import NovaRequisicaoDeCompra from "../requisicao-de-compra/nova-requisicao-de-compra.modal";

const cep = require("cep-promise");

const _jobService = new JobService();
export default function Job() {
  //#region Variáveis e Refs
  let { jobId } = useParams<any>();
  const { data: job } = useSWR(
    Endpoints.jobs + jobId,
    () => _jobService.obterJob(jobId),
    { revalidateOnFocus: false }
  );
  const { data: jobData } = job?.data || {};
  const [modalReembolso, setModalReembolso] = useState(false);
  const [
    gerarRelatorioRequisicoesDeCompra,
    setGerarRelatorioRequisicoesDeCompra,
  ] = useState(false);
  const history = useHistory();

  const { TabPane } = Tabs;
  const [formRef] = Form.useForm();
  //#endregion
  useTitle(`Job ${identificadorFormatter(jobData?.identificador)}`);
  useEffect(() => {
    const colaboradores =
      jobData?.equipe?.colaboradores?.map((c) => {
        return { key: c.id, label: c.nome, value: c.id };
      }) || [];

    formRef.setFieldsValue({
      dataDeCompetencia: moment(jobData?.dataDeCompetencia),
      equipe: colaboradores,
      nome: jobData?.nome,
      status: jobData?.status,
      descricao: jobData?.descricao,
      cliente: jobData?.cliente?.id,
      empresa: jobData?.empresa?.id,
      gerente: jobData?.gerente?.id,
      endereco: {
        cep: jobData?.endereco?.cep,
        bairro: jobData?.endereco?.bairro,
        logradouro: jobData?.endereco?.logradouro,
        estado: jobData?.endereco?.estado,
        cidade: jobData?.endereco?.cidade,
        numero: jobData?.endereco?.numero,
        complemento: jobData?.endereco?.complemento,
        pais: "BR",
      },
    });
  }, [jobData]);

  //#region Componentes Internos
  function InformacoesEquipe() {
    //#region Variaveis e Refs
    const _colaboradorService = new ColaboradorService();
    const [optionsEquipe, setOptionsEquipe] = useState([]);
    const [optionsGerente, setOptionsGerente] = useState([]);
    const [optionsGerenteSearch, setOptionsGerenteSearch] = useState(false);
    const [optionsEquipeSearch, setOptionsEquipeSearch] = useState(false);
    const [participantes, setParticipantes] = useState([]);

    const [searchColaboradoresValue, setSearchColaboradoresValue] = useState(
      []
    );

    //#endregion

    useEffect(() => {
      setOptionsGerente([
        {
          key: jobData?.gerente?.id,
          label: jobData?.gerente?.nome,
          value: jobData?.gerente?.id,
        },
      ]);
      const colaboradores =
        jobData?.equipe?.colaboradores?.map((c) => {
          return { key: c.id, label: c.nome, value: c.id };
        }) || [];
      formRef.setFieldsValue({
        equipe: colaboradores,
      });

      setParticipantes(colaboradores);
    }, [jobData]);

    return jobData ? (
      <div>
        <Card title="Equipe" type="inner">
          <Row>
            <Col span={24}>
              <Form.Item
                initialValue={jobData?.gerente?.id}
                name="gerente"
                label="Gerente"
                rules={[
                  {
                    required: true,
                    message: "Defina o gerente do Job",
                  },
                ]}
              >
                <Select
                  placeholder="Selecione o gerente"
                  showSearch
                  options={optionsGerente}
                  loading={optionsGerenteSearch}
                  onSelect={(_, option) => {
                    const equipeBkp = participantes.filter(
                      (p) => p.key !== option.key
                    );
                    setParticipantes([...equipeBkp, option]);
                    formRef.setFieldsValue({
                      equipe: [...equipeBkp, option],
                    });
                  }}
                  filterOption={(input, option) =>
                    option?.label
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSearch={async (value) => {
                    if (value.length >= 3) {
                      setOptionsGerenteSearch(true);
                      const result =
                        await _colaboradorService.obterSimplificado(value);
                      const opts = result?.data?.data.map((o) => {
                        return {
                          key: o.id,
                          value: o.id,
                          label: o.nome,
                        };
                      });
                      setOptionsGerente(opts);
                      setOptionsGerenteSearch(false);
                    }
                  }}
                />
              </Form.Item>
              <Form.Item name="equipe" label="Selecione os participantes">
                <Card
                  title="Participantes"
                  type="inner"
                  bodyStyle={{ padding: 0 }}
                  extra={
                    <Select
                      placeholder="Buscar Colaboradores"
                      value={searchColaboradoresValue}
                      style={{ width: "250px" }}
                      mode="multiple"
                      showSearch
                      options={optionsEquipe}
                      loading={optionsEquipeSearch}
                      filterOption={(input, option) =>
                        option?.label
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onSearch={async (value) => {
                        if (value.length >= 3) {
                          setOptionsEquipeSearch(true);
                          const result =
                            await _colaboradorService.obterSimplificado(value);
                          const opts = result?.data?.data.map((o) => {
                            return {
                              key: o.id,
                              value: o.id,
                              label: o.nome,
                            };
                          });
                          setOptionsEquipe(opts);
                          setOptionsEquipeSearch(false);
                        }
                      }}
                      onSelect={(value, option) => {
                        setParticipantes([
                          ...participantes.filter((p) => p.key !== option.key),
                          option,
                        ]);
                        setSearchColaboradoresValue([]);
                        setOptionsEquipe([]);
                        formRef.setFieldsValue({
                          equipe: [...participantes, option],
                        });
                      }}
                    />
                  }
                >
                  <Table
                    rowKey="key"
                    pagination={false}
                    dataSource={participantes}
                    columns={[
                      { title: "Colaborador", dataIndex: "label" },
                      {
                        title: "Ação",
                        width: 120,
                        render: (_, obj) => (
                          <Button
                            size="small"
                            type="link"
                            style={{ color: "red" }}
                            onClick={() => {
                              try {
                                const gerente =
                                  formRef.getFieldValue("gerente");

                                if (obj.key === gerente)
                                  throw new Error(
                                    "Selecione outro gerente antes de remover o atual"
                                  );

                                const equipeBkp = participantes.filter(
                                  (p) => p.key !== obj.key
                                );
                                setParticipantes([...equipeBkp]);

                                formRef.setFieldsValue({
                                  equipe: equipeBkp,
                                });
                              } catch (error) {
                                message.error(error.message);
                              }
                            }}
                          >
                            Remover
                          </Button>
                        ),
                      },
                    ]}
                    size="small"
                    bordered
                  />
                </Card>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </div>
    ) : (
      <Loading />
    );
  }

  function RequisicoesDeCompra() {
    const [novaRequisicaoDrawer, setNovaRequisicaoDrawer] = useState(false);
    const [atualizarOrcamento, setAtualizarOrcamento] = useState(false);

    const { data: { data: { data: requisicoesData } = {} } = {} } = useSWR(
      Endpoints.requisicoesDeCompra + atualizarOrcamento,
      () => _jobService.obterRequisicoes(jobId),
      { revalidateOnFocus: false }
    );

    const [totalRequisicoes, setTotalRequisicoes] = useState(0);
    const [orcamentorequisicoesDeCompra, setOrcamentorequisicoesDeCompra] =
      useState(0);
    useEffect(() => {
      setOrcamentorequisicoesDeCompra(
        jobData?.orcamento?.orcamentoPararequisicoesDeCompra || 0
      );
      let total = 0;

      for (const i of requisicoesData?.requisicoes || []) {
        if (
          i?.status === StatusRequisicaoCompra.Aprovado ||
          i?.status === StatusRequisicaoCompra.EnviadoAoFinanceiro ||
          i?.status === StatusRequisicaoCompra.FornecedorNaoVerificado
        )
          total += i?.valorTotal;
      }
      for (const c of requisicoesData?.contasAPagar || []) {
        if (c?.status !== StatusContaAPagar.Cancelado) {
          total += c?.valor;
        }
      }
      setTotalRequisicoes(total);
    }, [requisicoesData]);

    const columns = [
      { title: "Requisição", dataIndex: "sequencial", width: 105 },

      {
        title: "Fornecedor",
        dataIndex: "fornecedor",
        width: 400,
        render: (value) => (
          <a>
            <Link to={`/inicio/atendimento/fornecedores/${value?.id}`}>
              {value?.nomeFantasia ? value?.nomeFantasia : value?.razaoSocial}
            </Link>
          </a>
        ),
      },
      {
        title: "Valor Total",
        dataIndex: "valorTotal",
        width: 100,
        className: "currency",
        render: (value) => currencyFormatter.format(value, { code: "BRL" }),
      },
      {
        title: "Status",
        dataIndex: "status",
        width: 200,
        render: (value) => (
          <Tag color={StatusRequisicaoDeCompraInfo[value].color}>
            {StatusRequisicaoDeCompraInfo[value].label}
          </Tag>
        ),
      },
      {
        title: "Solicitante",
        width: 120,
        dataIndex: "solicitante",
        render: (value) => String(value.nome)?.split(" ")?.[0],
      },

      {
        title: "Data de Entrega",
        width: 140,
        dataIndex: "dataDeEntrega",
        render: (value) => moment(value).format("DD/MM/YYYY "),
      },
      {
        title: "Ação",
        width: 140,
        render: (_, obj) => (
          <a>
            <Link to={`/inicio/atendimento/requisicoes-de-compra/${obj.id}`}>
              Acessar
            </Link>
          </a>
        ),
      },
    ];
    return (
      <>
        <NovaRequisicaoDeCompra
          atualizarPai={setAtualizarOrcamento}
          setVisible={setNovaRequisicaoDrawer}
          visible={novaRequisicaoDrawer}
          job={jobData}
        />
        <Card>
          <Row style={{ textAlign: "center" }}>
            <Col span={8}>
              <Statistic
                title="Orçamento"
                value={currencyFormatter.format(orcamentorequisicoesDeCompra, {
                  code: "BRL",
                })}
                valueStyle={{
                  color: orcamentorequisicoesDeCompra <= 0 ? "red" : "#3f8600",
                }}
              />
            </Col>
            <Col span={8}>
              <Statistic
                title="Requisições"
                value={currencyFormatter.format(totalRequisicoes, {
                  code: "BRL",
                })}
                valueStyle={{ color: "#3f8600" }}
              />
            </Col>
            <Col span={8}>
              <Statistic
                title="Saldo"
                value={currencyFormatter.format(
                  orcamentorequisicoesDeCompra - totalRequisicoes,
                  {
                    code: "BRL",
                  }
                )}
                valueStyle={{
                  color:
                    orcamentorequisicoesDeCompra - totalRequisicoes <= 0
                      ? "red"
                      : "#3f8600",
                }}
              />
            </Col>
          </Row>
        </Card>
        <Card
          bodyStyle={{ padding: 0 }}
          type="inner"
          extra={
            <>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                style={{ background: "#0d6efd", borderColor: "#0d6efd" }}
                onClick={() => setNovaRequisicaoDrawer(true)}
              >
                NOVA REQUISIÇÃO
              </Button>
              <Button
                loading={gerarRelatorioRequisicoesDeCompra}
                type="primary"
                icon={<FileExcelOutlined />}
                style={{
                  background: "green",
                  marginLeft: 3,
                  borderColor: "green",
                }}
                onClick={async () => {
                  setGerarRelatorioRequisicoesDeCompra(true);
                  const xlsx = await _jobService.obterRelatorioRequisicoes(
                    jobData?.id
                  );

                  setGerarRelatorioRequisicoesDeCompra(false);
                  saveAs(xlsx.data, `relatorio.xlsx`);
                }}
              >
                EXPORTAR RELATÓRIO
              </Button>
              <Button
                disabled
                style={{ marginLeft: 3 }}
                icon={<RetweetOutlined />}
                type="primary"
                onClick={() => setModalReembolso(true)}
              >
                SOLICITAR REEMBOLSO
              </Button>
            </>
          }
        >
          <Tabs type="card">
            <Tabs.TabPane tab="Requisições deste job" key="1">
              <Table
                bordered
                columns={columns}
                size="small"
                scroll={{ y: 200 }}
                dataSource={requisicoesData?.requisicoes}
                loading={!requisicoesData}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Contas a pagar S/ PC" key="2">
              <Table
                bordered
                columns={columnsContasAPagar}
                size="small"
                scroll={{ y: 200 }}
                dataSource={requisicoesData?.contasAPagar}
                loading={!requisicoesData}
              />
            </Tabs.TabPane>
          </Tabs>
        </Card>
      </>
    );
  }

  function InformacoesJob() {
    const [optionsEmpresa, setOptionsEmpresa] = useState([]);
    const [optionsEmpresaSearch, setOptionsEmpresaSearch] = useState(false);

    const {
      options: optionsCliente,
      setOptions: setOptionsCliente,
      loading: optionsClienteSearch,
      buscarCliente,
      filterOptions: filterOptsCliente,
    } = useBuscarClienteHook();

    const _empresaService = new EmpresaService();

    useEffect(() => {
      setOptionsEmpresa([
        {
          key: jobData?.empresa?.id,
          value: jobData?.empresa?.id,
          label: jobData?.empresa?.nomeFantasia,
        },
      ]);
      setOptionsCliente([
        {
          key: jobData?.cliente?.id,
          value: jobData?.cliente?.id,
          label: jobData?.cliente?.nomeFantasia,
        },
      ]);
    }, [jobData]);

    return jobData?.id ? (
      <div>
        <Row>
          <Col span={24} className="col-input-entry">
            <Card title="Geral" type="inner">
              <Row>
                <Col span={24} className="col-input-entry">
                  <Input.Group compact>
                    <Form.Item
                      label="Job"
                      initialValue={jobData?.nome}
                      name="nome"
                      rules={[
                        { required: true, message: "Informe o nome do Job" },
                      ]}
                      style={{ width: "50%", paddingRight: 5 }}
                    >
                      <Input placeholder="Nome do Job" />
                    </Form.Item>

                    <Form.Item
                      initialValue={moment(jobData?.dataDeCompetencia)}
                      style={{ width: "25%", paddingRight: 5 }}
                      name="dataDeCompetencia"
                      label="Competência do job"
                      rules={[
                        {
                          required: true,
                          message: "Defina da data do Job",
                        },
                      ]}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        disabledDate={(date) => {
                          const now = new Date();
                          return (
                            date.toDate().getMonth() < now.getMonth() &&
                            date.toDate().getFullYear() === now.getFullYear()
                          );
                        }}
                        placeholder={"Informe o mês e ano de competência"}
                        format="MM/YYYY"
                        picker="month"
                      />
                    </Form.Item>
                    <Form.Item
                      style={{ width: "25%" }}
                      name="status"
                      label="Status"
                      initialValue={jobData?.status}
                      rules={[
                        {
                          required: true,
                          message: "Selecione o status do Job",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Selecione o status"
                        options={statusJobArray}
                      />
                    </Form.Item>
                    <Input.Group compact>
                      <Form.Item
                        style={{ width: "50%", paddingRight: 5 }}
                        initialValue={jobData?.empresa?.id}
                        name="empresa"
                        label="Empresa"
                        rules={[
                          { required: true, message: "Defina a empresa" },
                        ]}
                      >
                        <Select
                          placeholder="Selecione a empresa"
                          showSearch
                          options={optionsEmpresa}
                          loading={optionsEmpresaSearch}
                          filterOption={(input, option) =>
                            option?.label
                              .toString()
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onSearch={async (value) => {
                            if (value.length >= 3) {
                              setOptionsEmpresaSearch(true);
                              const result =
                                await _empresaService.obterEmpresasSimplificado(
                                  value
                                );
                              const opts = result?.data?.data.map((o) => {
                                return {
                                  key: o.id,
                                  value: o.id,
                                  label: o.nomeFantasia,
                                };
                              });
                              setOptionsEmpresa(opts);
                              setOptionsEmpresaSearch(false);
                            }
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        style={{ width: "50%" }}
                        name="cliente"
                        label="Cliente"
                        initialValue={jobData?.cliente?.id}
                        rules={[
                          {
                            required: true,
                            message: "Selecione o cliente",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Selecione o Cliente"
                          showSearch
                          options={optionsCliente}
                          loading={optionsClienteSearch}
                          filterOption={filterOptsCliente}
                          onSearch={buscarCliente}
                        />
                      </Form.Item>
                    </Input.Group>
                  </Input.Group>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Descrição"
                    name="descricao"
                    initialValue={jobData?.descricao}
                  >
                    <Input.TextArea placeholder="Descrição" />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={24} className="col-input-entry">
            <Card title="Endereço" type="inner">
              <Col span={24} className="col-input-entry">
                <Input.Group compact>
                  <Form.Item
                    initialValue={jobData?.endereco?.cep}
                    name="cep"
                    label="CEP"
                    style={{ width: "10%", paddingRight: "5px" }}
                    rules={[
                      {
                        max: 9,
                        min: 9,
                        pattern: /^\d{5}-\d{3}$/g,
                      },
                    ]}
                  >
                    <Input
                      placeholder="00000-000"
                      maxLength={9}
                      onChange={(event) => {
                        const cepValue = event.target.value;

                        formRef.setFieldsValue({
                          cep: cepFormatter(event.target.value),
                        });
                        if (cepValue.replace("-", "").length >= 8) {
                          cep(cepValue).then((response) => {
                            formRef.setFieldsValue({
                              estado: response.state,
                              logradouro: response.street,
                              bairro: response.neighborhood,
                              cidade: response.city,
                            });
                          });
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    initialValue={jobData?.endereco?.logradouro}
                    name="logradouro"
                    label="Rua"
                    style={{ width: "40%", paddingRight: "5px" }}
                  >
                    <Input placeholder="Rua" />
                  </Form.Item>
                  <Form.Item
                    initialValue={jobData?.endereco?.numero}
                    name="numero"
                    label="Número"
                    style={{ width: "10%", paddingRight: 5 }}
                  >
                    <Input placeholder="Número" type="number" />
                  </Form.Item>

                  <Form.Item
                    initialValue={jobData?.endereco?.bairro}
                    name="bairro"
                    label="Bairro"
                    style={{ width: "20%", paddingRight: "5px" }}
                  >
                    <Input placeholder="Bairro" />
                  </Form.Item>
                  <Form.Item
                    initialValue={jobData?.endereco?.cidade}
                    name="cidade"
                    label="Cidade"
                    rules={[{ required: true, message: "Defina a cidade" }]}
                    style={{ width: "15%", paddingRight: "5px" }}
                  >
                    <Input placeholder="Cidade" />
                  </Form.Item>
                  <Form.Item
                    initialValue={jobData?.endereco?.estado}
                    name="estado"
                    label="Estado"
                    rules={[{ required: true, message: "Defina o estado" }]}
                    style={{ width: "5%" }}
                  >
                    <Input placeholder="Estado" />
                  </Form.Item>
                  <Form.Item
                    initialValue={jobData?.endereco?.complemento}
                    name="complemento"
                    label="Complemento"
                    style={{ width: "100%" }}
                  >
                    <Input placeholder="Complemento" />
                  </Form.Item>
                </Input.Group>
              </Col>
            </Card>
          </Col>
        </Row>
      </div>
    ) : (
      <Loading />
    );
  }

  //#endregion

  //#region Handlers
  async function AtualizarJobHandler() {
    try {
      const {
        nome,
        descricao,
        status,
        dataDeCompetencia,
        cep,
        logradouro,
        estado,
        cidade,
        numero,
        bairro,
        complemento,
        cliente,
        gerente,
        empresa,
        equipe,
      } = formRef.getFieldsValue([
        "empresa",
        "nome",
        "cliente",
        "gerente",
        "status",
        "descricao",
        "equipe",
        "cep",
        "logradouro",
        "complemento",
        "dataDeCompetencia",
        "numero",
        "bairro",
        "estado",
        "cidade",
      ]);

      const job: AtualizarJobDto = {
        id: jobId,
        nome,
        descricao,
        status,
        dataDeCompetencia: dataDeCompetencia.toString(),
        cliente: {
          id: cliente,
        },
        gerente: {
          id: gerente,
        },
        empresa: {
          id: empresa,
        },
        equipe,
        endereco: {
          id: jobData?.endereco?.id,
          cep,
          bairro,
          logradouro,
          estado,
          cidade,
          numero,
          complemento,
          pais: "BR",
        },
      };

      if (equipe.length > 0) {
        job.equipe = {
          id: jobData?.equipe?.id,
          nome: `${job.nome} Equipe`,
          colaboradores: equipe.map((c) => {
            return { id: c.key, nome: c.label };
          }),
        };
      }

      await _jobService.atualizarJob(jobId, job);
      message.success("Job Atualizado!");
      history.goBack();
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message);
    }
  }
  //#endregion

  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        style={{ zIndex: 10 }}
        title={`Job ${identificadorFormatter(jobData?.identificador)}`}
      />

      <SolicitarReembolsoModal
        visible={modalReembolso}
        setVisible={setModalReembolso}
      />

      <Card
        bodyStyle={{ padding: 0, height: "100%" }}
        style={{ marginTop: 10 }}
      >
        <Form
          layout="vertical"
          form={formRef}
          onFinish={() => AtualizarJobHandler()}
        >
          <Tabs type="card">
            <TabPane tab="Requisições de compra" key="2">
              <RequisicoesDeCompra />
            </TabPane>
            <TabPane
              tab="Informações do Job"
              key="1"
              style={{ height: "100%", flex: 1 }}
            >
              <InformacoesJob />
            </TabPane>
            <TabPane tab="Equipe" key="3">
              <InformacoesEquipe />
            </TabPane>
          </Tabs>
          <Row
            style={{
              position: "fixed",
              top: 80,
              padding: 10,
              marginTop: 10,
              justifyContent: "end",
              marginLeft: 40,
              width: "94%",
              zIndex: 100,
            }}
          >
            <div
              style={{
                background: "white",
                padding: 8,
                borderRadius: 5,
              }}
            >
              <Button
                type="primary"
                size="middle"
                htmlType="submit"
                icon={<SaveOutlined />}
                style={{ marginRight: 4 }}
              >
                SALVAR
              </Button>
              <Button
                type="ghost"
                icon={<CloseCircleOutlined />}
                size="middle"
                onClick={() => history.goBack()}
              >
                CANCELAR
              </Button>
            </div>
          </Row>
        </Form>
      </Card>
    </div>
  );
}

const columnsContasAPagar = [
  { title: "Descrição", dataIndex: "descricao" },
  {
    title: "Valor",
    dataIndex: "valor",
    className: "currency",
    render: (value) =>
      currencyFormatter.format(value, {
        code: "BRL",
      }),
  },
  { title: "Job", dataIndex: "job", render: (job) => job?.nome },
  {
    title: "Status",

    dataIndex: "status",
    render: (status) => (
      <Tag color={StatusContaAPagarDescricaoCor[status]}>
        {StatusContaAPagarDescricao[status]}
      </Tag>
    ),
  },
];

export const JobRoute = {
  id: "job",
  path: "/inicio/atendimento/jobs/:jobId",
  sidebar: "Jobs",
  exact: true,
  renderInMenu: false,
  main: () => <Job />,
  icon: null,
  permission: Job,
};
