import { AtualizarPedidoDeCompraDto } from "./dto/atualizar-pedido-de-compra.dto";
import { BaseService } from "../base.service";
import { Endpoints } from "../../core/constants/endpoints";

export class PedidoDeCompraService extends BaseService {
  async obterTodos(filtro: string) {
    try {
      return await this.api.get(`${Endpoints.pedidosDeCompra}?${filtro}`);
    } catch (error) {
      throw error;
    }
  }
  async gerarPdf(id: string) {
    try {
      return await this.api.download(
        `${Endpoints.pedidosDeCompra}/${id}/download`
      );
    } catch (error) {
      throw error;
    }
  }
  async obterInformacoesRelatorio(filtro: string = "") {
    try {
      return await this.api.download(
        `${Endpoints.pedidosDeCompra}/relatorio?${filtro}`
      );
    } catch (error) {
      throw error;
    }
  }
  async carregarAnexos(form: FormData, id: string) {
    try {
      const {
        data: {
          data: { url, mimetype, arquivo: filename },
        },
      } = await this.api.upload(Endpoints.arquivosPedidoDeCompra, form);
      await this.api.post(`${Endpoints.pedidosDeCompra}/${id}/anexos`, [
        {
          url,
          mimetype,
          filename,
        },
      ]);
      return { url, mimetype, filename };
    } catch (error) {
      throw error;
    }
  }
  async obterPorId(id: string) {
    try {
      return await this.api.get(`${Endpoints.pedidosDeCompra}/${id}`);
    } catch (error) {
      throw error;
    }
  }
  async atualizarPedido(id: string, data: AtualizarPedidoDeCompraDto) {
    try {
      return await this.api.patch(`${Endpoints.pedidosDeCompra}/${id}`, data);
    } catch (error) {
      throw error;
    }
  }
  async confirmarRecebimento(id: string) {
    try {
      return await this.api.post(
        `${Endpoints.pedidosDeCompra}/${id}${Endpoints.confirmarRecebimentoPedidosDeCompra}`,
        {}
      );
    } catch (error) {
      throw error;
    }
  }
  async reenviarPedido(id: string, email: string) {
    try {
      return await this.api.post(
        `${Endpoints.pedidosDeCompra}/${id}${Endpoints.reenviarPedido}`,
        { email }
      );
    } catch (error) {
      throw error;
    }
  }
  async cancelarPedido(id: string) {
    try {
      return await this.api.patch(
        `${Endpoints.pedidosDeCompra}/${id}${Endpoints.cancelar}`,
        {}
      );
    } catch (error) {
      throw error;
    }
  }
}
