import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Modal,
  PageHeader,
  Radio,
  Select,
  Table,
  Tag,
  message,
} from "antd";
import {
  CheckCircleTwoTone,
  ClearOutlined,
  DeleteTwoTone,
  EditTwoTone,
  FileExcelOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import {
  StatusContaAReceberDescricao,
  StatusContaAReceberDescricaoCor,
  statusContaAReceberList,
} from "../../../core/enums/status-conta-a-receber.enum";
import {
  StatusParcela,
  StatusParcelaDescricao,
  StatusParcelaList,
} from "../../../core/enums/status-parcela.enum";
import { useEffect, useState } from "react";

import { Action } from "../../../core/permissions/actions/actions";
import { Can } from "../../../core/permissions/permission.context";
import { ContaAReceber } from "../../../core/permissions/subjects";
import { ContaAReceberService } from "../../../services/conta-a-receber/conta-a-receber.service";
import { ContaBancariaService } from "../../../services/conta-bancaria/conta-bancaria.service";
import CurrencyInput from "react-currency-input-field";
import { Endpoints } from "../../../core/constants/endpoints";
import NovaContaAReceber from "./nova-conta-a-receber.page";
import { RegistrarPagamentoContaAReceberDto } from "../../../services/conta-a-receber/dto/registrar-pagamento-conta-a-receber.dto";
import c from "currency-formatter";
import { currencyFormatter } from "../../../core/utils/formatters/currency.formatter";
import { identificadorFormatter } from "../../../core/utils/formatters/job.formatter";
import moment from "moment";
import { saveAs } from "file-saver";
import { sum } from "lodash";
import useSWR from "swr";

const { RangePicker } = DatePicker;

const _contaAReceberService = new ContaAReceberService();
export default function ContasAReceber() {
  const history = useHistory();
  const [filtro, setFiltro] = useState("");
  const [revalidar, setRevalidar] = useState(Date.now());
  const [filtrosFormRef] = Form.useForm();
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalItens, setTotalItens] = useState(10);
  const [novaContaAReceber, setNovaContaAReceber] = useState(false);
  const [parcelaParaPagamento, setParcelaParaPagamento] = useState(null);
  const [gerarRelatorioContasAReceber, setGerarRelatorioContasAReceber] =
    useState(false);
  const [parcela, setParcela] = useState(null);
  const { data: contasAReceber, error } = useSWR(
    Endpoints.contasAReceber + filtro + novaContaAReceber + revalidar,
    () => _contaAReceberService.obterTodas(filtro),
    { revalidateOnFocus: false }
  );

  function salvarCachePaginacao(paginaAtual: number, totalItens: number) {
    sessionStorage.setItem(
      "paginacao-conta-a-receber",
      JSON.stringify({
        paginaAtual,
        totalItens,
      })
    );
  }
  function verificarCachePaginacao() {
    let paginacaoCache = JSON.parse(
      sessionStorage.getItem("paginacao-conta-a-receber")
    );
    if (paginacaoCache?.paginaAtual) {
      setPaginaAtual(paginacaoCache?.paginaAtual);
    }
    if (paginacaoCache?.totalItens) {
      setTotalItens(paginacaoCache?.totalItens);
    }
  }

  useEffect(() => {
    verificarCachePaginacao();
    let filterCache = JSON.parse(
      sessionStorage.getItem("filtros-conta-a-receber")
    );
    if (filterCache) {
      setFiltro(filterCache?.query);
      if (filterCache?.status?.length > 0) {
        filtrosFormRef.setFieldsValue({
          status: filterCache?.status,
        });
      }
      if (filterCache?.intervalo) {
        const [inicial, final] = filterCache?.intervalo;
        filtrosFormRef.setFieldsValue({
          intervalo: [moment(inicial), moment(final)],
        });
      }
    }
  }, [history.location]);

  function EditarParcela() {
    const [parcelaFormRef] = Form.useForm();
    return (
      <Modal
        okText="ATUALIZAR"
        cancelText="CANCELAR"
        visible={parcela}
        width={600}
        bodyStyle={{ padding: 0 }}
        closable={false}
        onOk={async () => {
          try {
            const p = parcelaFormRef.getFieldValue("parcela");
            await _contaAReceberService.atualizarParcela(parcela?.contaAPagar, {
              id: parcela?.id,
              dataVencimento: p?.dataVencimento,
              status: parcela?.status,
            });

            setParcela(null);
          } catch (error) {
            if (error?.response?.data?.message && typeof Array) {
              for (const e of error?.response?.data?.message
                .toString()
                .split(",")) {
                message.error(e);
              }
            } else {
              message.error(error?.message);
            }
          }
        }}
        onCancel={() => {
          setParcela(null);
          parcelaFormRef.resetFields();
        }}
      >
        <Card title={`Alterar vencimento - ${parcela?.descricao}`}>
          <Form layout="vertical" form={parcelaFormRef}>
            <Input.Group compact>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Data de vencimento é obrigatório",
                  },
                ]}
                style={{ width: "30%", paddingRight: 5 }}
                name={["parcela", "dataVencimento"]}
                label="Novo vencimento"
                initialValue={moment(parcela?.dataVencimento)}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  style={{ width: "100%" }}
                  placeholder="Vencimento em"
                />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Descrição é obrigatório",
                  },
                ]}
                initialValue={parcela?.descricao}
                style={{ width: "40%", paddingRight: 5 }}
                name={["parcela", "descricao"]}
                label="Descrição"
              >
                <Input
                  style={{ width: "100%" }}
                  placeholder="Descrição"
                  disabled={true}
                />
              </Form.Item>

              <Form.Item
                style={{ width: "30%", paddingRight: 5 }}
                name={["parcela", "status"]}
                rules={[
                  {
                    required: true,
                    message: "Status é obrigatório",
                  },
                ]}
                initialValue={parcela?.status}
                label="Situação"
              >
                <Select
                  disabled={true}
                  style={{ width: "100%" }}
                  placeholder="Selecione o Status"
                  options={StatusParcelaList}
                />
              </Form.Item>
            </Input.Group>
          </Form>
        </Card>
      </Modal>
    );
  }

  function RegistrarPagamento() {
    const [registrarPagamentoFormRef] = Form.useForm();
    const [optsContasBancarias, setOptsContasBancarias] = useState([]);
    const [loadingOptsContasBancarias, setLoadingOptsContasBancarias] =
      useState(false);
    const _contaBancariaService = new ContaBancariaService();
    return (
      <Modal
        okText="REGISTRAR PAGAMENTO"
        cancelText="CANCELAR"
        visible={parcelaParaPagamento}
        width={800}
        bodyStyle={{ padding: 0 }}
        closable={false}
        onOk={async () => {
          await registrarPagamentoFormRef.validateFields();
          try {
            const p = registrarPagamentoFormRef.getFieldValue("parcela");
            const dto: RegistrarPagamentoContaAReceberDto = {
              parcela: { id: parcelaParaPagamento?.id },
              contaBancaria: { id: p?.contaBancaria },
              dataPagamento: p?.dataPagamento?.toISOString(),
              descontoRiscoSacado: p?.descontoRiscoSacado || 0,
            };
            const response = await _contaAReceberService.registrarPagamento(
              parcelaParaPagamento.contaAPagar,
              dto
            );
            setParcelaParaPagamento(null);
          } catch (error) {
            if (error?.response?.data?.message && typeof Array) {
              for (const e of error?.response?.data?.message
                .toString()
                .split(",")) {
                message.error(e);
              }
            } else {
              message.error(error?.message);
            }
          }
        }}
        onCancel={() => {
          setParcelaParaPagamento(null);
          registrarPagamentoFormRef.resetFields();
        }}
      >
        <Card
          title={`Registrar Pagamento de ${parcelaParaPagamento?.descricao}`}
        >
          <Form layout="vertical" form={registrarPagamentoFormRef}>
            <Input.Group compact>
              <Form.Item
                style={{ width: "33%", paddingRight: 5 }}
                name={["parcela", "dataPagamento"]}
                label="Data Pagamento"
                rules={[
                  { required: true, message: "Informe a data de pagamento" },
                ]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  style={{ width: "100%" }}
                  placeholder="Pago em"
                />
              </Form.Item>
              <Form.Item
                style={{ width: "33%", paddingRight: 5 }}
                rules={[
                  { required: true, message: "Conta bancária é obrigatório" },
                ]}
                name={["parcela", "contaBancaria"]}
                initialValue={parcela?.status}
                label="Conta bancária"
              >
                <Select
                  loading={loadingOptsContasBancarias}
                  style={{ width: "100%" }}
                  onClick={async () => {
                    if (optsContasBancarias?.length === 0) {
                      setLoadingOptsContasBancarias(true);
                      const response = await _contaBancariaService.obterTodas();
                      const opts = response?.data?.data?.map((c) => {
                        return {
                          key: c.id,
                          value: c.id,
                          label: c?.descricao,
                        };
                      });

                      setOptsContasBancarias(opts);
                      setLoadingOptsContasBancarias(false);
                    }
                  }}
                  placeholder="Selecione a conta bancária"
                  options={optsContasBancarias}
                />
              </Form.Item>
              <Form.Item
                label="Desconto risco sacado ?"
                style={{ width: "33%", paddingRight: 5 }}
              >
                <CurrencyInput
                  name="valor"
                  placeholder="Valor"
                  className="ant-input ant-input-sm"
                  decimalScale={2}
                  onValueChange={(value = "0") => {
                    registrarPagamentoFormRef.setFieldsValue({
                      parcela: {
                        descontoRiscoSacado: c.unformat(value, {
                          code: "BRL",
                        }),
                      },
                    });
                  }}
                  intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                />
              </Form.Item>
            </Input.Group>
          </Form>
        </Card>
      </Modal>
    );
  }

  async function estornarPagamentoHandler(parcelaId: string) {
    try {
      await _contaAReceberService.estornarPagamento(parcelaId);
      message.success("Pagamento estornado");
      setRevalidar(Date.now());
    } catch (error) {
      message.error(error.response?.data?.message || error.message);
    }
  }
  function definirFiltros(values: any) {
    try {
      const { intervalo, status } = values;
      const [dataInicio, dataFim] = intervalo || [];

      let query = `${
        status ? `status=${status.map((v) => v.key).toString()}&` : ""
      }${
        intervalo
          ? `intervalo=${dataInicio.toString()},${dataFim.toString()}`
          : ""
      }`;

      sessionStorage.setItem(
        "filtros-conta-a-receber",
        JSON.stringify({
          intervalo,
          status,
          query,
        })
      );
      setFiltro(query);
    } catch (error) {
      throw error;
    }
  }

  if (error) message.error(error?.message);
  function Parcelas({ row }: any) {
    const { parcelas } = row;

    const columns = [
      { title: "Parcela", dataIndex: "descricao", key: "descricao" },
      {
        title: "Valor",
        dataIndex: "valor",
        className: "currency",
        render: (value) => currencyFormatter(value, "BRL"),
      },

      {
        title: "Data Vencimento",
        dataIndex: "dataVencimento",
        render: (value) => moment(value).format("DD/MM/YYYY"),
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (value) => (
          <Tag color={StatusParcelaDescricao[value].cor}>
            {StatusParcelaDescricao[value].label}
          </Tag>
        ),
      },
      {
        title: "Ações",
        render: (item) => (
          <>
            <Button
              type="dashed"
              size="small"
              disabled={item?.status === StatusParcela.Paga}
              style={{ marginRight: 3 }}
              onClick={() => setParcela(item)}
              icon={<EditTwoTone />}
            >
              Alterar Vencimento
            </Button>
            {item?.status !== StatusParcela.Paga && (
              <Button
                icon={<CheckCircleTwoTone />}
                size="small"
                type="dashed"
                onClick={() => setParcelaParaPagamento(item)}
              >
                Registrar Pagamento
              </Button>
            )}
            {item?.status === StatusParcela.Paga && (
              <Button
                icon={<DeleteTwoTone />}
                size="small"
                type="dashed"
                onClick={() => estornarPagamentoHandler(item.id)}
              >
                estornar Pagamento
              </Button>
            )}
            {item?.status === StatusParcela.Conciliada && (
              <Button
                icon={<DeleteTwoTone />}
                size="small"
                type="dashed"
                disabled
              >
                estornar Pagamento
              </Button>
            )}
          </>
        ),
        width: 380,
      },
    ];

    return (
      <Table
        size="small"
        rowKey="id"
        columns={columns}
        dataSource={parcelas}
        pagination={false}
      />
    );
  }

  return (
    <div>
      <RegistrarPagamento />
      <EditarParcela />
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title="Contas A Receber"
        subTitle="Todas as Contas a receber"
        extra={
          <>
            <Button
              size="middle"
              loading={gerarRelatorioContasAReceber}
              icon={<FileExcelOutlined />}
              onClick={async () => {
                setGerarRelatorioContasAReceber(true);
                const xlsx =
                  await _contaAReceberService.obterInformacoesRelatorio(filtro);

                setGerarRelatorioContasAReceber(false);
                saveAs(xlsx.data, `Relatório.xlsx`);
              }}
            >
              exportar para excel
            </Button>
            <Button
              type="primary"
              size="middle"
              icon={<PlusOutlined />}
              onClick={() => setNovaContaAReceber(true)}
            >
              Nova Conta A Receber
            </Button>
          </>
        }
      />
      <Can do={Action.Ler} on={ContaAReceber} passThrough>
        {(ability) =>
          ability ? (
            <div>
              <NovaContaAReceber
                visible={novaContaAReceber}
                setVisible={setNovaContaAReceber}
              />
              <Card title="Filtros" size="small" style={{ marginTop: 10 }}>
                <Form
                  onFinish={(values) => definirFiltros(values)}
                  layout="inline"
                  form={filtrosFormRef}
                >
                  <Form.Item name="intervaloPredefinido" initialValue={1}>
                    <Radio.Group
                      defaultValue="1"
                      style={{ marginRight: 5 }}
                      onChange={(e) => {
                        filtrosFormRef.setFieldsValue({
                          intervalo: [
                            moment(new Date()),
                            moment(new Date()).add(e.target.value, "days"),
                          ],
                        });
                      }}
                    >
                      {[
                        { label: "Diário", value: 1 },
                        { label: "Semanal", value: 7 },
                        { label: "Mensal", value: 30 },
                      ].map(({ value, label }) => (
                        <Radio.Button value={value}>{label}</Radio.Button>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item name="intervalo">
                    <RangePicker format="DD/MM/yyyy" />
                  </Form.Item>
                  <Form.Item name="status">
                    <Select
                      mode="tags"
                      labelInValue
                      style={{ marginLeft: 5, minWidth: 220, marginRight: 5 }}
                      options={statusContaAReceberList}
                      placeholder="Status"
                    />
                  </Form.Item>
                  <Button
                    type="primary"
                    icon={<SearchOutlined />}
                    htmlType="submit"
                  >
                    Buscar
                  </Button>
                  <Button
                    type="default"
                    icon={<ClearOutlined />}
                    style={{ marginLeft: 4 }}
                    onClick={() => {
                      setFiltro("");
                      sessionStorage.removeItem("filtros-conta-a-receber");
                      filtrosFormRef.resetFields();
                    }}
                  >
                    Limpar filtro
                  </Button>
                </Form>
              </Card>

              <Table
                rowKey="id"
                size="small"
                columns={columns}
                bordered
                style={{ marginTop: 10 }}
                expandable={{
                  expandedRowRender: (row) => <Parcelas row={row} />,
                }}
                loading={!contasAReceber?.data?.data}
                dataSource={contasAReceber?.data?.data}
                onChange={(pagination) => {
                  salvarCachePaginacao(pagination.current, pagination.pageSize);
                  setPaginaAtual(pagination.current);
                  setTotalItens(pagination.pageSize);
                }}
                pagination={{ current: paginaAtual, pageSize: totalItens }}
              />
            </div>
          ) : (
            <p>Sem Permissão...</p>
          )
        }
      </Can>
    </div>
  );
}

const columns = [
  { key: "id", title: "Descrição", dataIndex: "descricao" },
  {
    title: "JOB",
    dataIndex: "job",
    key: "job",
    render: (_, obj) =>
      obj?.job
        ? identificadorFormatter(String(obj?.job?.identificador))
        : "N/A",
  },
  {
    title: "Valor Total",
    dataIndex: "valor",
    key: "valor",
    className: "currency",
    render: (_, obj) =>
      currencyFormatter(
        obj?.valor + obj?.descontos + obj?.descontosTomador,
        "BRL"
      ),
  },

  {
    title: "impostos federais",
    dataIndex: "descontosTomador",
    key: "descontosTomador",
    className: "currency-negative",
    render: (value) => currencyFormatter(value, "BRL"),
  },
  {
    title: "impostos municipais",
    dataIndex: "descontos",
    key: "descontos",
    className: "currency-negative",
    render: (value) => currencyFormatter(value, "BRL"),
  },
  {
    title: "RISCO SACADO",
    dataIndex: "parcelas",
    key: "parcelas",
    className: "currency-negative",
    render: (value) =>
      currencyFormatter(
        value ? sum(value?.map((v) => Number(v.descontoRiscoSacado))) : 0,
        "BRL"
      ),
  },
  {
    key: "dataVencimento",
    title: "Data Vencimento",
    dataIndex: "dataVencimento",
    render: (value) => moment(value).format("DD/MM/YYYY"),
  },

  {
    title: "GRUPO FINANCEIRO",
    dataIndex: "grupoFinanceiro",
    key: "grupoFinanceiro",
    render: (_, obj) => <Tag>{obj?.grupoFinanceiro?.grupo}</Tag>,
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (value) => (
      <Tag color={StatusContaAReceberDescricaoCor[value]}>
        {StatusContaAReceberDescricao[value]}
      </Tag>
    ),
  },
  {
    title: "Ação",
    width: 100,
    key: "x",
    render: (value, obj) => (
      <a>
        <Link to={`contas-a-receber/${obj.id}`}>Acessar</Link>
      </a>
    ),
  },
];

export const ContasAReceberRoute = {
  id: "contas-a-receber",
  path: "/inicio/financeiro/contas-a-receber",
  sidebar: "Contas A Receber",
  exact: true,
  renderInMenu: true,
  main: () => <ContasAReceber />,
  icon: <PlusCircleOutlined />,
  permission: ContaAReceber,
};
