import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  PageHeader,
  Popconfirm,
  Table,
  Tag,
  message,
} from "antd";
import { Link, useHistory } from "react-router-dom";
import {
  PlusOutlined,
  SendOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";

import { Endpoints } from "../../../core/constants/endpoints";
import { Fornecedor } from "../../../core/permissions/subjects";
import { FornecedorService } from "../../../services/fornecedor/fornecedor.service";
import NovoFornecedorModal from "./novo-fornecedor.modal";
import { cnpjFormatter } from "../../../core/utils/formatters/cnpj.formatter";
import moment from "moment";
import useSWR from "swr";
import useTitle from "../../../core/hooks/title.hook";

const _fornecedorService = new FornecedorService();
export default function Fornecedores() {
  const [cadastrarFornecedor, setCadastrarFornecedor] = useState(false);
  const [fornecedorSelecionado, setFornecedorSelecionado] = useState<any>(null);
  const [filtro, setFiltro] = useState("");
  const [atualizarLista, setAtualizarLista] = useState<any>(0);
  const { data: fornecedores, error } = useSWR(
    Endpoints.fornecedores + filtro + atualizarLista + cadastrarFornecedor,
    () => _fornecedorService.obterTodos(filtro),
    { revalidateOnFocus: false }
  );

  if (error) message.error(error.message);
  useTitle(`Fornecedores`);
  const history = useHistory();

  function InativarFornecedor() {
    const [formInativar] = Form.useForm();
    async function inativarFornecedorHandler() {
      try {
        const motivo = formInativar.getFieldValue("motivo");
        await _fornecedorService.inativarCadastro(
          fornecedorSelecionado?.id,
          motivo
        );
        message.success(
          `Fornecedor ${fornecedorSelecionado?.nomeFantasia} foi ${
            fornecedorSelecionado?.ativo ? "Inativado" : "Ativado"
          }`
        );
        setFornecedorSelecionado(null);
        setAtualizarLista(Date.now());
      } catch (error) {
        message.error(error.response.data.message || error.message);
      }
    }

    return (
      <Modal
        closable={false}
        bodyStyle={{ padding: 0 }}
        visible={fornecedorSelecionado !== null}
        footer={null}
      >
        <Card
          title={`${
            fornecedorSelecionado?.ativo ? "Inativar" : "Reativar"
          } Fornecedor ${fornecedorSelecionado?.nomeFantasia}`}
        >
          <Form
            layout="vertical"
            form={formInativar}
            onFinish={async () => await inativarFornecedorHandler()}
          >
            <Form.Item rules={[{ required: true, max: 400 }]} name="motivo">
              <Input.TextArea
                placeholder={`Informe o motivo da ${
                  fornecedorSelecionado?.ativo ? "inativação" : "reativação"
                }`}
              />
            </Form.Item>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                size="middle"
                onClick={() => {
                  setFornecedorSelecionado(null);
                }}
                style={{ marginRight: 5 }}
              >
                Cancelar
              </Button>
              <Button type="primary" size="middle" htmlType="submit">
                {fornecedorSelecionado?.ativo ? "Inativar" : "Reativar"}
              </Button>
            </div>
          </Form>
        </Card>
      </Modal>
    );
  }

  const columns = [
    {
      title: "CNPJ",
      dataIndex: "cnpj",
      width: 150,
      render: (value, obj) => (
        <Link to={`/inicio/atendimento/fornecedores/${obj.id}`}>
          {cnpjFormatter(value)}
        </Link>
      ),
    },
    { title: "Nome Fantasia", dataIndex: "nomeFantasia" },
    {
      title: "Razão Social",
      dataIndex: "razaoSocial",
      render: (value) => (value ? value : "N/A"),
    },

    {
      title: "Cadastro Verificado",
      dataIndex: "cadastroVerificado",
      width: 175,
      render: (value, row) =>
        value ? (
          <Tag color="green">VERIFICADO</Tag>
        ) : (
          <>
            <Tag color="red">NÃO VERIFICADO</Tag>
            <Popconfirm
              placement="top"
              title={`Reenviar confirmação de cadastro para  ${row?.nomeFantasia} ?`}
              onConfirm={async () => {
                await _fornecedorService.reenviarConfirmacaoCadastro(row?.id);
                message.success(
                  `Confirmação de cadastro reenviada para ${row?.nomeFantasia}`
                );
              }}
              okText="SIM"
              cancelText="Não"
            >
              <Button
                type="link"
                size="middle"
                about="Reenviar confirmação"
                icon={<SendOutlined />}
              />
            </Popconfirm>
          </>
        ),
    },
    {
      title: "Data de Cadastro",
      width: 140,
      dataIndex: "createdAt",
      render: (value) => moment(value).format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "Status",
      dataIndex: "ativo",
      width: 80,
      render: (value) =>
        value ? (
          <Tag color="green">ATIVO</Tag>
        ) : (
          <Tag color="orange">INATIVO</Tag>
        ),
    },
    {
      title: "Ação",
      width: 160,
      dataIndex: "createdAt",
      render: (_, obj) => (
        <>
          <a style={{ paddingRight: 8 }}>
            <Link to={`/inicio/atendimento/fornecedores/${obj.id}`}>
              Acessar
            </Link>
          </a>

          <Popconfirm
            placement="bottom"
            title={`Deseja  ${
              obj?.ativo ? "inativar" : "ativar"
            } o cadastro de ${obj?.nomeFantasia} ?`}
            onConfirm={() => setFornecedorSelecionado(obj)}
            okText="SIM"
            cancelText="Não"
          >
            <a style={{ color: obj?.ativo ? "#900c3f" : "#1B4F72" }}>
              {obj?.ativo ? "Inativar" : "Ativar"}
            </a>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <div>
      <InativarFornecedor />
      <NovoFornecedorModal
        visible={cadastrarFornecedor}
        setVisible={setCadastrarFornecedor}
      />
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title="Fornecedores"
        subTitle="Todos os forncedores"
        extra={
          <Button
            type="primary"
            size="middle"
            icon={<PlusOutlined />}
            onClick={() => setCadastrarFornecedor(true)}
          >
            Novo Fornecedor
          </Button>
        }
      />
      <div style={{ paddingTop: 10 }}>
        <Input.Search
          placeholder="Buscar..."
          size="middle"
          onSearch={(value) => setFiltro(value)}
        />
      </div>
      <Table
        rowKey="id"
        loading={!fornecedores}
        columns={columns}
        bordered
        style={{ marginTop: 10 }}
        dataSource={fornecedores?.data?.data || []}
        scroll={{ y: 450 }}
      />
    </div>
  );
}

export const FornecedoresRoute = {
  id: "fornecedores",
  path: "/inicio/atendimento/fornecedores",
  sidebar: "Fornecedores",
  exact: true,
  renderInMenu: true,
  main: () => <Fornecedores />,
  icon: <SolutionOutlined />,
  permission: Fornecedor,
};
