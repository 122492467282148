import { AtualizarPrazoDePagamentoDto } from "./dto/atualizar-prazo-de-pagamento.dto";
import { BaseService } from "../base.service";
import { Endpoints } from "../../core/constants/endpoints";
import { NovoPrazoDePagamentoDto } from "./dto/novo-prazo-de-pagamento.dto";

export class PrazoDePagamentoService extends BaseService {
  async obterTodas() {
    try {
      return await this.api.get(Endpoints.prazosDePagamento);
    } catch (error) {
      throw error;
    }
  }
  async obterPorId(id: string) {
    try {
      return await this.api.get(`${Endpoints.prazosDePagamento}/${id}`);
    } catch (error) {
      throw error;
    }
  }
  async inativar(id: string) {
    try {
      return await this.api.patch(
        `${Endpoints.prazosDePagamento}/${id}/inativar`
      );
    } catch (error) {
      throw error;
    }
  }
  async obterPrazosDePagamentoSimplificado(filtro: string) {
    try {
      if (filtro) {
        return await this.api.get(
          `${Endpoints.prazosDePagamentoSimplificado}?filtro=${filtro}`
        );
      }
      return await this.api.get(`${Endpoints.prazosDePagamentoSimplificado}`);
    } catch (error) {
      throw error;
    }
  }
  async cadastrarPrazoDePagamento(dto: NovoPrazoDePagamentoDto) {
    try {
      return await this.api.post(Endpoints.prazosDePagamento, dto);
    } catch (error) {
      throw error;
    }
  }
  async atualizarPrazoDePagamento(
    id: string,
    dto: AtualizarPrazoDePagamentoDto
  ) {
    try {
      return await this.api.patch(`${Endpoints.prazosDePagamento}/${id}`, dto);
    } catch (error) {
      throw error;
    }
  }
}
