import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  PageHeader,
  Select,
  Table,
  Tabs,
  Tag,
  message,
} from "antd";
import {
  ClearOutlined,
  FileExcelOutlined,
  SearchOutlined,
  TagsOutlined,
} from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import {
  StatusPedidoDeCompraInfo,
  statusPedidoDeCompraList,
} from "../../../core/enums/status-pedido-de-compra.enum";
import {
  StatusRequisicaoDeCompraInfo,
  statusRequisicaoDeCompraList,
} from "../../../core/enums/status-requisicao-de-compra.enum";
import {
  sequencialPedidoDeCompraFormatter,
  sequencialRequisicaoDeCompraFormatter,
} from "../../../core/utils/formatters/sequencial.formatter";
import { useEffect, useState } from "react";

import { ColaboradorService } from "../../../services/colaborador/colaborador.service";
import { EmpresaService } from "../../../services/empresa/empresas.service";
import { Endpoints } from "../../../core/constants/endpoints";
import { FornecedorService } from "../../../services/fornecedor/fornecedor.service";
import { PedidoDeCompra } from "../../../core/permissions/subjects";
import { PedidoDeCompraService } from "../../../services/pedido-de-compra/pedido-de-compra.service";
import RequisicaoDeCompraModal from "../../atendimento/requisicao-de-compra/requisicao-de-compra.modal";
import { RequisicaoDeCompraService } from "../../../services/requisicao-de-compra/requisicao-de-compra.service";
import { currencyFormatter } from "../../../core/utils/formatters/currency.formatter";
import { identificadorFormatter } from "../../../core/utils/formatters/job.formatter";
import moment from "moment";
import { saveAs } from "file-saver";
import useBuscarJobHook from "../../../components/hooks/buscar-job.hook";
import { useQuery } from "../../../components/hooks/use-query.hook";
import useSWR from "swr";

export default function PedidosDeCompra() {
  const queryParams = useQuery();

  const _requisicoesDeCompraService = new RequisicaoDeCompraService();
  const _pedidosDeCompraService = new PedidoDeCompraService();
  const _empresaService = new EmpresaService();
  const _colaboradorService = new ColaboradorService();
  const _fornecedorService = new FornecedorService();

  const [filtrosRequisicoesRef] = Form.useForm();
  const [filtrosPedidosRef] = Form.useForm();
  const [abaAtiva, setAbaAtiva] = useState("requisicoes");
  const history = useHistory();
  const {
    loading: loadingJobs,
    options: optsJob,
    filterOption: filterOptionJob,
    buscarJob,
  } = useBuscarJobHook();

  const [filtroRequisicoes, setFiltrosRequisicoes] = useState("status=0,4");
  const [filtroPedidos, setFiltroPedidos] = useState("");
  const [requisicaoId, setRequisicaoId] = useState(null);
  const [gerarRelatorioPedidosDeCompra, setGerarRelatorioPedidosDeCompra] =
    useState(false);
  const [statusRequisicao, setStatusRequisicao] = useState(null);
  const [optionsFornecedor, setOptionsFornecedor] = useState([]);
  const [paginaAtualRequisicoes, setPaginaAtualRequisicoes] = useState(1);
  const [paginaAtualPedidos, setPaginaAtualPedidos] = useState(1);
  const [totalItensPedidos, setTotalItensPedidos] = useState(10);
  const [totalItensRequisicoes, setTotalItensRequisicoes] = useState(10);
  const [optionsFornecedorSearch, setOptionsFornecedorSearch] = useState(false);
  const [optionsSolicitante, setOptionsSolicitante] = useState([]);
  const [optionsSolicitanteSearch, setOptionsSolicitanteSearch] =
    useState(false);
  const [optionsEmpresa, setOptionsEmpresa] = useState([]);
  const [optionsEmpresaSearch, setOptionsEmpresaSearch] = useState(false);
  const { data: { data: { data: requisicoesDeCompra } = {} } = {}, error } =
    useSWR(
      Endpoints.requisicoesDeCompra + filtroRequisicoes + requisicaoId,
      () => _requisicoesDeCompraService.obterTodos(filtroRequisicoes),
      { revalidateOnFocus: false }
    );

  const {
    data: { data: { data: pedidosDeCompra } = {} } = {},
    error: errorPedidosDeCompra,
  } = useSWR(
    Endpoints.pedidosDeCompra + filtroPedidos,
    () => _pedidosDeCompraService.obterTodos(filtroPedidos),
    { revalidateOnFocus: false }
  );

  function verificarPaginacaoRequisicoesEmCache() {
    const paginaAtualRequisicoesCache = JSON.parse(
      sessionStorage.getItem("paginacao-requisicoes")
    );
    if (paginaAtualRequisicoesCache?.paginaAtual) {
      setPaginaAtualRequisicoes(paginaAtualRequisicoesCache?.paginaAtual);
    }
    if (paginaAtualRequisicoesCache?.totalItens) {
      setTotalItensRequisicoes(paginaAtualRequisicoesCache?.totalItens);
    }
  }
  function verificarPaginacaoPedidosEmCache() {
    const paginaAtualPedidosCache = JSON.parse(
      sessionStorage.getItem("paginacao-pedidos")
    );
    if (paginaAtualPedidosCache?.paginaAtual) {
      setPaginaAtualPedidos(paginaAtualPedidosCache?.paginaAtual);
    }
    if (paginaAtualPedidosCache?.totalItens) {
      setTotalItensPedidos(paginaAtualPedidosCache?.totalItens);
    }
  }

  function salvarCachePaginacaoRequisicoes(
    paginaAtual: number,
    totalItens: number
  ) {
    sessionStorage.setItem(
      "paginacao-requisicoes",
      JSON.stringify({
        paginaAtual,
        totalItens,
      })
    );
  }
  function salvarCachePaginacaoPedidos(
    paginaAtual: number,
    totalItens: number
  ) {
    sessionStorage.setItem(
      "paginacao-pedidos",
      JSON.stringify({ paginaAtual, totalItens })
    );
  }

  function verificarFiltrosRequisicoesEmCache() {
    let filterCacheRequisicao = JSON.parse(
      sessionStorage.getItem("filtros-requisicoes")
    );
    if (filterCacheRequisicao) {
      setFiltrosRequisicoes(filterCacheRequisicao?.query);
      if (filterCacheRequisicao?.status?.length > 0) {
        filtrosRequisicoesRef.setFieldsValue({
          status: filterCacheRequisicao?.status,
        });
      }
      if (filterCacheRequisicao?.intervalo) {
        const [inicial, final] = filterCacheRequisicao?.intervalo;
        filtrosRequisicoesRef.setFieldsValue({
          intervalo: [moment(inicial), moment(final)],
        });
      }
      if (filterCacheRequisicao?.optionsFornecedor) {
        setOptionsFornecedor(filterCacheRequisicao?.optionsFornecedor);
        filtrosRequisicoesRef.setFieldsValue({
          fornecedor: filterCacheRequisicao?.fornecedor,
        });
      }
      if (filterCacheRequisicao?.optionsEmpresa) {
        setOptionsEmpresa(filterCacheRequisicao?.optionsEmpresa);
        filtrosRequisicoesRef.setFieldsValue({
          empresa: filterCacheRequisicao?.empresa,
        });
      }
      if (filterCacheRequisicao?.optionsSolicitante) {
        setOptionsEmpresa(filterCacheRequisicao?.optionsSolicitante);
        filtrosRequisicoesRef.setFieldsValue({
          solicitante: filterCacheRequisicao?.solicitante,
        });
      }
    }
  }
  function verificarFiltrosPedidosEmCache() {
    let filterCachePedidos = JSON.parse(
      sessionStorage.getItem("filtros-pedidos")
    );
    if (filterCachePedidos) {
      setFiltroPedidos(filterCachePedidos?.query);
      if (filterCachePedidos?.status?.length > 0) {
        filtrosPedidosRef.setFieldsValue({
          status: filterCachePedidos?.status,
        });
      }
      if (filterCachePedidos?.intervalo) {
        const [inicial, final] = filterCachePedidos?.intervalo;
        filtrosPedidosRef.setFieldsValue({
          intervalo: [moment(inicial), moment(final)],
        });
      }
      if (filterCachePedidos?.optionsFornecedor) {
        setOptionsFornecedor(filterCachePedidos?.optionsFornecedor);
        filtrosPedidosRef.setFieldsValue({
          fornecedor: filterCachePedidos?.fornecedor,
        });
      }
      if (filterCachePedidos?.optionsEmpresa) {
        setOptionsEmpresa(filterCachePedidos?.optionsEmpresa);
        filtrosPedidosRef.setFieldsValue({
          empresa: filterCachePedidos?.empresa,
        });
      }
      if (filterCachePedidos?.job) {
        filtrosPedidosRef.setFieldsValue({
          job: filterCachePedidos?.job,
        });
      }
    }
  }

  function handleSearchRequisicoes(values: any) {
    const { intervalo, status, empresa, solicitante, fornecedor } = values;

    const [dataInicio, dataFim] = intervalo || [];
    let query = `${status ? `status=${status.toString()}` : "status=0"}${
      intervalo
        ? `&intervalo=${dataInicio.toString()},${dataFim.toString()}`
        : ""
    }${empresa ? `&empresa=${empresa}` : ""}
    ${fornecedor ? `&fornecedor=${fornecedor}` : ""}
    ${solicitante ? `&solicitante=${solicitante}` : ""}`;

    sessionStorage.setItem(
      "filtros-requisicoes",
      JSON.stringify({
        intervalo,
        status,
        empresa,
        solicitante,
        fornecedor,
        query,
        optionsFornecedor: optionsFornecedor ? optionsFornecedor : null,
        optionsEmpresa: optionsEmpresa ? optionsEmpresa : null,
        optionsSolicitante: optionsSolicitante ? optionsSolicitante : null,
      })
    );
    setFiltrosRequisicoes(query);
  }

  function handleSearchPedidos(values: any) {
    const { intervalo, status, solicitante, fornecedor, job, sequencial } =
      values;

    const [dataInicio, dataFim] = intervalo || [];
    let query = `${status ? `status=${status.toString()}` : ""}${
      intervalo
        ? `&intervalo=${dataInicio.toString()},${dataFim.toString()}`
        : ""
    }${sequencial ? `&sequencial=${sequencial}` : ""}
    ${fornecedor ? `&fornecedor=${fornecedor}` : ""}
    ${solicitante ? `&solicitante=${solicitante}` : ""}
    ${job ? `&job=${job}` : ""}`;

    sessionStorage.setItem(
      "filtros-pedidos",
      JSON.stringify({
        intervalo,
        status,
        sequencial,
        solicitante,
        fornecedor,
        job,
        query,
        optionsFornecedor: optionsFornecedor ? optionsFornecedor : null,
        optionsSolicitante: optionsSolicitante ? optionsSolicitante : null,
      })
    );
    setFiltroPedidos(query);
  }

  const columnsRequisicaoDeCompra = [
    {
      title: "Requisição",
      dataIndex: "sequencial",
      width: 105,
      render: (value) => sequencialRequisicaoDeCompraFormatter(String(value)),
    },

    {
      title: "Job",
      width: 100,
      dataIndex: "job",
      render: (value) => (
        <a>
          <Link to={`/inicio/atendimento/jobs/${value.id}`}>
            {identificadorFormatter(value.identificador)}
          </Link>
        </a>
      ),
    },
    {
      title: "Empresa",
      dataIndex: "empresa",
      render: (value) => (
        <a>
          <Link to={`/inicio/financeiro/empresas/${value?.id}`}>
            {value?.nomeFantasia}
          </Link>
        </a>
      ),
    },

    {
      title: "Fornecedor",
      dataIndex: "fornecedor",
      render: (value) => (
        <a>
          <Link to={`/inicio/atendimento/fornecedores/${value?.id}`}>
            {value?.nomeFantasia}
          </Link>
        </a>
      ),
    },
    {
      title: "Valor",
      width: 140,
      className: "currency",
      dataIndex: "valorTotal",
      render: (value) => currencyFormatter(Number(value), "BRL"),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 220,
      render: (value) => (
        <Tag color={StatusRequisicaoDeCompraInfo[value].color}>
          {StatusRequisicaoDeCompraInfo[value].label}
        </Tag>
      ),
    },
    {
      title: "Data Solicitação",
      width: 140,
      dataIndex: "createdAt",
      render: (value) => moment(value).format("DD/MM/YYYY HH:mm"),
    },

    {
      title: "Ação",
      width: 130,
      render: (_, obj) => (
        <>
          <Button
            type="link"
            size="small"
            onClick={() => {
              setRequisicaoId(obj?.id);
              setStatusRequisicao(obj?.status);
            }}
          >
            Visualizar
          </Button>
        </>
      ),
    },
  ];
  const columnsPedidosDeCompra = [
    {
      title: "Pedido",
      dataIndex: "sequencial",
      width: 105,
      render: (value) => sequencialPedidoDeCompraFormatter(String(value)),
    },
    {
      title: "Job",
      width: 100,
      dataIndex: "job",
      render: (value) => (
        <a>
          <Link to={`/inicio/atendimento/jobs/${value.id}`}>
            {identificadorFormatter(value.identificador)}
          </Link>
        </a>
      ),
    },
    {
      title: "Contratante",
      dataIndex: "empresa",
      render: (value) => (
        <a>
          <Link to={`/inicio/financeiro/empresas/${value?.id}`}>
            {value?.nomeFantasia}
          </Link>
        </a>
      ),
    },
    {
      title: "Fornecedor",
      dataIndex: "fornecedor",
      render: (value) => (
        <a>
          <Link to={`/inicio/atendimento/fornecedores/${value?.id}`}>
            {value?.nomeFantasia}
          </Link>
        </a>
      ),
    },
    {
      title: "Valor",
      width: 120,
      dataIndex: "valorTotal",
      className: "currency",
      render: (value) => currencyFormatter(value, "BRL"),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 210,
      render: (value) => (
        <Tag color={StatusPedidoDeCompraInfo[value].color}>
          {StatusPedidoDeCompraInfo[value].label}
        </Tag>
      ),
    },
    {
      title: "Data do Pedido",
      width: 130,
      dataIndex: "createdAt",
      render: (value) => moment(value).format("DD/MM/YYYY"),
    },

    {
      title: "Ação",
      width: 130,
      render: (_, obj) => (
        <>
          <Button
            type="link"
            size="small"
            onClick={() => history.push(`pedidos-de-compra/${obj?.id}`)}
          >
            Acessar
          </Button>
        </>
      ),
    },
  ];

  useEffect(() => {
    const aba = queryParams.get("aba");

    setAbaAtiva(aba ? aba : "requisicoes");
    if (aba === "pedidos") {
      verificarPaginacaoPedidosEmCache();
      verificarFiltrosPedidosEmCache();
    } else {
      verificarPaginacaoRequisicoesEmCache();
      verificarFiltrosRequisicoesEmCache();
    }
  }, [history.location]);

  if (errorPedidosDeCompra) message.error(errorPedidosDeCompra?.message);
  if (error) message.error(error?.message);

  return (
    <div>
      <RequisicaoDeCompraModal
        requisicaoId={requisicaoId}
        setRequisicaoId={setRequisicaoId}
        status={statusRequisicao}
      />
      <PageHeader
        className="site-page-header"
        onBack={() => {
          history.goBack();
        }}
        title={`Pedidos de compra`}
        subTitle="Todos os pedidos de compra"
        style={{ marginBottom: 10 }}
        extra={
          <>
            <Button
              size="middle"
              icon={<FileExcelOutlined />}
              loading={gerarRelatorioPedidosDeCompra}
              onClick={async () => {
                setGerarRelatorioPedidosDeCompra(true);
                const xlsx =
                  await _pedidosDeCompraService.obterInformacoesRelatorio(
                    filtroPedidos
                  );

                setGerarRelatorioPedidosDeCompra(false);
                saveAs(xlsx.data, `relatorio.xlsx`);
              }}
            >
              exportar para excel
            </Button>
          </>
        }
      />

      <Tabs
        type="card"
        activeKey={abaAtiva}
        onChange={(key) => {
          if (key === "requisicoes") {
            history.push("?aba=requisicoes");
            setAbaAtiva("requisicoes");
          } else {
            history.push("?aba=pedidos");
            setAbaAtiva("pedidos");
          }
        }}
      >
        <Tabs.TabPane
          key="requisicoes"
          tab="Requisições de compra"
          style={{ padding: 0 }}
        >
          <Card style={{ marginBottom: 5 }} title="Filtros" size="small">
            <Form
              form={filtrosRequisicoesRef}
              layout="inline"
              style={{ display: "flex" }}
              onFinish={(values) => handleSearchRequisicoes(values)}
            >
              <Form.Item name="intervalo" style={{ flex: 1, minWidth: 300 }}>
                <DatePicker.RangePicker
                  format="DD/MM/yyyy"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item name="status" style={{ flex: 1 }}>
                <Select
                  placeholder="Status"
                  options={statusRequisicaoDeCompraList}
                  mode="tags"
                />
              </Form.Item>
              <Form.Item style={{ flex: 1 }} name="empresa">
                <Select
                  placeholder="Empresa"
                  showSearch
                  style={{ display: "flex" }}
                  options={optionsEmpresa}
                  loading={optionsEmpresaSearch}
                  filterOption={(input, option) =>
                    option?.label
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSearch={async (value) => {
                    if (value.length >= 3) {
                      setOptionsEmpresaSearch(true);
                      const result =
                        await _empresaService.obterEmpresasSimplificado(value);
                      const opts = result?.data?.data.map((o) => {
                        return {
                          key: o.id,
                          value: o.id,
                          label: o.nomeFantasia,
                        };
                      });
                      setOptionsEmpresa(opts);
                      setOptionsEmpresaSearch(false);
                    }
                  }}
                />
              </Form.Item>

              <Form.Item name="solicitante" style={{ flex: 1 }}>
                <Select
                  placeholder="Solicitante"
                  showSearch
                  options={optionsSolicitante}
                  loading={optionsSolicitanteSearch}
                  filterOption={(input, option) =>
                    option?.label
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSearch={async (value) => {
                    if (value.length >= 3) {
                      setOptionsSolicitanteSearch(true);
                      const result =
                        await _colaboradorService.obterSimplificado(value);
                      const opts = result?.data?.data.map((o) => {
                        return {
                          key: o.id,
                          value: o.id,
                          label: o.nome,
                        };
                      });
                      setOptionsSolicitante(opts);
                      setOptionsSolicitanteSearch(false);
                    }
                  }}
                />
              </Form.Item>
              <Form.Item name="fornecedor" style={{ flex: 1 }}>
                <Select
                  placeholder="Fornecedor"
                  showSearch
                  options={optionsFornecedor}
                  loading={optionsFornecedorSearch}
                  filterOption={false}
                  onSearch={async (value) => {
                    if (value.length >= 3) {
                      setOptionsFornecedorSearch(true);
                      const result = await _fornecedorService.obterSimplificado(
                        value
                      );
                      const opts = result?.data?.data.map((o) => {
                        return {
                          key: o.id,
                          value: o.id,
                          label: o.nomeFantasia,
                        };
                      });

                      setOptionsFornecedor(opts);
                      setOptionsFornecedorSearch(false);
                    }
                  }}
                />
              </Form.Item>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                htmlType="submit"
              >
                Buscar
              </Button>
              <Button
                type="default"
                icon={<ClearOutlined />}
                onClick={() => {
                  setFiltrosRequisicoes("");
                  setOptionsEmpresa([]);
                  setOptionsSolicitante([]);
                  filtrosRequisicoesRef.resetFields();
                  sessionStorage.removeItem("filtros-requisicoes");
                }}
                style={{ marginLeft: 3 }}
              >
                Limpar filtro
              </Button>
            </Form>
          </Card>
          <Table
            bordered
            scroll={{ y: window.screen.height - window.screen.height * 0.54 }}
            columns={columnsRequisicaoDeCompra}
            dataSource={requisicoesDeCompra?.itens}
            loading={!requisicoesDeCompra?.itens}
            onChange={(pagination) => {
              salvarCachePaginacaoRequisicoes(
                pagination.current,
                pagination.pageSize
              );
              setPaginaAtualRequisicoes(pagination.current);
              setTotalItensRequisicoes(pagination.pageSize);
            }}
            pagination={{
              current: paginaAtualRequisicoes,
              pageSize: totalItensRequisicoes,
            }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="pedidos" tab="Pedidos de compra">
          <Card style={{ marginBottom: 5 }} title="Filtros" size="small">
            <Form
              form={filtrosPedidosRef}
              layout="inline"
              style={{ display: "flex" }}
              onFinish={(values) => handleSearchPedidos(values)}
            >
              <Form.Item style={{ flex: 1, maxWidth: 150 }} name="sequencial">
                <Input
                  placeholder="número do pedido"
                  onChange={() => filtrosPedidosRef.submit()}
                />
              </Form.Item>
              <Form.Item name="intervalo" style={{ minWidth: 300 }}>
                <DatePicker.RangePicker
                  format="DD/MM/yyyy"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item name="status" style={{ flex: 1 }}>
                <Select
                  placeholder="Status"
                  options={statusPedidoDeCompraList}
                  mode="tags"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item name="job" style={{ flex: 1 }}>
                <Select
                  placeholder="Job"
                  showSearch
                  style={{ display: "flex" }}
                  filterOption={filterOptionJob}
                  options={optsJob}
                  loading={loadingJobs}
                  onSearch={buscarJob}
                />
              </Form.Item>
              <Form.Item name="fornecedor" style={{ flex: 1 }}>
                <Select
                  placeholder="Fornecedor"
                  showSearch
                  options={optionsFornecedor}
                  loading={optionsFornecedorSearch}
                  filterOption={false}
                  onSearch={async (value) => {
                    if (value.length >= 3) {
                      setOptionsFornecedorSearch(true);
                      const result = await _fornecedorService.obterSimplificado(
                        value
                      );
                      const opts = result?.data?.data.map((o) => {
                        return {
                          key: o.id,
                          value: o.id,
                          label: o.nomeFantasia,
                        };
                      });

                      setOptionsFornecedor(opts);
                      setOptionsFornecedorSearch(false);
                    }
                  }}
                />
              </Form.Item>

              <Button
                type="primary"
                icon={<SearchOutlined />}
                htmlType="submit"
              >
                Buscar
              </Button>
              <Button
                type="default"
                icon={<ClearOutlined />}
                onClick={() => {
                  setFiltroPedidos("");
                  setOptionsEmpresa([]);
                  setOptionsSolicitante([]);
                  filtrosPedidosRef.resetFields();
                  sessionStorage.removeItem("filtros-pedidos");
                }}
                style={{ marginLeft: 3 }}
              >
                Limpar filtro
              </Button>
            </Form>
          </Card>
          <Table
            bordered
            scroll={{ y: window.screen.height - window.screen.height * 0.54 }}
            columns={columnsPedidosDeCompra}
            dataSource={pedidosDeCompra?.itens}
            loading={!pedidosDeCompra?.itens}
            onChange={(pagination) => {
              salvarCachePaginacaoPedidos(
                pagination.current,
                pagination.pageSize
              );
              setPaginaAtualPedidos(pagination.current);
              setTotalItensPedidos(pagination.pageSize);
            }}
            pagination={{
              current: paginaAtualPedidos,
              pageSize: totalItensPedidos,
            }}
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export const PedidosDeCompraRoute = {
  id: "pedidos-de-compra",
  path: "/inicio/financeiro/pedidos-de-compra",
  sidebar: "Pedidos de compra",
  exact: true,
  renderInMenu: true,
  main: () => <PedidosDeCompra />,
  icon: <TagsOutlined />,
  permission: PedidoDeCompra,
};
