import { Button, Card, Col, DatePicker, Form, Input, Modal, Row } from "antd";

import CurrencyInput from "react-currency-input-field";
import currencyFormatter from "currency-formatter";
import { datasValidas } from "../core/utils/formatters/datas.formatter";

export function SolicitarRetencaoModal({
  visible,
  setVisible,
  formRef,
  contexto,
}) {
  const [retencaoForm] = Form.useForm();
  return (
    <Modal
      visible={visible}
      closable={false}
      bodyStyle={{ padding: 0, marginBottom: -110 }}
      onCancel={() => {
        setVisible(false);
        formRef.setFieldsValue({
          [contexto]: {
            valorRetencao: null,
            notaFiscal: null,
            dataRetencao: null,
          },
        });
      }}
      footer
      width={830}
    >
      <Card title="Informe as condições fiscais" size="small">
        <Form
          form={retencaoForm}
          layout="vertical"
          onFinish={() => {
            const {
              notaFiscal = 1,
              valorRetencao = 0,
              dataRetencao,
            } = retencaoForm.getFieldsValue([
              "notaFiscal",
              "valorRetencao",
              "dataRetencao",
            ]);
            console.log(notaFiscal, valorRetencao, dataRetencao);
            formRef.setFieldsValue({
              [contexto]: {
                valorRetencao,
                notaFiscal,
                dataRetencao: dataRetencao?.toString(),
              },
            });
            setVisible(false);
          }}
        >
          <Row>
            <Col span={20} style={{ paddingRight: 5 }}>
              <Input.Group compact>
                <Form.Item
                  label="Nota Fiscal"
                  style={{ width: "33.33%", paddingRight: 5 }}
                  name="notaFiscal"
                  rules={[
                    {
                      required: true,
                      message: " ",
                    },
                  ]}
                >
                  <Input
                    placeholder="Informe a Nota Fiscal"
                    size="middle"
                    min={1}
                    max={24}
                  />
                </Form.Item>
                <Form.Item
                  label="Houve retenção de impostos ?"
                  style={{ width: "33.33%", paddingRight: 5 }}
                >
                  <CurrencyInput
                    name="valor"
                    placeholder="Valor"
                    className="ant-input ant-input-md"
                    decimalScale={2}
                    onValueChange={(value = "0") => {
                      retencaoForm.setFieldsValue({
                        valorRetencao: currencyFormatter.unformat(value, {
                          code: "BRL",
                        }),
                      });
                    }}
                    intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                  />
                </Form.Item>
                <Form.Item
                  label="Vencimento imposto"
                  style={{ width: "33.33%", paddingRight: 5 }}
                  name="dataRetencao"
                >
                  <DatePicker
                    placeholder="Selecione a data"
                    size="middle"
                    format={"DD/MM/yyyy"}
                    disabledDate={(date) => datasValidas(date.toDate())}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Input.Group>
            </Col>
            <Col span={4}>
              <Button
                type="primary"
                size="middle"
                className="btn-success"
                htmlType="submit"
                style={{ marginTop: 32 }}
              >
                CONFIRMAR
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </Modal>
  );
}
