import { AtualizarFornecedorDto } from "./dto/atualizar-fornecedor.dto";
import { BaseService } from "../base.service";
import { Endpoints } from "../../core/constants/endpoints";
import { NovoFornecedorDto } from "./dto/novo-fornecedor.dto";
import { PreCadastroFornecedorDto } from "./dto/pre-cadastro-fornecedor.dto";

export class FornecedorService extends BaseService {
  async obterTodos(busca: string = null) {
    try {
      if (busca && busca.length > 0)
        return await this.api.get(`${Endpoints.fornecedores}?busca=${busca}`);
      return await this.api.get(Endpoints.fornecedores);
    } catch (error) {
      throw error;
    }
  }
  async obterPorId(id: string) {
    try {
      return await this.api.get(`${Endpoints.fornecedores}/${id}`);
    } catch (error) {
      throw error;
    }
  }
  async gerarPreCadastro(dto: PreCadastroFornecedorDto) {
    try {
      return await this.api.post(Endpoints.fornecedoresPreCadastro, dto);
    } catch (error) {
      throw error;
    }
  }

  async verificarCnpjCadastrado(cnpj: string, cliente: string = null) {
    try {
      return await this.api.get(
        `${Endpoints.validarCnpjCadastradoFornecedor}?cnpj=${cnpj}`
      );
    } catch (error) {
      throw error;
    }
  }

  async obterSimplificado(filtro: string = "") {
    try {
      return await this.api.get(
        `${Endpoints.fornecedoresSimplificado}?filtro=${filtro}`
      );
    } catch (error) {
      throw error;
    }
  }
  async atualizar(id: string, dto: AtualizarFornecedorDto) {
    try {
      return await this.api.patch(`${Endpoints.fornecedores}/${id}`, dto);
    } catch (error) {
      throw error;
    }
  }
  async adicionar(dto: NovoFornecedorDto) {
    try {
      return await this.api.post(Endpoints.fornecedores, dto);
    } catch (error) {
      throw error;
    }
  }
  async obterCadastro(id: string) {
    try {
      return await this.api.get(
        `${Endpoints.fornecedoresConfirmacaoCadastro}/${id}`
      );
    } catch (error) {
      throw error;
    }
  }
  async atualizarCadastro(id: string, dto: AtualizarFornecedorDto) {
    try {
      return await this.api.patch(
        `${Endpoints.fornecedoresConfirmacaoCadastro}/${id}`,
        dto
      );
    } catch (error) {
      throw error;
    }
  }
  async reenviarConfirmacaoCadastro(id: string) {
    try {
      return await this.api.post(
        `${Endpoints.fornecedores}/${id}${Endpoints.reenviarConfirmacaoCadastroFornecedor}`,
        {}
      );
    } catch (error) {
      throw error;
    }
  }
  async inativarCadastro(id: string, motivo: string) {
    try {
      return await this.api.post(
        `${Endpoints.fornecedores}/${id}${Endpoints.inativarCadastro}`,
        { motivo }
      );
    } catch (error) {
      throw error;
    }
  }
}
