class StatusJobInfo {
  key: StatusJob;
  label: string;
  cor: string;
  value: StatusJob;
}

function enumToArray(enumerator: any) {
  const list = [];
  for (const option of Object.values(enumerator)) {
    list.push(option);
  }
  return list;
}

export enum StatusJob {
  Licitacao = 0,
  Reprovado = 1,
  EmAndamento = 2,
  Concluido = 3,
  Declinado = 4,
  Cancelado = 5,
}

export const StatusJobDescricao: {
  [key in StatusJob]: StatusJobInfo;
} = {
  [StatusJob.EmAndamento]: {
    key: StatusJob.EmAndamento,
    value: StatusJob.EmAndamento,
    label: "EM ANDAMENTO",
    cor: "SteelBlue",
  },
  [StatusJob.Licitacao]: {
    key: StatusJob.Licitacao,
    value: StatusJob.Licitacao,
    label: "EM CONCORRÊNCIA",
    cor: "gold",
  },
  [StatusJob.Reprovado]: {
    key: StatusJob.Reprovado,
    value: StatusJob.Reprovado,
    label: "REPROVADO",
    cor: "red",
  },

  [StatusJob.Concluido]: {
    key: StatusJob.Concluido,
    value: StatusJob.Concluido,
    label: "CONCLUÍDO",
    cor: "green",
  },
  [StatusJob.Declinado]: {
    key: StatusJob.Declinado,
    value: StatusJob.Declinado,
    label: "DECLINADO",
    cor: "DarkCyan",
  },

  [StatusJob.Cancelado]: {
    key: StatusJob.Cancelado,
    value: StatusJob.Cancelado,
    label: "CANCELADO",
    cor: "default",
  },
};

export const statusJobArray = enumToArray(StatusJobDescricao);
