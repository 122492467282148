import { BankOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, PageHeader, Popconfirm, Table, Tag, message } from "antd";
import { Link, useHistory } from "react-router-dom";
import React, { useState } from "react";

import { Action } from "../../../core/permissions/actions/actions";
import { Can } from "../../../core/permissions/permission.context";
import { ContaBancaria } from "../../../core/permissions/subjects";
import { ContaBancariaService } from "../../../services/conta-bancaria/conta-bancaria.service";
import { Endpoints } from "../../../core/constants/endpoints";
import NovaContaBancaria from "./nova-conta-bancaria.page";
import { currencyFormatter } from "../../../core/utils/formatters/currency.formatter";
import useSWR from "swr";

const _contaBancariaService = new ContaBancariaService();
export default function ContasBancarias() {
  const [atualizarLista, setAtualizarLista] = useState(null);
  const [drawerNovaContaBancaria, setDrawerNovaContaBancaria] = useState(false);
  const history = useHistory();
  const { data: contas, error } = useSWR(
    Endpoints.contasBancarias + atualizarLista,
    () => _contaBancariaService.obterTodas(),
    { revalidateOnFocus: false }
  );

  const columns = [
    {
      width: 220,
      title: "Descrição",
      dataIndex: "descricao",
    },
    {
      width: 220,
      title: "Banco",
      dataIndex: "banco",
      render: (value) => value.nome,
    },
    {
      width: 90,
      title: "Agência",
      dataIndex: "agencia",
    },
    {
      width: 90,
      title: "Conta",
      dataIndex: "conta",
    },
    {
      width: 300,
      title: "Empresa",
      dataIndex: "empresa",
      render: (value) => value.razaoSocial,
    },
    {
      title: "Saldo",
      dataIndex: "saldo",
      className: "currency",
      render: (value) => currencyFormatter(value, "BRL"),
    },
    {
      title: "Status",
      width: 78,
      dataIndex: "status",
      render: (value) =>
        value ? (
          <Tag color="green">ATIVA</Tag>
        ) : (
          <Tag color="warning">INATIVA</Tag>
        ),
    },
    {
      title: "Ação",
      width: 150,
      render: (_, obj) => (
        <>
          <Link
            to={`contas-bancarias/${obj.id}`}
            style={{ paddingRight: "10px" }}
          >
            Acessar
          </Link>
          <Popconfirm
            placement="bottom"
            title={`Deseja  ${
              obj?.status === 1 ? "inativar" : "ativar"
            } a conta bancária "${obj?.descricao}" ?`}
            onConfirm={async () => {
              await _contaBancariaService.inativarCadastro(obj?.id);
              message.success(
                `Conta bancária "${obj?.descricao}" foi ${
                  obj?.status === 1 ? "Inativada" : "Ativada"
                }`
              );
              setAtualizarLista(Date.now());
            }}
            okText="SIM"
            cancelText="Não"
          >
            <a style={{ color: obj?.status === 1 ? "#900c3f" : "#1B4F72" }}>
              {obj?.status === 1 ? "Inativar" : "Ativar"}
            </a>
          </Popconfirm>
        </>
      ),
    },
  ];

  if (error) message.error(error.message);

  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title="Contas Bancárias"
        subTitle="Todas as contas bancárias"
        extra={
          <Button
            type="primary"
            size="middle"
            icon={<PlusOutlined />}
            onClick={() => setDrawerNovaContaBancaria(true)}
          >
            Nova Conta
          </Button>
        }
      />
      <Can do={Action.Ler} on={ContaBancaria}>
        <NovaContaBancaria
          visible={drawerNovaContaBancaria}
          setVisible={setDrawerNovaContaBancaria}
        />
        <Table
          id="id"
          style={{ marginTop: 10 }}
          scroll={{ y: 450 }}
          bordered
          columns={columns}
          loading={!contas}
          dataSource={contas?.data?.data || []}
        />
      </Can>
    </div>
  );
}

export const ContasBancariasRoute = {
  id: "contas-bancarias",
  path: "/inicio/financeiro/contas-bancarias",
  sidebar: "Contas Bancárias",
  exact: true,
  renderInMenu: true,
  main: () => <ContasBancarias />,
  icon: <BankOutlined />,
  permission: ContaBancaria,
};
