import {
  Button,
  Card,
  Col,
  Form,
  Input,
  PageHeader,
  Row,
  Space,
  message,
} from "antd";
import { useHistory, useParams } from "react-router-dom";

import { AtualizarFormaDePagamentoDto } from "../../../services/formas-de-pagamento/dto/atualizar-forma-de-pagamento.dto";
import { Endpoints } from "../../../core/constants/endpoints";
import { FormaDePagamentoService } from "../../../services/formas-de-pagamento/formas-de-pagamento.service";
import Loading from "../../../components/loading.component";
import { FormaDePagamento as Permissao } from "../../../core/permissions/subjects";
import React from "react";
import useSWR from "swr";

export default function FormaDePagamentoPage() {
  const _formaDePagamentoService = new FormaDePagamentoService();
  const history = useHistory();
  const { id } = useParams<any>();
  const { data: { data: { data: formaDePagamentoData } = {} } = {} } = useSWR(
    Endpoints.formasDePagamento + id,
    () => _formaDePagamentoService.obterPorId(id),
    { revalidateOnFocus: false }
  );
  const [formRef] = Form.useForm();

  async function atualizarFormaDePagamentoHandler() {
    try {
      const formaDePagamento = formRef.getFieldValue("formaDePagamento");
      const dto: AtualizarFormaDePagamentoDto = {
        id: id,
        ...formaDePagamento,
      };

      await _formaDePagamentoService.atualizarFormaDePagamento(id, dto);
      message.success("Forma de Pagamento Atualizada!");
      history.goBack();
    } catch (error) {
      if (error?.response?.data?.message && typeof Array) {
        for (const e of error?.response?.data?.message.toString().split(",")) {
          message.error(e);
        }
      } else {
        message.error(error?.message);
      }
    }
  }

  return formaDePagamentoData ? (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => {
          history.goBack();
        }}
        title="Formas de pagamento"
        subTitle="Todas as formas de pagamento"
        style={{ marginBottom: 10 }}
      />
      <Card title="Informações forma de pagamento">
        <Form
          form={formRef}
          layout="vertical"
          onFinish={() => atualizarFormaDePagamentoHandler()}
        >
          <Input.Group compact>
            <Form.Item
              initialValue={formaDePagamentoData?.nome}
              style={{ width: "100%" }}
              name={["formaDePagamento", "nome"]}
              label="Nome"
              rules={[{ required: true, message: "Nome é obrigatório" }]}
            >
              <Input placeholder="Nome" />
            </Form.Item>
            <Form.Item
              style={{ width: "100%" }}
              initialValue={formaDePagamentoData?.descricao}
              name={["formaDePagamento", "descricao"]}
              label="Descrição"
              rules={[{ required: true, message: "Descrição é obrigatório" }]}
            >
              <Input.TextArea placeholder="Descrição" />
            </Form.Item>
          </Input.Group>

          <Row>
            <Col span={18} />
            <Col span={6} style={{ marginTop: "30px", textAlign: "end" }}>
              <Space>
                <Button type="primary" size="middle" htmlType="submit">
                  SALVAR
                </Button>
                <Button
                  type="ghost"
                  size="middle"
                  onClick={() => {
                    formRef.resetFields();
                  }}
                >
                  CANCELAR
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  ) : (
    <Loading />
  );
}

export const FormaDePagamentoRoute = {
  id: "forma-de-pagamento",
  path: "/inicio/financeiro/formas-de-pagamento/:id",
  sidebar: "Formas de pagamento",
  exact: true,
  renderInMenu: false,
  main: () => <FormaDePagamentoPage />,
  icon: null,
  permission: Permissao,
};
