import * as subjects from "./subjects";

import {
  Ability,
  AbilityBuilder,
  AbilityClass,
  ExtractSubjectType,
} from "@casl/ability";

import { Action } from "./actions/actions";
import { Subjects } from "./subjects";

export type AppAbility = Ability<[Action, Subjects]>;

export function createForUser() {
  const { can, build } = new AbilityBuilder<Ability<[Action, Subjects]>>(
    Ability as AbilityClass<AppAbility>
  );

  const { permissoes, administrador } = getTokenPermissions(
    sessionStorage.getItem("token")
  );

  if (administrador) {
    can(Action.Criar, "all");
    can(Action.Deletar, "all");
    can(Action.Ler, "all");
    can(Action.Atualizar, "all");
  } else {
    for (const p of permissoes) {
      if (p.acao === Action.Gerenciar) {
        can(Action.Criar, subjects[p.tipo]);
        can(Action.Deletar, subjects[p.tipo]);
        can(Action.Ler, subjects[p.tipo]);
        can(Action.Atualizar, subjects[p.tipo]);
      } else {
        can(p.acao, subjects[p.tipo]);
      }
    }
  }

  return build({
    detectSubjectType: (item: any) =>
      item?.constructor as ExtractSubjectType<Subjects>,
  });
}
const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

function getTokenPermissions(token: string) {
  const usuario = parseJwt(token) || {};
  const { roles = [], isAdmin } = usuario;
  const permissoes = [];

  for (const r of roles) {
    const [tipo, acao] = r.split(":");
    permissoes.push({ tipo, acao });
  }
  return { permissoes, administrador: isAdmin };
}

export function verifyAuth(history: any) {
  if (!sessionStorage.getItem("token")) {
    history.push("/");
  }
}
