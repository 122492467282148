import { BaseService } from "../base.service";
import { ConfiguracaoDashboardDto } from "./dto/configuracao-dashboard.dto";
import { Endpoints } from "../../core/constants/endpoints";

export class DashboardService extends BaseService {
  async obterConfiguracao() {
    try {
      return await this.api.get(Endpoints.dashboard);
    } catch (error) {
      throw error;
    }
  }

  async carregarAnexos(form: FormData) {
    try {
      return await this.api.upload(Endpoints.arquivosDashboard, form);
    } catch (error) {
      throw error;
    }
  }

  async atualizarConfiguracao(dto: ConfiguracaoDashboardDto) {
    try {
      return await this.api.post(Endpoints.dashboard, dto);
    } catch (error) {
      throw error;
    }
  }
}
