export function identificadorFormatter(identificador: string) {
  if (identificador && identificador.match(/^(\d{3})(\d{4})/g)) {
    return identificador.replace(/^(\d{3})(\d{4})/, "$1/$2");
  }

  if (identificador && identificador.match(/^(\d{2})(\d{4})/g)) {
    return identificador.replace(/^(\d{2})(\d{4})/, "0$1/$2");
  }

  if (identificador && identificador.match(/^(\d{1})(\d{4})/g)) {
    return identificador.replace(/^(\d{1})(\d{4})/, "00$1/$2");
  }

  return "";
}
