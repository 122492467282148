import { AtualizarRequisicaoDeCompraDto } from "./dto/atualizar-requisica-de-compra.dto";
import { BaseService } from "../base.service";
import { EmailDto } from "./dto/email.dto";
import { Endpoints } from "./../../core/constants/endpoints";
import { NovaRequisicaoDeCompraDto } from "./dto/nova-requisicao-de-compra.dto";
import { ReprovarRequisicaoDeCompraDto } from "./dto/reprovar-requisicao-de-compra.dto";

export class RequisicaoDeCompraService extends BaseService {
  async obterTodos(filtro: string) {
    try {
      return await this.api.get(`${Endpoints.requisicoesDeCompra}?${filtro}`);
    } catch (error) {
      throw error;
    }
  }
  async obterPorId(id: string) {
    try {
      return await this.api.get(`${Endpoints.requisicoesDeCompra}/${id}`);
    } catch (error) {
      throw error;
    }
  }
  async criarNovaRequisicao(dto: NovaRequisicaoDeCompraDto) {
    try {
      return await this.api.post(Endpoints.requisicoesDeCompra, dto);
    } catch (error) {
      throw error;
    }
  }
  async aprovarRequisicao(id: string, email: EmailDto) {
    try {
      return await this.api.patch(
        `${Endpoints.requisicoesDeCompra}/${id}${Endpoints.aprovarRequisicao}`,
        { id, email }
      );
    } catch (error) {
      throw error;
    }
  }
  async reprovarRequisicao(id: string, dto: ReprovarRequisicaoDeCompraDto) {
    try {
      return await this.api.patch(
        `${Endpoints.requisicoesDeCompra}/${id}${Endpoints.reprovarRequisicao}`,
        dto
      );
    } catch (error) {
      throw error;
    }
  }
  async atualizarNovaRequisicao(
    id: string,
    dto: AtualizarRequisicaoDeCompraDto
  ) {
    try {
      return await this.api.patch(
        `${Endpoints.requisicoesDeCompra}/${id}`,
        dto
      );
    } catch (error) {
      throw error;
    }
  }
}
