import React, { useContext } from "react";

import { AbilityContext } from "../core/permissions/permission.context";
import { Layout } from "antd";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";

const { Header } = Layout;

export default function GenericHeader() {
  const ability = useContext(AbilityContext);
  return (
    <div className="generic-header">
      <div className="logo">
        <Link to={"#"}>
          <img src={logo} alt="Logo" />
        </Link>
      </div>
    </div>
  );
}
