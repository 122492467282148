import "moment/locale/pt-br";

import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  PageHeader,
  Row,
  Space,
  message,
} from "antd";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { AtualizarClienteDto } from "../../../services/cliente/dto/atualizar-cliente.dto";
import { Cliente as ClientePermissao } from "../../../core/permissions/subjects";
import { ClienteService } from "../../../services/cliente/cliente.service";
import { ContactsOutlined } from "@ant-design/icons";
import { Endpoints } from "../../../core/constants/endpoints";
import Loading from "../../../components/loading.component";
import { cepFormatter } from "../../../core/utils/formatters/cep.formatter";
import { cnpjFormatter } from "../../../core/utils/formatters/cnpj.formatter";
import { cnpjValidator } from "../../../core/utils/validators/cnpj.validator";
import locale from "antd/es/date-picker/locale/pt_BR";
import moment from "moment";
import useSWR from "swr";
import useTitle from "../../../core/hooks/title.hook";

const cep = require("cep-promise");
export default function Cliente() {
  const { id } = useParams<any>();
  const { data: { data: { data: clienteData } = {} } = {} } = useSWR(
    Endpoints.clientes + id,
    () => _clienteService.obterClientePorId(id),
    { revalidateOnFocus: false }
  );

  const _clienteService = new ClienteService();
  const [cnpjCadastrado, setCnpjCadastrado] = useState({
    status: false,
    message: "",
  });
  const [formRef] = Form.useForm();
  const history = useHistory();
  async function atualizarClienteHandler() {
    try {
      const {
        cnpj,
        nomeFantasia,
        razaoSocial,
        email,
        cep,
        logradouro,
        estado,
        cidade,
        numero,
        bairro,
        complemento,
        diaPagamento,
      } = formRef.getFieldsValue([
        "cnpj",
        "nomeFantasia",
        "razaoSocial",
        "email",
        "cep",
        "logradouro",
        "estado",
        "cidade",
        "numero",
        "bairro",
        "complemento",
        "diaPagamento",
      ]);

      const dto: AtualizarClienteDto = {
        id: id,
        cnpj: cnpj.replace(/[^\d]+/g, ""),
        nomeFantasia,
        razaoSocial,
        email,
        diaPagamento: diaPagamento,
        endereco: {
          id: clienteData?.endereco?.id,
          cep,
          logradouro,
          estado,
          cidade,
          numero,
          bairro,
          complemento,
          pais: "BR",
        },
      };
      await _clienteService.atualizarCliente(id, dto);
      message.success("Cliente Atualizado!");
      history.goBack();
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message);
    }
  }
  useTitle(`${clienteData?.nomeFantasia}`);
  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title={`${clienteData?.nomeFantasia}`}
        style={{ marginBottom: 5 }}
        subTitle="CADASTRO CLIENTE"
      />
      {clienteData ? (
        <Form
          form={formRef}
          onFinish={async () => await atualizarClienteHandler()}
          layout="vertical"
        >
          <Card title="Geral" type="inner" style={{ marginBottom: 10 }}>
            <Row>
              <Col span={24}>
                <Input.Group compact>
                  <Form.Item
                    initialValue={cnpjFormatter(clienteData?.cnpj)}
                    label="CNPJ"
                    hasFeedback
                    validateStatus={cnpjCadastrado.status ? "error" : ""}
                    help={cnpjCadastrado.message}
                    rules={[
                      {
                        required: true,
                        max: 18,
                        message: "CNPJ é Obrigatório",
                      },
                    ]}
                    name="cnpj"
                    style={{ width: "50%", paddingRight: "5px" }}
                  >
                    <Input
                      maxLength={18}
                      placeholder="CNPJ"
                      onKeyUp={async (e) => {
                        try {
                          formRef.setFieldsValue({
                            cnpj: cnpjFormatter(formRef.getFieldValue("cnpj")),
                          });
                          const cnpj = formRef
                            .getFieldValue("cnpj")
                            .replace(/[^\d]+/g, "");
                          if (cnpj.length >= 14) {
                            cnpjValidator(cnpj);
                            await _clienteService.verificarCnpjCadastrado(
                              cnpj,
                              id
                            );
                            setCnpjCadastrado({
                              status: false,
                              message: "",
                            });
                          }
                        } catch (error) {
                          setCnpjCadastrado({
                            status: true,
                            message:
                              error?.response?.data?.message || error?.message,
                          });
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    initialValue={clienteData?.nomeFantasia}
                    label="Nome Fantasia"
                    name="nomeFantasia"
                    style={{ width: "50%" }}
                    rules={[
                      {
                        required: true,
                        message: "Nome fantasia é obrigatório",
                      },
                    ]}
                  >
                    <Input placeholder="Nome Fantasia" />
                  </Form.Item>
                  <Form.Item
                    initialValue={clienteData?.razaoSocial}
                    label="Razão Social"
                    rules={[
                      {
                        required: true,
                        message: "Razão social é obrigatório",
                      },
                    ]}
                    name="razaoSocial"
                    style={{ width: "50%", paddingRight: 5 }}
                  >
                    <Input placeholder="Razão Social" />
                  </Form.Item>
                  <Form.Item
                    label="E-mail"
                    initialValue={clienteData?.email}
                    name="email"
                    style={{ width: "30%", paddingRight: 5 }}
                    rules={[
                      {
                        required: true,
                        message: "E-mail é obrigatório",
                      },
                    ]}
                  >
                    <Input placeholder="E-mail" type="email" />
                  </Form.Item>
                  <Form.Item
                    initialValue={
                      clienteData?.diaPagamento
                        ? moment(new Date(clienteData?.diaPagamento))
                        : null
                    }
                    label="Dia de pagamento"
                    style={{ width: "20%" }}
                    name="diaPagamento"
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      format={"dddd"}
                      locale={locale}
                      disabledDate={(date) =>
                        date.toDate().getDay() === 6 ||
                        date.toDate().getDay() === 0
                      }
                    />
                  </Form.Item>
                </Input.Group>
              </Col>
            </Row>
          </Card>
          <Card title="Endereço" type="inner">
            <Col span={24}>
              <Input.Group compact>
                <Form.Item
                  initialValue={clienteData?.endereco?.cep}
                  name="cep"
                  label="CEP"
                  style={{ width: "10%", paddingRight: "5px" }}
                  rules={[
                    {
                      required: true,
                      message: "Informe o CEP",
                      max: 9,
                      min: 9,
                      pattern: /^\d{5}-\d{3}$/g,
                    },
                  ]}
                >
                  <Input
                    placeholder="00000-000"
                    maxLength={9}
                    onChange={(event) => {
                      const cepValue = event.target.value;

                      formRef.setFieldsValue({
                        cep: cepFormatter(event.target.value),
                      });
                      if (cepValue.replace("-", "").length >= 8) {
                        cep(cepValue).then((response) => {
                          formRef.setFieldsValue({
                            estado: response.state,
                            logradouro: response.street,
                            bairro: response.neighborhood,
                            cidade: response.city,
                          });
                        });
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="logradouro"
                  initialValue={clienteData?.endereco?.logradouro}
                  label="Rua"
                  style={{ width: "40%", paddingRight: "5px" }}
                  rules={[{ required: true, message: "Informe a Rua" }]}
                >
                  <Input placeholder="Rua" />
                </Form.Item>
                <Form.Item
                  initialValue={clienteData?.endereco?.numero}
                  name="numero"
                  label="Número"
                  style={{ width: "10%", paddingRight: 5 }}
                  rules={[{ required: true, message: "Informe o número" }]}
                >
                  <Input placeholder="Número" type="number" />
                </Form.Item>

                <Form.Item
                  initialValue={clienteData?.endereco?.bairro}
                  name="bairro"
                  label="Bairro"
                  rules={[{ required: true, message: "Informe o bairro" }]}
                  style={{ width: "20%", paddingRight: "5px" }}
                >
                  <Input placeholder="Bairro" />
                </Form.Item>
                <Form.Item
                  initialValue={clienteData?.endereco?.cidade}
                  name="cidade"
                  label="Cidade"
                  rules={[{ required: true, message: "Informe a cidade" }]}
                  style={{ width: "15%", paddingRight: "5px" }}
                >
                  <Input placeholder="Cidade" />
                </Form.Item>
                <Form.Item
                  initialValue={clienteData?.endereco?.estado}
                  name="estado"
                  label="Estado"
                  rules={[{ required: true, message: "Informe o estado" }]}
                  style={{ width: "5%" }}
                >
                  <Input placeholder="Estado" />
                </Form.Item>
                <Form.Item
                  initialValue={clienteData?.endereco?.complemento}
                  name="complemento"
                  label="Complemento"
                  style={{ width: "100%" }}
                >
                  <Input placeholder="Complemento" />
                </Form.Item>
              </Input.Group>
            </Col>
          </Card>
          <Row>
            <Col span={20} />
            <Col span={4} style={{ marginTop: "30px", textAlign: "end" }}>
              <Space>
                <Button type="primary" size="middle" htmlType="submit">
                  SALVAR
                </Button>
                <Button
                  type="ghost"
                  size="middle"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  CANCELAR
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      ) : (
        <Loading />
      )}
    </div>
  );
}

export const ClienteRoute = {
  id: "cliente",
  path: "/inicio/atendimento/clientes/:id",
  sidebar: "Novo Cliente",
  exact: true,
  renderInMenu: false,
  main: () => <Cliente />,
  icon: <ContactsOutlined />,
  permission: ClientePermissao,
};
