import {
  Button,
  Input,
  PageHeader,
  Popconfirm,
  Table,
  Tag,
  message,
} from "antd";
import { Link, useHistory } from "react-router-dom";
import { PlusOutlined, UserOutlined } from "@ant-design/icons";

import { Action } from "../../../core/permissions/actions/actions";
import { Can } from "../../../core/permissions/permission.context";
import { Colaborador } from "../../../core/permissions/subjects";
import { ColaboradorService } from "../../../services/colaborador/colaborador.service";
import { Endpoints } from "../../../core/constants/endpoints";
import NovoColaborador from "./novo-colaborador.page";
import { cpfFormatter } from "../../../core/utils/formatters/cpj.formatter";
import { telefoneCelularFormatter } from "../../../core/utils/formatters/telefoneCelular.formatter";
import useSWR from "swr";
import { useState } from "react";

const _colaboradorService = new ColaboradorService();
export default function Colaboradores() {
  const history = useHistory();
  const [filtro, setFiltro] = useState("");
  const [atualizarLista, setAtualizarLista] = useState(Date.now());
  const columns = [
    {
      title: "CPF",
      dataIndex: "cpf",
      render: (value, obj) => (
        <Link to={`/inicio/recursos-humanos/colaboradores/${obj.id}`}>
          {cpfFormatter(value)}
        </Link>
      ),
      width: 120,
    },
    { title: "Nome", dataIndex: "nome" },
    {
      title: "Celular",
      dataIndex: "telefoneCelular",
      render: (value) => telefoneCelularFormatter(value),
    },
    { title: "E-mail", dataIndex: "email" },

    {
      title: "Setor",
      dataIndex: "setor",
      render: (value) => <Tag>{value.nome.toUpperCase()}</Tag>,
    },
    {
      title: "Ação",
      width: 150,
      render: (_, obj) => (
        <>
          <Link
            to={`/inicio/recursos-humanos/colaboradores/${obj.id}`}
            style={{ marginRight: 3 }}
          >
            Acessar
          </Link>
          <Popconfirm
            placement="bottom"
            title={`Deseja  ${
              obj?.ativo ? "inativar" : "ativar"
            } o cadastro de ${obj?.nome} ?`}
            onConfirm={async () => {
              await _colaboradorService.inativarCadastro(obj?.id);
              message.success(
                `Colaborador ${obj?.nome} foi ${
                  obj?.ativo ? "Inativado" : "Ativado"
                }`
              );
              setAtualizarLista(Date.now());
            }}
            okText="SIM"
            cancelText="Não"
          >
            <a style={{ color: obj?.ativo ? "#900c3f" : "#1B4F72" }}>
              {obj?.ativo ? "Inativar" : "Ativar"}
            </a>
          </Popconfirm>
        </>
      ),
    },
  ];
  const [novoColaboradorVisible, setNovoColaboradorVisible] = useState(false);
  const { data: colaboradores, error } = useSWR(
    Endpoints.colaboradores + filtro + atualizarLista,
    () => _colaboradorService.obterTodos(filtro),
    { revalidateOnFocus: false }
  );

  return (
    <div>
      <NovoColaborador
        visible={novoColaboradorVisible}
        setVisible={setNovoColaboradorVisible}
      />
      <div>
        <PageHeader
          className="site-page-header"
          onBack={() => history.goBack()}
          title="Colaboradores"
          subTitle="Todas os colaboradores"
          extra={
            <Button
              type="primary"
              size="middle"
              icon={<PlusOutlined />}
              onClick={() => setNovoColaboradorVisible(true)}
            >
              Novo Colaborador
            </Button>
          }
        />
        <div style={{ paddingTop: 10 }}>
          <Input.Search
            placeholder="Buscar..."
            size="middle"
            onSearch={(value) => setFiltro(value)}
          />
        </div>
        <Can do={Action.Ler} on={Colaborador}>
          <Table
            rowKey="id"
            style={{ marginTop: 10 }}
            scroll={{ y: 450 }}
            bordered
            columns={columns}
            loading={!colaboradores}
            dataSource={colaboradores?.data?.data || []}
          />
        </Can>
      </div>
    </div>
  );
}

export const ColaboradoresRoute = {
  id: "colaboradores",
  renderInMenu: true,
  path: "/inicio/recursos-humanos/colaboradores",
  sidebar: "Colaboradores",
  exact: true,
  main: () => <Colaboradores />,
  icon: <UserOutlined />,
  permission: Colaborador,
};
