import { BaseService } from "../base.service";
import { Endpoints } from "../../core/constants/endpoints";
import { NovaFolhaDePagamentoDto } from "./dto/folha-de-pagamento.dto";

export class FolhaDePagamentoService extends BaseService {
  async obterColaboradoresParaPagamento() {
    try {
      return await this.api.get(Endpoints.folhaDePagamento);
    } catch (error) {
      throw error;
    }
  }

  async efetuarPagamentos(folhasDePagamento: NovaFolhaDePagamentoDto) {
    try {
      return await this.api.post(Endpoints.folhaDePagamento, folhasDePagamento);
    } catch (error) {
      throw error;
    }
  }
  async carregarAnexos(form: FormData) {
    try {
      const {
        data: {
          data: { url, mimetype, arquivo: filename },
        },
      } = await this.api.upload(Endpoints.arquivosHolerites, form);

      return { url, mimetype, filename };
    } catch (error) {
      throw error;
    }
  }
}
