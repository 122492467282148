import { MenuContextProvider } from "./menu.context";
import React from "react";

const GlobalContext: React.SFC = ({ children }) => {
  return (
    <>
      <MenuContextProvider>{children}</MenuContextProvider>
    </>
  );
};

export default GlobalContext;
