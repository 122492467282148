import { FornecedorService } from "../../services/fornecedor/fornecedor.service";
import { useState } from "react";

export default function useBuscarFornecedorHook() {
  const _fornecedorService = new FornecedorService();
  const [externalState, setExternalState] = useState([]);
  const [optionsFornecedorSearch, setOptionsFornecedorSearch] = useState(false);
  const filterOptions = (input, option) =>
    option?.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;

  async function buscarFornecedor(value) {
    if (value.length >= 3) {
      setOptionsFornecedorSearch(true);
      await _fornecedorService
        .obterSimplificado(String(value).toLowerCase())
        .then((res) => {
          const opts = res?.data?.data.map((o) => {
            return {
              key: o.id,
              value: o.id,
              label: o.nomeFantasia,
            };
          });
          setOptionsFornecedorSearch(false);
          setExternalState(opts);
        });
    }
  }

  return {
    setOptions: setExternalState,
    options: externalState,
    filterOptions,
    buscarFornecedor,
    optionsFornecedorSearch,
    loading: optionsFornecedorSearch,
  };
}
