import { TeamOutlined } from "@ant-design/icons";
import React from "react";
import { RecursosHumanos as RecursosHumanosPermissao } from "../../core/permissions/subjects";
import { ColaboradorRoute } from "./colaboradores/colaborador.page";
import { ColaboradoresRoute } from "./colaboradores/colaboradores.page";
import { PagamentoColaboradoresRoute } from "./pagamentos/pagamento-colaboradores.page";
export default function RecursosHumanos() {
  return <div>RecursosHumanos</div>;
}

export const RecursosHumanosRoute = {
  id: "recursos-humanos",
  path: "/inicio/recursos-humanos",
  exact: true,
  sidebar: "Recursos Humanos",
  icon: <TeamOutlined />,
  main: () => <RecursosHumanos />,
  renderInMenu: true,
  permission: RecursosHumanosPermissao,
  submenus: [ColaboradoresRoute, ColaboradorRoute, PagamentoColaboradoresRoute],
};
