import { useEffect, useState } from "react";

import { FormaDePagamentoService } from "../../services/formas-de-pagamento/formas-de-pagamento.service";

export default function useBuscarFormaDePagamentoHook() {
  const _formaDePagamentoService = new FormaDePagamentoService();
  const [optionsFormaDePagamento, setOptionsFormaDePagamento] = useState([]);
  const [optionsFormaDePagamentoSearch, setOptionsFormaDePagamentoSearch] =
    useState(false);
  useEffect(() => {
    _formaDePagamentoService
      .obterFormasDePagamentoSimplificado(null)
      .then((result) => {
        const opts = result?.data?.data.map((o) => {
          return {
            key: o.id,
            value: o.id,
            label: o.nome,
          };
        });
        setOptionsFormaDePagamento(opts);
      });
  }, []);
  const filterOptions = (input, option) =>
    option?.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;

  async function buscarFormaDePagamento(value) {
    if (value.length >= 3) {
      setOptionsFormaDePagamentoSearch(true);
      const result =
        await _formaDePagamentoService.obterFormasDePagamentoSimplificado(
          value
        );
      const opts = result?.data?.data.map((o) => {
        return {
          key: o.id,
          value: o.id,
          label: o.nome,
        };
      });
      setOptionsFormaDePagamento(opts);
      setOptionsFormaDePagamentoSearch(false);
    }
  }
  return {
    buscarFormaDePagamento,
    options: optionsFormaDePagamento,
    setOptions: setOptionsFormaDePagamento,
    loading: optionsFormaDePagamentoSearch,
    filterOptions,
  };
}
