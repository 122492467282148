import axios, { AxiosRequestConfig } from "axios";

import { Endpoints } from "./../constants/endpoints";

export class ApiClient {
  private token: string = sessionStorage.getItem("token") || null;
  private config: AxiosRequestConfig;

  async get(url: string, data: any = null) {
    try {
      await this.validarToken(url);
      axios.defaults.baseURL = process.env.REACT_APP_API_URL;
      return await axios.get(url, data);
    } catch (error) {
      throw error;
    }
  }
  async post(url: string, data: any) {
    try {
      await this.validarToken(url);
      axios.defaults.baseURL = process.env.REACT_APP_API_URL;
      return await axios.post(url, data);
    } catch (error) {
      throw error;
    }
  }
  async put(url: string, data: any) {
    try {
      await this.validarToken(url);
      axios.defaults.baseURL = process.env.REACT_APP_API_URL;
      return await axios.put(url, data);
    } catch (error) {
      throw error;
    }
  }
  async patch(url: string, data: any = null) {
    try {
      await this.validarToken(url);
      axios.defaults.baseURL = process.env.REACT_APP_API_URL;
      return await axios.patch(url, data);
    } catch (error) {
      throw error;
    }
  }
  private async validarToken(url: string) {
    if (
      url != Endpoints.login &&
      url != Endpoints.refresh &&
      !url.includes(Endpoints.fornecedoresConfirmacaoCadastro) &&
      !url.includes(Endpoints.bancos) &&
      !url.includes(Endpoints.confirmarRecebimentoPedidosDeCompra)
    )
      axios.defaults.headers[
        "Authorization"
      ] = `Bearer ${await this.getToken()}`;
  }

  async delete(url: string, data: any) {
    try {
      await this.validarToken(url);
      axios.defaults.baseURL = process.env.REACT_APP_API_URL;
      return await axios.delete(url);
    } catch (error) {
      throw error;
    }
  }
  async upload(url: string, form: FormData) {
    try {
      return await axios.post(url, form, {
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
          Authorization: `Bearer ${await this.getToken()}`,
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      throw error;
    }
  }
  async download(url: string) {
    try {
      return await axios.get(url, {
        baseURL: process.env.REACT_APP_API_URL,
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${await this.getToken()}`,
        },
      });
    } catch (error) {
      throw error;
    }
  }

  private async getToken() {
    try {
      return new Promise((resolve, reject) => {
        let localToken = null;
        if (this.token != null) resolve(this.token);
        localToken = sessionStorage.getItem("token");
        if (localToken) {
          this.token = localToken;
          resolve(localToken);
        }
        setTimeout(() => {
          reject(null);
        }, 10000);
      });
    } catch (error) {
      throw error;
    }
  }
}
