export enum TipoFolhaDePagamentoItem {
  DESCONTO = 0,
  BENEFICIO = 1,
  BONIFICACAO = 2,
}

class TipoFolhaDePagamentoItemInfo {
  key: TipoFolhaDePagamentoItem;
  label: string;
  cor: string;
  value: TipoFolhaDePagamentoItem;
}

function enumToArray(enumerator: any) {
  const list = [];
  for (const option of Object.values(enumerator)) {
    list.push(option);
  }
  return list;
}

export const TipoFolhaDePagamentoDescricao: {
  [key in TipoFolhaDePagamentoItem]: TipoFolhaDePagamentoItemInfo;
} = {
  [TipoFolhaDePagamentoItem.DESCONTO]: {
    key: TipoFolhaDePagamentoItem.DESCONTO,
    value: TipoFolhaDePagamentoItem.DESCONTO,
    label: "DESCONTO",
    cor: "red",
  },
  [TipoFolhaDePagamentoItem.BENEFICIO]: {
    key: TipoFolhaDePagamentoItem.BENEFICIO,
    value: TipoFolhaDePagamentoItem.BENEFICIO,
    label: "BENEFÍCIO",
    cor: "green",
  },
  [TipoFolhaDePagamentoItem.BONIFICACAO]: {
    key: TipoFolhaDePagamentoItem.BONIFICACAO,
    value: TipoFolhaDePagamentoItem.BONIFICACAO,
    label: "BONIFICAÇÃO",
    cor: "SteelBlue",
  },
};

export const tipoFolhaDePagamentoItemArray = enumToArray(
  TipoFolhaDePagamentoDescricao
);
