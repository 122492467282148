import "./App.less";

import { ConfigProvider } from "antd";
import GlobalContext from "./contexts";
import React from "react";
import Routes from "./routes";
import ptBR from "antd/lib/locale/pt_BR";

function App() {
  return (
    <GlobalContext>
      <ConfigProvider locale={ptBR} componentSize="small">
        <React.StrictMode>
          <Routes />
        </React.StrictMode>
      </ConfigProvider>
    </GlobalContext>
  );
}

export default App;
