import {
  Button,
  Card,
  Col,
  DatePicker,
  Descriptions,
  Form,
  Input,
  Modal,
  PageHeader,
  Popconfirm,
  Popover,
  Row,
  Select,
  Space,
  Table,
  Tag,
  message,
} from "antd";
import {
  CheckCircleTwoTone,
  DeleteTwoTone,
  EditTwoTone,
  PlusCircleOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import {
  StatusParcela,
  StatusParcelaDescricao,
  StatusParcelaList,
} from "../../../core/enums/status-parcela.enum";
import { TipoNf, tipoNfList } from "../../../core/enums/tipo-nota-fiscal.enum";
import { useHistory, useParams } from "react-router-dom";

import { AtualizarContaAReceberDto } from "../../../services/conta-a-receber/dto/atualizar-conta-a-receber.dto";
import CondicaoDePagamentoPopup from "../../../components/condicao-de-pagamento.component";
import { ContaAReceber as ContaAReceberPermissao } from "../../../core/permissions/subjects/";
import { ContaAReceberService } from "../../../services/conta-a-receber/conta-a-receber.service";
import { ContaBancariaService } from "../../../services/conta-bancaria/conta-bancaria.service";
import CurrencyInput from "react-currency-input-field";
import { EmpresaService } from "../../../services/empresa/empresas.service";
import { Endpoints } from "../../../core/constants/endpoints";
import { JobService } from "../../../services/job/job.service";
import Loading from "../../../components/loading.component";
import NovaFormaDePagamento from "../formas-de-pagamento/nova-forma-de-pagamento.page";
import NovoGrupoFinanceiro from "../grupos-financeiros/novo-grupo-financeiro.page";
import { RegimesTributariosEnum } from "../../../core/enums/regime-tributario.enum";
import { RegistrarPagamentoContaAReceberDto } from "../../../services/conta-a-receber/dto/registrar-pagamento-conta-a-receber.dto";
import { StatusContaAReceber } from "../../../core/enums/status-conta-a-receber.enum";
import c from "currency-formatter";
import { currencyFormatter } from "../../../core/utils/formatters/currency.formatter";
import { identificadorFormatter } from "../../../core/utils/formatters/job.formatter";
import moment from "moment";
import useGruposFinanceirosHook from "../../../components/hooks/buscar-grupo-financeiro.hook";
import useSWR from "swr";

export default function ContaAReceber() {
  //#region Variables and States
  const _contaReceberService = new ContaAReceberService();
  const { id } = useParams<any>();
  const [revalidar, setRevalidar] = useState(Date.now());
  const { data: { data: { data: contaAReceberData } = {} } = {} } = useSWR(
    Endpoints.contasAReceber + id + revalidar,
    () => _contaReceberService.obterPorId(id),
    { revalidateOnFocus: false }
  );
  const [parcela, setParcela] = useState(null);

  const [solicitarImpostos, setSolicitarImpostos] = useState({
    solicitar: false,
    regimeEmpresa: RegimesTributariosEnum.SimplesNacional,
  });
  const _empresaService = new EmpresaService();
  const _jobService = new JobService();

  const history = useHistory();
  const [formRef] = Form.useForm();
  const [optionsEmpresa, setOptionsEmpresa] = useState([]);
  const [optionsEmpresaSearch, setOptionsEmpresaSearch] = useState(false);

  const [optionsJob, setOptionsJob] = useState([]);
  const [optionsJobSearch, setOptionsJobSearch] = useState(false);
  const [formularioIniciado, setFormularioIniciado] = useState(false);
  const [formaDePagamento, setFormaDePagamento] = useState(false);
  const [parcelas, setParcelas] = useState(contaAReceberData?.parcelas || []);
  const [calcularParcelas, setCalcularParcelas] = useState(false);
  const [cadastroGrupoFinanceiro, setCadastroGrupoFinanceiro] = useState(false);
  const [parcelaParaPagamento, setParcelaParaPagamento] = useState(null);

  const { options: optsGruposFinanceiros } =
    useGruposFinanceirosHook("receita");
  //#endregion

  //#region Methods

  async function estornarContaAReceberHandler(id: string) {
    try {
      await _contaReceberService.estornar(id);
      message.success("Conta a receber cancelada");
      history.goBack();
    } catch (error) {
      message.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error.message
      );
    }
  }

  async function atualizarNovaContaAReceberHandler() {
    try {
      const contaAReceber = formRef.getFieldValue("contaAReceber");
      const imposto = formRef.getFieldValue("imposto");
      const dto: AtualizarContaAReceberDto = {
        id,
        descricao: contaAReceber?.descricao,
        valor: contaAReceber?.valor,
        tipoNf: contaAReceber?.tipoNf,
        numeroNf: contaAReceber?.numeroNf,
        dataLancamento: new Date(),
        cliente: { id: contaAReceber?.cliente },
        dataVencimento: contaAReceber?.dataVencimento.toString(),
        formaDePagamento: { id: contaAReceber?.formaDePagamento },
        prazoDePagamento: { id: contaAReceber?.prazoDePagamento },
        grupoFinanceiro: { id: contaAReceber?.grupoFinanceiro },
        job: contaAReceber?.job ? { id: contaAReceber?.job } : null,
        empresa: { id: contaAReceber?.empresa },
        imposto: solicitarImpostos.solicitar
          ? {
              id: contaAReceberData?.imposto?.id,
              iss: Number(imposto?.iss),
              ir: Number(imposto?.ir),
              csll: Number(imposto?.csll),
              pis: Number(imposto?.pis),
              cofins: Number(imposto?.cofins),
              impostoRecolhidoPeloPeloPrestador: true,
            }
          : null,
      };

      await _contaReceberService.atualizarContaAReceber(dto, id);
      message.success("Conta a receber atualizada");
      history.goBack();
    } catch (error) {
      if (error?.response?.data?.message && typeof Array) {
        for (const e of error?.response?.data?.message.toString().split(",")) {
          message.error(e);
        }
      } else {
        message.error(error?.message);
      }
    }
  }
  async function estornarPagamentoHandler(parcelaId: string) {
    try {
      await _contaReceberService.estornarPagamento(parcelaId);
      message.success("Pagamento estornado");
      setRevalidar(Date.now());
    } catch (error) {
      message.error(error.response?.data?.message || error.message);
    }
  }
  function iniciarForm(
    setOptionsEmpresa: React.Dispatch<React.SetStateAction<any[]>>,
    contaAReceberData: any,
    setOptionsJob: React.Dispatch<React.SetStateAction<any[]>>,
    formRef
  ) {
    setOptionsEmpresa([
      {
        key: contaAReceberData?.empresa?.id,
        value: contaAReceberData?.empresa?.id,
        label: contaAReceberData?.empresa?.nomeFantasia,
      },
    ]);
    setOptionsJob([
      {
        key: contaAReceberData?.job?.id,
        value: contaAReceberData?.job?.id,
        label: `${identificadorFormatter(
          contaAReceberData?.job?.identificador
        )} - ${contaAReceberData?.job?.nome}`,
      },
    ]);

    setParcelas(contaAReceberData?.parcelas);
    formRef.setFieldsValue({
      contaAReceber: {
        empresa: contaAReceberData?.empresa?.id,
        formaDePagamento: contaAReceberData?.formaDePagamento?.id,
        grupoFinanceiro: contaAReceberData?.grupoFinanceiro?.id,
        job: contaAReceberData?.job?.id,
        valor: contaAReceberData?.valor,
        descricao: contaAReceberData?.descricao,
        observacao: contaAReceberData?.observacao,
        dataVencimento: moment(contaAReceberData?.dataVencimento),
      },
      imposto: {
        iss: contaAReceberData?.imposto?.iss,
        ir: contaAReceberData?.imposto?.ir,
        csll: contaAReceberData?.imposto?.csll,
        pis: contaAReceberData?.imposto?.pis,
        cofins: contaAReceberData?.imposto?.cofins,
      },
    });
    setSolicitarImpostos({
      solicitar: contaAReceberData?.tipoNf === TipoNf.NotaFiscal ? true : false,
      regimeEmpresa: contaAReceberData?.empresa?.regimeTributario,
    });

    setFormularioIniciado(true);
  }

  function EditarParcela() {
    const [parcelaFormRef] = Form.useForm();
    return (
      <Modal
        okText="ATUALIZAR"
        cancelText="CANCELAR"
        visible={parcela}
        width={600}
        bodyStyle={{ padding: 0 }}
        closable={false}
        onOk={async () => {
          try {
            const pItens = parcelas.filter((p) => p.id != parcela.id);
            const p = parcelaFormRef.getFieldValue("parcela");

            pItens.push({ ...p, valor: parcela.valor, id: parcela.id });
            await _contaReceberService.atualizarParcela(id, {
              id: parcela?.id,
              dataVencimento: p?.dataVencimento,
              status: parcela?.status,
            });
            setParcelas(pItens);
            setParcela(null);
            setRevalidar(Date.now());
          } catch (error) {
            if (error?.response?.data?.message && typeof Array) {
              for (const e of error?.response?.data?.message
                .toString()
                .split(",")) {
                message.error(e);
              }
            } else {
              message.error(error?.message);
            }
          }
        }}
        onCancel={() => {
          setParcela(null);
          parcelaFormRef.resetFields();
        }}
      >
        <Card title={`Alterar vencimento - ${parcela?.descricao}`}>
          <Form layout="vertical" form={parcelaFormRef}>
            <Input.Group compact>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Data de vencimento é obrigatório",
                  },
                ]}
                style={{ width: "30%", paddingRight: 5 }}
                name={["parcela", "dataVencimento"]}
                label="Novo vencimento"
                initialValue={moment(parcela?.dataVencimento)}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  disabledDate={(date) =>
                    date.toDate().getDay() === 6 || date.toDate().getDay() === 0
                  }
                  style={{ width: "100%" }}
                  placeholder="Vencimento em"
                />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Descrição é obrigatório",
                  },
                ]}
                initialValue={parcela?.descricao}
                style={{ width: "40%", paddingRight: 5 }}
                name={["parcela", "descricao"]}
                label="Descrição"
              >
                <Input
                  style={{ width: "100%" }}
                  placeholder="Descrição"
                  disabled={true}
                />
              </Form.Item>

              <Form.Item
                style={{ width: "30%", paddingRight: 5 }}
                name={["parcela", "status"]}
                rules={[
                  {
                    required: true,
                    message: "Status é obrigatório",
                  },
                ]}
                initialValue={parcela?.status}
                label="Situação"
              >
                <Select
                  disabled={true}
                  style={{ width: "100%" }}
                  placeholder="Selecione o Status"
                  options={StatusParcelaList}
                />
              </Form.Item>
            </Input.Group>
          </Form>
        </Card>
      </Modal>
    );
  }

  function RegistrarPagamento() {
    const [registrarPagamentoFormRef] = Form.useForm();
    const [optsContasBancarias, setOptsContasBancarias] = useState([]);
    const [loadingOptsContasBancarias, setLoadingOptsContasBancarias] =
      useState(false);
    const _contaBancariaService = new ContaBancariaService();
    return (
      <Modal
        okText="REGISTRAR PAGAMENTO"
        cancelText="CANCELAR"
        visible={parcelaParaPagamento}
        width={800}
        bodyStyle={{ padding: 0 }}
        closable={false}
        onOk={async () => {
          await registrarPagamentoFormRef.validateFields();
          try {
            await atualizarNovaContaAReceberHandler();
            const p = registrarPagamentoFormRef.getFieldValue("parcela");
            const dto: RegistrarPagamentoContaAReceberDto = {
              parcela: { id: parcelaParaPagamento?.id },
              contaBancaria: { id: p?.contaBancaria },
              dataPagamento: p?.dataPagamento?.toISOString(),
              descontoRiscoSacado: p?.descontoRiscoSacado || 0,
            };
            const response = await _contaReceberService.registrarPagamento(
              id,
              dto
            );
            setParcelaParaPagamento(null);
            setRevalidar(Date.now());
          } catch (error) {
            if (error?.response?.data?.message && typeof Array) {
              for (const e of error?.response?.data?.message
                .toString()
                .split(",")) {
                message.error(e);
              }
            } else {
              message.error(error?.message);
            }
          }
        }}
        onCancel={() => {
          setParcelaParaPagamento(null);
          registrarPagamentoFormRef.resetFields();
        }}
      >
        <Card
          title={`Registrar Pagamento de ${parcelaParaPagamento?.descricao}`}
        >
          <Form layout="vertical" form={registrarPagamentoFormRef}>
            <Input.Group compact>
              <Form.Item
                style={{ width: "33%", paddingRight: 5 }}
                name={["parcela", "dataPagamento"]}
                label="Data Pagamento"
                rules={[
                  { required: true, message: "Informe a data de pagamento" },
                ]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  style={{ width: "100%" }}
                  placeholder="Pago em"
                  disabledDate={(date) =>
                    date.toDate().getDay() === 6 || date.toDate().getDay() === 0
                  }
                />
              </Form.Item>
              <Form.Item
                style={{ width: "33%", paddingRight: 5 }}
                rules={[
                  { required: true, message: "Conta bancária é obrigatório" },
                ]}
                name={["parcela", "contaBancaria"]}
                initialValue={parcela?.status}
                label="Conta bancária"
              >
                <Select
                  loading={loadingOptsContasBancarias}
                  style={{ width: "100%" }}
                  onClick={async () => {
                    if (optsContasBancarias?.length === 0) {
                      setLoadingOptsContasBancarias(true);
                      const response = await _contaBancariaService.obterTodas();
                      const opts = response?.data?.data?.map((c) => {
                        return {
                          key: c.id,
                          value: c.id,
                          label: c?.descricao,
                        };
                      });

                      setOptsContasBancarias(opts);
                      setLoadingOptsContasBancarias(false);
                    }
                  }}
                  placeholder="Selecione a conta bancária"
                  options={optsContasBancarias}
                />
              </Form.Item>
              <Form.Item
                label="Desconto risco sacado ?"
                style={{ width: "33%", paddingRight: 5 }}
              >
                <CurrencyInput
                  name="valor"
                  placeholder="Valor"
                  className="ant-input ant-input-sm"
                  decimalScale={2}
                  onValueChange={(value = "0") => {
                    registrarPagamentoFormRef.setFieldsValue({
                      parcela: {
                        descontoRiscoSacado: c.unformat(value, {
                          code: "BRL",
                        }),
                      },
                    });
                  }}
                  intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                />
              </Form.Item>
            </Input.Group>
          </Form>
        </Card>
      </Modal>
    );
  }
  //#endregion

  useEffect(() => {
    formRef.setFieldsValue({
      contaAReceber: {
        dataVencimento: moment(contaAReceberData?.dataVencimento),
      },
    });
    if (!formularioIniciado && contaAReceberData?.id)
      iniciarForm(setOptionsEmpresa, contaAReceberData, setOptionsJob, formRef);
  }, [calcularParcelas, cadastroGrupoFinanceiro, contaAReceberData]);

  return contaAReceberData?.id ? (
    <div>
      <NovaFormaDePagamento
        visible={formaDePagamento}
        setVisible={setFormaDePagamento}
        child={true}
      />
      <EditarParcela />
      <RegistrarPagamento />
      <NovoGrupoFinanceiro
        tipo={"receita"}
        visible={cadastroGrupoFinanceiro}
        setVisible={setCadastroGrupoFinanceiro}
      />
      <PageHeader
        className="site-page-header"
        onBack={() => {
          history.goBack();
        }}
        title={`Conta a receber - ${contaAReceberData?.sequencial}`}
        subTitle="Atualizar conta a receber"
        style={{ marginBottom: 10 }}
      />
      <Card
        title="Informações conta a receber"
        type="inner"
        extra={
          <>
            <Popconfirm
              title="Deseja realmente cancelar a conta a receber ?"
              placement="bottom"
              onConfirm={() => estornarContaAReceberHandler(id)}
              okText="confirmar"
              cancelText="Cancelar"
              disabled={
                contaAReceberData.status === StatusContaAReceber.Cancelado
              }
            >
              <Button
                type="primary"
                icon={<UndoOutlined />}
                className="btn-danger"
                disabled={
                  contaAReceberData.status === StatusContaAReceber.Cancelado
                }
              >
                CANCELAR
              </Button>
            </Popconfirm>
          </>
        }
      >
        <Form
          form={formRef}
          layout="vertical"
          onFinish={() => atualizarNovaContaAReceberHandler()}
        >
          <Row>
            <Col sm={24}>
              <Input.Group compact>
                <Form.Item
                  label="Descrição"
                  name={["contaAReceber", "descricao"]}
                  style={{ width: "66.66%", paddingRight: 5 }}
                  rules={[
                    {
                      required: true,
                      message: "Descrição é obrigatório",
                    },
                  ]}
                >
                  <Input placeholder="Ex: Conta de Água" disabled />
                </Form.Item>
                <Form.Item
                  style={{ width: "18%", paddingRight: 5 }}
                  initialValue={contaAReceberData?.tipoNf}
                  name={["contaAReceber", "tipoNf"]}
                  label="Nota fiscal"
                >
                  <Select
                    placeholder="Selecione o tipo"
                    options={tipoNfList}
                    onSelect={(opt) => {
                      if (opt !== TipoNf.NotaFiscal) {
                        setSolicitarImpostos({
                          solicitar: false,
                          regimeEmpresa: solicitarImpostos.regimeEmpresa,
                        });
                      } else {
                        setSolicitarImpostos({
                          solicitar: true,
                          regimeEmpresa: solicitarImpostos.regimeEmpresa,
                        });
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  style={{ width: "15%" }}
                  initialValue={contaAReceberData?.numeroNf}
                  name={["contaAReceber", "numeroNf"]}
                  label="Número nota fiscal"
                >
                  <Input placeholder="Informe o Número" />
                </Form.Item>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Form.Item
                    label="Job"
                    name={["contaAReceber", "job"]}
                    style={{ width: "33.3%", paddingRight: 5 }}
                  >
                    <Select
                      disabled={true}
                      placeholder="Selecione o Job"
                      showSearch
                      options={optionsJob}
                      loading={optionsJobSearch}
                      filterOption={(input, option) =>
                        option?.label
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onSearch={async (value) => {
                        if (value.length >= 0) {
                          setOptionsJobSearch(true);

                          const result = await _jobService.obterJobSimplificado(
                            value
                          );
                          const opts = result?.data?.data.map((o) => {
                            return {
                              key: o.id,
                              value: o.id,
                              label: `${identificadorFormatter(
                                o?.identificador
                              )} - ${o?.nome}`,
                            };
                          });
                          setOptionsJob(opts);
                          setOptionsJobSearch(false);
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Empresa"
                    rules={[
                      {
                        required: true,
                        message: "Empresa é obrigatório",
                      },
                    ]}
                    name={["contaAReceber", "empresa"]}
                    style={{ width: "33.3%", paddingRight: 5 }}
                  >
                    <Select
                      placeholder="Selecione a empresa"
                      showSearch
                      options={optionsEmpresa}
                      loading={optionsEmpresaSearch}
                      filterOption={(input, option) =>
                        option?.label
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onSearch={async (value) => {
                        if (value.length >= 3) {
                          setOptionsEmpresaSearch(true);
                          const result =
                            await _empresaService.obterEmpresasSimplificado(
                              value
                            );
                          const opts = result?.data?.data.map((o) => {
                            return {
                              key: o.id,
                              value: o.id,
                              label: o.nomeFantasia,
                            };
                          });
                          setOptionsEmpresa(opts);
                          setOptionsEmpresaSearch(false);
                        }
                      }}
                    />
                  </Form.Item>

                  <div
                    style={{ display: "flex", width: "33.3%", paddingRight: 5 }}
                  >
                    <Form.Item
                      label="Grupo Financeiro"
                      style={{ width: "100%" }}
                      name={["contaAReceber", "grupoFinanceiro"]}
                    >
                      <Select
                        placeholder="Selecione o Grupo"
                        options={optsGruposFinanceiros}
                      />
                    </Form.Item>
                    <Button
                      icon={<PlusCircleOutlined />}
                      type="default"
                      onClick={() => setCadastroGrupoFinanceiro(true)}
                      style={{
                        marginLeft: -5,
                        marginTop: 31,
                        borderTopRightRadius: 5,
                        borderEndEndRadius: 5,
                      }}
                    />
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{ display: "flex", width: "33.3%", paddingRight: 5 }}
                  >
                    <Form.Item
                      label="Condição de pagamento"
                      name={["contaAReceber", "condicaoDePagamento"]}
                      style={{ width: "100%" }}
                    >
                      <Popover
                        placement="left"
                        title="Detalhes condição de pagamento"
                        content={() => (
                          <CondicaoDePagamentoPopup
                            data={contaAReceberData?.condicaoDePagamento}
                          />
                        )}
                      >
                        <Button type="link">
                          {contaAReceberData?.condicaoDePagamento?.nome}
                        </Button>
                      </Popover>
                    </Form.Item>
                  </div>
                  <Form.Item
                    rules={[{ required: true, message: "Data de emissão" }]}
                    label="Próximo vencimento em"
                    name={["contaAReceber", "dataVencimento"]}
                    style={{ width: "33.3%", paddingRight: 5 }}
                  >
                    <DatePicker
                      disabled
                      onChange={() => setCalcularParcelas(true)}
                      placeholder="Data Vencimento"
                      format="DD/MM/YYYY"
                      disabledDate={(date) =>
                        date.toDate().getDay() === 6 ||
                        date.toDate().getDay() === 0
                      }
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                  <Form.Item
                    rules={[{ required: true, message: "Informe o valor" }]}
                    label="Valor liquido"
                    style={{ width: "33.3%", paddingRight: 5 }}
                  >
                    <CurrencyInput
                      disabled={true}
                      className="ant-input ant-input-sm"
                      decimalScale={2}
                      onBlur={() => setCalcularParcelas(true)}
                      defaultValue={contaAReceberData?.valor}
                      onValueChange={(value = "0") => {
                        formRef.setFieldsValue({
                          contaAReceber: {
                            valor: c.unformat(value, { code: "BRL" }),
                          },
                        });
                      }}
                      intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                    />
                  </Form.Item>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: solicitarImpostos?.solicitar ? "flex" : "none",
                  }}
                >
                  <Input.Group compact>
                    <Form.Item
                      name={["imposto", "iss"]}
                      label="ISS"
                      required={solicitarImpostos.solicitar}
                      style={{ paddingRight: 5, width: "20%" }}
                    >
                      <Input
                        placeholder="Informe o ISS"
                        type="number"
                        suffix="%"
                      />
                    </Form.Item>
                    <Form.Item
                      name={["imposto", "ir"]}
                      label="IR"
                      required={
                        solicitarImpostos.regimeEmpresa ===
                        RegimesTributariosEnum.LucroPresumido
                      }
                      style={{ paddingRight: 5, width: "20%" }}
                    >
                      <Input
                        placeholder="Informe o IR"
                        type="number"
                        suffix="%"
                        disabled={
                          solicitarImpostos.regimeEmpresa ===
                          RegimesTributariosEnum.SimplesNacional
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      style={{ paddingRight: 5, width: "20%" }}
                      name={["imposto", "csll"]}
                      label="CSLL"
                    >
                      <Input
                        placeholder="Informe o CSLL"
                        type="number"
                        suffix="%"
                        disabled={
                          solicitarImpostos.regimeEmpresa ===
                          RegimesTributariosEnum.SimplesNacional
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      style={{ paddingRight: 5, width: "20%" }}
                      name={["imposto", "pis"]}
                      label="PIS"
                      required={
                        solicitarImpostos.regimeEmpresa ===
                        RegimesTributariosEnum.LucroPresumido
                      }
                    >
                      <Input
                        placeholder="Informe o PIS"
                        type="number"
                        disabled={
                          solicitarImpostos.regimeEmpresa ===
                          RegimesTributariosEnum.SimplesNacional
                        }
                        suffix="%"
                      />
                    </Form.Item>
                    <Form.Item
                      name={["imposto", "cofins"]}
                      label="COFINS"
                      style={{ paddingRight: 5, width: "20%" }}
                      required={
                        solicitarImpostos.regimeEmpresa ===
                        RegimesTributariosEnum.LucroPresumido
                      }
                    >
                      <Input
                        disabled={
                          solicitarImpostos.regimeEmpresa ===
                          RegimesTributariosEnum.SimplesNacional
                        }
                        placeholder="Informe o COFINS"
                        type="number"
                        suffix="%"
                      />
                    </Form.Item>
                  </Input.Group>
                </div>
              </Input.Group>

              <Card type="inner" title="Parcelas" bodyStyle={{ padding: 0 }}>
                <Table
                  size="small"
                  bordered
                  scroll={{ y: 180 }}
                  dataSource={parcelas}
                  columns={[
                    { title: "Parcela", dataIndex: "descricao", width: 180 },

                    {
                      title: "Valor",
                      dataIndex: "valor",
                      width: 80,
                      className: "currency",
                      render: (value, row) =>
                        c.format(value + row?.descontoRiscoSacado, {
                          code: "BRL",
                        }),
                    },
                    {
                      title: "risco sacado ",
                      className: "currency-negative",
                      dataIndex: "descontoRiscoSacado",
                      width: 70,
                      render: (value) => c.format(value, { code: "BRL" }),
                    },
                    {
                      title: "Vencimento",
                      width: 60,
                      dataIndex: "dataVencimento",
                      render: (value) => moment(value).format("DD/MM/YYYY"),
                    },
                    {
                      title: "Situação",
                      dataIndex: "status",
                      width: 60,
                      render: (value) => (
                        <Tag color={StatusParcelaDescricao[value].cor}>
                          {StatusParcelaDescricao[value].label}
                        </Tag>
                      ),
                    },

                    {
                      title: "Ações",
                      render: (item) => (
                        <>
                          <Button
                            type="dashed"
                            size="small"
                            disabled={item?.status === StatusParcela.Paga}
                            style={{ marginRight: 3 }}
                            onClick={() => setParcela(item)}
                            icon={<EditTwoTone />}
                          >
                            Alterar Vencimento
                          </Button>
                          {item?.status !== StatusParcela.Paga && (
                            <Button
                              icon={<CheckCircleTwoTone />}
                              size="small"
                              type="dashed"
                              onClick={() => setParcelaParaPagamento(item)}
                            >
                              Registrar Pagamento
                            </Button>
                          )}
                          {item?.status === StatusParcela.Paga && (
                            <Button
                              icon={<DeleteTwoTone />}
                              size="small"
                              type="dashed"
                              onClick={() => estornarPagamentoHandler(item.id)}
                            >
                              estornar Pagamento
                            </Button>
                          )}
                          {item?.status === StatusParcela.Conciliada && (
                            <Button
                              icon={<DeleteTwoTone />}
                              size="small"
                              type="dashed"
                              disabled
                            >
                              estornar Pagamento
                            </Button>
                          )}
                        </>
                      ),
                      width: 200,
                    },
                  ]}
                  footer={() =>
                    contaAReceberData?.job && (
                      <div
                        style={{
                          justifyContent: "flex-end",
                          display: "flex",
                          width: "100%",
                        }}
                      >
                        <Descriptions bordered size="small">
                          <Descriptions.Item label="impostos municipais (iss)">
                            <span className="currency-negative">
                              {currencyFormatter(
                                contaAReceberData?.descontos,
                                "BRL"
                              )}
                            </span>
                          </Descriptions.Item>
                          <Descriptions.Item label="Impostos federais (IR,CSLL,PIS,COFINS)">
                            <span className="currency-negative">
                              {currencyFormatter(
                                contaAReceberData?.descontosTomador,
                                "BRL"
                              )}
                            </span>
                          </Descriptions.Item>
                        </Descriptions>
                      </div>
                    )
                  }
                />
              </Card>
              <Form.Item
                label="Observações"
                style={{ marginTop: 5 }}
                name={["contaAReceber", "observacao"]}
              >
                <Input.TextArea placeholder="Observações..." rows={3} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={18} />
            <Col span={6} style={{ marginTop: "15px", textAlign: "end" }}>
              <Space>
                <Button
                  type="primary"
                  size="middle"
                  htmlType="submit"
                  disabled={
                    contaAReceberData?.status === StatusContaAReceber.Pago
                  }
                >
                  SALVAR
                </Button>
                <Button
                  disabled={
                    contaAReceberData?.status === StatusContaAReceber.Pago
                  }
                  type="ghost"
                  size="middle"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  CANCELAR
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  ) : (
    <Loading />
  );
}

export const ContaAReceberRoute = {
  id: "conta-a-receber",
  path: "/inicio/financeiro/contas-a-receber/:id",
  sidebar: "Conta A Receber",
  exact: true,
  renderInMenu: false,
  main: () => <ContaAReceber />,
  icon: null,
  permission: ContaAReceberPermissao,
};
