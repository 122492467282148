export enum RegimesTributariosEnum {
  SimplesNacional = 0,
  LucroPresumido = 1,
  LucroReal = 2,
}

export const RegimesTributariosEnumDescricao: {
  [key in RegimesTributariosEnum]: string;
} = {
  [RegimesTributariosEnum.SimplesNacional]: "SIMPLES NACIONAL",
  [RegimesTributariosEnum.LucroPresumido]: "LUCRO PRESUMIDO",
  [RegimesTributariosEnum.LucroReal]: "LUCRO REAL",
};

function enumToArray(enumerator: any) {
  const list = [];
  for (
    let index = 0;
    index <
    Object.values(enumerator).filter((value) => !isNaN(Number(value))).length;
    index++
  ) {
    const key = Object.keys(enumerator)[index];
    const value = RegimesTributariosEnumDescricao[index];
    list.push({
      key: Number(key),
      value: Number(key),
      label: value.toUpperCase(),
    });
  }
  return list;
}

export const regimesTributariosEnumList = enumToArray(RegimesTributariosEnum);
