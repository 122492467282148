export class Endpoints {
  static login: string = "/auth";
  static dashboard: string = "/dashboard";
  static importarExtrato: string = "/conciliacao-bancaria/importar-extrato";
  static obterTransacoesPendentes: string =
    "/conciliacao-bancaria/transacoes-pendentes";
  static efetuarConciliacao: string =
    "/conciliacao-bancaria/efetuar-conciliacao";
  static obterSugestoesDeConciliacao: string =
    "/conciliacao-bancaria/transacoes-pendentes/sugestoes-de-conciliacao";
  static recuperarSenha: string = "/auth/recuperar-senha";
  static refresh: string = "/auth/refresh";
  static clientes: string = "/clientes";
  static validarCnpjCadastradoCliente: string = "/clientes/validar-cnpj";
  static validarCnpjCadastradoFornecedor: string = "/fornecedores/validar-cnpj";
  static validarCnpjCadastradoEmpresa: string = "/empresas/validar-cnpj";
  static clientesSimplificado: string = "/clientes/simplificado";
  static jobs: string = "/jobs";
  static verificarFaturamentoJob: string = "/verificar-faturamento";
  static jobsSimplificado: string = "/jobs/simplificado";
  static empresas: string = "/empresas";
  static empresasSimplificado: string = "/empresas/simplificado";
  static bancos: string = "/contas-bancarias/bancos";
  static contasBancarias: string = "/contas-bancarias";
  static colaboradores: string = "/colaboradores";
  static contratos: string = "/colaboradores/contratos";
  static atualizarSenha: string = "/atualizar-senha";
  static colaboradoresInfo: string = "/colaboradores/info";
  static setores: string = "/colaboradores/setores";
  static perfis: string = "/perfis";
  static colaboradoresSimplificado: string = "/colaboradores/simplificado";
  static fornecedores: string = "/fornecedores";
  static reenviarConfirmacaoCadastroFornecedor: string =
    "/reenviar-confirmacao";
  static inativarCadastro: string = "/inativar";
  static gruposFinanceiros: string = "/grupos-financeiros";
  static fornecedoresConfirmacaoCadastro: string =
    "/fornecedores/confirmacao-cadastro";
  static fornecedoresPreCadastro: string = "/fornecedores/pre-cadastro";
  static fornecedoresSimplificado: string = "/fornecedores/simplificado";
  static contasAPagar: string = "/contas-a-pagar";
  static estornar: string = "/estornar";
  static gerarParcelamentoContaAPagar: string =
    "contas-a-pagar/gerar-parcelamento";
  static gerarParcelamentoContaAReceber: string =
    "contas-a-receber/gerar-parcelamento";
  static contasAPagarRegistrarPagamento: string =
    "/contas-a-pagar/registrar-pagamento";
  static contasAPagarEstornarPagamento: string =
    "/contas-a-pagar/estornar-pagamento";
  static contasAReceberEstornarPagamento: string =
    "/contas-a-receber/estornar-pagamento";
  static contasAReceberRegistrarPagamento: string =
    "/contas-a-receber/registrar-pagamento";
  static atualizarParcela: string = "/atualizar-parcela";
  static contasAReceber: string = "/contas-a-receber";
  static fluxoDeCaixaTransacoes: string = "/fluxo-de-caixa/transacoes";
  static orcamentoJob: string = "/orcamento";
  static formasDePagamento: string = "/formas-de-pagamento";
  static prazosDePagamento: string = "/prazos-de-pagamento";
  static formasDePagamentoSimplificado: string =
    "/formas-de-pagamento/simplificado";
  static prazosDePagamentoSimplificado: string =
    "/prazos-de-pagamento/simplificado";
  static requisicoesDeCompra: string = "/requisicoes-de-compra";
  static aprovarRequisicao: string = "/aprovar";
  static reprovarRequisicao: string = "/reprovar";
  static pedidosDeCompra: string = "/pedidos-de-compra";
  static confirmarRecebimentoPedidosDeCompra: string = "/confirmar-recebimento";
  static reenviarPedido: string = "/reenviar";
  static reembolso: string = "/reembolsos";
  static cancelar: string = "/cancelar";
  static arquivosReembolso: string = "/arquivos/reembolsos";
  static arquivosDashboard: string = "/arquivos/dashboard";
  static arquivosPedidoDeCompra: string = "/arquivos/pedidos-de-compra";
  static arquivosHolerites: string = "/arquivos/holerites";
  static folhaDePagamento: string = "/folha-de-pagamento";
}
