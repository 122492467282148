import {
  Alert,
  Button,
  Card,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  PageHeader,
  Row,
  Select,
  Space,
  Table,
  Tag,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { TipoNf, tipoNfList } from "../../../core/enums/tipo-nota-fiscal.enum";

import { ContaAReceberService } from "../../../services/conta-a-receber/conta-a-receber.service";
import CurrencyInput from "react-currency-input-field";
import { NovaContaAReceberDto } from "../../../services/conta-a-receber/dto/nova-conta-a-receber.dto";
import NovoGrupoFinanceiro from "../grupos-financeiros/novo-grupo-financeiro.page";
import { NumeroDeParcelasModal } from "../../../components/numero-parcelas.modal.component";
import { PlusCircleOutlined } from "@ant-design/icons";
import { RegimesTributariosEnum } from "../../../core/enums/regime-tributario.enum";
import { StatusParcelaDescricao } from "../../../core/enums/status-parcela.enum";
import c from "currency-formatter";
import { identificadorFormatter } from "../../../core/utils/formatters/job.formatter";
import moment from "moment";
import useBuscarEmpresaHook from "../../../components/hooks/buscar-empresa.hook";
import useBuscarFormaDePagamentoHook from "../../../components/hooks/buscar-forma-de-pagamento.hook";
import useBuscarJobHook from "../../../components/hooks/buscar-job.hook";
import useBuscarPrazoDePagamentoHook from "../../../components/hooks/buscar-prazo-de-pagamento.hook";
import useGruposFinanceirosHook from "../../../components/hooks/buscar-grupo-financeiro.hook";

export default function NovaContaAReceber({
  visible,
  setVisible,
  job = null,
  setJob = null,
}) {
  //#region Variables and States

  const _contaAReceberService = new ContaAReceberService();

  const [solicitarImpostos, setSolicitarImpostos] = useState({
    solicitar: true,
    regimeEmpresa: RegimesTributariosEnum.SimplesNacional,
  });
  const [btnLoading, setBtnLoading] = useState(false);
  const [formRef] = Form.useForm();
  const [jobAtual, setJobAtual] = useState(job?.id);

  const [parcelas, setParcelas] = useState([]);
  const [calcularParcelas, setCalcularParcelas] = useState(Date.now());
  const [cadastroGrupoFinanceiro, setCadastroGrupoFinanceiro] = useState(false);
  const [modalNumeroDeParcelas, setModalNumeroDeParcelas] = useState(false);
  const {
    options: optionsFormaDePagamento,
    filterOptions: filterOptsFormaDePagamento,
    loading: optionsFormaDePagamentoSearch,
    buscarFormaDePagamento,
  } = useBuscarFormaDePagamentoHook();
  const { options: optsGruposFinanceiros } =
    useGruposFinanceirosHook("receita");
  const {
    buscarEmpresa,
    options: optionsEmpresa,
    setOptions: setOptionsEmpresa,
    loading: optionsEmpresaSearch,
    filterOptions: filterOptionsEmpresa,
  } = useBuscarEmpresaHook();
  const {
    buscarJob,
    options: optionsJob,
    setOptions: setOptionsJob,
    filterOption: filterOptionsJob,
    loading: loadingJobs,
  } = useBuscarJobHook();

  const {
    options: optionsPrazoDePagamento,
    filterOptions: filterOptsPrazoDePagamento,
    loading: optionsPrazoDePagamentoSearch,
    buscarPrazoDePagamento,
  } = useBuscarPrazoDePagamentoHook();
  //#endregion

  //#region Methods

  async function cadastrarNovaContaAReceberHandler() {
    try {
      setBtnLoading(true);
      const { contaAReceber, imposto } = formRef.getFieldsValue([
        "contaAReceber",
        "imposto",
      ]);
      const dto: NovaContaAReceberDto = {
        descricao: contaAReceber?.descricao,
        tipoNf: contaAReceber?.tipoNf,
        numeroNf: contaAReceber?.numeroNf,
        valor: contaAReceber?.valor,
        dataLancamento: new Date(),
        dataVencimento: contaAReceber?.dataVencimento.toString(),
        formaDePagamento: { id: contaAReceber?.formaDePagamento },
        prazoDePagamento: {
          id: contaAReceber?.prazoDePagamento,
          numeroDeParcelas: contaAReceber?.numeroDeParcelas,
          diasEntreParcelas: contaAReceber?.diasEntreParcelas,
          diasParaPrimeiraParcela: contaAReceber?.diasParaPrimeiraParcela,
        },
        grupoFinanceiro: { id: contaAReceber?.grupoFinanceiro },
        cliente: contaAReceber?.cliente ? { id: contaAReceber?.cliente } : null,
        empresa: { id: contaAReceber?.empresa },
        job: contaAReceber?.job ? { id: contaAReceber?.job } : null,
        imposto: solicitarImpostos.solicitar
          ? {
              iss: Number(imposto?.iss),
              ir: Number(imposto?.ir),
              csll: Number(imposto?.csll),
              pis: Number(imposto?.pis),
              cofins: Number(imposto?.cofins),
              impostoRecolhidoPeloPeloPrestador: true,
            }
          : null,
      };

      await _contaAReceberService.adicionarContaAReceber(dto);
      message.success("Conta a receber adicionada");
      formRef.resetFields();
      if (job) setJob(null);
      setVisible(false);
      setBtnLoading(false);
    } catch (error) {
      setBtnLoading(false);
      if (error?.response?.data?.message && typeof Array) {
        for (const e of error?.response?.data?.message.toString().split(",")) {
          message.error(e);
        }
      } else {
        message.error(error?.message);
      }
    }
  }

  //#endregion

  useEffect(() => {
    const {
      dataVencimento = null,
      prazoDePagamento = null,
      valor = null,
      descricao = null,
      numeroDeParcelas = 0,
      diasParaPrimeiraParcela,
      diasEntreParcelas,
      job,
    } = formRef.getFieldValue(["contaAReceber"]) || {};
    if (dataVencimento && prazoDePagamento && valor && descricao) {
      _contaAReceberService
        .gerarParcelamento({
          dataPrimeiroVencimento: dataVencimento,
          valor,
          descricao,
          prazo: {
            id: prazoDePagamento,
            numeroDeParcelas,
            diasParaPrimeiraParcela,
            diasEntreParcelas,
          },
          jobId: job,
        })
        .then(({ data: { data: pItens } }) => setParcelas(pItens));
    }
    if (job?.id != jobAtual && visible) {
      formRef.setFieldsValue({
        contaAReceber: {
          descricao: `FATURA JOB ${identificadorFormatter(
            job?.identificador
          )} - ${job?.nome}`,
          empresa: job?.empresa?.id,
          job: job?.id,
        },
      });
      setJobAtual(job?.id);
    }
  }, [calcularParcelas, cadastroGrupoFinanceiro, job, modalNumeroDeParcelas]);

  return visible ? (
    <Drawer
      visible={visible}
      width={1200}
      bodyStyle={{ padding: 5 }}
      closable={false}
      onClose={() => {
        setVisible(false);
        if (job) setJob(null);
      }}
    >
      <NumeroDeParcelasModal
        contexto={"contaAReceber"}
        visible={modalNumeroDeParcelas}
        setVisible={setModalNumeroDeParcelas}
        formRef={formRef}
      />
      <NovoGrupoFinanceiro
        tipo={"receita"}
        visible={cadastroGrupoFinanceiro}
        setVisible={setCadastroGrupoFinanceiro}
      />
      <PageHeader
        className="site-page-header"
        onBack={() => {
          setVisible(false);
        }}
        title={`Nova conta a receber`}
        subTitle="Incluir uma conta a receber"
        style={{ marginBottom: 10 }}
      />
      <Card title="Informações conta a receber" type="inner">
        <Form
          form={formRef}
          layout="vertical"
          onFinish={() => cadastrarNovaContaAReceberHandler()}
        >
          <Row>
            <Col sm={24}>
              <Input.Group compact>
                <Form.Item
                  label="Job"
                  initialValue={job?.id}
                  name={["contaAReceber", "job"]}
                  style={{ width: "25%", paddingRight: 5 }}
                >
                  <Select
                    disabled={job !== null}
                    placeholder="Selecione o Job"
                    showSearch
                    onSelect={(_, opt) => {
                      setSolicitarImpostos({
                        solicitar: true,
                        regimeEmpresa: opt?.empresa?.regimeTributario,
                      });

                      formRef.setFieldsValue({
                        contaAReceber: {
                          descricao: `FATURA JOB ${opt.label}`,
                          empresa: opt?.empresa?.id,
                        },
                      });
                    }}
                    options={optionsJob}
                    loading={loadingJobs}
                    filterOption={filterOptionsJob}
                    onSearch={buscarJob}
                  />
                </Form.Item>
                <Form.Item
                  label="Empresa"
                  initialValue={job?.empresa?.id}
                  rules={[
                    {
                      required: true,
                      message: "Empresa é obrigatório",
                    },
                  ]}
                  name={["contaAReceber", "empresa"]}
                  style={{ width: "30%", paddingRight: 5 }}
                >
                  <Select
                    placeholder="Selecione a empresa"
                    showSearch
                    options={optionsEmpresa}
                    onSelect={(value, opt) => {
                      setSolicitarImpostos({
                        solicitar: true,
                        regimeEmpresa: opt?.regimeTributario,
                      });
                    }}
                    loading={optionsEmpresaSearch}
                    filterOption={filterOptionsEmpresa}
                    onSearch={buscarEmpresa}
                  />
                </Form.Item>

                <Form.Item
                  style={{ width: "20%", paddingRight: 5 }}
                  name={["contaAReceber", "tipoNf"]}
                  label="Nota fiscal"
                >
                  <Select
                    placeholder="Selecione o tipo"
                    options={tipoNfList}
                    onSelect={(opt) => {
                      if (opt !== TipoNf.NotaFiscal) {
                        setSolicitarImpostos({
                          solicitar: false,
                          regimeEmpresa: solicitarImpostos.regimeEmpresa,
                        });
                      } else {
                        setSolicitarImpostos({
                          solicitar: true,
                          regimeEmpresa: solicitarImpostos.regimeEmpresa,
                        });
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  style={{ width: "25%" }}
                  name={["contaAReceber", "numeroNf"]}
                  label="Número nota fiscal"
                >
                  <Input placeholder="Informe o Número" />
                </Form.Item>

                <div
                  style={{
                    width: "100%",
                    display: solicitarImpostos?.solicitar ? "flex" : "none",
                  }}
                >
                  <Alert
                    message="informe os impostos:"
                    type="warning"
                    style={{
                      width: "18%",
                      height: "24px",
                      marginTop: 32,
                      marginRight: 5,
                    }}
                  />
                  <Input.Group compact>
                    <Form.Item
                      name={["imposto", "iss"]}
                      label="ISS"
                      required={solicitarImpostos.solicitar}
                      rules={[{ min: 0 }]}
                      style={{ paddingRight: 5, width: "20%" }}
                    >
                      <Input
                        placeholder="Informe o ISS"
                        type="number"
                        suffix="%"
                      />
                    </Form.Item>
                    <Form.Item
                      name={["imposto", "ir"]}
                      label="IR"
                      required={
                        solicitarImpostos.regimeEmpresa ===
                        RegimesTributariosEnum.LucroPresumido
                      }
                      style={{ paddingRight: 5, width: "20%" }}
                      initialValue={1.5}
                    >
                      <Input
                        placeholder="Informe o IR"
                        type="number"
                        suffix="%"
                        disabled={
                          solicitarImpostos.regimeEmpresa ===
                          RegimesTributariosEnum.SimplesNacional
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      style={{ paddingRight: 5, width: "20%" }}
                      name={["imposto", "csll"]}
                      label="CSLL"
                      initialValue={1}
                    >
                      <Input
                        placeholder="Informe o CSLL"
                        type="number"
                        suffix="%"
                        disabled={
                          solicitarImpostos.regimeEmpresa ===
                          RegimesTributariosEnum.SimplesNacional
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      style={{ paddingRight: 5, width: "20%" }}
                      name={["imposto", "pis"]}
                      label="PIS"
                      required={
                        solicitarImpostos.regimeEmpresa ===
                        RegimesTributariosEnum.LucroPresumido
                      }
                      initialValue={0.65}
                    >
                      <Input
                        placeholder="Informe o PIS"
                        type="number"
                        disabled={
                          solicitarImpostos.regimeEmpresa ===
                          RegimesTributariosEnum.SimplesNacional
                        }
                        suffix="%"
                      />
                    </Form.Item>
                    <Form.Item
                      name={["imposto", "cofins"]}
                      label="COFINS"
                      style={{ paddingRight: 5, width: "20%" }}
                      required={
                        solicitarImpostos.regimeEmpresa ===
                        RegimesTributariosEnum.LucroPresumido
                      }
                      initialValue={3}
                    >
                      <Input
                        disabled={
                          solicitarImpostos.regimeEmpresa ===
                          RegimesTributariosEnum.SimplesNacional
                        }
                        placeholder="Informe o COFINS"
                        type="number"
                        suffix="%"
                      />
                    </Form.Item>
                  </Input.Group>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 5,
                  }}
                >
                  <Form.Item
                    label="Descrição"
                    initialValue={job ? `Fatura ${job?.nome}` : null}
                    name={["contaAReceber", "descricao"]}
                    style={{ width: "66.66%", paddingRight: 5 }}
                    rules={[
                      {
                        required: true,
                        message: "Descrição é obrigatório",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Ex: Prestação de serviço"
                      onBlur={() => setCalcularParcelas(Date.now())}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Grupo Financeiro"
                    style={{ width: "40%" }}
                    rules={[
                      {
                        required: true,
                        message: "Selecione o grupo financeiro",
                      },
                    ]}
                    name={["contaAReceber", "grupoFinanceiro"]}
                  >
                    <Select
                      placeholder="Selecione o Grupo"
                      options={optsGruposFinanceiros}
                    />
                  </Form.Item>
                  <Button
                    icon={<PlusCircleOutlined />}
                    type="default"
                    onClick={() => setCadastroGrupoFinanceiro(true)}
                    style={{
                      marginLeft: -5,
                      marginTop: 32,
                      borderTopRightRadius: 5,
                      borderEndEndRadius: 5,
                    }}
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{ display: "flex", width: "25%", paddingRight: 5 }}
                  >
                    <Form.Item
                      label="Forma de Pagamento"
                      rules={[
                        {
                          required: true,
                          message: "Forma de Pagamento é obrigatório",
                        },
                      ]}
                      name={["contaAReceber", "formaDePagamento"]}
                      style={{ width: "100%" }}
                    >
                      <Select
                        placeholder="Selecione a forma de pagamento"
                        showSearch
                        options={optionsFormaDePagamento}
                        loading={optionsFormaDePagamentoSearch}
                        filterOption={filterOptsFormaDePagamento}
                        onSearch={buscarFormaDePagamento}
                      />
                    </Form.Item>
                  </div>
                  <div
                    style={{ display: "flex", width: "25%", paddingRight: 5 }}
                  >
                    <Form.Item
                      label="Prazo de Pagamento"
                      rules={[
                        {
                          required: true,
                          message: "Forma de Pagamento é obrigatório",
                        },
                      ]}
                      name={["contaAReceber", "prazoDePagamento"]}
                      style={{ width: "100%" }}
                    >
                      <Select
                        placeholder="Selecione o prazo de pagamento"
                        showSearch
                        onSelect={(value, opt: any) => {
                          if (opt?.solicitarParcelamento) {
                            setModalNumeroDeParcelas(true);
                          }
                        }}
                        onChange={() => setCalcularParcelas(Date.now())}
                        options={optionsPrazoDePagamento}
                        loading={optionsPrazoDePagamentoSearch}
                        filterOption={filterOptsPrazoDePagamento}
                        onSearch={buscarPrazoDePagamento}
                      />
                    </Form.Item>
                  </div>
                  <Form.Item
                    rules={[{ required: true, message: "Data de emissão" }]}
                    label="Emissão"
                    name={["contaAReceber", "dataVencimento"]}
                    style={{ width: "33.3%", paddingRight: 5 }}
                  >
                    <DatePicker
                      onChange={() => setCalcularParcelas(Date.now())}
                      placeholder="Data de emissão"
                      format="DD/MM/YYYY"
                      disabledDate={(date) =>
                        date.toDate().getDay() === 6 ||
                        date.toDate().getDay() === 0
                      }
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                  <Form.Item
                    rules={[{ required: true, message: "Informe o valor" }]}
                    label="Valor"
                    style={{ width: "33.3%", paddingRight: 5 }}
                  >
                    <CurrencyInput
                      className="ant-input ant-input-sm"
                      placeholder="Valor"
                      decimalScale={2}
                      onBlur={() => setCalcularParcelas(Date.now())}
                      defaultValue={job?.orcamento?.orcamentoEvento}
                      onValueChange={(value = "0") => {
                        formRef.setFieldsValue({
                          contaAReceber: {
                            valor: c.unformat(value, { code: "BRL" }),
                          },
                        });
                      }}
                      intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                    />
                  </Form.Item>
                </div>
              </Input.Group>
              <Card type="inner" title="Parcelas" bodyStyle={{ padding: 0 }}>
                <Table
                  size="small"
                  bordered
                  scroll={{ y: 180 }}
                  dataSource={parcelas}
                  columns={[
                    { title: "Parcela", dataIndex: "descricao", width: 250 },
                    {
                      title: "Valor",
                      dataIndex: "valor",
                      width: 120,
                      className: "currency",
                      render: (value) => c.format(value, { code: "BRL" }),
                    },
                    {
                      title: "Vencimento",
                      width: 120,
                      dataIndex: "dataVencimento",
                      render: (value) => moment(value).format("DD/MM/YYYY"),
                    },
                    {
                      title: "Situação",
                      dataIndex: "status",
                      width: 120,
                      render: (value) => (
                        <Tag color={StatusParcelaDescricao[value].cor}>
                          {StatusParcelaDescricao[value].label}
                        </Tag>
                      ),
                    },
                    {
                      title: "Ação",
                      render: (item) => (
                        <Button disabled={true} type="link" size="small">
                          Editar
                        </Button>
                      ),
                      width: 100,
                    },
                  ]}
                />
              </Card>
              <Form.Item
                label="Observações"
                style={{ marginTop: 5 }}
                name={["contaAReceber", "observacao"]}
              >
                <Input.TextArea placeholder="Observações..." rows={3} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={18} />
            <Col span={6} style={{ marginTop: "15px", textAlign: "end" }}>
              <Space>
                <Button
                  type="primary"
                  size="middle"
                  htmlType="submit"
                  loading={btnLoading}
                >
                  SALVAR
                </Button>
                <Button
                  type="ghost"
                  size="middle"
                  onClick={() => {
                    formRef.resetFields();
                    setVisible(false);
                  }}
                >
                  CANCELAR
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Card>
    </Drawer>
  ) : null;
}
