import {
  Button,
  Card,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  PageHeader,
  Row,
  Select,
  Space,
  message,
} from "antd";
import React, { useState } from "react";

import { ColaboradorService } from "../../../services/colaborador/colaborador.service";
import { NovoColaboradorDto } from "../../../services/colaborador/dto/novo-colaborador.dto";
import { cepFormatter } from "../../../core/utils/formatters/cep.formatter";
import { cpfFormatter } from "../../../core/utils/formatters/cpj.formatter";
import { removeMask } from "../../../core/utils/formatters/removeMask.formatter";
import { rgFormatter } from "../../../core/utils/formatters/rg.formatter";
import { telefoneCelularFormatter } from "../../../core/utils/formatters/telefoneCelular.formatter";

const cep = require("cep-promise");

export default function NovoColaborador({ visible, setVisible }) {
  const [formRef] = Form.useForm();
  const [optionsSetores, setOptionsSetores] = useState([]);
  const [optionsSetoresLoading, setOptionsSetoresLoading] = useState(false);
  const [optionsPerfis, setOptionsPerfis] = useState([]);
  const [optionsPerfisLoading, setOptionsPerfisLoading] = useState(false);
  const _colaboradorService = new ColaboradorService();

  async function adicionarColaboradorHandler() {
    try {
      const { colaborador, endereco } = formRef.getFieldsValue([
        "colaborador",
        "endereco",
      ]);
      const dto: NovoColaboradorDto = {
        ...colaborador,
        perfil: { id: colaborador?.perfil },
        setor: { id: colaborador?.setor },
        cpf: removeMask(colaborador?.cpf),
        rg: removeMask(colaborador?.rg),
        telefoneCelular: removeMask(colaborador?.telefoneCelular),
        endereco: { ...endereco, cep: removeMask(endereco?.cep) },
      };
      await _colaboradorService.adicionar(dto);
      message.success("Colaborador Cadastrado com sucesso!");
      setVisible(false);
      formRef.resetFields();
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  }

  return (
    <Drawer
      visible={visible}
      width={1100}
      bodyStyle={{ padding: 5 }}
      closable={false}
    >
      <PageHeader
        onBack={() => setVisible(false)}
        className="site-page-header"
        title={`Novo colaborador`}
        subTitle="Incluir colaborador"
        style={{ marginBottom: 10 }}
      />
      <Form
        form={formRef}
        layout="vertical"
        onFinish={() => adicionarColaboradorHandler()}
      >
        <Card title="Informações Colaborador" type="inner">
          <Input.Group compact>
            <Form.Item
              label="Nome Completo"
              name={["colaborador", "nome"]}
              style={{ width: "50%" }}
              rules={[
                {
                  required: true,
                  message: "Nome é obrigatório",
                },
              ]}
            >
              <Input placeholder="Ex: João da Silva" />
            </Form.Item>
            <Form.Item
              label="CPF"
              name={["colaborador", "cpf"]}
              rules={[
                {
                  required: true,
                  message: "CPF é obrigatório",
                },
              ]}
              style={{ width: "25%", paddingLeft: 5 }}
            >
              <Input
                placeholder="Ex: 000.000.000-00"
                onKeyUp={() => {
                  formRef.setFieldsValue({
                    colaborador: {
                      cpf: cpfFormatter(
                        formRef.getFieldValue(["colaborador", "cpf"])
                      ),
                    },
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="RG"
              name={["colaborador", "rg"]}
              style={{ width: "25%", paddingLeft: 5 }}
              rules={[
                {
                  required: true,
                  message: "RG é obrigatório",
                },
              ]}
            >
              <Input
                placeholder="Ex: 00.000.000-0"
                onKeyUp={() => {
                  formRef.setFieldsValue({
                    colaborador: {
                      rg: rgFormatter(
                        formRef.getFieldValue(["colaborador", "rg"])
                      ),
                    },
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Telefone Celular"
              rules={[
                {
                  required: true,
                  message: "Celular é obrigatório",
                },
              ]}
              name={["colaborador", "telefoneCelular"]}
              style={{ width: "25%" }}
            >
              <Input
                placeholder="Ex: (41) 99999-9999"
                onKeyUp={() => {
                  formRef.setFieldsValue({
                    colaborador: {
                      telefoneCelular: telefoneCelularFormatter(
                        formRef.getFieldValue([
                          "colaborador",
                          "telefoneCelular",
                        ])
                      ),
                    },
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="E-mail"
              rules={[
                {
                  required: true,
                  message: "E-mail é obrigatório",
                },
              ]}
              name={["colaborador", "email"]}
              style={{ width: "25%", paddingLeft: 5 }}
            >
              <Input placeholder="joao.s@tracao.com.br" type="email" />
            </Form.Item>
            <Form.Item
              label="Usuário sistema"
              name={["colaborador", "usuario"]}
              rules={[
                {
                  required: true,
                  message: "Usuário é obrigatório",
                },
              ]}
              style={{ width: "25%", paddingLeft: 5 }}
            >
              <Input placeholder="Ex: joao.s" />
            </Form.Item>
            <Form.Item
              label="Senha"
              name={["colaborador", "senha"]}
              rules={[
                {
                  required: true,
                  message: "Senha é obrigatório",
                },
              ]}
              style={{ width: "25%", paddingLeft: 5 }}
            >
              <Input placeholder="Informe uma nova senha" type="password" />
            </Form.Item>
            <Form.Item
              label="Setor"
              name={["colaborador", "setor"]}
              rules={[
                {
                  required: true,
                  message: "Setor é obrigatório",
                },
              ]}
              style={{ width: "33.33%", paddingLeft: 5 }}
            >
              <Select
                placeholder="Selecione o setor"
                options={optionsSetores}
                loading={optionsSetoresLoading}
                onFocus={async () => {
                  if (optionsSetores.length >= 0) {
                    setOptionsSetoresLoading(true);
                    const result = await _colaboradorService.obterSetores();
                    const setores = result?.data?.data?.map((s) => {
                      return { key: s.id, label: s.nome, value: s.id };
                    });
                    setOptionsSetores(setores);
                    setOptionsSetoresLoading(false);
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              label="Perfil"
              name={["colaborador", "perfil"]}
              rules={[
                {
                  required: true,
                  message: "Pefil é obrigatório",
                },
              ]}
              style={{ width: "33.33%", paddingLeft: 5 }}
            >
              <Select
                placeholder="Selecione o perfil"
                loading={optionsPerfisLoading}
                options={optionsPerfis}
                onFocus={async () => {
                  if (optionsPerfis.length <= 0) {
                    setOptionsPerfisLoading(true);

                    const result = await _colaboradorService.obterPerfis();

                    const perfis = result?.data?.data?.map((p) => {
                      return { key: p.id, label: p.nome, value: p.id };
                    });

                    setOptionsPerfis(perfis);
                    setOptionsPerfisLoading(false);
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              label="Data de admissão"
              rules={[
                {
                  required: true,
                  message: "Data de admissão é obrigatório",
                },
              ]}
              name={["colaborador", "dataDeAdmissao"]}
              style={{ width: "33.33%", paddingLeft: 5 }}
            >
              <DatePicker
                format={"DD/MM/yyyy"}
                placeholder="Selecione a data de admissão"
                disabledDate={(date) =>
                  date.toDate().getDay() === 6 || date.toDate().getDay() === 0
                }
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Input.Group>
        </Card>
        <Card type="inner" title="Endereço">
          <Input.Group compact>
            <Form.Item
              name={["endereco", "cep"]}
              label="CEP"
              style={{ width: "12%", paddingRight: "5px" }}
              rules={[
                {
                  required: true,
                  message: "Informe o CEP",
                  max: 9,
                  min: 9,
                  pattern: /^\d{5}-\d{3}$/g,
                },
              ]}
            >
              <Input
                placeholder="00000-000"
                maxLength={9}
                onKeyUp={() => {
                  formRef.setFieldsValue({
                    cep: cepFormatter(
                      formRef.getFieldValue(["endereco", "cep"])
                    ),
                  });
                }}
                onChange={(event) => {
                  const cepValue = event.target.value;
                  formRef.setFieldsValue({
                    endereco: {
                      cep: cepFormatter(event.target.value),
                    },
                  });
                  if (cepValue.replace("-", "").length >= 8) {
                    cep(cepValue).then((response) => {
                      formRef.setFieldsValue({
                        endereco: {
                          estado: response.state,
                          logradouro: response.street,
                          bairro: response.neighborhood,
                          cidade: response.city,
                        },
                      });
                    });
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              name={["endereco", "logradouro"]}
              label="Rua"
              style={{ width: "38%", paddingRight: "5px" }}
              rules={[{ required: true, message: "Informe a Rua" }]}
            >
              <Input placeholder="Rua" />
            </Form.Item>
            <Form.Item
              name={["endereco", "numero"]}
              label="Número"
              style={{ width: "10%", paddingRight: 5 }}
              rules={[{ required: true, message: "Informe o número" }]}
            >
              <Input placeholder="Número" type="number" />
            </Form.Item>

            <Form.Item
              name={["endereco", "bairro"]}
              label="Bairro"
              rules={[{ required: true, message: "Informe o bairro" }]}
              style={{ width: "20%", paddingRight: "5px" }}
            >
              <Input placeholder="Bairro" />
            </Form.Item>
            <Form.Item
              name={["endereco", "cidade"]}
              label="Cidade"
              rules={[{ required: true, message: "Informe a cidade" }]}
              style={{ width: "12%", paddingRight: "5px" }}
            >
              <Input placeholder="Cidade" />
            </Form.Item>
            <Form.Item
              name={["endereco", "estado"]}
              label="Estado"
              rules={[{ required: true, message: "Informe o estado" }]}
              style={{ width: "8%" }}
            >
              <Input placeholder="Estado" />
            </Form.Item>
            <Form.Item
              name={["endereco", "complemento"]}
              label="Complemento"
              style={{ width: "100%" }}
            >
              <Input placeholder="Complemento" />
            </Form.Item>
            <Form.Item name={["endereco", "pais"]} initialValue="BR" />
          </Input.Group>
        </Card>
        <Row>
          <Col span={18} />
          <Col span={6} style={{ marginTop: "15px", textAlign: "end" }}>
            <Space>
              <Button type="primary" size="middle" htmlType="submit">
                SALVAR
              </Button>
              <Button
                type="ghost"
                size="middle"
                onClick={() => {
                  setVisible(false);
                  formRef.resetFields();
                }}
              >
                CANCELAR
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}
